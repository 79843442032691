import React from 'react';
import CreditCardAgreementPdf from '../../../assets/credit_card.pdf';

const AccountPaymentInformation = (
  <>
    <div className="px-lg-5 px-3 py-3">
      <p>To determine if your telephone line qualifies as a local number, the area code and prefix of your telephone line has to be within the local calling area of the respective jail address. </p>
      <p>When you make a payment on a telephone account we must verify your current address and billing information prior to activating the account. Call 1-800-896-3201 to verify.</p>
      <p>Some credit cards will require additional verification. If this verification is requested, you must file a Credit Cardholder Letter of Acceptance or you can email us a picture of your Picture ID shown with your credit card.</p>
      <a className="btn btn-dark btn-block" href={CreditCardAgreementPdf} target="_blank">
        Download Credit Cardholder Letter of Acceptance
      </a>
    </div>
    <div className="bg-light rounded m-lg-4 m-2 p-lg-4 p-2">
      <p>We accept the following methods of payment:</p>
      <ul>
        <li>Cash/Check/Money Order mailed in or dropped off</li>
        <li>Visa/Mastercard/Discover/American Express online, by phone, or regular mail</li>
        <li>Moneygram Express Payment</li>
        <li>Debit card (with Visa/Mastercard logo)</li>
        <li>Check card (with Visa/Mastercard logo)</li>
      </ul>
    </div>
    <div className="px-lg-5 px-3">
      <p className="font-weight-bold mb-0"><u>Prepaid Debit Cards</u></p>
      <p>When using prepaid debit cards be sure that the address on the card matches the address that you entered for making a payment. You may have to call the number on the back and have them add your address to the card.</p>
      <p className="font-weight-bold mb-0"><u>Moneygram Express Payment Information</u></p>
      <ul>
        <li>Locate a Moneygram Agent: 1-800-555-3133 or online (or at any Walmart)</li>
        <li>Use the Moneygram Express Payment form</li>
        <li>The Receive Code is 3019</li>
        <li>Your account number is the phone number you receive calls at</li>
        <li>The City and State is East Grand Forks, MN</li>
        <li>You must bring cash. Moneygram’s fee is $7.95 ($7.50 at Walmart)</li>
        <li>You may also setup an account with Moneygram to make these payments online</li>
      </ul>
    </div>
  </>
)

export default AccountPaymentInformation;
