import React from 'react';
import Skeleton from 'react-loading-skeleton';

const LoadingRow: React.FC = _ =>
  <div className="mx-lg-5 mx-4 my-3 p-2 row rounded">
    <div className="col-lg-1 my-2 d-flex align-self-center px-2">
      <Skeleton circle={true} height={40} width={40} />
    </div>
    <div className="col-lg-5 my-2 d-flex flex-column align-self-center px-2">
      <Skeleton width={'100%'} height={20} />
    </div>
    <div className="col-lg-4 my-2 d-flex flex-column align-self-center px-2">
      <Skeleton width={'100%'} height={20} />
    </div>
    <div className="col-lg-2 my-2 d-flex align-self-center px-2">
      <Skeleton height={30} />
    </div>
  </div>

export default LoadingRow;
