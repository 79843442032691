import React, { useState } from 'react';
import { IFacility } from '../../../models/Facility';
import { IInmate } from '../../../models/Inmate';
import InmatePicker from '../../Inmate/Picker';
import AmountInput from './AmountInput';

interface IDetailsProps {
  facility: IFacility
  hideInmatePicker: boolean
  inmates: IInmate[]
  loadingInmates: boolean
  selectedInmate: IInmate
  onSubmit(inmate: IInmate, amount: number): void
}

const Details: React.FC<IDetailsProps> = props => {
  const [selectedInmate, selectInmate] = useState(props.selectedInmate);

  if (selectedInmate == null && props.selectedInmate) {
    selectInmate(props.selectedInmate);
  }

  return (
    <>
      {/* Only display the inmate picker if we didn't receive an inmate directly */}
      {!props.hideInmatePicker &&
        <InmatePicker
          facility={props.facility}
          warnIfNoDevice={true}
          inmates={props.inmates}
          loadingInmates={props.loadingInmates}
          selectedInmate={selectedInmate}
          onSelect={selectInmate}
          />
      }

      {/* Only display the amount picker after we have an inmate selected */}
      {selectedInmate &&
        <AmountInput
          onSubmit={data => props.onSubmit(selectedInmate, parseFloat(data.amount))}
          />
      }
    </>
  );
}

export default Details
