import { ICreditCard } from '../models/CreditCard';
import { IFacility } from '../models/Facility';
import { IAppState } from '../reducers';
import { Service } from '../reducers/checkout';

export const SELECT_FACILITY = 'CHECKOUT/SELECT_FACILITY';
export interface ICheckoutSelectFacilityAction {
  type: typeof SELECT_FACILITY
  facility: IFacility
}
export function selectFacility(facility: IFacility) {
  return { type: SELECT_FACILITY, facility };
}
export function selectFacilityById(facilityId: number) {
  return (dispatch, getState) => {
    const state: IAppState = getState();
    const facility = state.common.facilities.filter(f => f.id == facilityId)[0];
    dispatch(selectFacility(facility));
  }
}

export const SELECT_SERVICE = 'CHECKOUT/SELECT_SERVICE';
export interface ICheckoutSelectServiceAction {
  type: typeof SELECT_SERVICE
  service: Service
}
export function selectService(service: Service): ICheckoutSelectServiceAction {
  return { type: SELECT_SERVICE, service };
}

export const SELECT_SAVED_CREDIT_CARD = 'CHECKOUT/SELECT_SAVED_CREDIT_CARD';
export function selectSavedCreditCard(creditCard: ICreditCard) {
  return (dispatch, getState) => {
    const state = getState() as IAppState;
    if (state.checkout.common.selectedSavedCard == creditCard) {
      dispatch({ type: SELECT_SAVED_CREDIT_CARD, creditCard: null });
      return;
    }
    dispatch({ type: SELECT_SAVED_CREDIT_CARD, creditCard });
  }
}

export const ADD_CREDIT_CARD = 'CHECKOUT/ADD_CREDIT_CARD';
export function addCreditCard() {
  return { type: ADD_CREDIT_CARD };
}