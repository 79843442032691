import { IAccount } from './Account';
import { IDateTimeObject } from './DateTime';
import { IOnlineUserAccount } from './OnlineUserAccount';

export interface ITransaction {
  id: number
  amount: number
  date: IDateTimeObject
  TransactionSubTypeID: number
}

export enum AffectsType {
  Collect = 2,
  PhoneCard = 3,
  WalletPayment = 4
}

export interface ITransactionFeeData {
  affectsType: AffectsType
  fixedFee: number
  minAmount: number
  maxAmount: number
  percentFee: number
}

export class TransactionFee {
  affectsType: AffectsType
  fixedFee: number
  minAmount: number
  maxAmount: number
  percentFee: number

  constructor(data: ITransactionFeeData) {
    this.affectsType = data.affectsType;
    this.fixedFee = data.fixedFee || 0;
    this.minAmount = data.minAmount;
    this.maxAmount = data.maxAmount;
    this.percentFee = data.percentFee;
  }

  getFee(type: AffectsType, amount: number) {
    if (this.affectsType != type) {
      return 0;
    }

    let fee = this.fixedFee;

    if (
      (this.minAmount == null || amount >= this.minAmount) &&
      (this.maxAmount == null || amount <= this.maxAmount)
    ) {
      fee += amount * this.percentFee;
    }

    return fee;
  }
}

export interface ICreditCardPayment extends ITransaction {
  cardType: string,
  cardNumber: string,
  expirationDate: string,
  firstName: string,
  lastName: string,
  address: string,
  city: string,
  state: string,
  zip: string,
  approved: boolean,
  declineReason : string,
  dateCharged: string,
  saved: boolean,
  account?: IAccount,
  onlineUserAccount?: IOnlineUserAccount
}