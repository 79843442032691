import { IFacility } from '../models/Facility';
import { IInmate } from '../models/Inmate';

function shouldShowInmateNumber(facility: IFacility, inmate: IInmate) {
  return (
    inmate?.device?.smsNumber != null &&
    facility?.getConfigurationValue('WebsiteHideInmatePhoneNumber') != 'true'
  );
}

export default shouldShowInmateNumber;
