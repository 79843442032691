import React from 'react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../reducers';
import { maxValue, minValue, number, required } from '../../../validation';
import { ButtonSize, renderActionSubmit, renderTextInput } from '../../Form/RenderForm';
import Currency from '../../Format/Currency';

interface IAmountDataProps {
  maxAmount?: number
  onSubmit: (data: IAmountData) => void
}

interface IAmountData {
  amount: string
}

const AmountInput: React.FC<IAmountDataProps> = props => {
  const state = useSelector((appState: IAppState) => {
    const existing = appState.checkout.wallet.paymentData;
    return {
      initialValues: existing && existing.amount ? {
        amount: existing.amount
      } : null
    };
  })

  const initialAmount = ((state||{}).initialValues||{}).amount||'';

  return (
    <Form
      initialValues={{amount: initialAmount.toString()}}
      keepDirtyOnReinitialize={true}
      onSubmit={props.onSubmit}>
      {form =>
        <form onSubmit={form.handleSubmit} method="post">
          <h3 className="mt-5">Enter amount</h3>
          <p>Amount must be between $10 and <Currency value={props.maxAmount || 200} /></p>
          <div className="row my-lg-4 no-gutters">
            <div className="form-label-group col-lg-8">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">$</span>
                </div>
                {renderTextInput(
                  'amount',
                  'amount',
                  'Amount',
                  null,
                  [required, number, minValue(10), maxValue(props.maxAmount || 200)]
                )}
              </div>
            </div>
            <div className="col-lg-3 offset-lg-1 form-button-action">
              {renderActionSubmit(form, 'Continue', 'Continue', ButtonSize.Large)}
            </div>
          </div>
        </form>
      }
    </Form>
  )
}

export default AmountInput
