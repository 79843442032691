import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IInmate } from '../../models/Inmate';
import { IScheduleAvailability, IVisitation } from '../../models/Visitation';
import { IAppState } from '../../reducers';
import * as visitationResource from '../../resources/Visitation';
import Sentry from '../../services/sentry';

export const SET_DETAILS = 'VISITATION/SCHEDULE_SET_DETAILS';
export function setInmate(inmate: IInmate) {
  return (dispatch, getState) =>  {
    const state: IAppState = getState();
    dispatch({
      type: SET_DETAILS,
      details: {
        ...state.visitation.schedule.visitationData,
        inmate
      }
    });
  }
}
export function setTimeslot(timeslot) {
  return (dispatch, getState) =>  {
    const state: IAppState = getState();
    dispatch({
      type: SET_DETAILS,
      details: {
        ...state.visitation.schedule.visitationData,
        timeslot
      }
    });
  }
}
export function setVisitorInformation(visitorInformation) {
  return (dispatch, getState) =>  {
    const state: IAppState = getState();
    dispatch({
      type: SET_DETAILS,
      details: {
        ...state.visitation.schedule.visitationData,
        visitorInformation
      }
    });
  }
}

export const SCHEDULING = 'VISITATION/SCHEDULING';
export const SCHEDULE_SUCCESS = 'VISITATION/SCHEDULE_SUCCESS';
export const SCHEDULE_FAILURE = 'VISITATION/SCHEDULE_FAILURE';
export function schedule(): ThunkAction<Promise<IVisitation>, IAppState, null, Action> {
  return (dispatch, getState) => {
    const state = getState();
    const visitation = state.visitation.schedule.visitationData;
    const facility = state.checkout.common.facility;
    const onlineUser = state.auth.user;
    visitation.visitorInformation.onlineUserId = onlineUser.id;

    dispatch({ type: SCHEDULING, visitation });

    return visitationResource
      .post(facility, visitation)
      .then(res => {
        if (res == null || !res.success) {
          throw new Error('Invalid response from server');
        }

        dispatch({ type: SCHEDULE_SUCCESS, visitation: res.visitation });
        return res.visitation;
      })
      .catch(error => {
        Sentry.captureException(error);
        dispatch({ type: SCHEDULE_FAILURE, error });
        throw error;
      });
  }
}

export const RESET = 'VISITATION/RESET';
export function reset() {
  return { type: RESET };
}

export const LOADING_AVAILABILITY = 'VISITATION/LOADING_AVAILABILITY';
export const LOAD_AVAILABILITY_SUCCESS = 'VISITATION/LOAD_AVAILABILITY_SUCCESS';
export const LOAD_AVAILABILITY_FAILURE = 'VISITATION/LOAD_AVAILABILITY_FAILURE';
export function loadAvailability(facilityId: number, inmateId: number) : ThunkAction<Promise<IScheduleAvailability[]>, IAppState, undefined, Action> {
  return dispatch => {
    dispatch({ type: LOADING_AVAILABILITY });

    return visitationResource.availability(facilityId, inmateId)
      .then(res => {
        dispatch({ type: LOAD_AVAILABILITY_SUCCESS, availability: res });
        return res;
      })
      .catch(error => {
        dispatch({ type: LOAD_AVAILABILITY_FAILURE, error });
        throw error;
      })
  }
}