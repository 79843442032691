import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RouterProps, withRouter } from 'react-router';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as checkoutActions from '../../../actions/checkout';
import * as checkoutCardActions from '../../../actions/checkout/card';
import * as checkoutCollectActions from '../../../actions/checkout/collect';
import * as checkoutWalletActions from '../../../actions/checkout/wallet';
import * as dashboardActions from '../../../actions/dashboard';
import * as visitationScheduleActions from '../../../actions/visitation/schedule';
import { IFacility } from '../../../models/Facility';
import { IOnlineUser } from '../../../models/OnlineUser';
import { IPhoneCardPurchase } from '../../../models/PhoneCard';
import { ICreditCardPayment } from '../../../models/Transaction';
import { IVisitation } from '../../../models/Visitation';
import { IWalletPayment } from '../../../models/WalletPayment';
import { IAppState } from '../../../reducers';
import { Service } from '../../../reducers/checkout';
import FacilitySearch from '../../Facility/Search';
import PageTitle from '../../PageTitle';
import Navigation from '../Navigation';
import AccountDetails from './AccountDetails';
import RecentCollectCall from './RecentCollectCall';
import RecentPhoneCard from './RecentPhoneCard';
import RecentVisitation from './RecentVisitation';
import RecentWallet from './RecentWallet';

interface IDashboardProps extends RouterProps {
  loadRecentActions: () => void
  onRepeatVisitation: (visitation: IVisitation) => void
  onRepeatWalletPayment: (payment: IWalletPayment) => void
  onRepeatCollectPayment: (payment: ICreditCardPayment) => void
  onRepeatCardPurchase: (purchase: IPhoneCardPurchase, onlineUser: IOnlineUser) => void
}

const Dashboard: React.FC<IDashboardProps> = props => {
  const state = useSelector((appState: IAppState) => ({
    auth: appState.auth,
    dashboard: appState.dashboard,
    common: appState.common
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    props.loadRecentActions();
  }, []);

  let mostRecentWalletPayment: IWalletPayment = null;
  let mostRecentCollectPayment: ICreditCardPayment = null;
  let mostRecentOnlineOrder: IPhoneCardPurchase = null;
  let mostRecentVisitation: IVisitation = null;
  if (!state.dashboard.loadingRecentActions && state.dashboard.recentActions) {
    if (state.dashboard.recentActions.recentWalletPayments.length > 0)
      mostRecentWalletPayment = state.dashboard.recentActions.recentWalletPayments[0];
    if (state.dashboard.recentActions.recentCollectPayments.length > 0)
      mostRecentCollectPayment = state.dashboard.recentActions.recentCollectPayments[0];
    if (state.dashboard.recentActions.recentOnlineOrders.length > 0)
      mostRecentOnlineOrder = state.dashboard.recentActions.recentOnlineOrders[0];
    if (state.dashboard.recentActions.recentVisitations.length > 0)
      mostRecentVisitation = state.dashboard.recentActions.recentVisitations[0];
  }

  // Retrieve an in-memory facility if still active, otherwise fallback to the lighter dataset received from the API
  const facilityFromId = (id: number, fallback: IFacility) => {
    if ((state.common.facilities || []).filter(f => f.id == id).length > 0) {
      return state.common.facilities.filter(f => f.id == id)[0];
    }
    return fallback;
  }

  return (
    <>
      <PageTitle title="Account Dashboard" />
      <Navigation title="Account Dashboard" />

      <main className="container pt-3">
        <section className="bg-light rounded p-lg-5 p-4 my-3">
          <AccountDetails user={state.auth.user} />
        </section>

        <section className="row mt-3">
          {(state.dashboard.loadingRecentActions || mostRecentWalletPayment) &&
            <RecentWallet
              loading={state.dashboard.loadingRecentActions}
              payment={mostRecentWalletPayment}
              facility={mostRecentWalletPayment ?
                facilityFromId(mostRecentWalletPayment.inmate.facility.id, mostRecentWalletPayment.inmate.facility) :
                null
              }
              onRepeat={() => {
                props.onRepeatWalletPayment(mostRecentWalletPayment);
                props.history.push('/checkout/wallet');
              }} />}
          {(state.dashboard.loadingRecentActions || mostRecentCollectPayment) &&
            <RecentCollectCall
              loading={state.dashboard.loadingRecentActions}
              payment={mostRecentCollectPayment}
              onRepeat={() => {
                props.onRepeatCollectPayment(mostRecentCollectPayment);
                props.history.push('/checkout/collect');
              }} />}
          {(state.dashboard.loadingRecentActions || mostRecentOnlineOrder) &&
            <RecentPhoneCard
              loading={state.dashboard.loadingRecentActions}
              purchase={mostRecentOnlineOrder}
              facility={mostRecentOnlineOrder ?
                facilityFromId(mostRecentOnlineOrder.facility.directBillFacility.id, mostRecentOnlineOrder.facility.directBillFacility) :
                null
              }
              onRepeat={() => {
                props.onRepeatCardPurchase(mostRecentOnlineOrder, state.auth.user);
                props.history.push('/checkout/card');
              }}/>}
          {(state.dashboard.loadingRecentActions || mostRecentVisitation) &&
            <RecentVisitation
              loading={state.dashboard.loadingRecentActions}
              visitation={mostRecentVisitation}
              facility={mostRecentVisitation ?
                facilityFromId(mostRecentVisitation.facility.id, mostRecentVisitation.facility) :
                null
              }
              onRepeat={() => {
                props.onRepeatVisitation(mostRecentVisitation);
                props.history.push('/visitation/schedule');
              }} />}
          <FacilitySearch
            facilitiesLoading={state.common.loadingFacilities}
            facilities={state.common.facilities}
            onSubmit={facility => {
              dispatch(checkoutActions.selectFacility(facility[0]));
              props.history.push('/checkout/service');
            }} />
        </section>
      </main>
    </>
  );
}

export default withRouter(connect(
  (state: IAppState) => state.dashboard,
  ((dispatch: ThunkDispatch<IAppState, undefined, Action>) => ({
    loadRecentActions: () => dispatch(dashboardActions.loadRecentActions()),
    onRepeatWalletPayment: (payment: IWalletPayment) => {
      dispatch(checkoutActions.selectService(Service.Wallet));
      dispatch(checkoutActions.selectFacilityById(payment.inmate.facility.id));
      dispatch(checkoutWalletActions.setDetails(payment.inmate, payment.amount));
    },
    onRepeatCollectPayment: (payment: ICreditCardPayment) => {
      dispatch(checkoutActions.selectService(Service.Collect));
      dispatch(checkoutCollectActions.setAccount(payment.onlineUserAccount));
    },
    onRepeatCardPurchase: (purchase: IPhoneCardPurchase, onlineUser: IOnlineUser) => {
      dispatch(checkoutActions.selectService(Service.PhoneCard));
      dispatch(checkoutActions.selectFacilityById(purchase.summary.directBillFacilityId));
      dispatch(checkoutCardActions.setDetails({
        inmateInformation: {
          firstName: purchase.inmateFirstName,
          lastName: purchase.inmateLastName
        },
        inmate: purchase.inmate,
        typeValueId: purchase.onlineOrderItems[0].phoneCards[0].typeValue.id,
        firstName: onlineUser.firstName,
        lastName: onlineUser.lastName,
        address: onlineUser.address,
        city: onlineUser.city,
        state: onlineUser.state,
        zip: onlineUser.zip,
        emailAddress: onlineUser.emailAddress,
        phoneNumber: onlineUser.phoneNumber,
        receiptEmailAddress: onlineUser.emailAddress,
        updateAccountSettings: false
      }))
    },
    onRepeatVisitation: (visitation: IVisitation) => {
      dispatch(checkoutActions.selectService(Service.Visitation));
      dispatch(checkoutActions.selectFacilityById(visitation.facility.id));
      dispatch(visitationScheduleActions.setInmate(visitation.inmate));
    }
  }))
)(Dashboard));
