import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as CollectCallIcon } from '../../images/icon_collectcall.svg';
import { ReactComponent as InmateWalletIcon } from '../../images/icon_inmate_wallet.svg';
import { ReactComponent as PhoneCardIcon } from '../../images/icon_phonecard.svg';
import { ReactComponent as VideoVisitationIcon } from '../../images/icon_videovisitation.svg';
import { ReactComponent as StatesMap } from '../../images/map_states.svg';
import PageTitle from '../components/PageTitle';

const About: React.FC = () =>
  <div>
    <PageTitle title="About" />

    <div className="hero bg-primary text-light py-5">
      <div className="container">
        <div className="row pt-5">
          <div className="col-lg-8 offset-lg-2 pt-5">
          <h1 className="d-none">About Reliance Telephone</h1>
          <span className="page-icon rt-about"></span>
          <h2>At Reliance, we’re committed to helping inmates stay in touch with their friends and family.</h2>
          <p>We believe that good communication between inmates and their loved ones makes it easier to reconnect with them upon release. We have designed our communication options to make it easy and convenient for inmates to stay connected. Reliance Telephone is the only company in the industry providing live texting to inmates with their own personal texting device and dedicated telephone number.</p>
        </div>
      </div>

      </div>
    </div>

    <main className="container">
      <ul className="patents overline text-secondary bg-light rounded d-md-flex align-self-center text-center list-unstyled p-1 mt-n3 col-lg-8 offset-lg-2 mb-5 justify-content-md-around">
        <li>Patent Numbers:</li>
        <li>US9020115</li>
        <li>US9871548</li>
        <li>US10082835</li>
        <li>US10085126</li>
        <li>US10236929</li>
      </ul>

      <div className="row">
        <div className="col-md-4 offset-md-2">
          <p>Reliance Telephone is based in Grand Forks, ND and services over 160 correctional facilities across Iowa, Minnesota, North Dakota, South Dakota, and Wisconsin. Our team of customer service representatives and technicians provide live support and service that is unmatched in the industry—24 hours a day, 7 days a week.</p>
        </div>

        <div className="col-md-4">
          <span><StatesMap /></span>
        </div>
      </div>

      <div className="bg-dark rounded text-light text-center p-5 my-5">
        <div>
          <span className="icon-white s-5 inmatewallet"><InmateWalletIcon /></span>
          <span className="icon-white s-5 phonecard"><PhoneCardIcon /></span>
          <span className="icon-white s-5 collectcall"><CollectCallIcon /></span>
          <span className="icon-white s-5 videovisitation"><VideoVisitationIcon /></span>
        </div>
        <h3 className="pt-3">Want to know more about the inmate communication options we offer?</h3>
        <Link to="/services" className="btn btn-action mt-3">View communication options</Link>
      </div>
    </main>
  </div>

export default About;
