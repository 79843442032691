import React from 'react';
import { Form } from 'react-final-form';
import { match, password, required } from '../../../validation';
import ErrorMessage from '../../ErrorMessage';
import { InputType, renderSecondarySubmit, renderTextInput } from '../../Form/RenderForm';
import SuccessMessage from '../../SuccessMessage';

export interface IChangePasswordData {
  currentPassword: string
  newPassword: string
  verifyNewPassword: string
}

interface IChangePasswordProps {
  updated: boolean
  onChangePassword: (data: IChangePasswordData, form) => Promise<any>
}

const ChangePassword: React.FC<IChangePasswordProps> = props =>
  <div className="border border-dark rounded h-100">
    <h5 className="bg-dark text-light px-5 py-3"><span></span>Change Password</h5>
    <div className="p-lg-5 p-4">
      <Form
        onSubmit={(data: IChangePasswordData, form) =>
          props.onChangePassword(data, form)
        }>
        {form =>
          <form onSubmit={form.handleSubmit} method="post">
            {form.submitError &&
              <ErrorMessage
                error={form.submitError}
                httpCodeMessages={{
                  403: 'Invalid current password'
                }} />
            }

            {props.updated &&
              <SuccessMessage message="Changes were successfully saved" />
            }

            <div className="form-label-group">
              {renderTextInput(
                'changePassword.currentPassword',
                'currentPassword',
                'Enter current password',
                'Enter current password',
                [required],
                InputType.password
              )}
            </div>
            <div className="form-label-group">
              {renderTextInput(
                'changePassword.newPassword',
                'newPassword',
                'Enter new password',
                'Enter new password',
                [required, password],
                InputType.password
              )}
              <p className="small text-dark">Must be at least 8 characters and contain at least 1 numerical character</p>
            </div>
            <div className="form-label-group">
              {renderTextInput(
                'changePassword.verifyNewPassword',
                'verifyNewPassword',
                'Enter new password again',
                'Enter new password again',
                [required, match('newPassword', 'Passwords do not match')],
                InputType.password
              )}
            </div>
            {renderSecondarySubmit<IChangePasswordData>(form, 'Change password', 'Changing...')}
          </form>
        }
      </Form>
    </div>
  </div>

export default ChangePassword;
