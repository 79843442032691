import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as cardReceiptActions from '../../../actions/card/receipt';
import { IFacility } from '../../../models/Facility';
import { IPhoneCardPurchase } from '../../../models/PhoneCard';
import { IAppState } from '../../../reducers';
import Confirmation from '../../Checkout/Card/Confirmation';
import ErrorMessage from '../../ErrorMessage';
import PageTitle from '../../PageTitle';

interface ICardReceiptUrlParams {
  id?: string
}

interface ICardReceiptRouteState {
  purchase?: IPhoneCardPurchase
}

const CardReceipt: React.FC<RouteChildrenProps<ICardReceiptUrlParams, ICardReceiptRouteState>> = props => {
  const state = useSelector((appState: IAppState) => ({
    card: appState.card,
    common: appState.common
  }));
  const dispatch: ThunkDispatch<IAppState, undefined, Action> = useDispatch();

  useEffect(() => {
    dispatch(cardReceiptActions.loadPurchase(props.match.params.id ?? state.card.receipt?.purchase?.id));

    return function cleanupCardReceipt() {
      dispatch(cardReceiptActions.reset());
    };
  }, []);

  // If we still have the receipt email address (since it isn't persisted with the purchase), we will display it. Otherwise it'll be hidden
  const receiptEmailAddress = state.card.receipt.receiptEmailAddress;

  // Retrieve an in-memory facility if still active, otherwise fallback to the lighter dataset received from the API
  const facilityFromId = (id: number, fallback: IFacility) => {
    if ((state.common.facilities || []).filter(f => f.id == id).length > 0) {
      return state.common.facilities.filter(f => f.id == id)[0];
    }
    return fallback;
  }

  const directBillFacility = state.card.receipt.purchase ? (
      state.card.receipt.purchase.facility.directBillFacility ?
      state.card.receipt.purchase.facility.directBillFacility :
      (state.card.receipt.purchase.facility as any)
    ) : null;

  return (
    <main className="container align-items-top justify-content-center mt-5 mt-lg-3 pt-4">
      <section className="no-gutters">
        <PageTitle title="Phone Card Purchase" />

        {state.card.receipt.purchaseLoadError ?
          <ErrorMessage
            error={state.card.receipt.purchaseLoadError}
            httpCodeMessages={{
              404: 'This receipt was not found'
            }}
            /> :
          (
            state.card.receipt.purchaseLoading || !state.card.receipt.purchase ?
              <div className="row d-flex justify-content-center">
                <div className="col-md-6">
                  <Skeleton height={700} className="m-5" />
                </div>
              </div> :
              <Confirmation
                facility={facilityFromId(directBillFacility.id, directBillFacility)}
                purchase={state.card.receipt.purchase}
                receiptEmailAddress={receiptEmailAddress}
                />
          )
        }
      </section>
    </main>
  )
}

export default CardReceipt;
