import React from 'react';

interface IFaqRowProps {
  question: string
  answer: string|JSX.Element
  expanded: boolean
  onToggleExpansion: () => void
}

const FaqRow: React.FC<IFaqRowProps> = props =>
  <div className="question" onClick={props.onToggleExpansion}>
    <button className={"collapsible py-3 px-0 m-0 text-left " + (props.expanded ? 'active' : '')}>
      <span className="collapseiconfaq"></span>
      <p className="font-weight-bold ml-5">{props.question}</p>
    </button>
    <div className="collapsecontent py-3 pl-5" style={{ display: props.expanded ? 'block' : 'none' }}>
      <p>{props.answer}</p>
    </div>
  </div>

export default FaqRow;
