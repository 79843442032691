
import { applyMiddleware, CombinedState, compose, createStore } from 'redux';
import logger from 'redux-logger';
import { PersistConfig, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSentryMiddleware from 'redux-sentry-middleware';
import thunk from 'redux-thunk';
import * as cardActions from './actions/checkout/card';
import * as collectActions from './actions/checkout/collect';
import * as walletActions from './actions/checkout/wallet';
import { ICardPurchaseData } from './components/Checkout/Card/Details';
import { IPaymentData } from './components/Checkout/Collect/Payment';
import { IWalletPaymentData } from './models/WalletPayment';
import reducer, { IAppState } from './reducers';
import Sentry from './services/sentry';

// Handle migrations between app versions if the version changes
const migrationHandler = (state, currentVersion: number) => {
  console.log(process.env.WEBSITE_VERSION);
  if (state && localStorage.getItem('WEBSITE_VERSION') != process.env.WEBSITE_VERSION) {
    console.log('CACHE: Clearing for new version', { old: localStorage.getItem('WEBSITE_VERSION'), new: process.env.WEBSITE_VERSION });
    localStorage.setItem('WEBSITE_VERSION', process.env.WEBSITE_VERSION);

    // Retain the state auth
    return Promise.resolve({
      _persist: state._persist,
      auth: state.auth
    })
  } else {
    return Promise.resolve(state);
  }
}

const middleware = [];
if (process.env.TESTING !== 'true') {
  middleware.push(logger);
}
middleware.push(thunk);
middleware.push(
  createSentryMiddleware(Sentry, {
    getUserContext: (state: IAppState) => {
      return {
        ...state.auth.user,
        email: state.auth.user ? state.auth.user.emailAddress : undefined
      };
    },
    actionTransformer: action => {
      const sentryAction = {...action};

      // Sanitize sensitive data
      switch (action.type) {
        case walletActions.PAYMENT_PROCESSING:
          const walletPayment: IWalletPaymentData = {...(sentryAction.payment || {})};
          walletPayment.cc = walletPayment.cc ? {
            ...walletPayment.cc,
            cardSecurityCode: 'masked',
            cardNumber: walletPayment.cc.cardNumber ? ('****' + walletPayment.cc.cardNumber.substring(-4)) : undefined
          } : null;

        case cardActions.PURCHASE_PROCESSING:
          const purchase: ICardPurchaseData = {...(sentryAction.purchase || {})};
          purchase.cc = purchase.cc ? {
            ...purchase.cc,
            cardSecurityCode: 'masked',
            cardNumber: purchase.cc.cardNumber ? ('****' + purchase.cc.cardNumber.substring(-4)) : undefined
          } : null;

        case collectActions.PAYMENT_PROCESSING:
          const collectPayment: IPaymentData = {...(sentryAction.payment || {})};
          collectPayment.cc = collectPayment.cc ? {
            ...collectPayment.cc,
            cardSecurityCode: 'masked',
            cardNumber: collectPayment.cc.cardNumber ? ('****' + collectPayment.cc.cardNumber.substring(-4)) : undefined
          } : null;
      }

      return sentryAction;
    }
  })
);

const enhancer = compose(
  applyMiddleware(...middleware)
);

const persistConfig: PersistConfig<CombinedState<IAppState>> = {
  key: 'root',
  storage,
  timeout: 60 * 60,
  whitelist: ['auth', 'checkout', 'common'],
  blacklist: [
    'common.rehydrated',
    'wallet.receipt.payment',
    'card.receipt.payment',
    'collect.receipt.payment'
  ],
  migrate: migrationHandler
}

const persistedReducer = persistReducer(persistConfig, reducer);

export const appStore = createStore(persistedReducer, enhancer) as any; // Casting resolves a typescript complaint that doesn't make sense...
export const persistor = persistStore(appStore);
