import moment from 'moment';
import React from 'react';
import { ICall } from '../../../models/Call';
import { IPaginatedResponse } from '../../../services/api';
import Currency from '../../Format/Currency';
import Duration from '../../Format/Duration';
import Facility from '../../Format/Facility';

interface IRecentCallsProps {
  calls: IPaginatedResponse<ICall>
  expanded: boolean
  onToggleExpansion: () => void
}

const RecentCalls: React.FC<IRecentCallsProps> = props =>
  <section className="no-gutters">
    <div className="mb-3">
      <div className="col-md-12 rounded p-0 border border-dark">
        <button className={"collapsible btn-block text-left mobile-only text-light bg-dark px-lg-5 px-3 py-3 " + (props.expanded ? 'active' : '')} onClick={props.onToggleExpansion}>
          <h5 className="product m-0 pr-5"><span className="icon-button"></span>Recent Call History</h5><span className="collapseicon_secondary"></span>
        </button>
        {props.expanded && (
          <div className="col-md-12" style={{ overflow: 'scroll' }}>
            <table className="table table-striped p-4">
              <thead>
                <tr>
                  <th className="acct rc-date">Date</th>
                  <th className="acct rc-duration">Duration</th>
                  <th className="acct rc-origfacility">Originating Facility</th>
                  <th className="acct rc-charge">Charge</th>
                  <th className="acct rc-txfees">Tax + Fees</th>
                  <th className="acct rc-total">Total</th>
                </tr>
              </thead>
              <tbody>
                {props.calls && props.calls.data.map(c =>
                  <tr key={c.id}>
                    <td>{moment(c.date.date).format('MM/DD/YYYY')}</td>
                    <td><Duration value={c.duration * 60} /></td>
                    <td>{c.facility && c.facility.length > 0 && <Facility facility={c.facility[0]} />}</td>
                    <td><Currency value={c.charge} /></td>
                    <td><Currency value={c.cityTax + c.countyTax + c.stateTax + c.toStateTax + c.fedTax + c.usfTax} /></td>
                    <td><Currency value={c.charge + c.cityTax + c.countyTax + c.stateTax + c.toStateTax + c.fedTax + c.usfTax} /></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  </section>

export default RecentCalls;
