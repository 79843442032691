import React from 'react';
import PageTitle from '../components/PageTitle';
import AccountPaymentInformation from '../components/Policies/AccountPaymentInformation';
import CollectCallInformation from '../components/Policies/CollectCallInformation';
import InmateWalletInformation from '../components/Policies/InmateWalletInformation';
import OtherFeeInformation from '../components/Policies/OtherFeeInformation';
import PhoneCardInformation from '../components/Policies/PhoneCardInformation';
import RefundInformation from '../components/Policies/RefundInformation';
import PolicyRow from '../components/Policies/PolicyRow';

interface IPolicy {
  title: string
  content: JSX.Element
}

interface IPoliciesState {
  policies: IPolicy[],
  expanded: IPolicy[]
}

const policies: IPolicy[] = [
  {
    title: 'Account & Payment Information',
    content: AccountPaymentInformation
  },
  {
    title: 'Inmate Wallet (Texting) Information and Fees',
    content: InmateWalletInformation
  },
  {
    title: 'Phone Card Information and Fees',
    content: PhoneCardInformation
  },
  {
    title: 'Prepaid Collect Call Account Information and Fees',
    content: CollectCallInformation
  },
  {
    title: 'Other Fees',
    content: OtherFeeInformation
  },
  {
    title: 'Refunds',
    content: RefundInformation
  }
]

class Policies extends React.Component<{}, IPoliciesState> {
  constructor(props) {
    super(props);

    this.state = {
      policies,
      expanded: []
    }
  }

  toggleExpansion(policy: IPolicy) {
    let expanded = this.state.expanded;
    const existing = expanded.indexOf(policy);
    if (existing > -1) {
      expanded.splice(existing, 1);
      this.setState({ expanded });
    } else {
      this.setState({ expanded: [...expanded, policy] });
    }
  }

  render() {
    return (
      <div>
        <PageTitle title="Policies" />

        <div className="hero bg-primary text-light py-5">
          <div className="container">
            <div className="row py-5">
              <div className="col-lg-8 offset-lg-2 pt-5">
                <h1 className="d-none">Policies, Terms and Conditions</h1>
                <span className="page-icon rt-about"></span>
                <h2>Reliance Telephone Policies</h2>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          {this.state.policies.map(policy =>
            <PolicyRow
              key={policy.title}
              onToggleExpansion={() => this.toggleExpansion(policy)}
              expanded={this.state.expanded.indexOf(policy) > -1}
              title={policy.title}>
                {policy.content}
            </PolicyRow>
          )}
        </div>
      </div>
    );
  }
}

export default Policies;
