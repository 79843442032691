import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { BrowserRouter, Link } from 'react-router-dom';
import PageTitle from '../components/PageTitle';
import FaqRow from '../components/Support/FaqRow';

interface IQuestionAndAnswer {
  question: string
  answer: JSX.Element
}

const questionsAndAnswers: IQuestionAndAnswer[] = [
  {
    question: 'What is the least expensive way to communicate with an inmate?',
    answer: <>Communication via a text message, audio call or video call (where available) by funding an <Link to="/checkout/facility">Inmate Wallet</Link> account is the least expensive way to communicate.  Rates and details can be found by clicking "See details and costs" under <Link to="/services">Communication Options</Link>.</>
  },
  {
    question: 'How do I set up a collect call account?',
    answer: <>On the <Link to="/home">home page</Link>, locate the facility you’re looking to communicate with an inmate at and you’ll see the options available there. Under “Prepaid Collect Call,” enter the amount you’d like to prepay and continue to create an account and link your phone number. If you have an existing Reliance account, you can <Link to="/Login">log into</Link> your account, click “Prepaid Collect Calls,” and add a new account.</>
  },
  {
    question: 'Where is my PIN number for my phone card?',
    answer: <>You receive a phone card PIN on the receipt page of your transaction after submitting payment and in an email upon transaction completion. You can <Link to="/Login">log into</Link> your Reliance account at any time and see the phone cards you’ve purchased and their PIN numbers in your transaction history.</>
  },
  {
    question: 'What is the inmate\'s texting number?',
    answer: <>You can see an inmate’s texting number in the sidebar during the checkout process after you locate your inmate. If you’ve previously funded an inmate, you can see their texting number in your Account Dashboard.</>
  },
  {
    question: 'What is my balance?',
    answer: <>To view your balance on a prepaid collect call account, log in using the <Link to="/Login">Sign In</Link> button in the upper right hand corner and click “Prepaid Collect Call Accounts” in your account dashboard.</>
  },
  {
    question: 'How do you register an online account?',
    answer: <>Click the <Link to="/Login">Sign In</Link> button in the upper right hand corner of any page and you’ll be prompted to enter your email and create a password. This creates an account for you to view your transactions and manage prepaid collect call accounts. From your account dashboard you can get started by finding the facility where you’d like to communicate with an inmate.</>
  },
  {
    question: 'Why is my password not working?',
    answer: <>Passwords are case sensitive, so please confirm that your caps lock key is not on. You can also reset your password on the account sign in page by clicking the <Link to="/Login">Sign In</Link> button in the upper right hand corner and clicking <Link to="/login/forgot-password">Forgot Password?</Link></>
  },
  {
    question: 'What are the rates for texting, video and telephone calls?',
    answer: <>Rates can be found by clicking "See details and costs" under <Link to="/services">Communication Options</Link>.</>
  },
  {
    question: 'How do I set up texting?',
    answer: <>First, you’ll need to confirm that the inmate has access to a texting device. If you received a text from an inmate requesting you to make a payment—this is confirmation that they have a texting device. If you have not received any notice, the inmate will need to request a texting device. To enable texting, locate the jail, select the inmate’s name, and make a payment to their Inmate Wallet to fund the text messages. If an inmate’s name is not listed, you’ll see the option to send a voice message to the jail to leave your telephone number and ask the inmate to request one. Once they have a device, the inmate can send you a fund request text message for free.</>
  },
  {
    question: 'Can a collect call account transfer to an Inmate Wallet or a phone card?',
    answer: <>No. Only online phone cards can transfer to Inmate Wallet accounts.</>
  },
  {
    question: 'Can inmate wallet funds be transferred to a different jail?',
    answer: <>No. The funds put into an inmate’s account will stay at the facility for an unlimited time. If an inmate leaves and comes back later, the funds will still be there—even if they get a new telephone number the funds are kept in the inmate’s name. Funds can be used for texting, or if available at that facility, video and voice calls.</>
  },
  {
    question: 'Why was my card declined?',
    answer: <>Your card could have been declined due to an inaccurate address, wrong security code or insufficient funds. Please call your credit card company to determine the exact cause.</>
  },
  {
    question: 'Can the inmate text more than one number?',
    answer: <>Yes, the inmate can have many contacts.</>
  },
  {
    question: 'How can I get an account for my phone number only?',
    answer: <>You can set up a collect call account and those funds will only be able to be used for dialing out to your phone number. Phone Cards and Inmate Wallet allow the flexibility to communicate with multiple phone numbers.</>
  },
  {
    question: 'Can I text pictures and emojis to an inmate?',
    answer: <>No, only characters are allowed.</>
  },
  {
     question: 'Where do I find the correctional facility voice mail number? ',
    answer: <>Start by Finding a Facility on the <Link to="/home">home page</Link>.  In Step 2, the facility name will be displayed as a blue hyperlink, click on the facility name and it will bring you to the page that has facility specific information including the voice mail number toward the bottom of the page.</>
  },
  {
    question: 'In the Reliance Connect App, what should I do when a video call shows a white screen when I answer the call? ',
    answer: <>This is most likely due to a slow or bad internet connection.  Try using Wi-Fi instead of mobile data or vice versa. Mobile data will work but is known to cause more issues. Try logging out and logging in again. If this does not work, try closing the application and reopening it. If this does not work, try force stopping and restarting the app on the device. <Link to="/troubleshooting">Click here</Link> more troubleshooting tips.</>
  },
  {
    question: 'In the Reliance Connect App, how do I get past the frozen blue screen when I’m trying to answer a video call?',
    answer: <>Try closing the application and reopening it. On iOS you double click the home button and swipe up on the application. On Android you hit the square button on bottom right of the screen (LG, Motorola) or the button on the bottom left of the screen (Samsung). Try restarting your device. If this does not work, force stop and reopen the application. Select Yes/OK to the prompt that comes up. <Link to="/troubleshooting">Click here</Link> more troubleshooting tips.</>
  },
  {
    question: 'Why doesn’t my phone ring when my friend or family member is trying to video call me?',
    answer: <>This is most likely due to a slow or bad internet connection. Try using Wi-Fi instead of mobile data or vice versa. Mobile data will work but is known to cause more issues.  Move to a location with greater signal.  <Link to="/troubleshooting">Click here</Link> more troubleshooting tips.</>
  }
]

interface ISupportState {
  searchText: string,
  questionsAndAnswers: IQuestionAndAnswer[],
  expanded: IQuestionAndAnswer[]
}

class Support extends React.Component<{}, ISupportState> {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      questionsAndAnswers,
      expanded: []
    }

    this.searchChanged = this.searchChanged.bind(this);
  }

  searchChanged(event) {
    this.setState({ searchText: event.target.value });
  }

  toggleExpansion(questionAndAnswer: IQuestionAndAnswer) {
    let expanded = this.state.expanded;
    const existing = expanded.indexOf(questionAndAnswer);
    if (existing > -1) {
      expanded.splice(existing, 1);
      this.setState({ expanded });
    } else {
      this.setState({ expanded: [...expanded, questionAndAnswer] });
    }
  }

  render() {
    let filtered = this.state.questionsAndAnswers.filter(qa =>
      this.state.searchText == ''
      || qa.question.indexOf(this.state.searchText) > -1
      || renderToStaticMarkup(<BrowserRouter>{qa.answer}</BrowserRouter>).indexOf(this.state.searchText) > -1
    );

    return (
      <div>
        <PageTitle title="Support" />

        <div className="hero bg-primary text-light pt-5">
          <div className="container">
            <div className="row pt-5 pb-4">
              <div className="col-lg-8 offset-lg-2 pt-5">
                <h1 className="d-none">Frequently Asked Questions</h1>
                <span className="page-icon rt-faq"></span>
                <h2>Have questions?<br/>
                  We have answers.</h2>
                <h4 className="py-3">See a full list of our frequently asked questions below or search for quicker answers to your questions.</h4>
                <div className="row no-gutters">
                  <div className="form-label-group col-md-8">
                    <input type="search" id="searchFAQ" className="form-control" placeholder="Search our FAQs" required onChange={this.searchChanged} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <main className="container mt-4">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              {filtered.map(faq =>
                <FaqRow
                  key={faq.question}
                  onToggleExpansion={() => this.toggleExpansion(faq)}
                  expanded={this.state.expanded.indexOf(faq) > -1}
                  question={faq.question}
                  answer={faq.answer}
                  />
              )}
            </div>
          </div>

          <div className="bg-dark rounded text-light text-center p-5 my-5">
            <h3 className="pt-3">Reliance Telephone Policies</h3>
            <p>View our policies for more information about payments, refunds, and billing for our each communication service we provide.</p>
            <Link className="btn btn-action btn-block mt-3" to="/policies">View policies</Link>
          </div>
        </main>
      </div>
    );
  }
}

export default Support;
