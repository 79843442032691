import React from 'react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { ICreditCard } from '../../../models/CreditCard';
import { IAppState } from '../../../reducers';
import ErrorMessage from '../../ErrorMessage';
import { ButtonSize, renderActionSubmit } from '../../Form/RenderForm';
import Currency from '../../Format/Currency';
import PaymentInformation, { IPaymentInformationData } from '../PaymentInformation';
import PersonalInformation, { IPersonalInformationData } from '../PersonalInformation';
import Agreement from './Agreement';

export interface IPaymentData extends IPersonalInformationData, IPaymentInformationData {
  ani: string
  amount: number
}

interface IPaymentProps {
  amount: number
  fees: number
  onSubmit: (data: IPaymentData) => Promise<any>
  loadingSavedCreditCards: boolean
  savedCards: ICreditCard[]
  selectedSavedCard: ICreditCard
  onCreditCardSelect: (card: ICreditCard) => void
  onAddCreditCard: () => void
  addingCreditCard: boolean
}

const Payment: React.FC<IPaymentProps> = props => {
  const state = useSelector((appState: IAppState) => ({
    collect: appState.checkout.collect,
    auth: appState.auth
  }))

  return (
    <div className="collectcall service mainwindow ">
      <h3 className="product bg-action p-2 py-3 mb-0 text-white rounded-top border-action">
        <span className="icon-button" />
        Prepaid Collect Call
      </h3>
      <div className="col-md-12 service-window px-0">
        <div className="maincontent p-0 m-0 pt-0 pt-md-0 px-4 border border-action rounded-bottom border-top-0">
          <Form
            mutators={{
              copyPersonalToBilling: (args, formState, tools) => {
                tools.changeValue(formState, 'cc.address', () => formState.lastFormState.values.address)
                tools.changeValue(formState, 'cc.city', () => formState.lastFormState.values.city)
                tools.changeValue(formState, 'cc.state', () => formState.lastFormState.values.state)
                tools.changeValue(formState, 'cc.zip', () => formState.lastFormState.values.zip)
              },
              useSavedCreditCard: (args, formState, tools) => {
                tools.changeValue(formState, 'cc', () => ({}))
              }
            }}
            initialValues={{
              ...state.collect.paymentData,
              address: state.auth.user ? state.auth.user.address : '',
              city: state.auth.user ? state.auth.user.city : '',
              state: state.auth.user ? state.auth.user.state : '',
              zip: state.auth.user ? state.auth.user.zip : '',
              firstName: state.auth.user ? state.auth.user.firstName : '',
              lastName: state.auth.user ? state.auth.user.lastName : '',
              phoneNumber: state.auth.user ? state.auth.user.phoneNumber : '',
              receiptEmailAddress: state.auth.user ? state.auth.user.emailAddress : ''
            }}
            keepDirtyOnReinitialize={true}
            onSubmit={props.onSubmit}>
            {form =>
              <form className="pb-4" onSubmit={form.handleSubmit} method="post">
                {form.submitError &&
                  <ErrorMessage error={form.submitError} />
                }

                <PersonalInformation />
                <PaymentInformation
                  form={form}
                  loggedIn={state.auth.loggedIn}
                  loadingSavedCreditCards={props.loadingSavedCreditCards}
                  savedCards={props.savedCards}
                  selectedSavedCard={props.selectedSavedCard}
                  onCreditCardSelect={props.onCreditCardSelect}
                  onAddCreditCard={props.onAddCreditCard}
                  addingCreditCard={props.addingCreditCard} />
                <Agreement />

                {props.selectedSavedCard == null && form.values.cc == null &&
                  <ErrorMessage message="Please supply a credit card for payment" />
                }

                {form.submitError &&
                  <ErrorMessage error={form.submitError} />
                }

                {!(props.selectedSavedCard == null && form.values.cc == null) && !form.submitError && form.submitFailed && form.hasValidationErrors &&
                  <ErrorMessage message="The form has incomplete or invalid values." />
                }

                {renderActionSubmit(
                  form,
                  <strong>Pay <Currency value={props.amount + props.fees} /></strong>,
                  'Paying...',
                  ButtonSize.Large,
                  undefined,
                  undefined,
                  form.values.cc == null
                )}
              </form>
            }
          </Form>
        </div>
      </div>
    </div>
  )
}

export default Payment
