import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

interface IRecentActionProps {
  loading: boolean
  title: string
  repeatActionTitle: string
  repeatAction: () => void
  historyUrl: string
}

const RecentAction: React.FC<IRecentActionProps> = props =>
  <div className="col-lg-4 my-3">
    <div className="border border-info rounded p-lg-5 p-4 h-100 dashboard">
      {props.loading ?
      <>
        <Skeleton height={50} style={{marginBottom: 30}} />
        <Skeleton height={60} style={{marginBottom: 20}} />
        <Skeleton height={50} style={{marginBottom: 30}} />
        <Skeleton height={40} style={{marginBottom: 15}} />
        <Skeleton height={40} />
      </> :
      <div>
        <h4 className="text-info mr-4"><span className="dashboard-tile-icon rt-wallet"></span>{props.title}</h4>
        {props.children}
        <div className="pt-2">
          {props.repeatActionTitle &&
            <button className="btn btn-block bg-action text-light" onClick={props.repeatAction}>{props.repeatActionTitle}</button>
          }
          <Link className="btn btn-outline-action btn-block" to={props.historyUrl}>See More</Link>
        </div>
      </div>
    }
    </div>
  </div>

export default RecentAction;
