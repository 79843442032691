import React from 'react';

const InmateWalletInformation = (
  <>
    <p>The funds paid into an Inmate Wallet account are tied directly to an inmate’s personal device for them to use for texting, voice calls, and video calls (as available by facility). These funds are non-transferable and non-refundable, however, if the inmate returns to the jail at a future point in time the account balance will be available for them to use.</p>
    <p>Reliance Telephone Inmate Wallet costs are as follows:</p>
    <p className="font-weight-bold mb-0"><u>Cost of messages sent or received</u></p>
    <p>$0.09/message</p>
  </>
)

export default InmateWalletInformation;
