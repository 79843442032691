import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { RouteComponentProps, RouterProps, withRouter } from 'react-router';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as accountActions from '../../../actions/account';
import * as checkoutActions from '../../../actions/checkout';
import * as collectCheckoutActions from '../../../actions/checkout/collect';
import * as onlineUserAccountActions from '../../../actions/online-user-account';
import { AccountLinkStatus } from '../../../models/Account';
import { IOnlineUserAccount } from '../../../models/OnlineUserAccount';
import { IAppState } from '../../../reducers';
import { IAccountViewState } from '../../../reducers/account/view';
import { Service as ServiceType } from '../../../reducers/checkout';
import ErrorMessage from '../../ErrorMessage';
import PageTitle from '../../PageTitle';
import Navigation from '../Navigation';
import Details from './Details';
import MakePayment from './MakePayment';
import RecentCalls from './RecentCalls';
import RecentPayments from './RecentPayments';

interface IAccountViewUrlProps extends RouteComponentProps {
  ani?: string
}

interface IAccountViewProps extends IAccountViewState, RouterProps {
  load(ani: string): Promise<IOnlineUserAccount>
  toggleRecentCallsExpansion: () => void
  toggleRecentPaymentsExpansion: () => void
  makePayment: (account: IOnlineUserAccount, amount: number) => void
  reset: () => void
}

const AccountView: React.FC<IAccountViewUrlProps & IAccountViewProps> = props => {
  useEffect(() => {
    props.load(props.ani);

    return function accountViewCleanup() {
      props.reset();
    }
  }, []);

  return (
    <>
      <PageTitle title={`Account ${props.ani}`} />
      <Navigation title={props.ani} />

      {props.account ? (
        <main className="container pt-3">
          <section className="row pb-3">
            <div className="col-lg-6 my-3">
              <Details account={props.account} />
            </div>

            <div className="col-lg-6 my-3">
              {props.account.account.accountLinkStatus != AccountLinkStatus.DISABLED && props.account.account.accountLinkStatus != AccountLinkStatus.PAYMENT_HOLD ?
                <MakePayment
                  account={props.account}
                  onSubmit={data => {
                    props.makePayment(props.account, data.amount);
                    props.history.push('/checkout/collect');
                  }} /> :
                (
                  props.account.account.accountLinkStatus == AccountLinkStatus.DISABLED ?
                    <ErrorMessage message="The account you specified has been temporarily disabled. Please contact Reliance Telephone at 1-800-896-3201 between 9am-6pm CST for more information." /> :
                    <ErrorMessage message="The account you specified has a payment hold in place and is not available to receive payments at this moment. Please call us at 800-896-3201 between 9am-6pm CST." />
                )
              }
            </div>
          </section>

          <RecentCalls
            expanded={props.recentCallsExpanded}
            onToggleExpansion={props.toggleRecentCallsExpansion}
            calls={props.account.account.recentCalls} />
          <RecentPayments
            expanded={props.recentPaymentsExpanded}
            onToggleExpansion={props.toggleRecentPaymentsExpansion}
            payments={props.account.account.recentPayments} />
        </main>
      ) : (
        <main className="container pt-3">
          <section className="row pb-3">
            <div className="col-lg-6 my-3">
              <Skeleton height={360} />
            </div>

            <div className="col-lg-6 my-3">
              <Skeleton height={360} />
            </div>
          </section>

          <Skeleton height={56} />
          <Skeleton height={56} style={{ marginTop: 10, marginBottom: 10 }} />
        </main>
      )}
    </>
  );
}

export default withRouter(connect(
  (state: IAppState) => state.account.view,
  ((dispatch: ThunkDispatch<IAppState, undefined, Action>) => ({
    load: (ani: string) => dispatch(onlineUserAccountActions.loadAccount(ani)),
    toggleRecentCallsExpansion: () => dispatch(accountActions.toggleRecentCallsExpansion()),
    toggleRecentPaymentsExpansion: () => dispatch(accountActions.toggleRecentPaymentsExpansion()),
    reset: () => dispatch(onlineUserAccountActions.reset()),
    makePayment: (account: IOnlineUserAccount, amount: number) => {
      dispatch(checkoutActions.selectService(ServiceType.Collect));
      dispatch(collectCheckoutActions.setAccount(account));
      dispatch(collectCheckoutActions.setAmount(amount));
    }
  }))
)(AccountView));
