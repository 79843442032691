import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RouterProps, withRouter } from 'react-router';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as checkoutActions from '../../../actions/checkout';
import * as visitationActions from '../../../actions/visitation';
import { IVisitation } from '../../../models/Visitation';
import { IAppState } from '../../../reducers';
import { Service as ServiceType } from '../../../reducers/checkout';
import { IVisitationScheduledState } from '../../../reducers/visitation';
import { IPaginatedResponse } from '../../../services/api';
import PageTitle from '../../PageTitle';
import Navigation from '../Navigation';
import LoadingRow from './LoadingRow';
import ScheduledRow from './ScheduledRow';

interface IVisitationScheduledProps extends IVisitationScheduledState, RouterProps {
  load(): Promise<IPaginatedResponse<IVisitation>>
  cancel(visitation: IVisitation): Promise<any>
}

const VisitationScheduled: React.FC<IVisitationScheduledProps> = props => {
  useEffect(() => {
    props.load();
  }, []);

  const dispatch = useDispatch();

  return (
    <>
      <PageTitle title="Scheduled Video Visitation" />
      <Navigation title="Scheduled Video Visitation" />

      <main className="container pt-3">
        <section className="row">
          <div className="col mb-3">
            <div className="border border-dark rounded h-100 pb-4">
              <h5 className="bg-dark text-light px-5 py-3"><span></span>Scheduled Video Visitation</h5>
              <div className="px-lg-5 p-4">
                <button className="btn btn-info btn-block" onClick={() =>{
                  dispatch(checkoutActions.selectService(ServiceType.Visitation));
                  props.history.push('/visitation/schedule');
                  return false;
                }}>Schedule new video visitation</button>
              </div>

              {props.loading && <LoadingRow />}

              {props.scheduled && props.scheduled.data && props.scheduled.data.map(v =>
                <ScheduledRow
                  visitation={v}
                  cancelling={props.cancellingVisitation == v || props.loading}
                  onCancel={() => {
                    return props
                      .cancel(v)
                      .finally(() => props.load());
                  }}
                  key={v.id} />
              )}
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default withRouter(connect(
  (state: IAppState) => state.visitation.scheduled,
  ((dispatch: ThunkDispatch<IAppState, undefined, Action>) => ({
    load: () => dispatch(visitationActions.load()),
    cancel: (visitation: IVisitation) => dispatch(visitationActions.cancel(visitation))
  }))
)(VisitationScheduled));
