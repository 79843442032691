import { FormState } from 'final-form';
import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import { Field } from 'react-final-form';
import { composeValidators } from '../../validation';

function renderSubmit<T>(
  type: ButtonType,
  form: FormState<T>,
  text: string|JSX.Element,
  submittingText: string|JSX.Element,
  additionalCssClasses: string = 'btn-action',
  onClick?: (e) => any,
  disabled: boolean = false
) {
  return (
    <button
      onClick={onClick}
      className={`btn btn-block ${additionalCssClasses} ${type}`}
      type="submit"
      disabled={text != 'Cancel' && (disabled || (form && form.submitting))}>
      {form && form.submitting ? submittingText : text}
    </button>
  )
}

export enum ButtonSize {
  Default = '',
  Large = 'btn-lg'
}

export enum ButtonType {
  Secondary = 'btn-secondary',
  Action = 'btn-action',
  Danger = 'btn-danger',
  Primary = 'btn-primary'
}

export function renderSecondarySubmit<T>(
  form: FormState<T>,
  text: string|JSX.Element,
  submittingText?: string|JSX.Element,
  size: ButtonSize = ButtonSize.Default,
  onClick?: () => any,
  disabled: boolean = false
) {
  return renderSubmit(
    ButtonType.Secondary,
    form,
    text,
    submittingText || text,
    `text-light ${size}`,
    onClick,
    disabled
  )
}

export function renderActionSubmit<T>(
  form: FormState<T>,
  text: string|JSX.Element,
  submittingText?: string|JSX.Element,
  size: ButtonSize = ButtonSize.Default,
  outlined: boolean = false,
  onClick?: (e) => any,
  disabled: boolean = false
) {
  return renderSubmit(
    ButtonType.Action,
    form,
    text,
    submittingText || text,
    `${outlined ? 'btn-outline-action bg-light' : ''} ${size}`,
    onClick,
    disabled
  )
}

export function renderDangerSubmit<T>(
  form: FormState<T>,
  text: string|JSX.Element,
  submittingText?: string|JSX.Element,
  size: ButtonSize = ButtonSize.Default,
  outlined: boolean = false,
  onClick?: () => any,
  disabled: boolean = false
) {
  return renderSubmit(
    ButtonType.Danger,
    form,
    text,
    submittingText || text,
    `${outlined ? 'btn-outline-action bg-light' : ''} ${size}`,
    onClick,
    disabled
  )
}

export function renderPrimarySubmit<T>(
  form: FormState<T>,
  text: string|JSX.Element,
  submittingText?: string|JSX.Element,
  size: ButtonSize = ButtonSize.Default,
  onClick?: () => any,
  disabled: boolean = false
) {
  return renderSubmit(
    ButtonType.Primary,
    form,
    text,
    submittingText || text,
    `btn-primary ${size}`,
    onClick,
    disabled
  )
}

export const renderSelect = (
  id: string,
  name: string,
  label: string,
  options: JSX.Element[],
  validationRules: Array<any> = [],
  disabled: boolean = false
) =>
  <Field
    name={name}
    validate={composeValidators(validationRules)}>
    {({ input, meta }) => (
      <>
        <select
          {...input}
          id={id}
          name={id}
          disabled={disabled}
          className="form-control">
          {options}
        </select>

        {label &&
          <label htmlFor={id}>{label}</label>
        }

        {meta.error && meta.touched &&
          <p className="text-error subtitle errormessage py-1" style={{ flexBasis: '100%' }}>
            {meta.error}
          </p>
        }
      </>
    )}
  </Field>

export enum InputType {
  button = 'button',
  checkbox = 'checkbox',
  color = 'color',
  date = 'date',
  datetimeLocal = 'datetime-local',
  email = 'email',
  file = 'file',
  hidden = 'hidden',
  image = 'image',
  month = 'month',
  number = 'number',
  password = 'password',
  radio = 'radio',
  range = 'range',
  reset = 'reset',
  search = 'search',
  submit = 'submit',
  tel = 'tel',
  text = 'text',
  time = 'time',
  url = 'url',
  week = 'week',
}

export const renderTextInput = (
  id: string,
  name: string,
  placeholder: string,
  label: string,
  validationRules: Array<any> = [],
  type: InputType = InputType.text
) =>
  <Field
    name={name}
    validate={composeValidators(validationRules)}>
    {({ input, meta }) => (
      <>
        <input
          disabled={meta.submitting}
          {...input}
          type={type}
          placeholder={placeholder}
          id={id}
          name={id}
          className="form-control" />

        {label &&
          <label htmlFor={id} style={{zIndex: -10}}>{label}</label>
        }

        {meta.error && meta.touched &&
          <p className="text-error subtitle errormessage py-1">
            {meta.error}
          </p>
        }
      </>
    )}
  </Field>

export const renderTextAreaInput = (
  id: string,
  name: string,
  placeholder: string,
  label: string,
  rows: number,
  validationRules: Array<any> = [],
  type: InputType = InputType.text
) =>
  <Field
    name={name}
    validate={composeValidators(validationRules)}>
    {({ input, meta }) => (
      <>
        <textarea
          disabled={meta.submitting}
          {...input}
          rows={rows}
          placeholder={placeholder}
          id={id}
          name={id}
          className="form-control" />

        {label &&
          <label htmlFor={id}>{label}</label>
        }

        {meta.error && meta.touched &&
          <p className="text-error subtitle errormessage py-1">
            {meta.error}
          </p>
        }
      </>
    )}
  </Field>

export const renderCheckboxInput = (
  id: string,
  name: string,
  label: string,
  validationRules: any[] = [],
  labelClassName: string = 'bodysm'
) =>
  <Field
    type="checkbox"
    name={name}
    validate={composeValidators(validationRules)}>
    {({ input, meta }) => (
      <div className="form-group form-check m-0 p-0">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            {...input}
            disabled={meta.submitting}
            id={id}
            name={id} />
          <label className="form-check-label" htmlFor={id}>
            <strong className={labelClassName}>{label}</strong>
          </label>

          {meta.error && meta.touched &&
            <p className="text-error subtitle errormessage py-1">
              {meta.error}
            </p>
          }
        </div>
      </div>
    )}
  </Field>

interface IButtonGroupOption {
  variant
  value: any
  label: string|JSX.Element
}
export const renderButtonGroup = (
  id: string,
  name: string,
  label: string,
  options: IButtonGroupOption[],
  validationRules: Array<any> = []
) =>
  <Field
    id={id}
    name={name}
    validate={composeValidators(validationRules)}>
    {({ input, meta }) => (
      <>
        {label && <div><label htmlFor={id}>{label}</label></div>}
        <ButtonGroup>
          {options.map(o =>
            <Button
              disabled={meta.submitting}
              variant={input.value == o.value ? 'primary' : o.variant}
              key={o.value}
              onClick={() => input.onChange(o.value)}>
              {o.label}
            </Button>
          )}
        </ButtonGroup>
      </>
    )}
  </Field>

interface ITypeaheadOption {
  id: number
  label: string
}
export const renderTypeaheadInput = (
  id: string,
  name: string,
  placeholder: string,
  label: string,
  options: ITypeaheadOption[],
  validationRules: any[] = [],
  disabled: boolean = false,
  onSelect?: (value: ITypeaheadOption[]) => void
) =>
  <Field
    name={name}
    validate={composeValidators(validationRules)}>
    {({ input, meta }) => (
      <>
        <Typeahead
          onChange={onSelect}
          disabled={disabled || meta.submitting}
          inputProps={{
            className: "search",
            name
          }}
          id={id}
          placeholder={placeholder}
          options={options} />

        {label &&
          <label htmlFor={id}>{label}</label>
        }

        {meta.error && meta.touched &&
          <p className="text-error subtitle errormessage py-1">
            {meta.error}
          </p>
        }
      </>
    )}
  </Field>

export const renderFormInput = (
  id: string,
  name: string,
  label: string,
  validationRules: Array<any> = [],
  type: InputType = InputType.text
) =>
  <Field
    name={name}
    validate={composeValidators(validationRules)}>
    {({ input, meta }) => (
      <>
        {label &&
          <Form.Label>{label}</Form.Label>
        }
        <Form.Control
          disabled={meta.submitting}
          {...input}
          type={type}
          id={id}
          name={id}
          className="form-control" />

        {meta.error && meta.touched &&
          <p className="text-error subtitle errormessage py-1">
            {meta.error}
          </p>
        }
      </>
    )}
  </Field>
