import React from 'react';
import { Form } from 'react-final-form';
import { match, password, required } from '../../validation';
import ErrorMessage from '../ErrorMessage';
import { InputType, renderPrimarySubmit, renderTextInput } from '../Form/RenderForm';

interface IResetPasswordFormProps {
  onReset: (data: IResetPasswordRequestData, form) => Promise<any>
}

interface IResetPasswordRequestData {
  newPassword: string
}

const ResetPasswordForm: React.FC<IResetPasswordFormProps> = props =>
  <Form
    onSubmit={(data: IResetPasswordRequestData, form) =>
      props.onReset(data, form)
    }>
    {form =>
      <form className="form-account-new" onSubmit={form.handleSubmit} method="post">
        <div className="mb-4 text-center">
          <h3>Reset password</h3>
          <p className="bodysm">Enter your desired new password below.</p>
        </div>

        {form.submitError &&
          <ErrorMessage
            error={form.submitError}
            httpCodeMessages={{
              400: 'The password doesn\'t meet the requirements'
            }} />
        }

        <div className="form-label-group">
          {renderTextInput(
            'resetPassword.newPassword',
            'newPassword',
            'New password',
            'New password',
            [required, password],
            InputType.password
          )}
        </div>

        <div className="form-label-group">
          {renderTextInput(
            'resetPassword.confirmNewPassword',
            'confirmNewPassword',
            'Confirm new password',
            'Confirm new password',
            [required, match('newPassword', 'Passwords do not match')],
            InputType.password
          )}
        </div>
        {renderPrimarySubmit<IResetPasswordRequestData>(form, 'Reset password', 'Resetting...')}
      </form>
    }
  </Form>

export default ResetPasswordForm;