import { IApiSuccessResponse } from '../models/Api';
import { Facility } from '../models/Facility';
import { ITransactionFeeData, TransactionFee } from '../models/Transaction';
import { IWebsiteBaseData, IWebsiteContactData } from '../models/Website';
import Api from '../services/api';

export function postContact(contactData: IWebsiteContactData): Promise<IApiSuccessResponse<any>> {
  return Api
    .post<IApiSuccessResponse<any>>({
      url: '/website/contact',
      data: contactData,
      authenticated: false
    });
}

interface IWebsiteBaseDataResponse {
  facilities: Facility[]
  feeTable: ITransactionFeeData[]
}

export function getIndex(): Promise<IWebsiteBaseData> {
  return Api
    .get<IWebsiteBaseDataResponse>({
      url: '/website',
      authenticated: false
    })
    .then(res => ({
      ...res,
      facilities: res.facilities.map(f => new Facility(f)),
      feeTable: res.feeTable.map(t => new TransactionFee(t))
    }));
}