import { FORM_ERROR } from 'final-form';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouterProps, withRouter } from 'react-router';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as checkoutActions from '../../../actions/checkout';
import * as checkoutCollectActions from '../../../actions/checkout/collect';
import * as onlineUserAccountActions from '../../../actions/online-user-account';
import { IApiSuccessResponse } from '../../../models/Api';
import { IOnlineUserAccount } from '../../../models/OnlineUserAccount';
import { IAppState } from '../../../reducers';
import { IAccountsListState } from '../../../reducers/account/list';
import { Service } from '../../../reducers/checkout';
import PageTitle from '../../PageTitle';
import Navigation from '../Navigation';
import AccountRow from './AccountRow';
import AddModal from './AddModal';
import LoadingRow from './LoadingRow';

interface IAccountsProps extends IAccountsListState, RouterProps {
  loading: boolean
  load(): Promise<IOnlineUserAccount[]>
  add(ani: string, verificationMethod: 'Text'|'Call'): Promise<IOnlineUserAccount>
  resendVerification(ani: string, verificationMethod: 'Text'|'Call'): Promise<IOnlineUserAccount>
  verify(ani: string, verificationCode: string): Promise<IApiSuccessResponse<IOnlineUserAccount>>
  setupPayment: (account?: IOnlineUserAccount) => void
}

const Accounts: React.FC<IAccountsProps> = props => {
  useEffect(() => {
    props.load();
  }, [])

  const [addingNewAccount, showAddAccount] = useState(false)
  const [resendVerificationCode, showResendVerificationCode] = useState(false)
  const [verifyingAccount, verifyAccount] = useState<IOnlineUserAccount>(null)
  const [verifiedAccount, setVerifiedAccount] = useState(null)
  const [duplicateAccountError, setDuplicateAccountError] = useState(false)
  const [addAttemptCount, setAddAttemptCount] = useState(0)

  const accounts = props.accounts ?
    props.accounts.sort((a,b) =>
      ((a.dateVerified ? 0 : 1) > (b.dateVerified ? 0 : 1)) ? -1 : 0 &&
      a.ani.localeCompare(b.ani)) :
    []

  return (
    <>
      <PageTitle title="Accounts" />
      <Navigation title="Prepaid Collect Call Accounts" />

      <main className="container pt-3">
        <section className="row">
          <div className="col mb-3">
            <div className="border border-dark rounded h-100 pb-4">
              <h5 className="bg-dark text-light px-5 py-3"><span></span>Prepaid Collect Call Accounts</h5>
              <div className="px-lg-5 p-4">
                <button
                  className="btn btn-info btn-block"
                  onClick={() => showAddAccount(!addingNewAccount)}>
                  Add new account
                </button>
              </div>

              {addingNewAccount &&
                <AddModal
                  pendingAccount={verifyingAccount}
                  resendVerificationCode={resendVerificationCode}
                  showResendVerificationCode={() => showResendVerificationCode(!resendVerificationCode)}
                  isDuplicate={duplicateAccountError}
                  onCancel={() => {
                    showAddAccount(false)
                    verifyAccount(null)
                    setDuplicateAccountError(false)
                  }}
                  onSubmit={(data) => {
                    if (data.resendVerification && data.resendVerification.method) {
                      return props
                        .resendVerification(verifyingAccount.ani, data.resendVerification.method)
                        .then(() => showResendVerificationCode(false))
                        .catch(err => ({ [FORM_ERROR]: err.error ? err.error : err }));
                    }

                    if (duplicateAccountError) {
                      props.setupPayment(verifiedAccount);
                      setDuplicateAccountError(false);
                      props.history.push('/checkout/collect');
                      return null;
                    }

                    if (verifyingAccount == null) {
                      return props
                        .add(data.account.ani, data.verifyAccount.method)
                        .then((res: IOnlineUserAccount) => {
                          props.load();
                          verifyAccount(res);
                          setAddAttemptCount(addAttemptCount+1);
                          if (!res.dateVerified && addAttemptCount > 0) {
                            return Promise.reject(`${data.account.ani} is already linked, but not verified. Please verify.`);
                          }
                          return res;
                        })
                        .catch(err => {
                          if (err.response?.status == 409) {
                            if (err.data.error.reasonCode == 'DUPLICATE') {
                              setDuplicateAccountError(true);
                              return ({ [FORM_ERROR]: `${data.account.ani} is already linked`})
                            } else {
                              return ({ [FORM_ERROR]: `${data.account.ani} is linked to a different online user`})
                            }
                          }
                          return ({ [FORM_ERROR]: err.error ? err.error : err })
                        });
                    }

                    return props
                      .verify(verifyingAccount.ani, data.verifyAccount.code)
                      .then(res => {
                        if (res.success == false) {
                          return { [FORM_ERROR]: 'Invalid verification code' };
                        }
                        props.load()
                        showAddAccount(false)
                        verifyAccount(null)
                        setVerifiedAccount(verifyingAccount)
                        setAddAttemptCount(0)
                        return res;
                      })
                      .catch(err => ({ [FORM_ERROR]: err.error ? err.error : err }))
                  }} />
              }

              {props.loading && <LoadingRow />}

              {accounts.map(h =>
                <AccountRow
                  onVerify={() => {
                    showAddAccount(true)
                    verifyAccount(h)
                  }}
                  account={h}
                  key={h.id} />
              )}
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default withRouter(
  connect(
    (state: IAppState) => state.account.list,
    ((dispatch: ThunkDispatch<IAppState, undefined, Action>) => ({
      load: () => dispatch(onlineUserAccountActions.load()),
      add: (ani: string, verificationMethod: 'Text'|'Call') => dispatch(onlineUserAccountActions.add(ani, verificationMethod)),
      verify: (ani: string, verificationCode: string) => dispatch(onlineUserAccountActions.verify(ani, verificationCode)),
      resendVerification: (ani: string, verificationMethod: 'Text'|'Call') => dispatch(onlineUserAccountActions.resendVerificationCode(ani, verificationMethod)),
      setupPayment: (account: IOnlineUserAccount = null) => {
        dispatch(checkoutActions.selectService(Service.Collect));
        dispatch(checkoutCollectActions.setAccount(account));
      }
    }))
  )(Accounts)
);
