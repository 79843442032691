import moment from 'moment';
import { RehydrateAction } from 'redux-persist';
import { IAppState } from '.';
import * as loginActions from '../actions/auth';
import * as cardCheckoutActions from '../actions/checkout/card';
import * as collectCheckoutActions from '../actions/checkout/collect';
import * as walletCheckoutActions from '../actions/checkout/wallet';
import { InmateActionTypes } from '../actions/inmate';
import * as onlineUserActions from '../actions/online-user';
import { TaxActionTypes } from '../actions/tax';
import { IPaymentData } from '../components/Checkout/Collect/Payment';
import { IOnlineUser } from '../models/OnlineUser';
import { IToken } from '../resources/Auth';
import { ICardPurchaseRequest } from '../resources/PhoneCardPurchase';

export interface IAuthState {
  loggedIn: boolean
  user: IOnlineUser
  token: IToken
  registering
  registerError?: Error

  updatedProfile: boolean
  updatedPassword: boolean
}

const initialState: IAuthState = {
  loggedIn: false,
  user: null,
  token: null,
  registering: false,
  updatedProfile: false,
  updatedPassword: false
}

export function authReducer(
  state: IAuthState = initialState,
  action: (
    loginActions.AuthActionTypes |
    InmateActionTypes |
    TaxActionTypes |
    walletCheckoutActions.WalletActionTypes |
    onlineUserActions.OnlineUserActionTypes |
    collectCheckoutActions.CollectActionTypes |
    cardCheckoutActions.CardTypeActions |
    RehydrateAction
  )
): IAuthState {
  switch (action.type) {
    case loginActions.LOGGING_IN:
      return {
        ...state,
        loggedIn: false,
        user: null,
        token: null
      };

    case loginActions.LOGIN_FAILURE:
      return {
        ...state,
        loggedIn: false,
        user: null,
        token: null
      };

    case loginActions.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        user: action.user
      };

    case loginActions.LOGOUT:
      return {
        ...state,
        loggedIn: false,
        user: null,
        token: null
      };

    case loginActions.AUTH_TOKEN_LOAD_FAILED:
      return {
        ...state,
        loggedIn: false,
        user: null,
        token: null
      };

    case loginActions.AUTH_TOKEN_LOAD_SUCCESS:
      return {
        ...state,
        token: {
          ...action.token,
          expires: moment().add(action.token.expires_in, 'seconds').toDate()
        }
      };

    case onlineUserActions.PROFILE_UPDATING:
      return {
        ...state,
        updatedProfile: false
      };

    case onlineUserActions.PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        user: Object.assign({}, state.user, action.user),
        updatedProfile: true
      };

    case onlineUserActions.PROFILE_UPDATE_FAILURE:
      return {
        ...state,
        updatedProfile: false
      };

    case onlineUserActions.PASSWORD_CHANGING:
      return {
        ...state,
        updatedPassword: false
      };

    case onlineUserActions.PASSWORD_CHANGE_FAILURE:
      return {
        ...state,
        updatedPassword: false
      };

    case onlineUserActions.PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        updatedPassword: true
      };

    case onlineUserActions.REGISTERING:
      return {
        ...state,
        registering: true,
        registerError: null
      }

    case onlineUserActions.REGISTER_FAILURE:
      return {
        ...state,
        registering: false,
        registerError: action.error
      }

    case onlineUserActions.REGISTER_SUCCESS:
      return {
        ...state,
        registering: false,
        registerError: null
      }

    case collectCheckoutActions.PAYMENT_SUCCESS:
      // If the user hasn't populated their personal information yet, the API will have after this payment. Populate in-memory
      const collectPaymentData: IPaymentData = action.paymentData;
      if (state.user.firstName == '') {
        return {
          ...state,
          user: {
            ...state.user,
            firstName: collectPaymentData.firstName,
            lastName: collectPaymentData.lastName,
            address: collectPaymentData.address,
            city: collectPaymentData.city,
            state: collectPaymentData.state,
            zip: collectPaymentData.zip,
            emailAddress: state.user.emailAddress || collectPaymentData.receiptEmailAddress
          }
        }
      }
      return state

    case cardCheckoutActions.PURCHASE_SUCCESS:
      // If the user hasn't populated their personal information yet, the API will have after this payment. Populate in-memory
      const purchaseData: ICardPurchaseRequest = action.purchaseData;
      if (state.user.firstName == '') {
        return {
          ...state,
          user: {
            ...state.user,
            firstName: purchaseData.purchaserDetails.firstName,
            lastName: purchaseData.purchaserDetails.lastName,
            address: purchaseData.purchaserDetails.address,
            city: purchaseData.purchaserDetails.city,
            state: purchaseData.purchaserDetails.state,
            zip: purchaseData.purchaserDetails.zip,
            emailAddress: state.user.emailAddress || purchaseData.purchaserDetails.receiptEmailAddress
          }
        }
      }
      return state

    case walletCheckoutActions.PAYMENT_SUCCESS:
      // If the user hasn't populated their personal information yet, the API will have after this payment. Populate in-memory
      const walletPaymentData = action.paymentData;
      if (state.user.firstName == '') {
        return {
          ...state,
          user: {
            ...state.user,
            firstName: walletPaymentData.firstName,
            lastName: walletPaymentData.lastName,
            address: walletPaymentData.address,
            city: walletPaymentData.city,
            state: walletPaymentData.state,
            zip: walletPaymentData.zip,
            emailAddress: state.user.emailAddress || walletPaymentData.receiptEmailAddress
          }
        }
      }
      return state

    case 'persist/REHYDRATE':
      const lastState = action.payload as IAppState;

      // Set authorization token if available
      if (action && action.payload && lastState.auth && lastState.auth.token) {
        return {
          ...state,
          loggedIn: lastState.auth.loggedIn && lastState.auth.token != null && lastState.auth.user != null,
          token: lastState.auth.token,
          user: lastState.auth.user
        };
      }

      // If we do not have everything we need for authentication, mark the user as logged out
      if (action && lastState && lastState.auth && lastState.auth.token == null) {
        return {
          ...state,
          loggedIn: false,
          user: null,
          token: null
        };
      }
  }

  return state;
}
