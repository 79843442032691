import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as walletReceiptActions from '../../../actions/wallet/receipt';
import { IWalletPayment } from '../../../models/WalletPayment';
import { IAppState } from '../../../reducers';
import Confirmation from '../../Checkout/Wallet/Confirmation';
import ErrorMessage from '../../ErrorMessage';
import PageTitle from '../../PageTitle';

interface IWalletReceiptUrlParams {
  id?: string
}

interface IWalletReceiptRouteState {
  payment?: IWalletPayment
}

const WalletReceipt: React.FC<RouteChildrenProps<IWalletReceiptUrlParams, IWalletReceiptRouteState>> = props => {
  const state = useSelector((appState: IAppState) => ({
    wallet: appState.wallet,
    facilities: appState.common.facilities
  }));
  const dispatch: ThunkDispatch<IAppState, undefined, Action> = useDispatch();

  useEffect(() => {
    dispatch(walletReceiptActions.loadPayment(props.match.params.id ?? state.wallet.receipt?.payment?.id));

    return function cleanupWalletReceipt() {
      dispatch(walletReceiptActions.reset());
    };
  }, []);

  // If we still have the receipt email address (since it isn't persisted with the payment), we will display it. Otherwise it'll be hidden
  const receiptEmailAddress = state.wallet.receipt.receiptEmailAddress;

  const facility = (state.facilities || []).filter(f => f.id == state.wallet.receipt.payment?.inmate?.facility?.id).pop();

  return (
    <main className="container align-items-top justify-content-center mt-5 mt-lg-3 pt-4">
      <section className="no-gutters">
        <PageTitle title="Inmate Wallet Payment" />

        {state.wallet.receipt.paymentLoadError ?
          <ErrorMessage
            error={state.wallet.receipt.paymentLoadError}
            httpCodeMessages={{
              404: 'This receipt was not found'
            }}
            /> :
          (
            state.wallet.receipt.paymentLoading || !state.wallet.receipt.payment ?
              <div className="row d-flex justify-content-center">
                <div className="col-md-6">
                  <Skeleton height={700} className="m-5" />
                </div>
              </div> :
              <Confirmation
                facility={facility}
                payment={state.wallet.receipt.payment}
                receiptEmailAddress={receiptEmailAddress}
                />
          )
        }
      </section>
    </main>
  )
}

export default WalletReceipt;
