import { combineReducers } from 'redux';
import accountReducer, { IAccountState } from './account';
import { appsReducer, IAppsState } from './apps';
import { authReducer, IAuthState } from './auth';
import { cardReducer, ICardState } from './card';
import { checkoutReducer, ICheckoutState } from './checkout';
import { ICollectState, collectReducer } from './collect';
import { commonReducer, ICommonState } from './common';
import { dashboardReducer, IDashboardState } from './dashboard';
import { forgotPasswordReducer, IForgotPasswordState } from './forgotPassword';
import { homeReducer, IHomeState } from './home';
import { ILoginState, loginReducer } from './login';
import { IPhoneCardPurchaseHistoryState, phoneCardPurchaseHistoryReducer } from './phoneCardPurchaseHistory';
import { IVisitationState, visitationReducer } from './visitation/index';
import { IWalletState, walletReducer } from './wallet';
import { IWalletPaymentHistoryState, walletPaymentHistoryReducer } from './walletPaymentHistory';

export interface IAppState {
  account: IAccountState
  apps: IAppsState
  auth: IAuthState
  checkout: ICheckoutState
  common: ICommonState
  dashboard: IDashboardState
  home: IHomeState
  login: ILoginState
  forgotPassword: IForgotPasswordState
  phoneCardPurchaseHistory: IPhoneCardPurchaseHistoryState
  walletPaymentHistory: IWalletPaymentHistoryState
  visitation: IVisitationState
  wallet: IWalletState
  card: ICardState
  collect: ICollectState
}

export default combineReducers<IAppState>({
  account: accountReducer,
  apps: appsReducer,
  auth: authReducer,
  checkout: checkoutReducer,
  common: commonReducer,
  dashboard: dashboardReducer,
  home: homeReducer,
  login: loginReducer,
  forgotPassword: forgotPasswordReducer,
  phoneCardPurchaseHistory: phoneCardPurchaseHistoryReducer,
  visitation: visitationReducer,
  walletPaymentHistory: walletPaymentHistoryReducer,
  wallet: walletReducer,
  card: cardReducer,
  collect: collectReducer
});
