import { FORM_ERROR } from 'final-form';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as loginActions from '../actions/auth';
import * as onlineUserActions from '../actions/online-user';
import NewAccount from '../components/Login/NewAccount';
import ReturningCustomer from '../components/Login/ReturningCustomer';
import PageTitle from '../components/PageTitle';
import SuccessMessage from '../components/SuccessMessage';
import { IAppState } from '../reducers';

const Login: React.FC = props => {
  const state = useSelector((state: IAppState) => ({
    login: state.login,
    auth: state.auth
  }));
  let dispatch: ThunkDispatch<IAppState, undefined, Action> = useDispatch();

  useEffect(() => {
    return function cleanupLogin() {
      dispatch(loginActions.resetLogin());
    }
  }, []);

  if (state.auth.loggedIn) {
    return <Redirect to="/account/dashboard" />
  }

  return (
    <main className="container pt-5">
      <PageTitle title="Login" />

      <div className="row my-5 pt-5 border border-primary rounded">
        <div className="col-lg-6 my-5 p-5">
          {state.login.resetPassword &&
            <SuccessMessage message="Your password was successfully reset. Please login below." />
          }

          <ReturningCustomer
            onSubmit={data =>
              dispatch(loginActions.login(data.emailAddress.trim(), data.password))
                .catch(err => {
                  return { [FORM_ERROR]: err.error ? err.error : err };
                })
            } />

          <div style={{ marginTop: 10, textAlign: 'center' }}>
            <Link to="/login/forgot-password">Forgot your password?</Link>
          </div>
        </div>
        <div className="col-lg-6 my-5 p-5 vertical-divider-left">
          <NewAccount
            onSubmit={data =>
              dispatch(onlineUserActions.register(data.emailAddress.trim(), data.password))
                .catch(err => {
                  return { [FORM_ERROR]: err.error ? err.error : err };
                })
              } />
        </div>
      </div>
    </main>
  );
}

export default Login;