import React from 'react';
import { Facility, IFacility } from '../../../models/Facility';
import { ICardTypeAvailable } from '../../../models/PhoneCard';
import NameAndAddress from '../../Facility/NameAndAddress';
import Currency from '../../Format/Currency';

interface IInfoBoxProps {
  facility: IFacility
  cardType: ICardTypeAvailable
  fees: number
  inmateFirstName: string
  inmateLastName: string
  quantity: number
  taxes: number
}

const InfoBox:React.FC<IInfoBoxProps> = props =>
  <div className="col-md-4 mb-3 rt-status">
    <aside className="bg-secondary text-light rounded p-3 p-lg-5">
      <h4>You've selected:</h4>

      <div className="selected-service py-lg-2">
        <h5>Phone Card</h5>

        {props.cardType &&
          <div className="checkout-fees">
            <p className="total"><Currency value={props.cardType.unitPrice} /></p>
            {props.quantity > 1 && <p className="quantity">{props.quantity}</p>}
            <p className="transaction-fee"><Currency value={props.fees} /></p>
            {props.taxes > 0 && <p className="taxes"><Currency value={props.taxes} /></p>}
            <p className="total-charge"><Currency value={(props.cardType.unitPrice * props.quantity) + props.fees + props.taxes} /></p>
          </div>
        }

        <div className="bodysm">
          <p className="bodysm">
            Inmate Phone Cards are ONLY for calls made from the Reliance county correctional facilities. This card can be used at any one of the Reliance county facilities, but they are not normal prepaid phone cards so select the facility carefully.
          </p><p className="bodysm">
            Treat the PIN you receive at checkout as cash and share it with the inmate in a secure manner - do not leave it on the inmate voicemail. Be sure to write down or print the PIN as we CANNOT access it for you later.
          </p><p className="bodysm">
            Displayed rates are for domestic (US) calls only. Actual rates may vary by number/country called. Remaining phone card balances can be transferred to Inmate Wallet. Reliance is not responsible for unauthorized use and we cannot reimburse you for such use.
          </p>
        </div>
      </div>

      {props.inmateFirstName &&
        <>
          <hr className="border-light" />
          <div className="selected-facility py-lg-2">
            <h5>For inmate:</h5>

            <p className="subtitle subhead inmate-name">
              {props.inmateFirstName} {props.inmateLastName}
            </p>
          </div>
        </>
      }

      <hr className="border-light" />
      <div className="selected-facility py-lg-2">
        <h5>Facility:</h5>
        <NameAndAddress facility={new Facility(props.facility)} />
      </div>
    </aside>
  </div>

export default InfoBox;