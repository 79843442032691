import React, { useEffect, useState } from 'react';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { match, RouterProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import * as checkoutActions from '../../actions/checkout';
import { Facility, FacilityFeature } from '../../models/Facility';
import { IAppState } from '../../reducers';
import { Service as ServiceType } from '../../reducers/checkout';
import ErrorMessage from '../ErrorMessage';
import PhoneNumber from '../Format/PhoneNumber';
import PageTitle from '../PageTitle';

interface ISummaryUrlProps {
  state?: string
  name?: string
}

interface ISummaryProps extends RouterProps {
  match: match<ISummaryUrlProps>
}

const Summary: React.FC<ISummaryProps> = props => {
  const dispatch = useDispatch();
  const state = useSelector((appState: IAppState) => appState);
  const [facility, setFacility] = useState<Facility>(null);

  useEffect(() => {
    if (state.common.facilities) {
      const matches = state.common.facilities.filter(f =>
        f.state.toLowerCase() == props.match.params.state.toLowerCase() && f.getConfigurationValue('UrlName') == props.match.params.name.toLowerCase()
      );
      setFacility(matches[0]);
    }
  }, [state.common.facilities]);

  const hasWallet = facility && (
    facility.featureTexting ||
    facility.features.indexOf(FacilityFeature.KioskAudioCalls) > -1 ||
    facility.features.indexOf(FacilityFeature.WirelessAudioCalls) > -1 ||
    facility.features.indexOf(FacilityFeature.WirelessVideoCalls) > -1
  );

  const facilityOfflineMessage = facility?.getConfigurationValue('FacilityOfflineMessage');

  const hasPrepaidCollectCalls = facility && (
    facility.features.indexOf(FacilityFeature.PrepaidCollectCalls) > -1
  );

  return (
    <div>
      <PageTitle title={facility ? facility.name : 'Loading...'} />

      <div className="hero bg-primary text-light py-5">
        <div className="container">
          <div className="row pt-5">
            <div className="col-lg-8 offset-lg-2 pt-5">
              {facility ?
                <>
                  <h1 className="d-none">{facility.name}</h1>
                  <span className="page-icon rt-about"></span>
                  <h2>{facility.name}</h2>
                </> :
                <>
                  <span className="page-icon rt-about"></span>
                  <h2>Loading...</h2>
                </>
              }
            </div>
          </div>
        </div>
      </div>

      {facilityOfflineMessage &&
        <main className="container">
          <div className="row py-5">
            <div className="col-md-12 d-flex justify-content-center">
              <ErrorMessage
                severity="warning"
                message={facilityOfflineMessage} />
            </div>
          </div>
        </main>
      }

      {facility && facilityOfflineMessage == null &&
        <main className="container">
          {hasWallet &&
            <div className="row py-5">
              <div className="col-md-6 image-service srvc-inmatewallet d-flex justify-content-center">
                <span className="align-self-center"></span>
              </div>

              <div className="col-md-6 py-5">
                <h3>Inmate Wallet (Texting)</h3>
                <p>The Reliance Inmate Wallet makes funding jail communication fast and easy! Payments made into an Inmate Wallet account are linked to a dedicated telephone number and a free to use Reliance Tablet Phone provided in a protective enclosure. Inmate Wallet funds can be used for:</p>
                <div className="row">
                  <div className="col-md-6 text-left d-flex mb-3 mb-md-0">
                    <span className="icon-sm txtmsg"></span>
                    <h5 className="col-9">Personal Inmate Text Messaging</h5>
                  </div>
                  <div className="col-md-6 text-left d-flex">
                    <span className="icon-sm voicevideocall"></span>
                    <h5 className="col-9">Voice and Video Calls</h5>
                  </div>
                </div>
                <button type="button" className="btn btn-action btn-block mt-3" onClick={() => {
                  dispatch(checkoutActions.selectFacility(facility));
                  dispatch(checkoutActions.selectService(ServiceType.Wallet));
                  props.history.push('/checkout/wallet');
                }}>Add funds to inmate wallet</button>
              </div>
            </div>
          }

          <div className="row py-5 flex-row-reverse">
            <div className="col-md-6 image-service srvc-phonecard d-flex justify-content-center">
              <span className="align-self-center"></span>
            </div>

            <div className="col-md-6 py-5">
              <h3>Inmate Phone Cards</h3>
              <p>Reliance is the only inmate communication provider that sells inmate phone cards online. Phone cards, sold in $10, $20, $50, and $100 denominations, can be purchased online by friends and family of inmates - anywhere at anytime. Phone cards can be transferred and used at any jail in the Reliance Telephone network. Additionally, remaining balances from phone cards can be transferred easily to an inmate’s texting device.</p>
              <div className="phonecard-images d-flex">
                <span className="phonecard-10"></span>
                <span className="phonecard-20"></span>
                <span className="phonecard-50"></span>
                <span className="phonecard-100"></span>
              </div>
              <button type="button" className="btn btn-action btn-block mt-3" onClick={() => {
                dispatch(checkoutActions.selectFacility(facility));
                dispatch(checkoutActions.selectService(ServiceType.PhoneCard));
                props.history.push('/checkout/card');
              }}>
                Buy a phone card
              </button>
            </div>
          </div>

          {hasPrepaidCollectCalls &&
            <div className="row py-5">
              <div className="col-md-6 image-service srvc-collectcall d-flex justify-content-center">
                <span className="align-self-center"></span>
              </div>

              <div className="col-md-6 py-5">
                <h3>Prepaid Collect Calls</h3>
                <p>Prepaying for collect calls is a way for friends and family to receive calls from inmates by funding specific telephone numbers. These calls can be sent directly to a cell phone and/or landline and it’s easy to set up an account online.</p>
                <button type="button" className="btn btn-action btn-block mt-3" onClick={() => {
                  dispatch(checkoutActions.selectFacility(facility));
                  dispatch(checkoutActions.selectService(ServiceType.Collect));
                  props.history.push('/checkout/collect');
                }}>
                  Add funds to collect account
                </button>
              </div>
            </div>
          }

          {facility.visitationSchedulingEnabled &&
            <div className="row py-5 flex-row-reverse">
              <div className="col-md-6 image-service srvc-videovisit d-flex justify-content-center">
                <span className="align-self-center"></span>
              </div>

              <div className="col-md-6 py-5">
                <h3>Video Visitation</h3>
                <p>Our innovative Video Visitation system has gone beyond the traditional connections between low quality cameras and monitors to modern devices. Inmates can connect from a Reliance Tablet Station in their housing unit or from their personal Reliance Tablet Phone to a public Reliance Tablet Station. The system is designed to meet all on-site visitation requirements. For off-site video communication, Reliance Video Calls allows friends and family to receive see and hear the inmate directly on their Android or iOS device using our Reliance Connect app available in the App Store or Play Store.</p>
                <button type="button" className="btn btn-action btn-block mt-3" onClick={() => {
                  dispatch(checkoutActions.selectFacility(facility));
                  dispatch(checkoutActions.selectService(ServiceType.Visitation));
                  props.history.push('/visitation/schedule');
                }}>
                  Schedule a video visitation
                </button>
              </div>
            </div>
          }

          <div className="row">
            <div className="col-md-6">
              <h6>Address</h6>
              <p>
                {facility.address}<br/>
                {facility.city}, {facility.state} {facility.zip}
              </p>
              <a href={`https://www.google.com/maps/dir/?api=1&destination=${facility.latitude},${facility.longitude}`} target="_blank">Directions</a>
            </div>
            {facility.voicemail &&
              <div className="col-md-6">
                <h6>Voicemail</h6>
                <p>
                  <PhoneNumber value={facility.voicemail}/> {facility.voicemailExtension && <span>ext. {facility.voicemailExtension}</span>}
                </p>
              </div>
            }
          </div>

          {facility.latitude && facility.longitude &&
            <div className="row py-5 flex-row-reverse">
              <div className="col-md-12">
                <Map center={[facility.latitude, facility.longitude]} zoom={13} style={{ height: 600 }}>
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                  />
                  <Marker position={[facility.latitude, facility.longitude]}>
                    <Popup>
                      {facility.name}<br/>
                      {facility.address}<br/>
                      {facility.city}, {facility.state} {facility.zip}<br/>
                      <a href={`https://www.google.com/maps/dir/?api=1&destination=${facility.latitude},${facility.longitude}`} target="_blank">Directions</a>
                    </Popup>
                  </Marker>
                </Map>
              </div>
            </div>
          }
        </main>
      }
    </div>
  );
}

export default withRouter(Summary);
