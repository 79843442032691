import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { ReactComponent as IosAppStoreImage } from '../../images/ios_app_store.svg';
import * as inmateActions from '../actions/inmate';
import PageTitle from '../components/PageTitle';
import { IAppState } from '../reducers';

interface IAppsUrlProps {
  id?: string
}

const Apps: React.FC<RouteChildrenProps<IAppsUrlProps>> = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.match.params.id) {
      dispatch(inmateActions.loadInmateById(Number(props.match.params.id)));
    }
  }, [props.match.params.id]);

  const appsState = useSelector((appState: IAppState) => appState.apps);

  return (
    <div>
      <PageTitle title="Video Calling Apps" />

      <main className="container">
        <div className="row py-5 justify-content-center">
          <div className="col-md-6 py-5">
            <h3>Inmate Video Calling</h3>
            {appsState.loadingInmate &&
              <p>Loading...</p>
            }
            {appsState.inmate &&
              <p>Inmate {appsState.inmate.firstName} {appsState.inmate.lastName} at {appsState.inmate.facility.name} would like to video call you. To receive video calls, download the Reliance Connect app using one of the links below.</p>
            }
            <div className="row">
              <div className="col-md-6 text-left d-flex mb-3 mb-md-0">
                <span style={{width:'200px', display:'inline-block'}}>
                  <a href="https://itunes.apple.com/us/app/reliance-connect/id1424633414">
                    <IosAppStoreImage style={{ margin: '6%' }} />
                  </a>
                </span>
              </div>
              <div className="col-md-6 text-left d-flex">
                <span>
                  <a href="https://play.google.com/store/apps/details?id=com.reliancetelephone.relianceconnect">
                    <img src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png" width="200px"/>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Apps;