import { ICreditCardPayment, ITransaction } from '../models/Transaction';
import Api, { IPaginatedResponse } from '../services/api';

export function index(subTypes = [], page = 1, perPage = 250) {
  return Api
    .get<IPaginatedResponse<ITransaction>>({
      url: '/transaction',
      query: {page, perPage, subTypes}
    });
}

export function get(id: number) {
  return Api
    .get<ICreditCardPayment>({
      url: `/transaction/${id}`
    });
}
