import { IPersonalInformationData } from '../components/Checkout/PersonalInformation';
import { ICreditCardData } from '../models/CreditCard';
import { ICreditCardPayment } from '../models/Transaction';
import Api from '../services/api';

export interface IPaymentResponse {
  success: boolean
  transaction: ICreditCardPayment
  subTotal: number
  transactionFee: number
  total: number
}

export interface IPostPaymentData extends IPersonalInformationData {
  amount: number
  ani: string
  newCreditCard?: ICreditCardData
  savedCreditCardId?: number
  cardSecurityCode?: string,
  purchaserDetails: IPersonalInformationData
}

export function post(payment: IPostPaymentData): Promise<IPaymentResponse> {
  return Api
    .post<IPaymentResponse>({
      url: '/payment',
      data: payment
    });
}
