import moment from 'moment-timezone';
import React from 'react';
import AddToCalendar from 'react-add-to-calendar';
import { Link } from 'react-router-dom';
import { Facility } from '../../models/Facility';
import { IVisitation } from '../../models/Visitation';
import NameAndAddress from '../Facility/NameAndAddress';

interface IConfirmationProps {
  visitation: IVisitation
}

const Confirmation: React.FC<IConfirmationProps> = props => {
  const { inmate, facility, timeLimit, startDate } = props.visitation;

  const event = {
    description: `Visitation with inmate ${inmate.firstName} ${inmate.lastName} at ${facility.name} for ${timeLimit} minutes`,
    location: `${facility.address}, ${facility.city}, ${facility.state} ${facility.zip}`,
    startTime: moment(startDate.date).tz(facility.timezone || 'America/Chicago'),
    endTime: moment(startDate.date).tz(facility.timezone || 'America/Chicago').add(timeLimit, 'minutes'),
    title: `${facility.name} Visitation`,
  };

  return (
    <main className="container align-items-top justify-content-center my-5 my-lg-3 pt-4">
      <section className="no-gutters">
        <div className="main row">
          <div className="col-md-6 offset-md-3 mb-3 ">
            <h3 className="text-center">Confirmation</h3>
          </div>
          <div className="col-md-6 offset-md-3 mb-3 rt-status receipt">
            <aside className="bg-light text-dark rounded p-3 py-5 p-lg-5">
              <h3 className="text-action text-center">You scheduled:</h3>

              <div className="selected-service py-lg-2">
                <h5 className="text-center">Video Visitation</h5>
              </div>
              <hr className="border-muted"/>
              <div className="selected-facility py-lg-2">
                <h5>With inmate:</h5>
                <p className="subtitle subhead inmate-name">
                  {props.visitation.inmate.firstName} {props.visitation.inmate.lastName}
                </p>
                {props.visitation.inmate.account &&
                  <p className="inmate-phonenum">#{props.visitation.inmate.account}</p>
                }

                <h5>On:</h5>
                <p>{moment(props.visitation.startDate.date).format('ddd, MMM D HH:mm')}</p>
              </div>
              <hr className="border-muted"/>
              <div className="selected-facility py-lg-2">
                <h5>At Facility:</h5>
                <NameAndAddress facility={new Facility(props.visitation.facility)} />
              </div>
            </aside>
          </div>
          <div className="col-md-6 offset-md-3 ">
            <Link className="btn btn-action btn-block btn-lg text-white py-3" to="/account/dashboard"><strong>View account dashboard</strong></Link>

            <AddToCalendar
              buttonClassClosed=""
              buttonClassOpen=""
              buttonWrapperClass="btn btn-block btn-default btn-lg text-secondary py-2 my-3 calendar"
              dropdownClass="react-add-to-calendar__dropdown calendar-dropdown"
              event={event} />
          </div>
        </div>
      </section>
    </main>
  );
}

export default Confirmation
