import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { ITransaction } from '../../../models/Transaction';
import { IPaginatedResponse } from '../../../services/api';
import Currency from '../../Format/Currency';
import TransactionType from '../../Format/TransactionType';

interface IRecentPaymentsProps {
  payments: IPaginatedResponse<ITransaction>
  expanded: boolean
  onToggleExpansion: () => void
}

const RecentPayments: React.FC<IRecentPaymentsProps> = props =>
  <section className="no-gutters">
    <div className="mb-3">
      <div className="col-md-12 rounded p-0 border border-dark">
        <button className={"collapsible btn-block text-left mobile-only text-light bg-dark px-lg-5 px-3 py-3 " + (props.expanded ? 'active' : '')} onClick={props.onToggleExpansion}>
          <h5 className="product m-0 pr-5"><span className="icon-button"></span>Recent Payment History</h5><span className="collapseicon_secondary"></span>
        </button>
        {props.expanded && (
          <div className="col-md-12" style={{ overflow: 'scroll' }}>
            <table className="table table-striped p-4">
              <thead>
                <tr>
                  <th className="acct rc-date">Date</th>
                  <th className="acct rc-type">Type</th>
                  <th className="acct rc-total">Payment Amount</th>
                </tr>
              </thead>
              <tbody>
                {props.payments && props.payments.data.filter(t => t.TransactionSubTypeID == 12).map(p =>
                  <tr key={p.id}>
                    <td>{moment(p.date.date).format('MM/DD/YYYY')}</td>
                    <td><TransactionType subType={p.TransactionSubTypeID} /></td>
                    <td><Currency value={p.amount * -1} /></td>
                    <td><Link to={`/checkout/collect/receipt/${p.id}`}>View Receipt</Link></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  </section>

export default RecentPayments;
