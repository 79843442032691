import { IPaginatedResponse } from '../services/api';
import { ICall } from './Call';
import { IDateTimeObject } from './DateTime';
import { ITransaction } from './Transaction';

export interface IAccountBalance {
  balance: number
}

export interface IAccountBilling {
  firstName: string
  lastName: string
  address1: string
  address2: string
  city: string
  state: string
  zip: string
  companyName?: string
  isCompany: boolean
  dob: IDateTimeObject
}

export enum AccountLinkStatus {
  OPEN = 2,
  PAYMENT_HOLD = 3,
  DISABLED = 4,
  PROBATION = 6
}

export enum BlockType {
  COUNTY_BLOCK = 'County Block',
  RECORDING_BLOCK = 'Recording Block',
  COLLECT_BLOCK = 'Collect Block',
  RANGE_BLOCK = 'Range Block',
  OVERRIDE = 'Override',
  OPEN = 'Open'
}

export interface IAccount {
  id: number
  ani: string
  balance: IAccountBalance
  billing: IAccountBilling
  accountLinkStatus?: AccountLinkStatus
  recentPayments?: IPaginatedResponse<ITransaction>
  recentCalls?: IPaginatedResponse<ICall>
  monthlyPayments?: number // Amount of payments made in last 30 days
  blocked: boolean
  effectiveBlock: string
}