import moment from 'moment';
import React from 'react';
import { IFacility } from '../../models/Facility';
import { IScheduleAvailability } from '../../models/Visitation';
import VisitationWindowDay from './VisitationWindowDay';

interface IVisitationWindowProps {
  facility: IFacility
  loadingTimeslots: boolean
  availability: IScheduleAvailability[]
  onBlockSelect(block: IScheduleAvailability): void
  selectedBlock: IScheduleAvailability
}

class VisitationWindows extends React.Component<IVisitationWindowProps> {
  // If this gets changed, update Relcore Central's logic to match
  calculateMinMaxTimes() {
    if (this.props.availability == null) {
      return { min: 800, max: 1700 };
    }

    let dates = this.props.availability.map(a => moment(a.reservationStartDate.date));
    let times = dates.map(a =>
      parseInt(`${a.hour()}${("00" + Math.floor(a.minute()/60*100)).slice(-2)}`)
    );
    let earliest = times.reduce((prev, cur) => prev == null || cur < prev ? cur : prev);
    let latest = times.reduce((prev, cur) => prev == null || cur > prev ? cur : prev);

    return { min: earliest, max: latest };
  }

  render() {
    const { availability, facility, onBlockSelect } = this.props;

    let startDay = moment().add(facility.visitScheduleWindowMinHours, 'hour');
    let lastDay = moment().add(facility.visitScheduleWindowMaxHours, 'hour');
    let days = lastDay.diff(startDay, 'days') + 1;
    let minMax = this.calculateMinMaxTimes();

    if (this.props.loadingTimeslots || availability == null) {
      return null;
    }

    return (
      <div className="row my-4">
        {[...Array(days)].map(d => {
          let colDate = moment(startDay)
          startDay.add(1, 'day')

          return (
            <VisitationWindowDay
              key={`window_day_${colDate.day()}`}
              day={colDate}
              startTime={minMax.min}
              endTime={minMax.max}
              availability={availability}
              onBlockSelect={onBlockSelect}
              selectedBlock={this.props.selectedBlock}
              />
          )
        })}
      </div>
    )
  }
}

export default VisitationWindows;
