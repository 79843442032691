import moment from 'moment';
import React from 'react';
import { Facility, IFacility } from '../../models/Facility';
import { IInmate } from '../../models/Inmate';
import { IScheduleAvailability } from '../../models/Visitation';
import NameAndAddress from '../Facility/NameAndAddress';

interface IInfoBoxProps {
  facility: IFacility
  inmate: IInmate
  timeslot: IScheduleAvailability
}

const InfoBox:React.FC<IInfoBoxProps> = props =>
  <div className="col-md-4 mb-3 rt-status">
    <aside className="bg-secondary text-light rounded p-3 p-lg-5 h-100">
      <h4>You've selected:</h4>

      <div className="selected-service py-lg-2">
        <h5>Video Visitation</h5>
      </div>

      {props.inmate && (
        <>
          <hr className="border-light"/>
          <div className="selected-facility py-lg-2">
            <h5>With inmate:</h5>
            <p className="subtitle subhead inmate-name">
              {props.inmate.firstName} {props.inmate.lastName}
            </p>
            {props.inmate.account &&
              <p className="inmate-phonenum">#{props.inmate.account}</p>
            }

            {props.timeslot != null && (
              <>
                <h5>On:</h5>
                <p>{moment(props.timeslot.reservationStartDate.date).format('ddd, MMM D HH:mm')}</p>
              </>
            )}
          </div>
        </>
      )}

      {props.facility &&
        <>
          <hr className="border-light"/>
          <div className="selected-facility py-lg-2">
            <h5>At facility:</h5>
            <NameAndAddress facility={new Facility(props.facility)} />
          </div>
        </>
      }
    </aside>
  </div>

export default InfoBox;