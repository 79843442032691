import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';

interface INavigationProps {
  title: string
}

const Navigation: React.FC<INavigationProps> = (props) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="hero bg-primary text-light">
      <Container>
        <div className="row pt-5">
          <div className="col-6 col-lg-4">
            <h1 className="d-none">{props.title}</h1>
            <h3 className="mt-2"><Link className="text-light" to="/account/dashboard">Your Account</Link></h3>
          </div>
          <div className="col-6 col-lg-8 navbar-account d-flex justify-content-end flex-column mb-lg-n1">
            <Navbar expand="lg" variant="dark" className="p-0">
              <Container className="p-0 justify-content-end">
                <Navbar.Toggle data-target="#navbarDashboard" aria-controls="navbarMain" aria-expanded="false" aria-label="Toggle navigation" className="dash mb-3 mt-5" onClick={() => setExpanded(!expanded)}>
                  <span className="navbar-toggler-icon-arrow"></span>
                </Navbar.Toggle>

                <Navbar.Collapse id="navbarDashboard">
                  <Nav>
                    <Nav.Item className="text-center mx-lg-1 rounded">
                      <Link onClick={() => setTimeout(() => {setExpanded(false)}, 100)} className="nav-link py-3" to="/account/settings">Account Details</Link>
                    </Nav.Item>
                    <Nav.Item className="text-center mx-lg-1 rounded">
                      <Link onClick={() => setTimeout(() => {setExpanded(false)}, 100)} className="nav-link py-3" to="/wallet/history">Inmate Wallet</Link>
                    </Nav.Item>
                    <Nav.Item className="text-center mx-lg-1 rounded">
                      <Link onClick={() => setTimeout(() => {setExpanded(false)}, 100)} className="nav-link py-3" to="/accounts">Prepaid Collect Call Accounts</Link>
                    </Nav.Item>
                    <Nav.Item className="text-center mx-lg-1 rounded">
                      <Link onClick={() => setTimeout(() => {setExpanded(false)}, 100)} className="nav-link py-3" to="/card/history">Phone Cards</Link>
                    </Nav.Item>
                    <Nav.Item className="text-center mx-lg-1 rounded">
                      <Link onClick={() => setTimeout(() => {setExpanded(false)}, 100)} className="nav-link py-3" to="/visitation/scheduled">Video Visitation</Link>
                    </Nav.Item>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Navigation;
