import * as checkoutActions from '../../actions/checkout';
import * as inmateActions from '../../actions/inmate';
import * as scheduleActions from '../../actions/visitation/schedule';
import { IInmate } from '../../models/Inmate';
import { IOutsideParty, IScheduleAvailability, IVisitation } from '../../models/Visitation';
import { ApiError } from '../../services/api';

export interface IVisitationData {
  inmate?: IInmate
  timeslot: IScheduleAvailability
  visitorInformation: IOutsideParty
}

export interface IVisitationScheduleState {
  loadingInmates: boolean
  inmates: IInmate[]
  loadingTimeslots: boolean
  timeslotLoadingError: ApiError
  timeslots: IScheduleAvailability[]
  visitationData: IVisitationData
  scheduling: boolean
  scheduleError?: ApiError
  visitation: IVisitation
}

const initialState:IVisitationScheduleState = {
  loadingInmates: false,
  inmates: null,
  loadingTimeslots: false,
  timeslotLoadingError: null,
  timeslots: null,
  visitationData: null,
  visitation: null,
  scheduling: false,
  scheduleError: null
};

export function visitationScheduleReducer(state: IVisitationScheduleState = initialState, action): IVisitationScheduleState {
  switch (action.type) {
    case checkoutActions.SELECT_SERVICE:
      return initialState;

    case checkoutActions.SELECT_FACILITY:
      return initialState;

    case scheduleActions.SET_DETAILS:
      const lastState = {...state};
      return {
        ...state,
        visitationData: action.details,
        timeslots: lastState.visitationData && lastState.visitationData.inmate != action.details.inmate ? null : lastState.timeslots
      };

    case inmateActions.INMATES_LOADING:
      return {
        ...state,
        loadingInmates: true,
        inmates: null,
        timeslotLoadingError: null
      };

    case inmateActions.INMATES_LOAD_FAILURE:
      return {
        ...state,
        loadingInmates: false,
        inmates: null
      };

    case inmateActions.INMATES_LOAD_SUCCESS:
      return {
        ...state,
        loadingInmates: false,
        inmates: action.inmates
      };

    case scheduleActions.LOADING_AVAILABILITY:
      return {
        ...state,
        loadingTimeslots: true,
        timeslots: null,
        timeslotLoadingError: null
      };

    case scheduleActions.LOAD_AVAILABILITY_FAILURE:
      return {
        ...state,
        loadingTimeslots: false,
        timeslotLoadingError: action.error
      };

    case scheduleActions.LOAD_AVAILABILITY_SUCCESS:
      return {
        ...state,
        loadingTimeslots: false,
        timeslots: action.availability
      }

    case scheduleActions.SCHEDULING:
      return {
        ...state,
        scheduling: true,
        scheduleError: null
      };

    case scheduleActions.SCHEDULE_FAILURE:
      return {
        ...state,
        scheduling: false,
        scheduleError: action.error
      };

    case scheduleActions.SCHEDULE_SUCCESS:
      return {
        ...state,
        visitation: action.visitation,
        scheduling: false
      };

    case scheduleActions.RESET:
      return initialState;
  }

  return state;
}
