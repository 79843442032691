import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import shouldShowInmateNumber from '../../../functions/shouldShowInmateNumber';
import { IFacility } from '../../../models/Facility';
import { IWalletPayment } from '../../../models/WalletPayment';
import Currency from '../../Format/Currency';
import PhoneNumber from '../../Format/PhoneNumber';

interface IHistoryRowProps {
  facility: IFacility
  payment: IWalletPayment
  onBuyAgain: () => void
}

const HistoryRow: React.FC<IHistoryRowProps> = props =>
  <div className="mx-lg-5 mx-4 my-3 p-2 row bg-light rounded">
    <div className="col-lg-1 my-2 d-flex align-self-center px-2">
      <Link to={`/checkout/wallet/receipt/${props.payment.id}`}>
        <span className="dashboard-tab-icon inmate-wallet mr-3"></span>
      </Link>
    </div>
    <div className="col-lg-5 my-2 d-flex flex-column align-self-center px-2">
      <p className="m-0 subtitle subtwo">
        <Currency value={props.payment.amount} />&nbsp;
        Inmate Wallet Payment for
      </p>
      <p className="m-0 subtitle subtwo">
        {props.payment.inmate.firstName} {props.payment.inmate.lastName} #{props.payment.inmate.account}
      </p>
      {shouldShowInmateNumber(props.facility, props.payment.inmate) && (
        <p className="m-0 subtitle subtwo text-dark">
          Texting # <PhoneNumber value={props.payment.inmate.device.smsNumber.number} />
        </p>
      )}
    </div>
    <div className="col-lg-4 my-2 d-flex flex-column align-self-center px-2">
      <p className="m-0 subtitle subtwo">
        At {props.payment.inmate.facility.name} ({props.payment.inmate.facility.city}, {props.payment.inmate.facility.state})
      </p>
      <p className="m-0 subtitle subtwo">
        Purchased on {moment(props.payment.createdDate.date).format('M/D/YYYY')}
      </p>
    </div>
    <div className="col-lg-2 my-2 d-flex align-self-center px-2">
      {props.payment.inmate.device == null || props.payment.inmate.status != 'Enabled' ?
        <button className="btn btn-outline-action btn-block" disabled>
          {props.payment.inmate.status != 'Enabled' ? 'Inmate released' : 'No device'}
        </button> :
        <button className="btn btn-outline-action btn-block" onClick={props.onBuyAgain}>
          Buy again
        </button>
      }
    </div>
  </div>

export default HistoryRow;
