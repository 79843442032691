import React from 'react';
import { Form } from 'react-final-form';
import { email, password, required } from '../../validation';
import ErrorMessage from '../ErrorMessage';
import { InputType, renderPrimarySubmit, renderTextInput } from '../Form/RenderForm';

interface IRegisterData {
  emailAddress: string
  password: string
}

interface INewAccountProps {
  size?: string
  onSubmit: (data: IRegisterData) => Promise<any>
}

const NewAccount: React.FC<INewAccountProps> = props =>
  <Form
    onSubmit={props.onSubmit}>
    {form =>
      <form className="form-account-new" onSubmit={form.handleSubmit} method="post">
        <div className="mb-4 text-center">
          {props.size && props.size == 'small' ?
            <h4>Set up new account</h4> :
            <h3>Set up new account</h3>
          }
          <p className="bodysm">Create an account to save your information for faster checkout in the future.</p>
        </div>

        {form.submitError &&
          <ErrorMessage
            error={form.submitError}
            httpCodeMessages={{
              409: 'An account already exists with this email'
            }} />
        }

        <div className="form-label-group">
          {renderTextInput(
            'newAccount.emailAddress',
            'emailAddress',
            'Enter email',
            'Enter email',
            [required, email]
          )}
        </div>
        <div className="form-label-group">
          {renderTextInput(
            'newAccount.password',
            'password',
            'Create password',
            'Create password',
            [required, password],
            InputType.password
          )}
          <p className="small text-dark">Must be at least 8 characters and contain at least 1 numerical character</p>
        </div>
        {renderPrimarySubmit<IRegisterData>(form, 'Create account', 'Creating...')}
      </form>
    }
  </Form>

export default NewAccount
