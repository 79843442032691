import { ThunkAction } from 'redux-thunk';
import { IInmate } from '../models/Inmate';
import { IAppState } from '../reducers';
import * as inmateResource from '../resources/Inmate';

export const INMATES_LOADING = 'INMATE/INMATES_LOADING';
export const INMATES_LOAD_SUCCESS = 'INMATE/INMATES_LOAD_SUCCESS';
export const INMATES_LOAD_FAILURE = 'INMATE/INMATES_LOAD_FAILURE';
interface IInmatesLoadingAction {
  type: typeof INMATES_LOADING
  facilityId: number
}
interface IInmatesLoadSuccessAction {
  type: typeof INMATES_LOAD_SUCCESS
  inmates: IInmate[]
}
interface IInmatesLoadFailureAction {
  type: typeof INMATES_LOAD_FAILURE
  error: string
}
type InmatesLoadActionTypes = IInmatesLoadingAction | IInmatesLoadSuccessAction | IInmatesLoadFailureAction;
export function loadInmates(facilityId: number): ThunkAction<Promise<IInmate[]>, IAppState, null, InmatesLoadActionTypes> {
  return dispatch => {
    dispatch({ type: INMATES_LOADING, facilityId });

    return inmateResource
      .index({ facilityId })
      .then(res => {
        if (res == null) {
          throw new Error('Invalid response from server');
        }

        dispatch({ type: INMATES_LOAD_SUCCESS, inmates: res.data });
        return res.data;
      })
      .catch(error => {
        dispatch({ type: INMATES_LOAD_FAILURE, error: error.message });
        throw error;
      });
  }
}

export const INMATE_BY_ANI_LOADING = 'INMATE/INMATE_BY_ANI_LOADING';
export const INMATE_BY_ANI_LOAD_SUCCESS = 'INMATE/INMATE_BY_ANI_LOAD_SUCCESS';
export const INMATE_BY_ANI_LOAD_FAILURE = 'INMATE/INMATE_BY_ANI_LOAD_FAILURE';
interface IInmateByAniLoadingAction {
  type: typeof INMATE_BY_ANI_LOADING
  ani: string
}
interface IInmateByAniLoadSuccessAction {
  type: typeof INMATE_BY_ANI_LOAD_SUCCESS
  inmate: IInmate
}
interface IInmateByAniLoadFailureAction {
  type: typeof INMATE_BY_ANI_LOAD_FAILURE
  error: string
}
type InmatesLoadByAniActionTypes = IInmateByAniLoadingAction | IInmateByAniLoadSuccessAction | IInmateByAniLoadFailureAction;
export function loadInmateByAni(ani: string): ThunkAction<Promise<IInmate>, IAppState, null, InmatesLoadByAniActionTypes> {
  return dispatch => {
    dispatch({ type: INMATE_BY_ANI_LOADING, ani })
    return inmateResource
      .getByAni(ani)
      .then(inmate => {
        dispatch({ type: INMATE_BY_ANI_LOAD_SUCCESS, inmate });
        return inmate;
      })
      .catch(error => {
        dispatch({ type: INMATE_BY_ANI_LOAD_FAILURE, error: error.message });
        throw error;
      });
  }
}

export const INMATE_BY_ID_LOADING = 'INMATE/INMATE_BY_ID_LOADING';
export const INMATE_BY_ID_LOAD_SUCCESS = 'INMATE/INMATE_BY_ID_LOAD_SUCCESS';
export const INMATE_BY_ID_LOAD_FAILURE = 'INMATE/INMATE_BY_ID_LOAD_FAILURE';
interface IInmateByIdLoadingAction {
  type: typeof INMATE_BY_ID_LOADING
  id: number
}
interface IInmateByIdLoadSuccessAction {
  type: typeof INMATE_BY_ID_LOAD_SUCCESS
  inmate: IInmate
}
interface IInmateByIdLoadFailureAction {
  type: typeof INMATE_BY_ID_LOAD_FAILURE
  error: string
}
type InmatesLoadByIdActionTypes = IInmateByIdLoadingAction | IInmateByIdLoadSuccessAction | IInmateByIdLoadFailureAction;
export function loadInmateById(id: number): ThunkAction<Promise<IInmate>, IAppState, null, InmatesLoadByIdActionTypes> {
  return dispatch => {
    dispatch({ type: INMATE_BY_ID_LOADING, id })
    return inmateResource
      .getById(id)
      .then(inmate => {
        dispatch({ type: INMATE_BY_ID_LOAD_SUCCESS, inmate });
        return inmate;
      })
      .catch(error => {
        dispatch({ type: INMATE_BY_ID_LOAD_FAILURE, error: error.message });
        throw error;
      });
  }
}

export type InmateActionTypes = (
  InmatesLoadByAniActionTypes |
  InmatesLoadByIdActionTypes |
  InmatesLoadActionTypes
)