import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import * as checkoutActions from '../../actions/checkout';
import { Service as ServiceType } from '../../reducers/checkout';

interface IPhoneCardCostModalProps extends RouteComponentProps {
  show: boolean
  onHide: () => void
}

const PhoneCardCostModal: React.FC<IPhoneCardCostModalProps> = props => {
  const dispatch = useDispatch();

  return (
    <Modal show={props.show} size="lg" onHide={props.onHide}>
      <Modal.Header closeButton />
      <Modal.Body className="p-5">
        <h3>Inmate Phone Cards</h3>
        <p>Phone Cards can be used to call any telephone number at a fixed rate. These cards can be used at Reliance correctional facilities only.</p>
        <p className="font-weight-bold mb-0"><u>Phone Card In-State Calls</u></p>
        <p>$0.21 - $0.50 per minute depending on the facility</p>
        <p className="font-weight-bold mb-0"><u>Phone Card State to State Calls</u></p>
        <p>$0.21 per minute + Federal Universal Service Fund</p>

        <button className="btn btn-lg btn-action btn-block mt-5" onClick={() => {
          props.onHide();
          dispatch(checkoutActions.selectService(ServiceType.PhoneCard));
          props.history.push('/checkout/card');
        }}>
          Buy a phone card
        </button>
      </Modal.Body>
    </Modal>
  )
}

export default withRouter(PhoneCardCostModal);
