import React from 'react';
import { Link } from 'react-router-dom';
import { IOnlineUserAccount } from '../../../models/OnlineUserAccount';
import Currency from '../../Format/Currency';
import PhoneNumber from '../../Format/PhoneNumber';

interface IAccountRowProps {
  account: IOnlineUserAccount
  onVerify(): void
}

const AccountRow: React.FC<IAccountRowProps> = props =>
  <div className="mx-lg-5 mx-4 my-3 p-2 row bg-light rounded">
    <div className="col-lg-1 my-2 d-flex align-self-center px-2">
      {props.account.dateVerified ?
        <Link to={{
          pathname: `/accounts/${props.account.account.ani}`,
          state: {account: props.account.account}
        }}>
          <span className="dashboard-tab-icon collect-call mr-3"></span>
        </Link> :
        <span className="dashboard-tab-icon collect-call-unverified mr-3"></span>
      }
    </div>
    <div className="col-lg-3 my-2 d-flex flex-column align-self-center px-2">
      <p className="m-0 subtitle subtwo">
        Account # <PhoneNumber value={props.account.account.ani} />
      </p>
    </div>
    <div className="col-lg-3 my-2 d-flex flex-column align-self-center px-2">
      {renderBalance(props.account)}
    </div>
    <div className="col-lg-3 my-2 d-flex flex-column align-self-center px-2 text-align-right">
      <p className="m-0 subtitle subtwo">
        Status:&nbsp;
        {props.account.verificationCodeCount > 0 && props.account.dateVerified == null ?
          <span className="text-error">Unverified</span> :
          (props.account.dateVerified != null && props.account.blocked ?
            <span className="text-error">Blocked <span className="xicon"></span></span> :
            <span className="text-success">Open <span className="checkicon"></span></span>
          )
        }
      </p>
    </div>
    <div className="col-lg-2 my-2 d-flex align-self-center px-2">
      {props.account.verificationCodeCount > 0 && props.account.dateVerified == null ?
        <button className="btn btn-primary btn-block" onClick={props.onVerify}>Verify account</button> :
        <Link className="btn btn-outline-action btn-block" to={{
          pathname: `/accounts/${props.account.account.ani}`,
          state: {account: props.account.account}
        }}>View more</Link>
      }
    </div>
  </div>

const renderBalance = (account: IOnlineUserAccount) => {
  if (account.dateVerified) {
    const balance = account.account?.balance.balance;
    return balance > 0 ? <p className="m-0 subtitle subtwo">Balance: <span className='text-error'><Currency value={balance * -1} /></span></p> :
      <p className="m-0 subtitle subtwo">Balance: <span className='text-success'><Currency value={balance * -1} /></span></p>;
  }
  return <p className="m-0 subtitle subtwo"></p>;
};

export default AccountRow;
