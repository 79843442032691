import React from 'react';
import Alert from 'react-bootstrap/Alert';

interface ISuccessMessageProps {
  message: string
}

const SuccessMessage: React.FC<ISuccessMessageProps> = props =>
  <Alert variant="success">{props.message}</Alert>

export default SuccessMessage;
