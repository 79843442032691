import * as checkoutActions from '../../actions/checkout';
import * as walletActions from '../../actions/checkout/wallet';
import * as inmateActions from '../../actions/inmate';
import * as taxActions from '../../actions/tax';
import { IInmate } from '../../models/Inmate';
import { IWalletPayment, IWalletPaymentData } from '../../models/WalletPayment';
import { ITaxAmountResponse } from '../../resources/Tax';
import { ApiError } from '../../services/api';

export interface ICheckoutWalletState {
  inmateAni?: string
  loadingInmateByAni: boolean
  inmates?: IInmate[]
  inmatesLoading: boolean
  paymentData?: IWalletPaymentData
  payment?: IWalletPayment
  processing: boolean
  processingError?: ApiError
  fetchingTaxAmounts: boolean
  taxAmounts?: ITaxAmountResponse
}

const initialState:ICheckoutWalletState = {
  processing: false,
  inmatesLoading: false,
  loadingInmateByAni: false,
  fetchingTaxAmounts: false
};

export function checkoutWalletReducer(
  state: ICheckoutWalletState = initialState,
  action: walletActions.WalletActionTypes | taxActions.TaxActionTypes | inmateActions.InmateActionTypes
  ): ICheckoutWalletState {
  switch (action.type) {
    case checkoutActions.SELECT_FACILITY:
      return initialState;

    case checkoutActions.SELECT_SERVICE:
      return initialState;

    case walletActions.LOADING_INMATE_BY_ANI:
      return {
        ...state,
        inmateAni: action.ani,
        loadingInmateByAni: true
      };

    case walletActions.LOADED_INMATE_BY_ANI:
      return {
        ...state,
        inmateAni: action.ani,
        loadingInmateByAni: false
      };

    case walletActions.CLEAR_INMATE_ANI:
      return {
        ...state,
        inmateAni: undefined,
        loadingInmateByAni: false
      };

    case walletActions.SET_DETAILS:
      return {
        ...state,
        paymentData: action.details,
        loadingInmateByAni: false
      };

    case inmateActions.INMATES_LOADING:
      return {
        ...state,
        inmatesLoading: true,
        inmates: null
      };

    case inmateActions.INMATES_LOAD_FAILURE:
      return {
        ...state,
        inmatesLoading: false,
        inmates: null
      };

    case inmateActions.INMATES_LOAD_SUCCESS:
      const inmates: IInmate[] = action.inmates;
      return {
        ...state,
        inmatesLoading: false,
        inmates
      };

    case walletActions.PAYMENT_PROCESSING:
      return {
        ...state,
        processing: true,
        processingError: null
      };

    case walletActions.PAYMENT_FAILURE:
      return {
        ...state,
        processing: false,
        processingError: action.error
      };

    case walletActions.PAYMENT_SUCCESS:
      return {
        ...state,
        payment: action.payment,
        processing: false
      };

    case walletActions.RESET_SENSITIVE:
      return {
        ...state,
        payment: null,
        paymentData: {
          ...state.paymentData,
          cc: null
        }
      };

    case taxActions.AMOUNT_FETCHING:
      return {
        ...state,
        fetchingTaxAmounts: true,
        taxAmounts: null
      };

    case taxActions.AMOUNT_FETCHING_FAILURE:
      return {
        ...state,
        fetchingTaxAmounts: false
      };

    case taxActions.AMOUNT_FETCHING_SUCCESS:
      return {
        ...state,
        fetchingTaxAmounts: false,
        taxAmounts: action.amounts
      };

    case taxActions.AMOUNT_RESET:
      return {
        ...state,
        fetchingTaxAmounts: false,
        taxAmounts: null
      };

    case walletActions.RESET:
      return initialState;
  }

  return state;
}
