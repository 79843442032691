import React from 'react';
import { IFacility } from '../../models/Facility';
import { IInmate } from '../../models/Inmate';
import { IScheduleAvailability } from '../../models/Visitation';
import { ApiError } from '../../services/api';
import InmatePicker from '../Inmate/Picker';
import TimeslotPicker from './TimeslotPicker';

interface IDetailsProps {
  facility: IFacility
  loadingInmates: boolean
  inmates: IInmate[]
  selectedInmate: IInmate
  onInmateSelect(inmate: IInmate): void

  loadingTimeslots: boolean
  timeslots: IScheduleAvailability[]
  onTimeslotSelect(timeslot: IScheduleAvailability): void
  timeslotLoadingError: ApiError
  selectedTimeslot: IScheduleAvailability
}

const Details: React.FC<IDetailsProps> = props =>
  <>
    <InmatePicker
      facility={props.facility}
      loadingInmates={props.loadingInmates}
      inmates={props.inmates}
      selectedInmate={props.selectedInmate}
      onSelect={props.onInmateSelect}
      />
    <TimeslotPicker
      facility={props.facility}
      loadingTimeslots={props.loadingTimeslots}
      timeslots={props.timeslots}
      timeslotLoadingError={props.timeslotLoadingError}
      onSelect={props.onTimeslotSelect}
      selectedTimeslot={props.selectedTimeslot}
      />
  </>

export default Details
