import React from 'react';
import { Link } from 'react-router-dom';
import NewAccount from '../Login/NewAccount';
import ReturningCustomer from '../Login/ReturningCustomer';

interface ILoginOrRegisterProps {
  title: string
  serviceCssClass: string
  onLogin(emailAddress, password): Promise<any>
  onRegister(emailAddress, password): Promise<any>
}

const LoginOrRegister: React.FC<ILoginOrRegisterProps> = props =>
  <div className={`${props.serviceCssClass} service mainwindow`}>
    <h3 className="product bg-action p-2 py-3 mb-0 text-white rounded-top border-action">
      <span className="icon-button" />
      {props.title}
    </h3>
    <div className="col-md-12 service-window px-0">
      <div className="maincontent p-0 m-0 pt-0 pt-md-0 px-4 border border-action rounded-bottom border-top-0">
        <div className="row mt-0 pt-0 pt-md-0">
          <div className="col-lg-6 my-5 pr-lg-4">
            <ReturningCustomer
              size="small"
              onSubmit={data => props.onLogin(data.emailAddress.trim(), data.password)} />

            <div style={{ marginTop: 10, textAlign: 'center' }}>
              <Link to="/login/forgot-password" target="_blank">Forgot your password?</Link>
            </div>
          </div>

          <div className="col-lg-6 my-5 pl-lg-4 vertical-divider-left">
            <NewAccount
              onSubmit={data => props.onRegister(data.emailAddress.trim(), data.password)}
              size="small" />
          </div>
        </div>
      </div>
    </div>
  </div>

export default LoginOrRegister;
