import moment from 'moment';
import React from 'react';
import { Facility, IFacility } from '../../../models/Facility';
import { IPhoneCardPurchase } from '../../../models/PhoneCard';
import NameAndAddress from '../../Facility/NameAndAddress';
import Currency from '../../Format/Currency';
import RecentAction from './RecentAction';

interface IRecentPhoneCardProps {
  loading: boolean
  purchase: IPhoneCardPurchase
  facility: Facility|IFacility
  onRepeat: () => void
}

const RecentPhoneCard: React.FC<IRecentPhoneCardProps> = props =>
  <RecentAction
    loading={props.loading}
    title="Purchased Phone Cards"
    repeatActionTitle={props.purchase && (props.purchase.inmate == null || props.purchase.inmate.status == 'Enabled') ? 'Buy Again' : null}
    repeatAction={props.onRepeat}
    historyUrl="/card/history">
    {props.purchase &&
      <>
        <div className="mt-4 mb-3">
          <h6 className="m-0">
            <Currency value={props.purchase.onlineOrderItems[0].unitPrice}/> inmate phone card
          </h6>
          <h6 className="m-0">PIN: {props.purchase.onlineOrderItems[0].phoneCards[0].pin}</h6>
        </div>
        <div className="text-dark mb-5">
          <p className="m-0 subtitle subtwo">
            Purchased on {moment(props.purchase.date.date).format('MM/DD/YY')}
          </p>
          <p className="m-0 subtitle subtwo">
            for {props.purchase.inmateFirstName} {props.purchase.inmateLastName}
          </p>
          <p className="m-0 subtitle subtwo">
            at <NameAndAddress facility={props.facility} oneLine={true} />
          </p>
        </div>
      </>
    }
  </RecentAction>

export default RecentPhoneCard;
