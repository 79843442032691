import React from 'react';

interface IPolicyRowProps {
  title: string
  expanded: boolean
  onToggleExpansion: () => void
}

const PolicyRow: React.FC<IPolicyRowProps> = props =>
  <div className="border border-info rounded mt-4">
    <button className={"collapsible btn-info text-light btn-block px-lg-5 px-3 py-3 m-0 text-left " + (props.expanded ? 'active' : '')} onClick={props.onToggleExpansion}>
      <h5 className="m-0 pr-5">{props.title}</h5>
      <span className="collapseicon"></span>
    </button>
    <div className="collapsecontent px-lg-5 px-3 py-3" style={{ display: props.expanded ? 'block' : 'none' }}>
      {props.children}
    </div>
  </div>

export default PolicyRow;
