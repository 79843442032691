import * as historyActions from "../actions/wallet-history";
import { IWalletPayment } from '../models/WalletPayment';
import { IPaginatedResponse } from '../services/api';

export interface IWalletPaymentHistoryState {
  loading: boolean,
  error?: Error,
  history?: IPaginatedResponse<IWalletPayment>
}

const initialState:IWalletPaymentHistoryState = {
  loading: false
};

export function walletPaymentHistoryReducer(state: IWalletPaymentHistoryState = initialState, action): IWalletPaymentHistoryState {
  switch (action.type) {
    case historyActions.LOADING:
      return {
        ...state,
        loading: true,
        error: undefined
      };

    case historyActions.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case historyActions.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        history: action.history
      };
  }

  return state;
}
