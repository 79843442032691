import { ICreditCard, ICreditCardData, ICreditCardUpdateData } from '../models/CreditCard';
import Api from '../services/api';

export function index() {
  return Api
    .get({
      url: '/credit-card/online-saved'
    })
    .then((res: ICreditCard[]) => res);
}

export function post(creditCard: ICreditCardData) {
  return Api
    .post({
      url: '/credit-card',
      data: creditCard
    });
}

export function put(creditCard: ICreditCardUpdateData) {
  return Api
    .put({
      url: `/credit-card/${creditCard.id}`,
      data: {
        ...creditCard,
        id: undefined
      }
    });
}

export function remove(creditCard: ICreditCard) {
  return Api
    .delete({
      url: `/credit-card/${creditCard.id}`
    });
}