import { IDateTimeObject } from './DateTime';
import { IFacility } from './Facility';

export enum CallType  {
  Local = 1,
  InState = 2,
  Internation = 3,
  OutOfState = 6,
  Global = 7
}

export interface ICall {
  id: number
  duration: number
  date: IDateTimeObject
  charge: number
  cityTax: number
  stateTax: number
  countyTax: number
  fedTax: number
  toStateTax: number
  usfTax: number
  facility: IFacility[]
}