import { FORM_ERROR } from 'final-form';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as creditCardActions from '../../../actions/credit-card';
import * as onlineUserActions from '../../../actions/online-user';
import { ICreditCard, ICreditCardData, ICreditCardUpdateData } from '../../../models/CreditCard';
import { IOnlineUser, IOnlineUserUpdateData } from '../../../models/OnlineUser';
import { IAppState } from '../../../reducers';
import { IAccountViewState } from '../../../reducers/account/view';
import AddModal from '../../CreditCard/AddModal';
import PageTitle from '../../PageTitle';
import Navigation from '../Navigation';
import ChangePassword from './ChangePassword';
import PersonalInformation from './PersonalInformation';
import SavedCards from './SavedCards';

interface ISettingsProps {
  updatedProfile: boolean
  updatedPassword: boolean
  onlineUser: IOnlineUser
  accountView: IAccountViewState
  loadSavedCards: () => void
  saveCreditCardChanges: (creditCard: ICreditCardUpdateData) => Promise<any>
  saveCreditCard: (creditCard: ICreditCardData) => Promise<any>
  removeCreditCard: (creditCard: ICreditCard) => Promise<any>
  updateProfile: (profileChanges: IOnlineUserUpdateData) => Promise<any>
  changePassword: (currentPassword: string, newPassword: string) => Promise<any>
}

const Settings: React.FC<ISettingsProps> = props => {
  useEffect(() => {
    props.loadSavedCards();
  }, []);

  const [addingNewCard, showAddCard] = useState(false);

  return (
    <>
      <PageTitle title="Your Account" />
      <Navigation title="Your Account" />

      <main className="container pt-3">
        <section className="row pb-3">
          <div className="col-lg-6 my-3">
            <PersonalInformation
              onlineUser={props.onlineUser}
              updated={props.updatedProfile}
              onSubmit={data =>
                props
                  .updateProfile(data)
                  .catch(err =>
                    ({ [FORM_ERROR]: err.error ? err.error : err })
                  )
              } />
          </div>

          <div className="col-lg-6 my-3">
            <ChangePassword
              updated={props.updatedPassword}
              onChangePassword={(data, form) =>
                props
                  .changePassword(data.currentPassword, data.newPassword)
                  .then(() => setTimeout(() => form.restart()))
                  .catch(err =>
                    ({ [FORM_ERROR]: err.error ? err.error : err })
                  )
              } />
          </div>
        </section>

        <section className="row">
          <SavedCards
            loading={props.accountView.savedCardsLoading}
            error={props.accountView.savedCardsLoadingError}
            cards={props.accountView.savedCards || []}
            onSave={props.saveCreditCardChanges}
            onNewCard={() => showAddCard(!addingNewCard)}
            removingCard={props.accountView.removingCard}
            onRemoveCard={props.removeCreditCard}
            />

          {addingNewCard &&
            <AddModal
              onCancel={() => showAddCard(false)}
              onSubmit={(data) =>
                props
                  .saveCreditCard(data)
                  .then(() => showAddCard(false))
              } />
          }
        </section>
      </main>
    </>
  )
}

export default connect(
  (state: IAppState) => ({
    onlineUser: state.auth.user,
    updatedProfile: state.auth.updatedProfile,
    updatedPassword: state.auth.updatedPassword,
    accountView: state.account.view
  }),
  ((dispatch: ThunkDispatch<IAppState, undefined, Action>) => ({
    loadSavedCards: () => dispatch(creditCardActions.load()),
    saveCreditCardChanges: (creditCard: ICreditCardUpdateData) => dispatch(creditCardActions.saveChanges(creditCard)),
    saveCreditCard: (creditCard: ICreditCardData) => dispatch(creditCardActions.save(creditCard)),
    removeCreditCard: (creditCard: ICreditCard) => dispatch(creditCardActions.remove(creditCard)),
    updateProfile: (profileChanges: IOnlineUserUpdateData) => dispatch(onlineUserActions.updateProfile(profileChanges)),
    changePassword: (currentPassword: string, newPassword: string) => dispatch(onlineUserActions.changePassword(currentPassword, newPassword))
  }))
)(Settings);
