import React from 'react';
import Alert from 'react-bootstrap/Alert';
import { ApiError } from '../services/api';

interface IErrorMessageProps {
  message?: string|JSX.Element // If supplied, this will be displayed always
  httpGenericMessage?: string
  httpCodeMessages?: {[code: number]: string}
  error?: ApiError
  severity?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light';
}

const defaultErrorMessage = 'An error occurred';

const ErrorMessage: React.FC<IErrorMessageProps> = props => {
  const { message, error, httpGenericMessage } = props

  if (!message && !error) { return <noscript/> }

  const httpCode = (
    (error && error.response && error.response.status) ? error.response.status :
    ((error && error.data && error.data.error && error.data.error.code) ? error.data.error.code : null)
  );

  let messageText: string|JSX.Element;
  if (typeof error == 'string') {
    messageText = error
  } else if (typeof message == 'string' || (message && React.isValidElement(message))) {
    messageText = message
  } else if (httpCode && props.httpCodeMessages && props.httpCodeMessages[httpCode]) {
    messageText = props.httpCodeMessages[httpCode]
  } else if (error && httpGenericMessage) {
    messageText = httpGenericMessage
  } else {
    messageText = defaultErrorMessage
  }

  return (
    <Alert variant={props.severity || 'danger'}><i className="fa fa-flag" /> {messageText}</Alert>
  )
};

export default ErrorMessage;
