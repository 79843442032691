import { FORM_ERROR } from 'final-form';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as loginActions from '../../actions/auth';
import * as checkoutActions from '../../actions/checkout';
import * as inmateActions from '../../actions/inmate';
import * as onlineUserActions from '../../actions/online-user';
import * as scheduleActions from '../../actions/visitation/schedule';
import { IOutsideParty } from '../../models/Visitation';
import { IAppState } from '../../reducers';
import LoginOrRegister from '../Checkout/LoginOrRegister';
import FacilitySearch from '../Facility/Search';
import PageTitle from '../PageTitle';
import Confirmation from './Confirmation';
import Container from './Container';
import Details from './Details';
import VisitorInformation from './VisitorInformation';

const Schedule: React.FC = () => {
  const state = useSelector((appState: IAppState) => ({
    auth: appState.auth,
    schedule: appState.visitation.schedule,
    checkout: appState.checkout,
    common: appState.common
  }));
  const dispatch = useDispatch() as ThunkDispatch<IAppState, undefined, Action>;

  useEffect(() => {
    // Load the available card types
    if (state.checkout.common.facility && state.schedule.inmates == null) {
      dispatch(inmateActions.loadInmates(state.checkout.common.facility.id));
    }

    return function cleanupVisitSchedule() {
      // Reset data when leaving (or showing up)
      if (state.schedule.visitation) {
        dispatch(scheduleActions.reset());
      }
    }
  }, [state.checkout.common.facility]);

  useEffect(() => {
    // Load the available schedule slots
    if (state.schedule.visitationData && state.schedule.visitationData.inmate && state.schedule.timeslots == null) {
      dispatch(scheduleActions.loadAvailability(state.checkout.common.facility.id, state.schedule.visitationData.inmate.id));
    }
  }, [state.schedule.visitationData]);

  if (state.checkout.common.facility == null) {
    return (
      <Container
        onResetService={() => dispatch(checkoutActions.selectService(null))} facility={null}>
        <FacilitySearch
          facilitiesLoading={state.common.loadingFacilities}
          facilities={state.common.facilities}
          onSubmit={facility =>
            dispatch(checkoutActions.selectFacility(facility[0]))
          }
          />
      </Container>
    )
  }
  if (!state.checkout.common.facility.visitationSchedulingEnabled) {
    return <Redirect to="/checkout/facility" />
  }

  if (!state.auth.loggedIn) {
    return (
      <Container
        onResetService={() => dispatch(checkoutActions.selectService(null))} facility={state.checkout.common.facility}>
        <LoginOrRegister
          title="Video Visitation"
          serviceCssClass="visitation"
          onLogin={(emailAddress, password) =>
            dispatch(loginActions.login(emailAddress, password))
              .catch(err => {
                return { [FORM_ERROR]: err.error ? err.error : err };
              })
          }
          onRegister={(emailAddress, password) =>
            dispatch(onlineUserActions.register(emailAddress, password))
              .catch(err => {
                return { [FORM_ERROR]: err.error ? err.error : err };
              })
          } />
      </Container>
    )
  }

  if (state.schedule.visitationData == null || state.schedule.visitationData.inmate == null || state.schedule.visitationData.timeslot == null) {
    return (
      <Container
        onResetService={() => dispatch(checkoutActions.selectService(null))}
        facility={state.checkout.common.facility}
        inmate={state.schedule.visitationData ? state.schedule.visitationData.inmate : null}>
        <Details
          facility={state.checkout.common.facility}
          loadingInmates={state.schedule.loadingInmates}
          inmates={state.schedule.inmates}
          loadingTimeslots={state.schedule.loadingTimeslots}
          timeslotLoadingError={state.schedule.timeslotLoadingError}
          timeslots={state.schedule.timeslots}
          selectedInmate={state.schedule.visitationData ? state.schedule.visitationData.inmate : null}
          onInmateSelect={inmate =>
            dispatch(scheduleActions.setInmate(inmate))
          }
          selectedTimeslot={state.schedule.visitationData ? state.schedule.visitationData.timeslot : null}
          onTimeslotSelect={timeslot =>
            dispatch(scheduleActions.setTimeslot(timeslot))
          }
          />
      </Container>
    )
  }

  if (state.schedule.visitation == null) {
    return (
      <Container
        onResetService={() => dispatch(checkoutActions.selectService(null))}
        facility={state.checkout.common.facility}
        inmate={state.schedule.visitationData ? state.schedule.visitationData.inmate : null}
        timeslot={state.schedule.visitationData ? state.schedule.visitationData.timeslot : null}>
        <VisitorInformation
          onSubmit={(data: IOutsideParty) => {
            dispatch(scheduleActions.setVisitorInformation(data));
            return dispatch(scheduleActions.schedule());
          }} />
      </Container>
    );
  }

  return (
    <>
      <PageTitle title="Schedule Visitation" />
      <Confirmation
        visitation={state.schedule.visitation}
        />
    </>
  )
}

export default Schedule
