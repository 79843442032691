import React from 'react';
import { Link } from 'react-router-dom';
import PageTitle from '../components/PageTitle';
import CollectCallCostModal from '../components/Services/CollectCallCostModal';
import PhoneCardCostModal from '../components/Services/PhoneCardCostModal';
import WalletCostModal from '../components/Services/WalletCostModal';
import WalletFeaturesModal from '../components/Services/WalletFeaturesModal';

enum ServiceModal {
  InmateWallet,
  PhoneCards,
  CollectCalls,
  WalletFeatures
}

interface IServicesState {
  modalToDisplay?: ServiceModal
}

class Services extends React.Component<{}, IServicesState> {
  constructor(props) {
    super(props);

    this.state = {};

    this.showModal = this.showModal.bind(this);
  }

  showModal(modalName: ServiceModal) {
    this.setState({ modalToDisplay: modalName });
  }

  clearModal() {
    this.setState({ modalToDisplay: null });
  }

  render() {
    return (
      <div>
        <PageTitle title="Communication Options" />

        <div className="hero bg-primary text-light py-5">
            <div className="container">
              <div className="row pt-5">
                <div className="col-lg-8 offset-lg-2 py-5">
                <h1 className="d-none">Communication Options</h1>
                <span className="page-icon rt-communications"></span>
                <h2>With Reliance, you have more flexible options to connect with your friends and family</h2>
              </div>
            </div>

            </div>
          </div>

          <main className="container">
            <div className="row py-5">
              <div className="col-md-6 image-service srvc-inmatewallet d-flex justify-content-center">
                <span className="align-self-center"></span>
              </div>

              <div className="col-md-6 py-5">
                <h3>Inmate Wallet (Texting)</h3>
                <p>The Reliance Inmate Wallet makes funding jail communication fast and easy! Payments made into an Inmate Wallet account are linked to a dedicated telephone number and a free to use Reliance Tablet Phones in a protective enclosure. Inmate Wallet funds can be used for:</p>
                <div className="row">
                  <div className="col-md-6 text-left d-flex mb-3 mb-md-0">
                    <span className="icon-sm txtmsg"></span>
                    <h5 className="col-9">Personal Inmate Text Messaging</h5>
                  </div>
                  <div className="col-md-6 text-left d-flex">
                    <span className="icon-sm voicevideocall"></span>
                    <h5 className="col-9">Voice and Video Calls</h5>
                  </div>
              </div>
              <button type="button" className="btn btn-action btn-block mt-3" onClick={() => this.showModal(ServiceModal.InmateWallet)}>See details and costs</button>
              <button type="button" className="btn btn-action btn-block mt-3" onClick={() => this.showModal(ServiceModal.WalletFeatures)}>See Reliance Tablet Phone Features</button>
            </div>
          </div>

          <WalletCostModal
            show={this.state.modalToDisplay == ServiceModal.InmateWallet}
            onHide={this.clearModal.bind(this)} />

          <WalletFeaturesModal
            show={this.state.modalToDisplay == ServiceModal.WalletFeatures}
            onHide={this.clearModal.bind(this)} />

          <div className="row py-5 flex-row-reverse">
            <div className="col-md-6 image-service srvc-phonecard d-flex justify-content-center">
              <span className="align-self-center"></span>
            </div>

            <div className="col-md-6 py-5">
              <h3>Inmate Phone Cards</h3>
              <p>Reliance is the only inmate communication provider that sells inmate phone cards online. Phone cards, sold in $10, $20, $50, and $100 denominations, can be purchased online by friends and family of inmates - anywhere at anytime. Phone cards can be transferred and used at any jail in the Reliance Telephone network. Additionally, remaining balances from phone cards can be transferred easily to a Reliance Inmate Wallet (texting) account.</p>
              <div className="phonecard-images d-flex">
                <span className="phonecard-10"></span>
                <span className="phonecard-20"></span>
                <span className="phonecard-50"></span>
                <span className="phonecard-100"></span>
              </div>
              <button type="button" className="btn btn-action btn-block mt-3" onClick={() => this.showModal(ServiceModal.PhoneCards)}>See details and costs</button>
            </div>
          </div>

          <PhoneCardCostModal
            show={this.state.modalToDisplay == ServiceModal.PhoneCards}
            onHide={this.clearModal.bind(this)} />

          <div className="row py-5">
            <div className="col-md-6 image-service srvc-collectcall d-flex justify-content-center">
              <span className="align-self-center"></span>
            </div>

            <div className="col-md-6 py-5">
              <h3>Prepaid Collect Calls</h3>
              <p>Prepaying for collect calls is a way for friends and family to receive calls from inmates by funding specific telephone numbers. These calls can be sent directly to a cell phone and/or landline and it’s easy to set up an account online.</p>
              <button type="button" className="btn btn-action btn-block mt-3" onClick={() => this.showModal(ServiceModal.CollectCalls)}>See details and costs</button>
            </div>
          </div>

          <CollectCallCostModal
            show={this.state.modalToDisplay == ServiceModal.CollectCalls}
            onHide={this.clearModal.bind(this)} />
        </main>

        <div className="bg-dark text-light p-5">
          <div className="container">
            <div className="row py-5">
              <div className="col-lg-10 offset-lg-2">
                <span className="section-icon rt-helpline"></span>
                <h3>Inmate Help Line & Text Support</h3>
                <p>The inmate help line allows inmates to contact our support directly with any communication problems they may have. Additionally, inmates can text our support staff through their personal texting device for assistance when needed.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="bg-light rounded text-center p-5 my-5">
            <h5>Services vary by location. To learn what’s available at your facility…</h5>
            <Link to="/checkout/facility" className="btn btn-block btn-action mt-3">Get Started</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
