import ClientOAuth2 from 'client-oauth2';
import { IOnlineUser, IOnlineUserUpdateData } from '../models/OnlineUser';
import { IPhoneCardPurchase } from '../models/PhoneCard';
import { ICreditCardPayment } from '../models/Transaction';
import { IVisitation } from '../models/Visitation';
import { IWalletPayment } from '../models/WalletPayment';
import Api from '../services/api';

export interface IRecentActions {
  recentWalletPayments: IWalletPayment[]
  recentCollectPayments: ICreditCardPayment[]
  recentOnlineOrders: IPhoneCardPurchase[]
  recentVisitations: IVisitation[]
}

export function getRecentActions() {
  return Api
    .get({
      url: '/onlineuser/recent-actions'
    })
    .then((res: IRecentActions) => res);
}

interface IOnlineUserPasswordChangeRequest {
  currentPassword: string
  newPassword: string
}

export function putSettings(
  profileChange: IOnlineUserUpdateData = null,
  passwordChange: IOnlineUserPasswordChangeRequest = null
) {
  return Api
    .put({
      url: '/onlineuser/settings',
      data: {
        profileChange,
        passwordChange
      }
    });
}

export function postPasswordReset(
  emailAddress: string
) {
  return Api
    .post({
      url: '/onlineuser/password/reset',
      data: {
        emailAddress
      }
    });
}

export function putPasswordReset(
  emailAddress: string,
  resetCode: string,
  newPassword: string
) {
  return Api
    .put({
      url: '/onlineuser/password/reset',
      data: {
        emailAddress,
        code: resetCode,
        newPassword
      }
    });
}

export interface IPostResponse {
  success: boolean
  onlineUser: IOnlineUser
  token: ClientOAuth2.Token
}

export function post(
  emailAddress: string,
  password: string
): Promise<IPostResponse> {
  return Api
    .post({
      url: '/onlineuser',
      data: {
        emailAddress,
        password
      }
    })
}