import moment from 'moment';
import React from 'react';
import shouldShowInmateNumber from '../../../functions/shouldShowInmateNumber';
import { Facility, IFacility } from '../../../models/Facility';
import { IWalletPayment } from '../../../models/WalletPayment';
import NameAndAddress from '../../Facility/NameAndAddress';
import Currency from '../../Format/Currency';
import PhoneNumber from '../../Format/PhoneNumber';
import RecentAction from './RecentAction';

interface IRecentWalletProps {
  loading: boolean
  payment: IWalletPayment
  facility: Facility|IFacility
  onRepeat: () => void
}

const RecentWallet: React.FC<IRecentWalletProps> = props =>
  <RecentAction
    loading={props.loading}
    title="Inmate Wallet"
    repeatActionTitle={props.payment && (props.payment.inmate.status == 'Enabled' && props.payment.inmate.device) ? 'Buy Again' : null}
    repeatAction={props.onRepeat}
    historyUrl="/wallet/history">
    {props.payment &&
      <>
        <div className="mb-4 mt-5">
          <h6 className="m-0"><Currency value={props.payment.amount} /> paid for:</h6>
          <h6 className="m-0">
            {props.payment.inmate.firstName}&nbsp;
            {props.payment.inmate.lastName}&nbsp;
            #{props.payment.inmate.account}
          </h6>
          {shouldShowInmateNumber(props.facility, props.payment.inmate) &&
            <h6 className="m-0">
              Texting # <PhoneNumber value={props.payment.inmate.device.smsNumber.number}/>
            </h6>
          }
        </div>
        <div className="text-dark mb-5">
          <p className="m-0 subtitle subtwo">
            Purchased on {moment(props.payment.createdDate.date).format('MM/DD/YY')}
          </p>
          <p className="m-0 subtitle subtwo">
            For use at <NameAndAddress facility={props.facility} oneLine={true} />
          </p>
        </div>
      </>
    }
  </RecentAction>

export default RecentWallet;
