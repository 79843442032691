import * as visitationActions from "../actions/visitation";
import { IVisitation } from '../models/Visitation';
import { IPaginatedResponse } from '../services/api';

export interface IVisitationScheduledState {
  loading: boolean
  error?: Error
  scheduled?: IPaginatedResponse<IVisitation>
  cancellingVisitation?: IVisitation
  cancelVisitationError?: Error
}

const initialState:IVisitationScheduledState = {
  loading: false
};

export function visitationScheduledReducer(state: IVisitationScheduledState = initialState, action): IVisitationScheduledState {
  switch (action.type) {
    case visitationActions.LOADING_SCHEDULED:
      return {
        ...state,
        loading: true,
        error: undefined
      };

    case visitationActions.LOAD_SCHEDULED_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case visitationActions.LOAD_SCHEDULED_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        scheduled: action.scheduled
      };

    case visitationActions.CANCELLING:
      return {
        ...state,
        cancellingVisitation: action.visitation
      };

    case visitationActions.CANCEL_FAILURE:
      return {
        ...state,
        cancellingVisitation: null,
        cancelVisitationError: action.error
      };

    case visitationActions.CANCEL_SUCCESS:
      return {
        ...state,
        cancellingVisitation: null,
        cancelVisitationError: null
      };
  }

  return state;
}
