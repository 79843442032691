import React from 'react';
import { Link } from 'react-router-dom';
import { IAccount } from '../../../models/Account';
import { Facility } from '../../../models/Facility';
import { IOnlineUserAccount } from '../../../models/OnlineUserAccount';
import { ICreditCardPayment } from '../../../models/Transaction';
import NameAndAddress from '../../Facility/NameAndAddress';
import Currency from '../../Format/Currency';
import PhoneNumber from '../../Format/PhoneNumber';

interface IConfirmationProps {
  payment: ICreditCardPayment
  facility: Facility
  account: IOnlineUserAccount|IAccount
  receiptEmailAddress: string
  subTotal: number
  transactionFee: number
  total: number
}

const Confirmation: React.FC<IConfirmationProps> = props => {
  const hasSubTotal = props.subTotal !== null && props.subTotal !== undefined;
  const hasTransactionFee = props.transactionFee !== null && props.transactionFee !== undefined;
  const hasBreakdown = hasSubTotal && hasTransactionFee;

  return (
    <main className="container align-items-top justify-content-center my-5 my-lg-3 pt-4">
      <section className="no-gutters">
        <div className="main row">
          <div className="col-md-6 offset-md-3 mb-3 ">
            <h3 className="text-center">Transaction complete</h3>
            {props.receiptEmailAddress &&
              <p className="bodysm text-center">A receipt has been emailed to {props.receiptEmailAddress}</p>
            }
          </div>
          <div className="col-md-6 offset-md-3 mb-3 rt-status receipt">
            <aside className="bg-light text-dark rounded p-3 py-5 p-lg-5">
              <h3 className="text-action text-center">Your purchase:</h3>

              <div className="selected-service py-lg-2">
                <h5 className="text-center">Prepaid Collect Call</h5>
                <div className="checkout-fees">
                  {hasSubTotal &&
                    <p className="total"><Currency value={-1 * props.subTotal} /></p>
                  }
                  {hasTransactionFee &&
                    <p className="transaction-fee"><Currency value={props.transactionFee} /></p>
                  }
                  {hasBreakdown &&
                    <p className="total-charge"><Currency value={-1 * props.total} /></p>
                  }
                  {!hasBreakdown &&
                    <p className="total-charge-standalone"><Currency value={-1 * props.total} /></p>
                  }
                </div>
                <p className="bodysm">
                  Collect call accounts are on a prepayment basis and you must maintain a positive credit balance to keep your line open. Any calls received that exceed account balance will be billed to your account and due immediately.
                </p>
                <p className="bodysm">
                  Payments to your collect call account can be made online or over the phone by calling 1-800-896-3201. Live representatives are available Monday-Friday 9am to 6pm CST.
                </p>
              </div>
              <hr className="border-muted" />
              <div className="selected-account py-lg-2">
                <h5>For account:</h5>
                <p><PhoneNumber value={props.account.ani} /></p>
              </div>
              {props.facility &&
                <>
                  <hr className="border-muted" />
                  <div className="selected-facility py-lg-2">
                    <h5>At facility:</h5>
                    <NameAndAddress facility={props.facility} />
                  </div>
                </>
              }
            </aside>
          </div>
          <div className="col-md-6 offset-md-3 ">
            <button className="btn btn-block btn-outline-secondary btn-lg text-secondary py-2 my-3 print" type="submit" onClick={() => window.print()}>
              <strong>Print Receipt</strong>
            </button>
            <Link className="btn btn-action btn-block btn-lg text-white py-3" type="submit" to="/account/dashboard"><strong>View account dashboard</strong></Link>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Confirmation;
