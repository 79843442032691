import React from 'react';
import Form from 'react-bootstrap/Form';
import { ICreditCard } from '../../models/CreditCard';
import { creditCard, creditCardExpiration, postalCode, required } from '../../validation';
import { renderFormInput } from '../Form/RenderForm';
import State from '../Form/State';

interface IDetailsFormProps {
  editCard?: ICreditCard
}

const DetailsForm: React.FC<IDetailsFormProps> = props => {
  let idPrefix = props.editCard ? `editCard.${props.editCard.id}` : 'addCard'

  return (
    <>
      <div>
        {renderFormInput(
          idPrefix + '.cardDetails.nameOnCard',
          'nameOnCard',
          'Name on card',
          [required]
        )}
      </div>
      <div>
        {renderFormInput(
          idPrefix + '.cardDetails.cardNumber',
          'cardNumber',
          `Card number${props.editCard == null ? '' : ' (leave empty to not change)'}`,
          props.editCard == null ? [required, creditCard] : [creditCard]
        )}
      </div>
      <div className="row no-gutters">
        <div className=" col-lg-6">
          {renderFormInput(
            idPrefix + '.cardDetails.expirationDate',
            'expirationDate',
            'Expiration date (MM/YY)',
            [required, creditCardExpiration]
          )}
        </div>
      </div>
      <div>
        {renderFormInput(
          idPrefix + '.cardDetails.address',
          'address',
          'Billing address',
          [required]
        )}
      </div>
      <div>
        {renderFormInput(
          idPrefix + '.cardDetails.city',
          'city',
          'City',
          [required]
        )}
      </div>
      <div className="row no-gutters">
        <div className=" col-lg-6">
          <Form.Label>State</Form.Label>
          <State
            name="state"
            required={true}
            includeCanada={true} />
        </div>
        <div className="col-lg-5 offset-lg-1">
          {renderFormInput(
            idPrefix + '.cardDetails.zip',
            'zip',
            'Postal code',
            [required, postalCode]
          )}
        </div>
      </div>
    </>
  )
}

export default DetailsForm
