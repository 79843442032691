import React from 'react';
import { ICardTypeAvailable } from '../../../models/PhoneCard';
import { requiredWithIgnored } from '../../../validation';
import { renderSelect } from '../../Form/RenderForm';

interface IPhoneCardPickerProps {
  cardTypes: ICardTypeAvailable[]
}

const PhoneCardPicker: React.FC<IPhoneCardPickerProps> = props =>
  <>
    <h3 className="pt-4">Select phone card amount</h3>
    <div className="input-group mb-3">
      <div className="input-group-prepend">
        <label className="input-group-text bodysm text-secondary" htmlFor="inputGroupSelect01">
          {props.cardTypes == null
            ? 'Loading available phone cards...'
            : 'Phone cards available in ' + (props.cardTypes.map(t => '$'+t.unitPrice).join(', '))
          }
        </label>
      </div>

      {renderSelect(
        'phoneCard.typeValueId',
        'typeValueId',
        null,
        [
          <option key='card-type-select' value={'false'}>Choose...</option>,
          ...(props.cardTypes||[]).map(t =>
            <option value={t.typeValueId} key={`card-type-${t.typeValueId}`}>${t.unitPrice} Phone Card</option>
          )
        ],
        [requiredWithIgnored(['false'])],
        (props.cardTypes||[]).length == 0
      )}
    </div>

    <div className="input-group mb-3">
      <div className="input-group-prepend">
        <label className="input-group-text bodysm text-secondary">
          Number of cards to purchase
        </label>
      </div>

      {renderSelect(
        'phoneCard.quantity',
        'quantity',
        null,
        [
          <option key='card-quantity-select' value={'false'}>Select quantity</option>,
          <option key='card-quantity-1'>1</option>,
          <option key='card-quantity-2'>2</option>,
          <option key='card-quantity-3'>3</option>,
          <option key='card-quantity-4'>4</option>,
          <option key='card-quantity-5'>5</option>
        ],
        [requiredWithIgnored(['false'])]
      )}
    </div>
  </>

export default PhoneCardPicker
