import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { IWalletReceiptState, walletReceiptReducer } from './receipt';

const walletPersistConfig = {
  key: 'wallet',
  timeout: 60 * 60,
  storage,
  whitelist: ['receipt']
}

export interface IWalletState {
  receipt: IWalletReceiptState
}

export const walletReducer = persistReducer(
  walletPersistConfig,
  combineReducers<IWalletState>({
    receipt: walletReceiptReducer
  })
) as any; // Casting resolves a typescript complaint that doesn't make sense...
