import * as inmateActions from "../actions/inmate";
import { IInmate } from '../models/Inmate';

export interface IAppsState {
  loadingInmate: boolean,
  inmate?: IInmate,
  error?: Error
}

const initialState:IAppsState = {
  loadingInmate: false
};

export function appsReducer(state: IAppsState = initialState, action): IAppsState {
  switch (action.type) {
    case inmateActions.INMATE_BY_ID_LOADING:
      return {
        ...state,
        loadingInmate: true,
        error: null
      };

    case inmateActions.INMATE_BY_ID_LOAD_FAILURE:
      return {
        ...state,
        loadingInmate: false,
        error: action.error
      };

    case inmateActions.INMATE_BY_ID_LOAD_SUCCESS:
      return {
        ...state,
        loadingInmate: false,
        error: undefined,
        inmate: action.inmate
      };
  }

  return state;
}
