import { ThunkAction } from 'redux-thunk';
import { IPaymentInformationData } from '../../components/Checkout/PaymentInformation';
import { IPersonalInformationData } from '../../components/Checkout/PersonalInformation';
import { ICreditCard } from '../../models/CreditCard';
import { IInmate } from '../../models/Inmate';
import { IWalletPayment, IWalletPaymentData } from '../../models/WalletPayment';
import { IAppState } from '../../reducers';
import { Service } from '../../reducers/checkout';
import * as inmateResource from '../../resources/Inmate';
import * as inmateAccountPaymentResource from '../../resources/InmateAccountPayment';
import Sentry from '../../services/sentry';
import * as checkoutActions from '../checkout';
import { ICheckoutSelectFacilityAction, ICheckoutSelectServiceAction } from '../checkout';
import { IProfileUpdateSuccessAction, PROFILE_UPDATE_SUCCESS } from '../online-user';

export const LOADING_INMATE_BY_ANI = 'CHECKOUT/WALLET/LOADING_INMATE_BY_ANI';
export const LOADED_INMATE_BY_ANI = 'CHECKOUT/WALLET/LOADED_INMATE_BY_ANI';
interface IWalletInmateByAniLoadingAction {
  type: typeof LOADING_INMATE_BY_ANI
  ani: string
}
interface IWalletInmateByAniLoadedAction {
  type: typeof LOADED_INMATE_BY_ANI
  ani: string
}
type WalletInmateByAniActions = IWalletInmateByAniLoadingAction | IWalletInmateByAniLoadedAction | ICheckoutSelectServiceAction | ICheckoutSelectFacilityAction;
export function loadInmateByAni(ani: string): ThunkAction<Promise<IInmate>, IAppState, null, WalletInmateByAniActions> {
  return dispatch => {
    dispatch({ type: LOADING_INMATE_BY_ANI, ani })
    return inmateResource
      .getByAni(ani)
      .then(inmate => {
        dispatch(checkoutActions.selectFacilityById(inmate.facility.id));
        dispatch(checkoutActions.selectService(Service.Wallet));
        dispatch(setDetails(inmate, null));
        dispatch({ type: LOADED_INMATE_BY_ANI, ani });
        return inmate;
      })
  }
}

export const CLEAR_INMATE_ANI = 'CHECKOUT/WALLET/CLEAR_INMATE_ANI';
interface IWalletClearInmateAniAction {
  type: typeof CLEAR_INMATE_ANI
}
export function clearInmateAni(): IWalletClearInmateAniAction {
  return { type: CLEAR_INMATE_ANI };
}

export const SET_DETAILS = 'CHECKOUT/WALLET/SET_DETAILS';
interface IWalletSetDetailsAction {
  type: typeof SET_DETAILS
  details: any
}
export function setDetails(
  inmate: IInmate,
  amount: string|number
): ThunkAction<void, IAppState, null, IWalletSetDetailsAction> {
  return (dispatch, getState) =>
    dispatch({
      type: SET_DETAILS,
      details: {
        ...(getState() as IAppState).checkout.wallet.paymentData,
        inmate,
        amount: amount ? parseFloat(amount.toString()) : null,
        facility: (getState() as IAppState).checkout.common.facility
      }
    })
}
export function setPaymentDetails(
  details: IPaymentInformationData & IPersonalInformationData
): ThunkAction<IWalletPaymentData, IAppState, null, IWalletSetDetailsAction> {
  return (dispatch, getState) => {
    dispatch({
      type: SET_DETAILS,
      details: Object.assign({}, (getState() as IAppState).checkout.wallet.paymentData, details)
    })
    return (getState() as IAppState).checkout.wallet.paymentData;
  }
}

export const PAYMENT_PROCESSING = 'CHECKOUT/WALLET/PAYMENT_PROCESSING';
export const PAYMENT_SUCCESS = 'CHECKOUT/WALLET/PAYMENT_SUCCESS';
export const PAYMENT_FAILURE = 'CHECKOUT/WALLET/PAYMENT_FAILURE';
interface IWalletPaymentProcessingAction {
  type: typeof PAYMENT_PROCESSING
  payment: IWalletPaymentData
}
interface IWalletPaymentFailureAction {
  type: typeof PAYMENT_FAILURE
  error
}
interface IWalletPaymentSuccessAction {
  type: typeof PAYMENT_SUCCESS
  payment: IWalletPayment
  paymentData: IWalletPaymentData
}

type WalletPaymentActionTypes = IWalletPaymentSuccessAction | IWalletPaymentFailureAction | IWalletPaymentProcessingAction | IProfileUpdateSuccessAction;
export function processPayment(
  payment: IWalletPaymentData,
  savedCreditCard: ICreditCard
): ThunkAction<Promise<IWalletPayment>, IAppState, null, WalletPaymentActionTypes> {
  return dispatch => {
    dispatch({ type: PAYMENT_PROCESSING, payment });

    if (payment.cc && payment.cc.expirationDate) {
      const expParts = payment.cc.expirationDate.split('/');
      const expMonth = expParts[0];
      const expYear = expParts[1];
      payment.cc.expMonth = expMonth;
      payment.cc.expYear = expYear.substr(-2);
    }

    // Remove any expected spaces or dashes in the card number
    if (payment.cc && payment.cc.cardNumber) {
      payment.cc.cardNumber = payment.cc.cardNumber.replace(/[^0-9]/g, '');
    }

    return inmateAccountPaymentResource
      .post({
        payment: {
          accountType: 'wallet',
          facilityId: payment.facility.id,
          inmateId: payment.inmate.id,
          amount: payment.amount,
          receiptEmailAddress: payment.receiptEmailAddress
        },
        savedCreditCardId: savedCreditCard ? savedCreditCard.id : null,
        cardSecurityCode: payment.cc.cardSecurityCode,
        newCreditCard: savedCreditCard ? null : payment.cc,
        purchaserDetails: {
          firstName: payment.firstName,
          lastName: payment.lastName,
          address: payment.address,
          city: payment.city,
          state: payment.state,
          zip: payment.zip,
          emailAddress: payment.emailAddress?.trim(),
          phoneNumber: payment.phoneNumber,
          updateAccountSettings: payment.updateAccountSettings
        }
      })
      .then(res => {
        if (res == null || !res.success) {
          throw new Error('Invalid response from server');
        }

        // Update the in-memory profile data with the personal information supplied
        dispatch({ type: PROFILE_UPDATE_SUCCESS, user: {
          firstName: payment.firstName,
          lastName: payment.lastName,
          emailAddress: payment.emailAddress?.trim(),
          address: payment.address,
          city: payment.city,
          state: payment.state,
          zip: payment.zip,
          phoneNumber: payment.phoneNumber
        }});

        dispatch({ type: PAYMENT_SUCCESS, payment: res.payment, paymentData: payment });
        return res.payment;
      })
      .catch(error => {
        Sentry.captureException(error);
        dispatch({ type: PAYMENT_FAILURE, error });
        throw error;
      });
  }
}

export const RESET = 'CHECKOUT/WALLET/RESET';
interface IWalletResetAction {
  type: typeof RESET
}
export function reset(): ThunkAction<void, IAppState, null, IWalletResetAction|IWalletResetSensitiveAction> {
  return (dispatch, getState) => {
    const state = getState();

    if (state.checkout.wallet.payment) {
      dispatch({ type: RESET });
    } else {
      dispatch(resetSensitive());
    }
  }
}

export const RESET_SENSITIVE = 'CHECKOUT/WALLET/RESET_SENSITIVE';
interface IWalletResetSensitiveAction {
  type: typeof RESET_SENSITIVE
}
export function resetSensitive(): IWalletResetSensitiveAction {
  return { type: RESET_SENSITIVE };
}

export type WalletActionTypes = (
  WalletPaymentActionTypes |
  WalletInmateByAniActions |
  IWalletResetSensitiveAction |
  IWalletResetAction |
  IWalletSetDetailsAction |
  IWalletClearInmateAniAction
);