import * as onlineUserAccountActions from "../../actions/online-user-account";
import { IOnlineUserAccount } from '../../models/OnlineUserAccount';

export interface IAccountsListState {
  loading: boolean
  error?: Error
  accounts?: IOnlineUserAccount[]

  verifying?: boolean
  verifyError?: Error

  adding?: boolean
  addError?: Error
}

const initialState:IAccountsListState = {
  loading: false
}

export function accountsListReducer(state: IAccountsListState = initialState, action): IAccountsListState {
  switch (action.type) {
    case onlineUserAccountActions.LOADING:
      return {
        ...state,
        loading: true,
        error: undefined
      };

    case onlineUserAccountActions.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case onlineUserAccountActions.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        accounts: action.accounts
      };

    case onlineUserAccountActions.ADDING:
      return {
        ...state,
        adding: true,
        addError: null
      };

    case onlineUserAccountActions.ADD_FAILURE:
      return {
        ...state,
        adding: false,
        addError: action.error
      };

    case onlineUserAccountActions.ADD_SUCCESS:
      return {
        ...state,
        adding: false,
        addError: null
      };

    case onlineUserAccountActions.VERIFYING:
      return {
        ...state,
        verifying: true,
        verifyError: null
      };

    case onlineUserAccountActions.VERIFY_FAILURE:
      return {
        ...state,
        verifying: false,
        verifyError: action.error
      };

    case onlineUserAccountActions.VERIFY_SUCCESS:
      return {
        ...state,
        verifying: false,
        verifyError: null
      };
  }

  return state;
}
