import React from 'react';
import shouldShowInmateNumber from '../../../functions/shouldShowInmateNumber';
import { Facility, IFacility } from '../../../models/Facility';
import { IInmate } from '../../../models/Inmate';
import NameAndAddress from '../../Facility/NameAndAddress';
import Currency from '../../Format/Currency';
import PhoneNumber from '../../Format/PhoneNumber';

interface IInfoBoxProps {
  facility: IFacility
  inmate: IInmate
  cost: number
  fees: number
  taxes: number
  loadingTaxes: boolean
}

const InfoBox:React.FC<IInfoBoxProps> = props =>
  <div className="col-md-4 mb-3 rt-status">
    <aside className="bg-secondary text-light rounded p-3 p-lg-5">
      <h4>You've selected:</h4>

      <div className="selected-service py-lg-2">
        <h5>Inmate Wallet (Texting)</h5>

        {props.cost > 0 &&
          <div className="checkout-fees">
            <p className="total"><Currency value={props.cost} /></p>
            <p className="transaction-fee"><Currency value={props.fees} /></p>
            {props.taxes > 0 && <p className="taxes"><Currency value={props.taxes} /></p>}
            <p className="total-charge"><Currency value={props.cost + props.fees + props.taxes} /></p>
          </div>
        }

        <p className="bodysm">
          The funds paid into an Inmate Wallet account are tied directly to an inmate’s personal device for them to use for texting, and video and voice calls where available. These funds are non-transferable and non-refundable.
        </p>
      </div>

      {props.inmate &&
        <>
          <hr className="border-light" />
          <div className="selected-facility py-lg-2">
            <h5>For inmate:</h5>

            <p className="subtitle subhead inmate-name">
              {props.inmate.firstName} {props.inmate.lastName}
            </p>

            {props.inmate.account &&
              <p className="inmate-phonenum">#{props.inmate.account}</p>
            }

            {shouldShowInmateNumber(props.facility, props.inmate) &&
              <>
                <p className="subtitle subhead inmate-txtdev">Texting Device #</p>
                <p className="inmate-textdevnum">
                  <a href={`sms:+1${props.inmate.device.smsNumber.number}`}>
                    <PhoneNumber value={props.inmate.device.smsNumber.number} />
                  </a>
                </p>
              </>
            }
          </div>
        </>
      }

      {props.facility &&
        <>
          <hr className="border-light" />
          <div className="selected-facility py-lg-2">
            <h5>Facility:</h5>
            <NameAndAddress facility={new Facility(props.facility)} />
          </div>
        </>
      }
    </aside>
  </div>

export default InfoBox;