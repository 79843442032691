import React from 'react';
import { Form } from 'react-final-form';
import { IFacility } from '../../models/Facility';
import { required } from '../../validation';
import { renderTypeaheadInput } from '../Form/RenderForm';

interface IFacilitySearchProps {
  facilitiesLoading: boolean
  facilities: IFacility[]
  onSubmit: (facility: IFacility[]) => any
}

const FacilitySearch: React.FC<IFacilitySearchProps> = props =>
  <div className="col-lg-12 my-3">
    <div className="border border-dark rounded p-lg-5 p-4 h-100 d-flex flex-column justify-content-center">
      <h4>Purchase a communication option by finding a facility and inmate</h4>
      <p>Search by state, city, county, or facility name</p>
      <div className="my-4">
        <Form
          onSubmit={({facility}) =>
            props.onSubmit(props.facilities.filter(f => f.id == facility[0].id))
          }>
          {form =>
            <form onSubmit={form.handleSubmit} method="post">
              <div className="form-label-group">
                {renderTypeaheadInput(
                  'facility',
                  'facility',
                  props.facilitiesLoading ? 'Loading facilities...' : 'Search for facility...',
                  null,
                  props.facilities ? props.facilities.map(f => ({id: f.id, label: `${f.name} (${f.city}, ${f.state})`})) : [],
                  [required],
                  props.facilitiesLoading,
                  value => props.onSubmit(props.facilities.filter(f => f.id == value[0].id))
                )}
              </div>
            </form>
          }
        </Form>
      </div>
    </div>
  </div>

export default FacilitySearch;
