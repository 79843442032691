import { FORM_ERROR } from 'final-form';
import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as loginActions from '../../actions/auth';
import * as checkoutActions from '../../actions/checkout';
import * as cardActions from '../../actions/checkout/card';
import * as creditCardActions from '../../actions/credit-card';
import * as inmateActions from '../../actions/inmate';
import * as onlineUserActions from '../../actions/online-user';
import * as taxActions from '../../actions/tax';
import { ICardTypeAvailable } from '../../models/PhoneCard';
import { TaxAffectsType } from '../../models/Tax';
import { AffectsType } from '../../models/Transaction';
import { IAppState } from '../../reducers';
import ErrorMessage from '../ErrorMessage';
import Container from './Card/Container';
import Details from './Card/Details';
import Payment from './Card/Payment';
import LoginOrRegister from './LoginOrRegister';

const Card: React.FC = () => {
  const state = useSelector((appState: IAppState) => ({
    checkout: appState.checkout,
    auth: appState.auth,
    common: appState.common
  }));
  const dispatch: ThunkDispatch<IAppState, undefined, Action> = useDispatch();

  useEffect(() => {
    // Load the available card types
    if (state.checkout.common.facility && state.checkout.card.cardTypes == null) {
      dispatch(cardActions.loadAvailableCardTypes(state.checkout.common.facility.id));
    }

    // Reset data when leaving
    return function cleanupCardCheckout() {
      dispatch(cardActions.reset());
    }
  }, []);

  useEffect(() => {
    // Load saved credit cards
    if (state.auth.loggedIn && state.checkout.common.savedCreditCards == null) {
      dispatch(creditCardActions.load());
    }
  }, [state.auth.loggedIn]);

  useEffect(() => {
    // Load the inmate roster
    if (state.checkout.common.facility && state.checkout.common.facility.getConfigurationValue('RelayPhoneCardsViaVoicemail')) {
      dispatch(inmateActions.loadInmates(state.checkout.common.facility.id));
    }
  }, [state.checkout.common.facility]);

  // If we have a payment completed, display it as the other items will be cleared out at this point
  if (state.checkout.card.purchase) {
    return <Redirect to={{
      pathname: `/checkout/card/receipt/${state.checkout.card.purchase.id}`,
      state: { purchase: state.checkout.card.purchase }
    }} />
  }

  // If this page was accessed directly, without having previously set a facility, redirect to the homepage with the facility picker
  if (state.checkout.common.facility == null || !state.checkout.common.facility.phoneCardsEnabled) {
    return <Redirect to="/checkout/facility" />
  }

  const purchaseData = state.checkout.card.purchaseData;
  const quantity = (purchaseData||{}).quantity || 1;
  const taxes = state.checkout.card.taxAmounts ? state.checkout.card.taxAmounts.combinedRate : 0;

  // Retrieve the selected card type from the typeValueId
  let cardType: ICardTypeAvailable = null;
  if (
    state.checkout.card.cardTypes &&
    purchaseData &&
    purchaseData.typeValueId
  ) {
    cardType = state.checkout.card.cardTypes
      .filter(t => t.typeValueId == purchaseData.typeValueId)[0];
  }

  // Calculate the transaction fees
  let fees = 0;
  if (cardType) {
    fees = Math.round(
      state.common.feeTable.reduce((prev, cur) => prev + cur.getFee(AffectsType.PhoneCard, (cardType.unitPrice * quantity)), 0) * 100
    ) / 100;
  }

  const inmateFirstName = purchaseData == null ? '' : (
    purchaseData.inmate ? purchaseData.inmate.firstName : (purchaseData.inmateInformation||{}).firstName
  );
  const inmateLastName = purchaseData == null ? '' : (
    purchaseData.inmate ? purchaseData.inmate.lastName : (purchaseData.inmateInformation||{}).lastName
  );

  // Check if the incoming inmate is disabled (if selected from a historical purchase)
  let selectedInmateDisabled = false;
  if (purchaseData && purchaseData.inmate && purchaseData.inmate.status == 'Disabled') {
    selectedInmateDisabled = true;
  }

  // Start by requesting the inmate and card details
  if (!state.checkout.card.detailsSet || selectedInmateDisabled) {
    return (
      <Container
        onResetService={() => dispatch(checkoutActions.selectService(null))}
        quantity={quantity}
        cardType={cardType}
        fees={fees}
        taxes={taxes}
        facility={state.checkout.common.facility}
        inmateFirstName={inmateFirstName}
        inmateLastName={inmateLastName}>
        <Details
          facility={state.checkout.common.facility}
          inmates={state.checkout.card.inmates}
          loadingInmates={state.checkout.card.inmatesLoading}
          selectedInmate={(purchaseData||{}).inmate}
          cardTypes={state.checkout.card.cardTypes}
          loadingCardTypes={state.checkout.card.loadingCardTypes}
          onSubmit={data => {
            dispatch(cardActions.setDetails(data));
            window.scrollTo(0, 0);
          }} />
      </Container>
    )
  }

  // Login or register next
  if (!state.auth.loggedIn) {
    return (
      <Container
        onResetService={() => dispatch(checkoutActions.selectService(null))}
        quantity={quantity}
        cardType={cardType}
        fees={fees}
        taxes={taxes}
        facility={state.checkout.common.facility}
        inmateFirstName={inmateFirstName}
        inmateLastName={inmateLastName}>
        <LoginOrRegister
          serviceCssClass="phonecard"
          title="Phone Card: Checkout"
          onLogin={(emailAddress, password) =>
            dispatch(loginActions.login(emailAddress, password))
              .then(() => window.scrollTo(0, 0))
              .catch(err => {
                return { [FORM_ERROR]: err.error ? err.error : err };
              })
          }
          onRegister={(emailAddress, password) =>
            dispatch(onlineUserActions.register(emailAddress, password))
              .then(() => window.scrollTo(0, 0))
              .catch(err => {
                return { [FORM_ERROR]: err.error ? err.error : err };
              })
          } />
      </Container>
    );
  }

  // Receive payment information
  if (state.checkout.card.purchase == null) {
    const subtotal = cardType ? (cardType.unitPrice * quantity) + fees : 0;
    return (
      <Container
        onResetService={() => dispatch(checkoutActions.selectService(null))}
        quantity={quantity}
        cardType={cardType}
        fees={fees}
        taxes={taxes}
        facility={state.checkout.common.facility}
        inmateFirstName={inmateFirstName}
        inmateLastName={inmateLastName}>
        <>
          {state.checkout.card.processingError &&
            <ErrorMessage
              error={state.checkout.card.processingError}
              httpGenericMessage={'An error occurred while processing your payment'}
              httpCodeMessages={{
                402: 'The credit card charge was declined',
                423: 'This credit card has a hold on it. Please contact Reliance Telephone at 1-800-896-3201 between 9am-6pm CST.',
                409: 'There is no longer sufficient inventory of this card type'
              }} />
          }
          {(state.checkout.card.loadingCardTypes || cardType == null) ?
            <Skeleton height={150} />
            :
            <Payment
              loggedIn={state.auth.loggedIn}
              loadingSavedCreditCards={state.checkout.common.loadingSavedCreditCards}
              savedCards={state.checkout.common.savedCreditCards}
              selectedSavedCard={state.checkout.common.selectedSavedCard}
              onCreditCardSelect={card => {
                dispatch(checkoutActions.selectSavedCreditCard(card));
                dispatch(taxActions.getTaxAmount(TaxAffectsType.PhoneCardPurchase, subtotal, card.zip));
              }}
              onAddCreditCard={() => {
                dispatch(checkoutActions.addCreditCard());
                dispatch(taxActions.resetTaxAmount());
              }}
              onZipCodeChanged={zip => {
                if (zip.length >= 5) {
                  dispatch(taxActions.getTaxAmount(TaxAffectsType.PhoneCardPurchase, subtotal, zip));
                }
              }}
              addingCreditCard={state.checkout.common.addingCreditCard}
              onSubmit={data =>
                dispatch(cardActions.processPurchase({
                  facilityId: state.checkout.common.facility.id,
                  typeValueId: cardType.typeValueId,
                  quantity,
                  inmateFirstName: purchaseData.inmate == null ? purchaseData.inmateInformation.firstName : purchaseData.inmate.firstName,
                  inmateLastName: purchaseData.inmate == null ? purchaseData.inmateInformation.lastName : purchaseData.inmate.lastName,
                  inmateId: purchaseData.inmate ? purchaseData.inmate.id : null,
                  savedCreditCardId: state.checkout.common.selectedSavedCard ? state.checkout.common.selectedSavedCard.id : null,
                  cardSecurityCode: data.cc.cardSecurityCode,
                  newCreditCard: state.checkout.common.selectedSavedCard ? null : data.cc,
                  receiptEmailAddress: data.receiptEmailAddress,
                  purchaserDetails: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    address: data.address,
                    city: data.city,
                    state: data.state,
                    zip: data.zip,
                    emailAddress: data.emailAddress,
                    phoneNumber: data.phoneNumber,
                    updateAccountSettings: data.updateAccountSettings
                  }
                }))
                .then(() => window.scrollTo(0, 0))
                .catch(() => window.scrollTo(0, 0))
              }
              amount={cardType.unitPrice}
              fees={fees}
              quantity={quantity}
              taxes={taxes} />
          }
        </>
      </Container>
    )
  }

  return <Redirect to="/" />
}

export default Card
