import * as dashboardActions from "../actions/dashboard";
import { IRecentActions } from '../resources/OnlineUser';

export interface IDashboardState {
  loadingRecentActions: boolean,
  recentActionsError?: Error,
  recentActions?: IRecentActions
}

const initialState:IDashboardState = {
  loadingRecentActions: false
};

export function dashboardReducer(state: IDashboardState = initialState, action): IDashboardState {
  switch (action.type) {
    case dashboardActions.LOADING_RECENT_ACTIONS:
      return {
        ...state,
        loadingRecentActions: true,
        recentActionsError: undefined
      };

    case dashboardActions.LOAD_RECENT_ACTIONS_FAILURE:
      return {
        ...state,
        loadingRecentActions: false,
        recentActionsError: action.error
      };

    case dashboardActions.LOAD_RECENT_ACTIONS_SUCCESS:
      return {
        ...state,
        loadingRecentActions: false,
        recentActionsError: undefined,
        recentActions: action.recentActions
      };
  }

  return state;
}
