import React from 'react';
import NumberFormat from 'react-number-format';

interface ICurrencyProps {
  value: number
}

const Currency: React.FC<ICurrencyProps> = props =>
  <NumberFormat value={props.value} displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} />

export default Currency;
