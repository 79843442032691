import React, { FunctionComponent } from 'react';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';

const Footer: FunctionComponent = () =>
  <footer className="py-5 bg-light footer d-print-none">
    <Container>
      <div className="row d-md-flex flex-row-reverse">
        <div className="col-md-4 ftr-list-right">
          <h5 className="ftrheading">Company</h5>
          <ul className="list-unstyled text-small">
            <li><Link className="text-muted" to="/about">About</Link></li>
            <li><Link className="text-muted" to="/services">Communication Options</Link></li>
          </ul>
        </div>
        <div className="col-md-4 ftr-list-right">
          <h5 className="ftrheading">Resources</h5>
          <ul className="list-unstyled text-small">
            <li><Link className="text-muted" to="/support">Support | FAQ</Link></li>
            <li><Link className="text-muted" to="/contact">Contact Information</Link></li>
            <li><Link className="text-muted" to="/policies">Policies and Terms</Link></li>
          </ul>
        </div>
        <div className="col-md-4">
          <p>
            <b>Reliance Telephone, Inc.</b>
            <br/>1533 S. 42nd St
            <br/>Grand Forks, ND 58201-3746
          </p>
          <p>
            <svg xmlns="http://www.w3.org/2000/svg" className="ftrlogo" viewBox="0 0 32.176 40"><defs></defs><g transform="translate(0 0)"><path className="rlogo" d="M1811.068,743.516a17.472,17.472,0,0,1-.116-1.98V724.685h29.832v11.6a4.907,4.907,0,0,0-.844.043v-10.8H1811.8v16.007c0,.167.008.331.013.5C1811.583,742.444,1811.317,742.96,1811.068,743.516Zm27.226,6.176c-2.392,5.091-6.875,8.768-9.918,11.261a25.075,25.075,0,0,0-2.508,2.213,25.08,25.08,0,0,0-2.509-2.213c-.811-.665-1.725-1.413-2.675-2.251l-1.045.2c1.131,1.02,2.228,1.92,3.186,2.7a15.263,15.263,0,0,1,2.681,2.471l.363.607.363-.607a15.268,15.268,0,0,1,2.68-2.471c3.333-2.73,8.352-6.842,10.648-12.7A9.916,9.916,0,0,1,1838.294,749.692Zm-22.837,3.4c-.373-.086-.821-.189-1.226-.279a28.7,28.7,0,0,0,3.179,3.929l.786-.4A29.363,29.363,0,0,1,1815.457,753.093Zm.353-1.04c-.354-3.012,2.523-14.01,13-17.869-1.7.72-3.466,2.269-3.828,3.607a19.316,19.316,0,0,1,1.426,2.564,20.9,20.9,0,0,1,6.115-7.258c-7.812-1.182-12.721,1.163-12.721,1.163a5.735,5.735,0,0,1,2.267.746,24.364,24.364,0,0,0-7.38,5.6,18.831,18.831,0,0,0-2.882,4.573C1810.285,750.613,1815.81,752.053,1815.81,752.053Zm-2.926-12.006V726.491h25.968v12.18c-.136,1.673-1.4,3.789-4.348,6.511a13.231,13.231,0,0,1-3.914-1.753.628.628,0,0,0-.072-.084c5.376-3.591,5.725-10.952,5.725-14.014H1815.81v8.2A13.028,13.028,0,0,0,1812.884,740.047Zm10.517,6.385a23.392,23.392,0,0,0,4.521,3.886q-2.035,1.4-4.521,2.966Zm18.859-2.1c-2.257,3.863-9.409,8.22-16.84,11.434l-.114.072c3.063-1.125,5.72-2.2,8.013-3.2.561.137,1.135.25,1.728.334-3.53,4.517-8.359,7.283-9.179,8.658a12.641,12.641,0,0,0-2.467-2.259v-2.765a60.446,60.446,0,0,1-11.854,3.573A91.194,91.194,0,0,0,1823.4,754.4v-.016l.442-.244c12.228-7.209,18.721-15.1,15.948-16.22C1841.82,736.945,1844.547,740.252,1842.26,744.333Z" transform="translate(-1810.952 -724.685)"/></g></svg>
            <small>&nbsp; &copy; 2020 Reliance Telephone, Inc.</small>
          </p>
        </div>
      </div>
    </Container>
  </footer>

export default Footer;
