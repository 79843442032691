import * as cardPurchaseResource from '../../resources/PhoneCardPurchase';

export const LOAD_PURCHASE_LOADING = 'CARD/RECEIPT/LOAD_PURCHASE_LOADING';
export const LOAD_PURCHASE_SUCCESS = 'CARD/RECEIPT/LOAD_PURCHASE_SUCCESS';
export const LOAD_PURCHASE_FAILURE = 'CARD/RECEIPT/LOAD_PURCHASE_FAILURE';
export function loadPurchase(id: number|string) {
  return dispatch => {
    dispatch({ type: LOAD_PURCHASE_LOADING, id });

    return cardPurchaseResource
      .get(Number(id))
      .then(purchase => dispatch({ type: LOAD_PURCHASE_SUCCESS, purchase}))
      .catch(error => dispatch({ type: LOAD_PURCHASE_FAILURE, error }))
  }
}

export const RESET = 'CARD/RECEIPT/RESET';
export function reset() {
  return { type: RESET };
}
