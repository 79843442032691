import moment from 'moment';
import React from 'react';
import { Facility, IFacility } from '../../../models/Facility';
import { IVisitation } from '../../../models/Visitation';
import NameAndAddress from '../../Facility/NameAndAddress';
import RecentAction from './RecentAction';

interface IRecentVisitationProps {
  loading: boolean
  visitation: IVisitation
  facility: Facility|IFacility
  onRepeat: () => void
}

const RecentVisitation: React.FC<IRecentVisitationProps> = props =>
  <RecentAction
    loading={props.loading}
    title="Scheduled Video Visitation"
    repeatActionTitle="Schedule Again"
    repeatAction={props.onRepeat}
    historyUrl="/visitation/scheduled">
    {props.visitation &&
      <>
        <h6 className="mb-4 mt-5">Your next visitation:</h6>
        <div className="text-dark mb-5">
          <p className="m-0 subtitle subtwo">
            {moment(props.visitation.startDate.date).format('ddd, MMM D HH:mm')}
          </p>
          <p className="m-0 subtitle subtwo">
            with {props.visitation.inmate.firstName} {props.visitation.inmate.lastName} {props.visitation.inmate.account && `#${props.visitation.inmate.account}`}
          </p>
          <p className="m-0 subtitle subtwo">
            at <NameAndAddress facility={props.facility} oneLine={true} />
          </p>
        </div>
      </>
    }
  </RecentAction>

export default RecentVisitation;
