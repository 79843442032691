import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IPhoneCardPurchase } from '../models/PhoneCard';
import { IAppState } from '../reducers';
import { index } from '../resources/PhoneCardPurchase';
import { IPaginatedResponse } from '../services/api';

export const LOADING_PURCHASES = 'PHONE_CARDS/LOADING_PURCHASES';
export const LOAD_PURCHASES_SUCCESS = 'PHONE_CARDS/LOAD_PURCHASES_SUCCESS';
export const LOAD_PURCHASES_FAILURE = 'PHONE_CARDS/LOAD_PURCHASES_FAILURE';
export function loadPurchases(): ThunkAction<Promise<IPaginatedResponse<IPhoneCardPurchase>>, IAppState, undefined, Action> {
  return dispatch => {
    dispatch({ type: LOADING_PURCHASES });

    return index()
      .then((paginatedResponse): IPaginatedResponse<IPhoneCardPurchase> => {
        dispatch({ type: LOAD_PURCHASES_SUCCESS, purchases: paginatedResponse });
        return paginatedResponse;
      })
      .catch(error => {
        dispatch({ type: LOAD_PURCHASES_FAILURE, error });
        throw error;
      })
  }
}