import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouterProps, withRouter } from 'react-router';
import * as checkoutActions from '../../actions/checkout';
import { IAppState } from '../../reducers';
import FacilitySearch from '../Facility/Search';
import PageTitle from '../PageTitle';
import Navigation from './Navigation';
import { CheckoutStep } from './Service';

const Facility: React.FC<RouterProps> = props => {
  let state = useSelector((appState: IAppState) => ({common: appState.common, checkout: appState.checkout}));
  let dispatch = useDispatch();

  return (
    <>
      <PageTitle title="Select Facility" />
      <Navigation step={CheckoutStep.Facility} />

      <main className="container align-items-top justify-content-center mt-3">
        <section className="no-gutters">
          <div className="main row">
            <div className="col-md-8">
              <FacilitySearch
                facilities={state.common.facilities}
                facilitiesLoading={state.common.loadingFacilities}
                onSubmit={facility => {
                  dispatch(checkoutActions.selectFacility(facility[0]));
                  props.history.push('/checkout/service');
                }} />
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default withRouter(Facility);
