import ClientOAuth2 from 'client-oauth2';
import moment from 'moment';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as authActions from '../actions/auth';
import { ApiErrorResponse, IApiErrorResponse, isErrorResponse } from '../models/Api';
import { IAppState } from '../reducers';
import Sentry from '../services/sentry';
import { appStore } from '../store';

const phpDateFormat = 'YYYY-MM-DD HH:mm:ss';

interface IRequestOptions {
  url: string
  data?: any
  query?: { [key:string]: any }
  contentType?: string|boolean
  processData?: boolean
  authenticated?: boolean
}

const baseUrl = process.env.API_URL;

class Api {
  refreshing: boolean = false
  oauthClient: ClientOAuth2

  constructor() {
    this.oauthClient = new ClientOAuth2({
      accessTokenUri: baseUrl + '/auth/token',
      clientId: 'website'
    });
  }

  hydrateToken(tokenData: any) {
    if (tokenData == null) {
      return null;
    }
    const token = this.oauthClient.createToken(tokenData.access_token, tokenData.refresh_token, tokenData.token_type, tokenData.data);

    const now = moment();
    const expirationDate = moment(tokenData.expires);
    const secondsToExpire = expirationDate.diff(now, 'seconds');
    token.expiresIn(secondsToExpire);

    return token;
  }

  buildUrl(resource: string, queryParams: {[key:string]: any}) {
    return baseUrl + resource + this.buildQuery(resource, queryParams);
  }

  get<T>(opts: IRequestOptions) {
    return this.request<T>('GET', opts);
  }

  post<T>(opts: IRequestOptions) {
    return this.request<T>('POST', opts);
  }

  put<T>(opts: IRequestOptions) {
    return this.request<T>('PUT', opts);
  }

  delete<T>(opts: IRequestOptions) {
    return this.request<T>('DELETE', opts);
  }

  request<T>(method: 'GET'|'POST'|'PUT'|'DELETE', opts: IRequestOptions): Promise<T> {
    const queryParams = (opts.query||{});
    const url = this.buildUrl(opts.url, queryParams);

    let startPromise: Promise<ClientOAuth2.Token|null>;
    if (opts.authenticated !== false) {
      startPromise = this.refreshToken();
    } else {
      startPromise = Promise.resolve(null);
    }

    return startPromise
      .then((token: ClientOAuth2.Token) =>
        fetch(url, buildRequest(method, (token ? token.accessToken : null), opts.data))
      )
      .then((res: Response) => {
        if (res.status >= 500) {
          throw new Error('Internal server error');
        }
        return res;
      })
      .then(res => throwNon200(res, { url, queryParams, data: JSON.stringify(opts.data)}))
      .then(res => json<T|IApiErrorResponse>(res))
      .then(res => {
        if (isErrorResponse(res)) {
          throw new ApiErrorResponse(res);
        }

        return res as T;
      });
  }

  addParam(key: string, value: any, url: string, query: string) {
    if (query == null) { query = ''; }
    if ((query.length > 0) || (url.indexOf('?') > -1)) { query += '&'; }
    if ((query.length === 0) && (url.indexOf('?') === -1)) { query += '?'; }
    query += key+'='+encodeURIComponent(value);
    return query;
  }

  buildQuery(url: string, queryParams: {[key: string]: any}) {
    if (queryParams == null) { queryParams = {}; }
    let query = '';
    for (const k in queryParams) {
      if (queryParams.hasOwnProperty(k)) {
        const v = queryParams[k];
        if ((v == null) || (v === '')) {
          continue;
        }

        // Convert moment dates into the format PHP expects
        if (v && v !== '' && moment.isMoment(v)) {
          query = this.addParam(k, v.format(phpDateFormat), url, query);

        } else if (v.startDate != null) {
          query = this.addParam('startDate', v.startDate.format(phpDateFormat), url, query);
          query = this.addParam('endDate', v.endDate.format(phpDateFormat), url, query);

        } else if (Array.isArray(v)) {
          if (v.length === 0) {
            continue;
          }
          query = this.addParam(k, v.join(','), url, query);

        } else if ((v != null) && (v !== '')) {
          query = this.addParam(k, v, url, query);
        }
      }
    }
    return query;
  }

  refreshToken(): Promise<ClientOAuth2.Token> {
    return new Promise(async (resolve) => {
      // If we are already refreshing, don't attempt a second refresh (as the refresh_token will be invalid)
      if (this.refreshing) {
        await new Promise(refreshResolve => setInterval(() => !this.refreshing ? refreshResolve() : null, 100));
      }

      const token: ClientOAuth2.Token = this.hydrateToken(appStore.getState().auth.token);

      if (token == null) {
        return resolve(null);
      }

      if (!token.expired()) {
        return resolve(token);
      }

      this.refreshing = true;

      (appStore.dispatch as ThunkDispatch<IAppState, undefined, Action>)(authActions.refreshToken(token))
        .then(refreshedToken => {
          this.refreshing = false;
          resolve(refreshedToken);
        });
    });
  }
}

export default new Api();

interface IBaseRequest {
  method: 'GET'|'POST'|'PUT'|'DELETE',
  headers: {[key: string]: string},
  body: any
}

const buildRequest = (method: 'GET'|'POST'|'PUT'|'DELETE', accessToken?: string, body?: any): IBaseRequest => {
  const request: IBaseRequest = {
    method,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': undefined
    },
    body: undefined
  }

  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
    // request.headers.Authorization = `Bearer 452a1cf3e7c892c5e9e24a009e0823da751a6a33`;
  }

  if (method !== 'GET' && body) {
    request.body = JSON.stringify(body);
  }

  return request;
};

export class ApiError extends Error {
  response: Response
  data: any
}

export const throwNon200 = (response: Response, request: any) => {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else {
    return response.json().then(errorData => {
      // Ignore login failures
      if (errorData == null || errorData.error == null || errorData.error.message == null || errorData.error.message.indexOf('invalid_grant') === -1) {
        Sentry.captureEvent({
          message: 'API request error',
          contexts: { data: {
            statusCode: response.status,
            request,
            errorData: JSON.stringify(errorData)
          }}
        });
      }

      const error = new ApiError(response.statusText)
      error.response = response;
      error.data = errorData;
      throw error
    })
  }
};

export const json = <T>(response: Response): Promise<T> => {
  try {
    return response.json();
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        'body': response.text()
      }
    });
    throw e;
  }
};

export interface IPaginatedResponse<T, S={}> {
  page: number
  perPage: number
  total: number
  data: T[]
  supportingData: S
}
