import { ThunkAction } from 'redux-thunk';
import { IPaymentData } from '../../components/Checkout/Collect/Payment';
import { ApiErrorResponse } from '../../models/Api';
import { ICreditCard } from '../../models/CreditCard';
import { IOnlineUserAccount } from '../../models/OnlineUserAccount';
import { ICreditCardPayment } from '../../models/Transaction';
import { IAppState } from '../../reducers';
import * as paymentResource from '../../resources/Payment';
import { IPostPaymentData } from '../../resources/Payment';
import Sentry from '../../services/sentry';
import { IProfileUpdateSuccessAction, PROFILE_UPDATE_SUCCESS } from '../online-user';

export const SELECT_COLLECT_ACCOUNT = 'CHECKOUT/SELECT_COLLECT_ACCOUNT';
interface ICollectSelectAccountAction {
  type: typeof SELECT_COLLECT_ACCOUNT
  account: IOnlineUserAccount
}
export function setAccount(account: IOnlineUserAccount): ICollectSelectAccountAction {
  return { type: SELECT_COLLECT_ACCOUNT, account };
}

export const CLEAR_COLLECT_ACCOUNT = 'CHECKOUT/CLEAR_COLLECT_ACCOUNT';
interface ICollectClearAccountAction {
  type: typeof CLEAR_COLLECT_ACCOUNT
}
export function clearAccount(): ICollectClearAccountAction {
  return { type: CLEAR_COLLECT_ACCOUNT };
}

export const SET_AMOUNT = 'CHECKOUT/COLLECT_SET_AMOUNT';
interface ICollectSetAmountAction {
  type: typeof SET_AMOUNT
  amount: number
}
export function setAmount(amount: string|number): ICollectSetAmountAction {
  return { type: SET_AMOUNT, amount: parseFloat(amount.toString()) };
}

export const RESET_SENSITIVE = 'CHECKOUT/COLLECT_RESET_SENSITIVE';
interface ICollectResetSensitiveAction {
  type: typeof RESET_SENSITIVE
}
export function resetSensitive(): ICollectResetSensitiveAction {
  return { type: RESET_SENSITIVE };
}

export const RESET = 'CHECKOUT/COLLECT_RESET';
interface ICollectResetAction {
  type: typeof RESET
}
export function reset(): ThunkAction<void, IAppState, undefined, ICollectResetAction |  ICollectResetSensitiveAction> {
  return (dispatch, getState) => {
    const state: IAppState = getState();

    if (state.checkout.collect.payment) {
      dispatch({ type: RESET });
    } else {
      dispatch(resetSensitive());
    }
  }
}

export const PAYMENT_PROCESSING = 'CHECKOUT/COLLECT_PAYMENT_PROCESSING';
export const PAYMENT_SUCCESS = 'CHECKOUT/COLLECT_PAYMENT_SUCCESS';
export const PAYMENT_FAILURE = 'CHECKOUT/COLLECT_PAYMENT_FAILURE';
interface IPaymentProcessingAction {
  type: typeof PAYMENT_PROCESSING
  payment: IPaymentData
}
interface IPaymentSuccessAction {
  type: typeof PAYMENT_SUCCESS
  payment: ICreditCardPayment
  subTotal: number
  transactionFee: number
  total: number
  paymentData: IPaymentData
}
interface IPaymentFailureAction {
  type: typeof PAYMENT_FAILURE
  error: Error|ApiErrorResponse
}
type PaymentActionTypes = IPaymentProcessingAction | IPaymentSuccessAction | IPaymentFailureAction | IProfileUpdateSuccessAction;
export function processPayment(
  payment: IPaymentData,
  savedCreditCard: ICreditCard
): ThunkAction<Promise<ICreditCardPayment>, IAppState, null, PaymentActionTypes> {
  return dispatch => {
    dispatch({ type: PAYMENT_PROCESSING, payment });

    const paymentWithoutCC = {...payment};
    delete paymentWithoutCC.cc;
    const apiData: IPostPaymentData = {
      ...paymentWithoutCC,
      purchaserDetails: {
        firstName: paymentWithoutCC.firstName,
        lastName: paymentWithoutCC.lastName,
        address: paymentWithoutCC.address,
        city: paymentWithoutCC.city,
        state: paymentWithoutCC.state,
        zip: paymentWithoutCC.zip,
        emailAddress: paymentWithoutCC.emailAddress,
        phoneNumber: payment.phoneNumber,
        updateAccountSettings: paymentWithoutCC.updateAccountSettings
      }
    };

    if (savedCreditCard) {
      apiData.savedCreditCardId = savedCreditCard.id;
      delete apiData.newCreditCard;
    } else {
      apiData.newCreditCard = {...payment.cc};
      delete apiData.savedCreditCardId;
    }

    apiData.cardSecurityCode = payment.cc.cardSecurityCode;

    if (apiData.newCreditCard && apiData.newCreditCard.expirationDate) {
      const expParts = apiData.newCreditCard.expirationDate.split('/');
      const expMonth = expParts[0];
      const expYear = expParts[1];
      apiData.newCreditCard.expMonth = expMonth;
      apiData.newCreditCard.expYear = expYear.substr(-2);
    }

    // Remove any expected spaces or dashes in the card number
    if (apiData.newCreditCard && apiData.newCreditCard.cardNumber) {
      apiData.newCreditCard.cardNumber = apiData.newCreditCard.cardNumber.replace(/[^0-9]/g, '');
    }

    return paymentResource
      .post(apiData)
      .then(res => {
        if (res == null || !res.success) {
          throw new Error('Invalid response from server');
        }

        if (!res.transaction.approved) {
          throw new Error(res.transaction.declineReason || 'The credit card charge failed');
        }

        // Update the in-memory profile data with the personal information supplied
        dispatch({ type: PROFILE_UPDATE_SUCCESS, user: {
          firstName: payment.firstName,
          lastName: payment.lastName,
          emailAddress: payment.emailAddress,
          address: payment.address,
          city: payment.city,
          state: payment.state,
          zip: payment.zip,
          phoneNumber: payment.phoneNumber
        }});

        dispatch({
          type: PAYMENT_SUCCESS,
          payment: res.transaction,
          subTotal: res.subTotal,
          transactionFee: res.transactionFee,
          total: res.total,
          paymentData: payment
        });
        return res.transaction;
      })
      .catch(error => {
        Sentry.captureException(error);
        dispatch({ type: PAYMENT_FAILURE, error });
        throw error;
      });
  }
}

export type CollectActionTypes = (
  ICollectSelectAccountAction |
  ICollectResetAction |
  ICollectResetSensitiveAction |
  PaymentActionTypes
)