import React from 'react';
import { Form } from 'react-final-form';
import { required, usPhoneNumber } from '../../../validation';
import { ButtonSize, renderActionSubmit, renderTextInput } from '../../Form/RenderForm';

interface IAddNewNumberProps {
  onAddAni: (ani: string) => void
}

interface IAddNewNumberData {
  ani: string
}

const AddNewNumber: React.FC<IAddNewNumberProps> = props => {

  return (
    <>
      <h4>Add a new number</h4>
      <p>Enter phone number to link to your account</p>

      <Form
        onSubmit={(data: IAddNewNumberData) => { props.onAddAni(data.ani) }}>
        {form =>
          <form onSubmit={form.handleSubmit} method="post">
            <div className="row my-lg-5 no-gutters">
              <div className="form-label-group col-lg-8">
                {renderTextInput(
                  'checkout.collect.addNumber.ani',
                  'ani',
                  'Add number...',
                  'Add number...',
                  [required, usPhoneNumber]
                )}
              </div>
              <div className="col-lg-3 offset-lg-1 form-button-action">
                {renderActionSubmit<IAddNewNumberData>(form, 'Add number', 'Adding...', ButtonSize.Large, false)}
              </div>
            </div>
          </form>
        }
      </Form>
    </>
  )
}

export default AddNewNumber;
