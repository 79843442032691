import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, RouterProps, withRouter } from 'react-router';
import * as checkoutActions from '../../actions/checkout';
import { Facility, FacilityFeature, IFacility } from '../../models/Facility';
import { IAppState } from '../../reducers';
import { Service as ServiceType } from '../../reducers/checkout';
import ErrorMessage from '../ErrorMessage';
import NameAndAddress from '../Facility/NameAndAddress';
import { ButtonSize, renderActionSubmit } from '../Form/RenderForm';
import Currency from '../Format/Currency';
import PageTitle from '../PageTitle';
import Navigation from './Navigation';

export enum CheckoutStep {
  Facility,
  Service,
  Checkout
}

function facilityHasService(facility: IFacility, service: ServiceType): boolean {
  if (facility.getConfigurationValue('FacilityOfflineMessage')) {
    return false;
  }

  switch (service) {
    case ServiceType.Wallet:
      return (
        facility.featureTexting ||
        facility.features.indexOf(FacilityFeature.KioskAudioCalls) > -1 ||
        facility.features.indexOf(FacilityFeature.WirelessAudioCalls) > -1 ||
        facility.features.indexOf(FacilityFeature.WirelessVideoCalls) > -1
      );

    case ServiceType.Visitation:
      return (
        facility.visitationSchedulingEnabled
      )

    case ServiceType.PhoneCard:
      return facility.phoneCardsEnabled;

    case ServiceType.Collect:
      return facility.features.indexOf(FacilityFeature.PrepaidCollectCalls) > -1;
  }

  return false;
}

const Service: React.FC<RouterProps> = props => {
  const state = useSelector((appState: IAppState) => ({...appState.checkout}));
  const dispatch = useDispatch();
  const [expandedService, expandService] = useState<ServiceType>(null);
  const [message, setMessage] = useState<string>(null);

  if (state.common.facility == null) {
    return <Redirect to="/" />
  }

  useEffect(() => {
    if (state.common.facility?.getConfigurationValue('FacilityOfflineMessage')) {
      setMessage(state.common.facility.getConfigurationValue('FacilityOfflineMessage'));
    }
  }, [state.common.facility]);

  if (state.common.service !== null && state.common.facility?.getConfigurationValue('FacilityOfflineMessage') == null) {
    switch (state.common.service) {
      case ServiceType.Wallet:
        // Make sure facility supports wallet payments before redirect
        if (facilityHasService(state.common.facility, ServiceType.Wallet)) {
          props.history.push('/checkout/wallet');
          return null;
        }

      case ServiceType.Visitation:
        // Make sure facility supports visitations before redirect
        if (facilityHasService(state.common.facility, ServiceType.Visitation)) {
          props.history.push('/visitation/schedule');
          return null;
        }

      case ServiceType.PhoneCard:
        props.history.push('/checkout/card');
        return null;

      case ServiceType.Collect:
        // Make sure facility supports prepaid collect call payments before redirect
        if (facilityHasService(state.common.facility, ServiceType.Collect)) {
          props.history.push('/checkout/collect');
        }
        return null;
    }
  }

  return (
    <>
      <PageTitle title="Select Service" />
      <Navigation step={CheckoutStep.Service} onResetService={() => dispatch(checkoutActions.selectService(null))} />

      <main className="container align-items-top justify-content-center mt-3">
        <section className="no-gutters">
          <div className="main row">
            <div className="col-md-4 mb-3 rt-status">
              <aside className="bg-secondary text-light rounded p-3 p-lg-5">
                <h4>You've selected:</h4>
                <h5>Facility:</h5>
                <NameAndAddress facility={new Facility(state.common.facility)} />
              </aside>
            </div>

            <div className="col-md-8">
              {message &&
                <ErrorMessage
                  severity="warning"
                  message={message} />
              }

              {facilityHasService(state.common.facility, ServiceType.Wallet) &&
                <Accordion
                  className="bg-info text-light rounded inmatewallet service pt-lg-5 mb-3"
                  onSelect={() => expandService(ServiceType.Wallet)}>
                  <Accordion.Toggle
                    as={Button}
                    eventKey={ServiceType.Wallet.toString()}
                    className={`collapsible btn-info text-light btn-block px-lg-5 px-3 py-3 m-0 text-left rounded mobile-only ${expandedService == ServiceType.Wallet ? 'active' : ''}`}>
                    <h3 className="product m-0 pr-3"><span className="icon-button"></span>Inmate Wallet (Texting)</h3><span className="collapseicon"></span>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={ServiceType.Wallet.toString()} className="collapsecontent d-lg-block no-gutters">
                    <div>
                      <h3 className="product title-desktop"><span className="icon-product"></span>Inmate Wallet (Texting)</h3>
                      <div className="bodysm">
                        <p>Inmate wallet provides the ability to text and, if available, voice or video communications.</p>

                        <div className="product-details">
                          <h6 className="subtitle subone">Texting Costs</h6>
                          <p>$0.09/message sent or received</p>

                          <h6 className="subtitle subone">Phone Call Costs</h6>
                          {state.common.facility.state == 'MT' ?
                            <p>$0.21/minute + applicable taxes</p> :
                            <p>$0.25/minute + applicable taxes</p>
                          }

                          <h6 className="subtitle subone">Video Call Costs</h6>
                          <p>$0.25/minute</p>
                        </div>
                      </div>

                      <div className="form-button-action">
                        {renderActionSubmit(null, 'Fund Inmate Wallet', 'Fund Inmate Wallet', ButtonSize.Large, true, () => {
                            dispatch(checkoutActions.selectService(ServiceType.Wallet));
                            props.history.push('/checkout/wallet');
                          }
                        )}
                      </div>
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              }

              <div className="rounded service pt-md-2 row no-gutters">
                {facilityHasService(state.common.facility, ServiceType.PhoneCard) &&
                  <Accordion
                    className="col-lg-6 border border-midgray phonecard-gray rounded p-lg-2 pt-lg-5 mb-3"
                    onSelect={() => expandService(ServiceType.PhoneCard)}>
                    <Accordion.Toggle
                      as={Button}
                      variant="light"
                      eventKey={ServiceType.PhoneCard.toString()}
                      className={`collapsible btn-info text-secondary btn-block px-lg-5 px-3 py-3 m-0 text-left rounded mobile-only ${expandedService == ServiceType.PhoneCard ? 'active' : ''}`}>
                      <h3 className="product m-0 pr-3"><span className="icon-button"></span>Phone Card</h3><span className="collapseicon_secondary"></span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={ServiceType.PhoneCard.toString()} className="collapsecontent d-lg-block no-gutters">
                      <div>
                        <div className="bodysm">
                          <h3 className="product title-desktop text-dark mr-4"><span className="dashboard-tile-icon rt-phonecard"></span>Phone Card</h3>
                          <div className="text-secondary">
                            <p>Inmate Phone Cards are ONLY for calls made from the Reliance county correctional facilities. Treat the PIN you receive at checkout as cash and share it with the inmate in a secure manner.</p>
                            <div className="product-details">
                              <span></span>
                              <h6 className="subtitle subone">Phone Card In-State Calls</h6>
                              <p><Currency value={state.common.facility.phoneCardRate} /> minute</p>

                              <h6 className="subtitle subone">Phone Card State to State Calls</h6>
                              <p>$0.21/minute + Federal Universal Service Fund</p>
                            </div>
                          </div>
                        </div>
                        <p>
                          <button
                            onClick={() => {
                              dispatch(checkoutActions.selectService(ServiceType.PhoneCard));
                              props.history.push('/checkout/card');
                              return false;
                            }}
                            className="btn btn-block bg-action text-white">
                            Buy Phone Card
                          </button>
                        </p>
                      </div>
                    </Accordion.Collapse>
                  </Accordion>
                }

                {facilityHasService(state.common.facility, ServiceType.Collect) &&
                  <Accordion
                    className="col-lg-6 border border-midgray collectcall-gray rounded p-lg-2 pt-lg-5 mb-3"
                    onSelect={() => expandService(ServiceType.Collect)}>
                    <Accordion.Toggle
                      as={Button}
                      variant="light"
                      eventKey={ServiceType.Collect.toString()}
                      className={`collapsible btn-info text-secondary btn-block px-lg-5 px-3 py-3 m-0 text-left rounded mobile-only ${expandedService == ServiceType.Collect ? 'active' : ''}`}>
                      <h3 className="product m-0 pr-3"><span className="icon-button"></span>Prepaid Collect Calls</h3><span className="collapseicon_secondary"></span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={ServiceType.Collect.toString()} className="collapsecontent d-lg-block no-gutters">
                      <div>
                        <div className="bodysm">
                          <h3 className="product title-desktop text-dark mr-4"><span className="dashboard-tile-icon rt-phonecard"></span>Prepaid Collect Calls</h3>
                          <div className="text-secondary">
                            <p>Collect call accounts are on a prepayment basis and you must maintain a positive credit balance to keep your line open. Prepaid collect call accounts are set up with a specific telephone number for the inmate to call.</p>
                            <div className="product-details">
                              <span></span>
                              <h6 className="subtitle subone">Collect Local Calls</h6>
                              <p><Currency value={state.common.facility.getCollectLocalCallRate()} />/minute + applicable taxes</p>

                              <h6 className="subtitle subone">Collect In-State Calls</h6>
                              <p><Currency value={state.common.facility.getCollectInStateCallRate()} />/minute + applicable taxes</p>

                              <h6 className="subtitle subone">Collect State to State Calls</h6>
                              <p><Currency value={state.common.facility.getCollectOutOfStateCallRate()} />/minute + Federal Universal
                              Service Fund</p>
                            </div>
                          </div>
                          <button
                            onClick={() => {
                              dispatch(checkoutActions.selectService(ServiceType.Collect));
                              props.history.push('/checkout/collect');
                              return false;
                            }}
                            className="btn btn-block bg-action text-white">
                            Set up Collect Call Account
                          </button>
                        </div>
                      </div>
                    </Accordion.Collapse>
                  </Accordion>
                }
              </div>

              {facilityHasService(state.common.facility, ServiceType.Visitation) &&
                <div className="service pt-md-2 row no-gutters">
                  <Accordion
                    className="col-lg-12 border border-midgray visitation-gray rounded p-lg-2 pt-lg-5 mb-3"
                    onSelect={() => expandService(ServiceType.Visitation)}>
                    <Accordion.Toggle
                      as={Button}
                      variant="light"
                      eventKey={ServiceType.Visitation.toString()}
                      className={`collapsible btn-info text-secondary btn-block px-lg-5 px-3 py-3 m-0 text-left rounded mobile-only ${expandedService == ServiceType.Visitation ? 'active' : ''}`}>
                      <h3 className="product m-0 pr-3"><span className="icon-button"></span>Schedule a Visitation</h3><span className="collapseicon_secondary"></span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={ServiceType.Visitation.toString()} className="collapsecontent d-lg-block no-gutters">
                      <div>
                        <div className="bodysm">
                          <h3 className="product title-desktop text-dark mr-4"><span className="dashboard-tile-icon rt-phonecard"></span>Schedule a Video Visitation at the Jail</h3>
                          <div className="text-secondary">
                            <p>Create an account, or log in to an existing account, to schedule a video visitation at the jail.</p>
                          </div>
                        </div>
                        <div>
                          <p>
                            <a
                              onClick={() => {
                                dispatch(checkoutActions.selectService(ServiceType.Visitation));
                                props.history.push('/visitation/schedule');
                                return false;
                              }}
                              className="btn btn-block bg-secondary text-white">
                              Schedule Video Visitation
                            </a>
                          </p>
                        </div>
                      </div>
                    </Accordion.Collapse>
                  </Accordion>
                </div>
              }
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default withRouter(Service);
