import React from 'react';
import { requiredWithMessage } from '../../../validation';
import { renderCheckboxInput } from '../../Form/RenderForm';

const Agreement: React.FC = () =>
  <div className="alert alert-secondary" role="alert">
    <p className="bodysm">
    All purchases are final and no credits will be given once purchased.  These funds are non-transferable, however, if the inmate returns to
     the jail at a future point in time the account balance will always be available for them to use.
    </p>
    <hr/>
    {renderCheckboxInput(
      'checkout.wallet.agreement',
      'checkout.wallet.agreement',
      'I understand and confirm all information is correct',
      [requiredWithMessage('You must agree before submitting')]
    )}
  </div>

export default Agreement;
