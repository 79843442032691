import { ICreditCardPayment } from '../../models/Transaction';
import * as transactionResource from '../../resources/Transaction';

export const LOAD_PAYMENT_LOADING = 'COLLECT/RECEIPT/LOAD_PAYMENT_LOADING';
export const LOAD_PAYMENT_SUCCESS = 'COLLECT/RECEIPT/LOAD_PAYMENT_SUCCESS';
export const LOAD_PAYMENT_FAILURE = 'COLLECT/RECEIPT/LOAD_PAYMENT_FAILURE';
export function loadPayment(id: number|string, existingPayment?: ICreditCardPayment) {
  return dispatch => {
    if (existingPayment) {
      return { type: LOAD_PAYMENT_SUCCESS, payment: existingPayment };
    }

    dispatch({ type: LOAD_PAYMENT_LOADING, id });

    return transactionResource
      .get(Number(id))
      .then(payment => dispatch({ type: LOAD_PAYMENT_SUCCESS, payment}))
      .catch(error => dispatch({ type: LOAD_PAYMENT_FAILURE, error }))
  }
}

export const RESET = 'COLLECT/RECEIPT/RESET';
export function reset() {
  return { type: RESET };
}
