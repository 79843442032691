import { IPersonalInformationData } from '../components/Checkout/PersonalInformation';
import { ICreditCardData } from '../models/CreditCard';
import { IPhoneCardPurchase } from '../models/PhoneCard';
import Api, { IPaginatedResponse } from '../services/api';

export function index(page = 1, perPage = 250) {
  return Api
    .get<IPaginatedResponse<IPhoneCardPurchase>>({
      url: '/online-order',
      query: {page, perPage, includeCards: true}
    });
}

export function get(id: number) {
  return Api
    .get<IPhoneCardPurchase>({
      url: `/online-order/${id}`
    });
}

export interface IPhoneCardPurchaseResponse {
  success: boolean
  order: IPhoneCardPurchase
}

export interface ICardPurchaseRequest {
  facilityId: number
  typeValueId: number
  quantity: number
  inmateFirstName: string
  inmateLastName: string
  inmateId: number
  savedCreditCardId: number
  cardSecurityCode: string
  newCreditCard: ICreditCardData
  receiptEmailAddress: string
  purchaserDetails: IPersonalInformationData
}

export function post(purchaseData: ICardPurchaseRequest) {
  return Api
    .post<IPhoneCardPurchaseResponse>({
      url: '/online-order',
      data: purchaseData
    });
}