import React from 'react';
import { IFacility } from '../../../models/Facility';
import { IInmate } from '../../../models/Inmate';
import { required } from '../../../validation';
import { renderTextInput } from '../../Form/RenderForm';
import InmatePicker from '../../Inmate/Picker';

export interface IInmateInformationData {
  firstName: string
  lastName: string
}

interface IInmateInformationProps {
  facility: IFacility
  inmates: IInmate[]
  loadingInmates: boolean
  selectedInmate: IInmate
  selectInmate: (inmate: IInmate) => void
}

const InmateInformation: React.FC<IInmateInformationProps> = props => {
  if (
    props.facility.getConfigurationValue('RelayPhoneCardsViaVoicemail') === 'true' ||
    props.facility.getConfigurationValue('RelayPhoneCardsViaVoicemail') === true
  ) {
    return (
      <div>
        <InmatePicker
          facility={props.facility}
          inmates={props.inmates}
          loadingInmates={props.loadingInmates}
          selectedInmate={props.selectedInmate}
          onSelect={props.selectInmate} />
      </div>
    );
  }

  return (
    <div>
      <h3>Enter inmate information</h3>
      <div className="col-12 col-lg-12 form-label-group">
        {renderTextInput(
          'inmateInformation.firstName',
          'inmateInformation.firstName',
          'First name',
          'First name',
          [required]
        )}
      </div>
      <div className="col-12 col-lg-12 form-label-group">
        {renderTextInput(
          'inmateInformation.lastName',
          'inmateInformation.lastName',
          'Last name',
          'Last name',
          [required]
        )}
      </div>
    </div>
  );
}

export default InmateInformation
