import * as phoneCardActions from "../actions/phone-cards";
import { IPhoneCardPurchase } from '../models/PhoneCard';
import { IPaginatedResponse } from '../services/api';

export interface IPhoneCardPurchaseHistoryState {
  loading: boolean,
  error?: Error,
  history?: IPaginatedResponse<IPhoneCardPurchase>
}

const initialState:IPhoneCardPurchaseHistoryState = {
  loading: false
};

export function phoneCardPurchaseHistoryReducer(state: IPhoneCardPurchaseHistoryState = initialState, action): IPhoneCardPurchaseHistoryState {
  switch (action.type) {
    case phoneCardActions.LOADING_PURCHASES:
      return {
        ...state,
        loading: true,
        error: undefined
      };

    case phoneCardActions.LOAD_PURCHASES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case phoneCardActions.LOAD_PURCHASES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        history: action.purchases
      };
  }

  return state;
}
