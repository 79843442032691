import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { RouteChildrenProps } from 'react-router';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as collectReceiptActions from '../../../actions/collect/receipt';
import { Facility } from '../../../models/Facility';
import { ICreditCardPayment } from '../../../models/Transaction';
import { IAppState } from '../../../reducers';
import Confirmation from '../../Checkout/Collect/Confirmation';
import ErrorMessage from '../../ErrorMessage';
import PageTitle from '../../PageTitle';

interface ICollectReceiptUrlParams {
  id?: string
}

interface ICollectReceiptRouteState {
  payment?: ICreditCardPayment
  facility?: Facility
  subTotal?: number
  transactionFee?: number
}

const CollectReceipt: React.FC<RouteChildrenProps<ICollectReceiptUrlParams, ICollectReceiptRouteState>> = props => {
  const state = useSelector((appState: IAppState) => ({
    collect: appState.collect
  }));
  const dispatch: ThunkDispatch<IAppState, undefined, Action> = useDispatch();

  useEffect(() => {
    if (!state.collect.receipt.payment || state.collect.receipt.payment.id !== Number(props.match.params.id)) {
      dispatch(collectReceiptActions.loadPayment(props.match.params.id));
    } else {
      dispatch(collectReceiptActions.loadPayment(null, state.collect.receipt.payment));
    }

    return function cleanupCollectReceipt() {
      dispatch(collectReceiptActions.reset());
    };
  }, []);

  // If we still have the receipt email address (since it isn't persisted with the payment), we will display it. Otherwise it'll be hidden
  const receiptEmailAddress = state.collect.receipt.receiptEmailAddress;

  const locationState: ICollectReceiptRouteState = props.location.state || {};

  return (
    <main className="container align-items-top justify-content-center mt-5 mt-lg-3 pt-4">
      <section className="no-gutters">
        <PageTitle title="Prepaid Collect Payment" />

        {state.collect.receipt.paymentLoadError ?
          <ErrorMessage
            error={state.collect.receipt.paymentLoadError}
            httpCodeMessages={{
              404: 'This receipt was not found'
            }}
            /> :
          (
            state.collect.receipt.paymentLoading || !state.collect.receipt.payment ?
              <div className="row d-flex justify-content-center">
                <div className="col-md-6">
                  <Skeleton height={700} className="m-5" />
                </div>
              </div> :
              <Confirmation
                facility={locationState.facility}
                account={state.collect.receipt.payment.account}
                payment={state.collect.receipt.payment}
                total={state.collect.receipt.payment.amount}
                receiptEmailAddress={receiptEmailAddress}
                subTotal={locationState.subTotal}
                transactionFee={locationState.transactionFee}
                />
          )
        }
      </section>
    </main>
  )
}

export default CollectReceipt;
