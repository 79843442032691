import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { IVisitationScheduledState, visitationScheduledReducer } from '../visitation';
import { IVisitationScheduleState, visitationScheduleReducer as visitationScheduleReducer } from './schedule';

const visitationPersistConfig = {
  key: 'visitation',
  timeout: 60 * 60,
  storage,
  whitelist: ['schedule']
}

export interface IVisitationState {
  schedule: IVisitationScheduleState,
  scheduled: IVisitationScheduledState
}

export const visitationReducer = persistReducer(
  visitationPersistConfig,
  combineReducers<IVisitationState>({
    schedule: visitationScheduleReducer,
    scheduled: visitationScheduledReducer
  })
) as any; // Casting resolves a typescript complaint that doesn't make sense...
