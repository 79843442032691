import { ThunkAction } from 'redux-thunk';
import { IApiErrorResponse } from '../models/Api';
import { TaxAffectsType } from '../models/Tax';
import { IAppState } from '../reducers';
import * as taxResource from '../resources/Tax';

export const AMOUNT_FETCHING = 'TAX/AMOUNT_FETCHING';
export const AMOUNT_FETCHING_SUCCESS = 'TAX/AMOUNT_FETCHING_SUCCESS';
export const AMOUNT_FETCHING_FAILURE = 'TAX/AMOUNT_FETCHING_FAILURE';
interface ITaxGetAmountFetchingAction {
  type: typeof AMOUNT_FETCHING
  affectsType: TaxAffectsType
  amount: number
}
interface ITaxGetAmountFetchingSuccessAction {
  type: typeof AMOUNT_FETCHING_SUCCESS
  amounts: taxResource.ITaxAmountResponse
}
interface ITaxGetAmountFetchingFailureAction {
  type: typeof AMOUNT_FETCHING_FAILURE
  error: Error | IApiErrorResponse
}
type TaxGetAmountActionTypes = ITaxGetAmountFetchingAction | ITaxGetAmountFetchingSuccessAction | ITaxGetAmountFetchingFailureAction;
export function getTaxAmount(type: TaxAffectsType, amount: number, zip: string): ThunkAction<Promise<any>, IAppState, undefined, TaxGetAmountActionTypes> {
  return dispatch => {
    dispatch({ type: AMOUNT_FETCHING, affectsType: type, amount });

    return taxResource.getTaxAmount(type, amount, zip)
      .then(res => {
        if (res.success) {
          dispatch({ type: AMOUNT_FETCHING_SUCCESS, amounts: res.data });
        } else {
          dispatch({ type: AMOUNT_FETCHING_FAILURE, error: new Error('Non-successful response') });
        }
        return res;
      })
      .catch(error => {
        dispatch({ type: AMOUNT_FETCHING_FAILURE, error });
        throw error;
      })
  }
}

export const AMOUNT_RESET = 'TAX/AMOUNT_RESET';
interface ITaxAmountResetAction {
  type: typeof AMOUNT_RESET
}
export function resetTaxAmount(): ITaxAmountResetAction {
  return { type: AMOUNT_RESET };
}

export type TaxActionTypes = (
  TaxGetAmountActionTypes |
  ITaxAmountResetAction
)