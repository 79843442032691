import ClientOAuth2 from 'client-oauth2';
import { ThunkAction } from 'redux-thunk';
import { IOnlineUser } from '../models/OnlineUser';
import { IAppState } from '../reducers';
import { IToken, postToken } from '../resources/Auth';
import { ApiError } from '../services/api';

export const LOGGING_IN = 'auth/LOGGING_IN';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'auth/LOGIN_FAILURE';
export const LOGIN_NEEDS_ACTIVATION = 'auth/LOGIN_NEEDS_ACTIVATION';
export const AUTH_TOKEN_LOAD_SUCCESS = 'auth/AUTH_TOKEN_LOAD_SUCCESS';
export const AUTH_TOKEN_LOAD_FAILED = 'auth/AUTH_TOKEN_LOAD_FAILED';
interface ILoggingInAction {
  type: typeof LOGGING_IN
  email: string
}
interface ILoginSuccessAction {
  type: typeof LOGIN_SUCCESS
  email: string
  user: IOnlineUser,
  token: IToken
}
interface ILoginFailureAction {
  type: typeof LOGIN_FAILURE
  email: string
  error
}
interface ILoginNeedsActivationAction {
  type: typeof LOGIN_NEEDS_ACTIVATION
  email: string
}
interface IAuthTokenLoadSuccessAction {
  type: typeof AUTH_TOKEN_LOAD_SUCCESS
  email?: string,
  token: IToken
}
interface IAuthTokenLoadFailedAction {
  type: typeof AUTH_TOKEN_LOAD_FAILED
  error
}
type LoginActions = ILoggingInAction | ILoginSuccessAction | ILoginFailureAction | IAuthTokenLoadSuccessAction | ILoginNeedsActivationAction
export function login(
  email: string,
  password: string
): ThunkAction<Promise<boolean>, IAppState, null, LoginActions> {
  return dispatch => {
    dispatch({ type: LOGGING_IN, email });

    return postToken(email, password)
      .then(response => {
        dispatch({
          type: AUTH_TOKEN_LOAD_SUCCESS,
          email,
          token: response
        });
        dispatch({
          type: LOGIN_SUCCESS,
          user: response.onlineUser,
          email,
          token: response
        });
        return true;
      })
      .catch(error => {
        if (error instanceof ApiError) {
          if (error.response.status === 403) {
            dispatch({ type: LOGIN_NEEDS_ACTIVATION, email });
            return false;
          }
        }
        dispatch({ type: LOGIN_FAILURE, error, email });
        throw error;
      })
  }
}

export const LOGOUT = 'auth/LOGOUT';
interface ILogoutAction {
  type: typeof LOGOUT
}
export function logout(): ILogoutAction {
  return { type: LOGOUT };
}

export function refreshToken(
  token: ClientOAuth2.Token
): ThunkAction<Promise<ClientOAuth2.Token>, IAppState, null, IAuthTokenLoadSuccessAction | IAuthTokenLoadFailedAction> {
  return dispatch =>
    token
      .refresh()
      .then(result => {
        dispatch({ type: AUTH_TOKEN_LOAD_SUCCESS, token: result.data as any }); // TODO: Review this and get the typescript working after making sure it works as expected
        return result;
      })
      .catch((err) => {
        dispatch({ type: AUTH_TOKEN_LOAD_FAILED, error: err });
        window.location.pathname = '/logout';
        return null;
      });
}

export const RESET_LOGIN = 'auth/RESET_LOGIN';
interface IResetLoginAction {
  type: typeof RESET_LOGIN
}
export function resetLogin(): IResetLoginAction {
  return { type: RESET_LOGIN };
}

export type AuthActionTypes = (
  IAuthTokenLoadSuccessAction |
  IAuthTokenLoadFailedAction |
  LoginActions |
  IResetLoginAction |
  ILogoutAction
)