import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import * as checkoutActions from '../../actions/checkout';
import { Service as ServiceType } from '../../reducers/checkout';

interface IWalletCostModalProps extends RouteComponentProps {
  show: boolean
  onHide: () => void
}

const WalletCostModal: React.FC<IWalletCostModalProps> = props => {
  const dispatch = useDispatch();

  return (
    <Modal show={props.show} size="lg" onHide={props.onHide}>
      <Modal.Header closeButton />
      <Modal.Body className="p-5">
        <h3>Inmate Wallet (Texting)</h3>
        <p>Payments made into an Inmate Wallet account are linked to a dedicated telephone number for inmates who use one of our Reliance Tablet Phones in a protective enclosure. Inmate Wallet funds can be used for:</p>
        <div className="row pt-3">
          <div className="col-md-6">
            <span></span>
            <h5>Personal Inmate Text Messaging</h5>
            <p>As texting has become a preferred method of communication over calling, we’ve created the ability for inmates to text with friends and family. Inmates who are provided with a free to use Reliance Tablet Phone have a dedicated phone number and device for direct access to their loved ones from the privacy of their cell.</p>
            <p>In addition to being portable and convenient, our Reliance Tablet Phones are a great option for those who are hearing-impaired.</p>
            <p className="font-weight-bold mb-0"><u>Cost of texting</u></p>
            <p>$0.09/message sent or received up to 160 characters</p>
          </div>
          <div className="col-md-6">
            <span></span>
            <h5>Voice and Video Calls</h5>
            <p>Our Reliance Tablet Phones and Reliance Tablet Stations also allow for inmates to make voice and video calls—making communication more convenient and accessible for inmates and their friends and family. </p>
            <p className="font-weight-bold mb-0"><u>Cost of voice calls</u></p>
            <p>$0.21 - $0.25/minute + applicable taxes (Rate may vary depending on facility)</p>
            <p className="font-weight-bold mb-0"><u>Cost of video calls</u></p>
            <p>$0.25/minute</p>
          </div>
        </div>
        <button className="btn btn-lg btn-action btn-block mt-5" onClick={() => {
          props.onHide();
          dispatch(checkoutActions.selectService(ServiceType.Wallet));
          props.history.push('/checkout/wallet');
        }}>
          Fund an inmate wallet account
        </button>
      </Modal.Body>
    </Modal>
  )
}

export default withRouter(WalletCostModal);
