import moment from 'moment';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IVisitation } from '../../../models/Visitation';
import Facility from '../../Format/Facility';

interface IVisitationScheduledRowProp {
  visitation: IVisitation
  onCancel: () => Promise<any>
  cancelling: boolean
}

function statusMessage(visitation: IVisitation) {
  switch(visitation.status) {
    case 'Approved':
      return <span className="text-success">Approved</span>
    case 'Rejected':
      return <span className="text-error">Rejected</span>
    case 'Cancelled':
      return <span className="text-error">Cancelled</span>
  }
  return <span className="text-warning">Pending</span>
}

const HistoryRow: React.FC<IVisitationScheduledRowProp> = props => {
  const [showingCancel, showCancel] = useState(false);

  return (
    <div className="mx-lg-5 mx-4 my-3 p-2 row bg-light rounded">
      <div className="col-lg-1 my-2 d-flex align-self-center px-2">
        <span className="dashboard-tab-icon video-visitation mr-3"></span>
      </div>
      <div className="col-lg-3 my-2 d-flex flex-column align-self-center px-2">
        <p className="m-0 subtitle subtwo">{props.visitation.inmate.firstName} {props.visitation.inmate.lastName} (#{props.visitation.inmate.account})</p>
        <p className="m-0 subtitle subtwo">{moment(props.visitation.startDate.date).format('llll')}</p>
      </div>
      <div className="col-lg-5 my-2 d-flex flex-column align-self-center px-2">
        <p className="m-0 subtitle subtwo"><Facility facility={props.visitation.facility} /></p>
        <p className="m-0 subtitle subtwo">
          Approval Status: {statusMessage(props.visitation)}
        </p>
      </div>
      {/* <div className="col-lg-2 my-2 d-flex align-self-center px-2">
        <a className="btn btn-action btn-block text-light" href="#">Edit</a>
      </div> */}
      <div className="col-lg-2 my-2 d-flex align-self-center px-2">
        {(props.visitation.status == 'Pending' || props.visitation.status == 'Approved') &&
          <button
            className="btn btn-outline-action btn-block"
            type="button"
            onClick={() => showCancel(true)}>
            Cancel
          </button>
        }
      </div>

      <Modal show={showingCancel} onHide={() => showCancel(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Request visitation cancellation</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h5 style={{ textAlign: 'center' }}>{moment(props.visitation.startDate.date).format('llll')}</h5>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn btn-outline-action btn-block"
            onClick={() => showCancel(false)}
            disabled={props.cancelling}>
            Close
          </button>
          <button
            className="btn btn-outline-danger btn-block"
            onClick={() => {
              props.onCancel().then(() => showCancel(false));
            }}
            disabled={props.cancelling}>
            {props.cancelling ? 'Processing...' : 'Request cancellation'}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default HistoryRow;
