import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RouterProps, withRouter } from 'react-router';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as checkoutActions from '../../../actions/checkout';
import * as checkoutWalletActions from '../../../actions/checkout/wallet';
import * as walletHistoryActions from '../../../actions/wallet-history';
import { IFacility } from '../../../models/Facility';
import { IWalletPayment } from '../../../models/WalletPayment';
import { IAppState } from '../../../reducers';
import { Service, Service as ServiceType } from '../../../reducers/checkout';
import { IWalletPaymentHistoryState } from '../../../reducers/walletPaymentHistory';
import { IPaginatedResponse } from '../../../services/api';
import PageTitle from '../../PageTitle';
import Navigation from '../Navigation';
import HistoryRow from './HistoryRow';
import LoadingRow from './LoadingRow';

interface IHistoryProps extends RouterProps {
  facilities: IFacility[]
  paymentHistory: IWalletPaymentHistoryState
  load(): Promise<IPaginatedResponse<IWalletPayment>>
  buyAgain: (payment: IWalletPayment) => void
}

const History: React.FC<IHistoryProps> = props => {
  useEffect(() => {
    props.load();
  }, []);

  const dispatch = useDispatch();

  return (
    <>
      <PageTitle title="Wallet Transaction History" />
      <Navigation title="Wallet Transaction History" />

      <main className="container pt-3">
        <section className="row">
          <div className="col mb-3">
            <div className="border border-dark rounded h-100 pb-4">
              <h5 className="bg-dark text-light px-5 py-3"><span></span>Inmate Wallet Purchase History</h5>
              <div className="px-lg-5 p-4">
                <button className="btn btn-info btn-block" onClick={() =>{
                  dispatch(checkoutActions.selectService(ServiceType.Wallet));
                  props.history.push('/checkout/wallet');
                  return false;
                }}>Make new Inmate Wallet payment</button>
              </div>

              {props.paymentHistory.loading && <LoadingRow />}

              {props.paymentHistory && props.paymentHistory.history && props.paymentHistory.history.data && props.paymentHistory.history.data.map(h => {
                const facility = (props.facilities || []).filter(f => f.id == h.inmate.facility?.id).pop();
                return (
                  <HistoryRow
                    facility={facility}
                    payment={h}
                    onBuyAgain={() => {
                      props.buyAgain(h);
                      props.history.push('/checkout/wallet');
                    }}
                    key={h.id} />
                );
              })}
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default withRouter(connect(
  (state: IAppState) => ({
    paymentHistory: state.walletPaymentHistory,
    facilities: state.common.facilities
  }),
  ((dispatch: ThunkDispatch<IAppState, undefined, Action>) => ({
    load: () => dispatch(walletHistoryActions.load()),
    buyAgain: (payment: IWalletPayment) => {
      dispatch(checkoutWalletActions.reset());
      dispatch(checkoutActions.selectService(Service.Wallet));
      dispatch(checkoutActions.selectFacilityById(payment.inmate.facility.id));
      dispatch(checkoutWalletActions.setDetails(payment.inmate, payment.amount));
    }
  }))
)(History));
