import React from 'react';
import { requiredWithMessage } from '../../../validation';
import { renderCheckboxInput } from '../../Form/RenderForm';

const Agreement: React.FC = () =>
  <div className="alert alert-secondary" role="alert">
    <p className="bodysm">Collect call accounts are on a prepayment basis and you must maintain a positive credit balance to keep your line open. Please verify that all information is correct before completing transaction.</p>
    <hr/>
    {renderCheckboxInput(
      'checkout.collect.agreement',
      'checkout.collect.agreement',
      'I understand and confirm all information is correct',
      [requiredWithMessage('You must agree before submitting')]
    )}
  </div>

export default Agreement;
