import moment from 'moment';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RouterProps, withRouter } from 'react-router';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as checkoutActions from '../../../actions/checkout';
import * as checkoutCardActions from '../../../actions/checkout/card';
import * as phoneCardActions from '../../../actions/phone-cards';
import { IOnlineUser } from '../../../models/OnlineUser';
import { IPhoneCard, IPhoneCardPurchase, IPhoneCardPurchaseItem } from '../../../models/PhoneCard';
import { IAppState } from '../../../reducers';
import { Service, Service as ServiceType } from '../../../reducers/checkout';
import { IPhoneCardPurchaseHistoryState } from '../../../reducers/phoneCardPurchaseHistory';
import { IPaginatedResponse } from '../../../services/api';
import PageTitle from '../../PageTitle';
import Navigation from '../Navigation';
import HistoryRow from './HistoryRow';

interface IHistoryProps extends RouterProps {
  purchaseHistory: IPhoneCardPurchaseHistoryState
  onlineUser: IOnlineUser
  loadPurchases(): Promise<IPaginatedResponse<IPhoneCardPurchase>>
  buyAgain: (purchase: IPhoneCardPurchase, onlineUser: IOnlineUser) => void
}

interface ICardDetail {
  order: IPhoneCardPurchase
  cards: IPhoneCard[]
  items: IPhoneCardPurchaseItem[]
}

const History: React.FC<IHistoryProps> = props => {
  useEffect(() => {
    props.loadPurchases();
  }, [])

  const dispatch = useDispatch();

  const allCards = props.purchaseHistory.history && props.purchaseHistory.history.data ?
  props.purchaseHistory.history.data
    .sort((a,b) => moment(b.date.date).unix() - moment(a.date.date).unix())
    .map(h => ({
      order: h,
      items: h.onlineOrderItems,
      cards: h.onlineOrderItems.reduce((p,c) => p.concat(c.phoneCards), [])
    } as ICardDetail)) :
  [];

  return (
    <>
      <PageTitle title="Purchased Phone Cards" />
      <Navigation title="Purchased Phone Cards" />

      <main className="container pt-3">
        <section className="row">
          <div className="col mb-3">
            <div className="border border-dark rounded h-100 pb-4">
              <h5 className="bg-dark text-light px-5 py-3"><span></span>Purchased Phone Cards</h5>
              <div className="px-lg-5 p-4">
                <button className="btn btn-info btn-block" onClick={() =>{
                  dispatch(checkoutActions.selectService(ServiceType.PhoneCard));
                  props.history.push('/checkout/card');
                  return false;
                }}>Purchase new phone card</button>
              </div>

              {allCards.map(h =>
                h.cards.map(c =>
                <HistoryRow
                  id={h.order.id}
                  inmateFirstName={h.order.inmateFirstName}
                  inmateLastName={h.order.inmateLastName}
                  unitPrice={h.order.summary.unitPrice}
                  pin={c.pin}
                  facilityName={h.order.summary.facilityName}
                  facilityCity={h.order.summary.facilityCity}
                  facilityState={h.order.summary.facilityState}
                  onBuyAgain={() => {
                    props.buyAgain(h.order, props.onlineUser);
                    props.history.push('/checkout/card');
                  }}
                  key={c.pin} />
              ))}
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default withRouter(connect(
  (state: IAppState) => ({
    purchaseHistory: state.phoneCardPurchaseHistory,
    onlineUser: state.auth.user
  }),
  ((dispatch: ThunkDispatch<IAppState, undefined, Action>) => ({
    loadPurchases: () => dispatch(phoneCardActions.loadPurchases()),
    buyAgain: (purchase: IPhoneCardPurchase, onlineUser: IOnlineUser) => {
      dispatch(checkoutCardActions.reset());
      dispatch(checkoutActions.selectService(Service.PhoneCard));
      dispatch(checkoutActions.selectFacilityById(purchase.summary.directBillFacilityId));
      dispatch(checkoutCardActions.setDetails({
        inmateInformation: {
          firstName: purchase.inmateFirstName,
          lastName: purchase.inmateLastName
        },
        inmate: purchase.inmate,
        typeValueId: purchase.onlineOrderItems[0].phoneCards[0].typeValue.id,
        firstName: onlineUser.firstName,
        lastName: onlineUser.lastName,
        address: onlineUser.address,
        city: onlineUser.city,
        state: onlineUser.state,
        zip: onlineUser.zip,
        emailAddress: onlineUser.emailAddress,
        phoneNumber: onlineUser.phoneNumber,
        receiptEmailAddress: onlineUser.emailAddress,
        updateAccountSettings: false
      }))
    }
  }))
)(History));
