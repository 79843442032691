import * as walletActions from '../../actions/checkout/wallet';
import * as walletReceiptActions from '../../actions/wallet/receipt';
import { IWalletPayment, IWalletPaymentData } from '../../models/WalletPayment';
import { ApiError } from '../../services/api';

export interface IWalletReceiptState {
  receiptEmailAddress?: string
  payment?: IWalletPayment
  paymentLoading: boolean
  paymentLoadError?: ApiError
}

const initialState:IWalletReceiptState = {
  paymentLoading: false
};

export function walletReceiptReducer(state: IWalletReceiptState = initialState, action): IWalletReceiptState {
  switch (action.type) {
    case walletReceiptActions.LOAD_PAYMENT_LOADING:
      return {
        ...state,
        payment: null,
        paymentLoading: true,
        paymentLoadError: null
      };

    case walletReceiptActions.LOAD_PAYMENT_FAILURE:
      return {
        ...state,
        payment: null,
        paymentLoading: false,
        paymentLoadError: action.error
      };

    case walletReceiptActions.LOAD_PAYMENT_SUCCESS:
      return {
        ...state,
        payment: action.payment,
        paymentLoading: false,
        paymentLoadError: null
      };

    case walletActions.PAYMENT_SUCCESS:
      const paymentData: IWalletPaymentData = action.paymentData;
      return {
        ...state,
        payment: action.payment,
        receiptEmailAddress: paymentData.receiptEmailAddress
      };

    case walletReceiptActions.RESET:
      return initialState;
  }

  return state;
}
