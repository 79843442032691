import React, { useState } from 'react';
import { ICreditCard, ICreditCardUpdateData } from '../../../models/CreditCard';
import EditableCreditCardRow from '../../CreditCard/EditableCreditCardRow';
import RemoveModal from '../../CreditCard/RemoveModal';
import SavedCardLoadingRow from './SavedCardLoadingRow';

interface ISavedCardProps {
  loading: boolean
  error: Error
  cards: any[]
  onSave: (changes: ICreditCardUpdateData) => Promise<any>
  onNewCard: () => void

  removingCard: boolean
  onRemoveCard: (creditCard: ICreditCard) => Promise<any>
}

const SavedCards: React.FC<ISavedCardProps> = props => {
  const [removingCard, removeCard] = useState(null);

  return (
    <div className="col mb-3">
      <div className="border border-dark rounded h-100">
        <h5 className="bg-dark text-light px-5 py-3 mb-4"><span></span>Your Saved Cards</h5>

        <div className="px-lg-5 p-4">
          <button
            className="btn btn-info btn-block"
            onClick={props.onNewCard}>
            Add new card
          </button>
        </div>

        {props.loading && <SavedCardLoadingRow />}

        {props.cards.map(card =>
          <EditableCreditCardRow
            key={`credit-card-${card.id}`}
            onRemove={() => removeCard(card)}
            onSubmit={data => props.onSave({
              ...data,
              id: card.id
            })}
            creditCard={card} />
        )}

        {removingCard &&
          <RemoveModal
            creditCard={removingCard}
            removing={props.removingCard}
            onCancel={() => removeCard(false)}
            onRemove={() =>
              props
                .onRemoveCard(removingCard)
                .then(() => removeCard(false))
              } />
        }
      </div>
    </div>
  )
}

export default SavedCards;
