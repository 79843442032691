import React from 'react';
import { required, usPhoneNumber } from '../../../validation';
import { renderButtonGroup, renderFormInput } from '../../Form/RenderForm';

interface IDetailsFormProps {
  newAccount: boolean
}

const DetailsForm: React.FC<IDetailsFormProps> = props =>
  <>
    {props.newAccount &&
      <div>
        {renderFormInput(
          'account.ani',
          'account.ani',
          'Phone number',
          [required, usPhoneNumber]
        )}
      </div>
    }
    <div className="form-label-group">
      <div>
        {renderButtonGroup(
          'verifyAccount.method',
          'verifyAccount.method',
          'Verification method',
          [
            { variant: 'light', value: 'Text', label: 'Text/SMS' },
            { variant: 'light', value: 'Call', label: 'Phone Call' }
          ],
          [required]
        )}
        <p className="small text-dark">We will send a verification code to your number to verify ownership</p>
      </div>
    </div>
  </>

export default DetailsForm
