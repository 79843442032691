import moment from 'moment';
import * as React from 'react';

function formatDuration(start, end = null) {
  // Convert strings to date objects
  let seconds;
  if (end == null) { end = 0; }
  if ((end !== null) && !(end instanceof Date) && isNaN(parseFloat(start))) {
    end = moment(end);
  }
  if ((start !== null) && !(start instanceof Date) && isNaN(parseFloat(start))) {
    start = moment(start);
  }

  // If given start and end, calculate number of seconds between
  if ((start !== null) && (end !== null)) {
    seconds = Math.abs(end - start);
    // If original start date was a date object or string, assume seconds is milliseconds
    if (isNaN(parseFloat(start))) {
      seconds = seconds / 1000;
    }
  } else {
    seconds = start;
  }

  if (seconds === 0) { return '0s'; }

  const hr = Math.floor(seconds/3600);
  const m = parseInt(String(Math.floor((seconds%3600)/60)));
  const s = parseInt(String(seconds%60));

  return (
    (hr > 0 ? `${hr}hr ` : "") + // Hours
    (m > 0 ? `${m}m ` : "") + // Minutes
    (s > 0 ? `${s}s` : "") // Seconds
  ).trim();
}

export interface IDurationProps {
  value: any
}

const Duration = (props:IDurationProps) => {
  const duration = formatDuration(props.value);
  return <span>{duration}</span>;
}

export default Duration;
