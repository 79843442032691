import React from 'react';

interface ITransactionTypeProps {
  subType: number
}

function subTypeToText(subType) {
  switch(subType) {
    case 1:
      return 'Credit Card Payment';
    case 2:
      return 'Invoice';
    case 3:
      return 'Money Transfer';
    case 4:
      return 'Check Payment';
    case 5:
      return 'Internal Debit';
    case 6:
      return 'Internal Credit';
    case 7:
      return 'Cash Payment';
    case 10:
      return 'Money Order';
    case 11:
      return 'Refund';
    case 12:
      return 'Online Credit Card Payment';
    case 13:
      return 'Reconnection Fee';
    case 14:
      return 'Reconnection Fee Refund';
    case 15:
      return 'Transaction Fee';
    case 16:
      return 'Phone Credit Card Payment';
  }

  return 'Other';
}

const TransactionType: React.FC<ITransactionTypeProps> = props =>
  <span>{subTypeToText(props.subType)}</span>

export default TransactionType;
