import * as websiteActions from '../actions/website';
import { Facility } from '../models/Facility';
import { TransactionFee } from '../models/Transaction';

export interface ICommonState {
  rehydrated: boolean
  loadingFacilities: boolean
  error?: Error
  facilities?: Facility[]
  feeTable?: TransactionFee[]
  recentActions: string[]
}

const initialState:ICommonState = {
  rehydrated: false,
  loadingFacilities: false,
  recentActions: []
};

export function commonReducer(state: ICommonState = initialState, action): ICommonState {
  switch (action.type) {
    case websiteActions.BASE_DATA_LOADING:
      return {
        ...state,
        loadingFacilities: true,
        error: undefined
      };

    case websiteActions.BASE_DATA_LOAD_FAILURE:
      return {
        ...state,
        loadingFacilities: false,
        error: action.error
      };

    case websiteActions.BASE_DATA_LOAD_SUCCESS:
      return {
        ...state,
        loadingFacilities: false,
        error: undefined,
        facilities: action.facilities,
        feeTable: action.feeTable
      };

    case 'persist/REHYDRATE':
      let newState = state;

      // Track if we rehydrated the root state
      if (action.key == 'root') {
        newState.rehydrated = true;
      }

      // Set fee table and facility list if available
      if (action && action.payload && action.payload.common && action.payload.common.feeTable) {
        return {
          ...newState,
          feeTable: action.payload.common.feeTable.map(t => new TransactionFee(t)),
          facilities: action.payload.common.facilities.map(f => new Facility(f))
        };
      }

      return newState;
  }

  return state;
}
