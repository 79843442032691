import React from 'react';
import { IFacility } from '../../../models/Facility';
import { ICardTypeAvailable } from '../../../models/PhoneCard';
import PageTitle from '../../PageTitle';
import Navigation from '../Navigation';
import { CheckoutStep } from '../Service';
import InfoBox from './InfoBox';

interface IContainerProps {
  facility: IFacility
  inmateFirstName?: string
  inmateLastName?: string
  cardType: ICardTypeAvailable
  fees: number
  taxes: number
  quantity: number
  onResetService: () => void
}

const Container: React.FC<IContainerProps> = props =>
  <>
    <PageTitle title="Phone Card" />
    <Navigation step={CheckoutStep.Service} onResetService={props.onResetService} />

    <main className="container align-items-top justify-content-center mt-3 pb-4">
      <section className="no-gutters">
        <div className="main row">
          <InfoBox
            facility={props.facility}
            inmateFirstName={props.inmateFirstName}
            inmateLastName={props.inmateLastName}
            cardType={props.cardType}
            fees={props.fees}
            quantity={props.quantity}
            taxes={props.taxes}
            />

          <div className="col-md-8 mb-3">
            {props.children}
          </div>
        </div>
      </section>
    </main>
  </>

export default Container;
