import { FORM_ERROR } from 'final-form';
import React, { useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import { match, RouterProps } from 'react-router';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as onlineUserActions from '../../actions/online-user';
import PageTitle from '../../components/PageTitle';
import { IAppState } from '../../reducers';
import SuccessMessage from '../SuccessMessage';
import ForgotPasswordForm from './ForgotPasswordForm';
import ResetPasswordForm from './ResetPasswordForm';

interface IForgotPasswordUrlProps {
  email?: string
  resetCode?: string
}

interface IForgotPasswordProps extends RouterProps {
  match: match<IForgotPasswordUrlProps>
}

const ForgotPassword: React.FC<IForgotPasswordProps> = props => {
  const state = useSelector((state: IAppState) => ({
    forgotPassword: state.forgotPassword
  }));
  let dispatch: ThunkDispatch<IAppState, undefined, Action> = useDispatch();

  useEffect(() => {
    // Reset the state of this page on visiting or leaving
    dispatch(onlineUserActions.resetPasswordReset());
  }, []);

  return (
    <main className="container pt-5">
      <PageTitle title="Forgot Password" />

      <Row>
        <Col lg={3} />
        <Col lg={6}>
          <Row className="my-5 pt-5 border border-primary rounded">
            <Col lg={12} className="my-5 p-5">
              {(props.match && props.match.params.email && props.match.params.resetCode) ?
                <ResetPasswordForm
                  onReset={(data, form) =>
                    dispatch(onlineUserActions.resetPassword(props.match.params.email, props.match.params.resetCode, data.newPassword))
                      .then(() => setTimeout(() => form.restart()))
                      .then(() => props.history.push('/login'))
                      .catch(err =>
                        ({ [FORM_ERROR]: err.error ? err.error : err })
                      )
                  } /> :
                <>
                  {state.forgotPassword.sentResetEmail &&
                    <SuccessMessage message="If your email address is on file, you will receive a password reset email shortly." />
                  }

                  <ForgotPasswordForm
                    onSendResetEmail={(data, form) =>
                      dispatch(onlineUserActions.requestPasswordReset(data.emailAddress.trim()))
                        .then(() => setTimeout(() => form.restart()))
                        .catch(err =>
                          ({ [FORM_ERROR]: err.error ? err.error : err })
                        )
                    } />
                </>
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </main>
  );
}

export default ForgotPassword;