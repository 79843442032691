import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { IFacility } from '../../models/Facility';
import { IScheduleAvailability } from '../../models/Visitation';
import { ApiError } from '../../services/api';
import ErrorMessage from '../ErrorMessage';
import VisitationWindows from './VisitationWindows';

interface ITimeslotPickerProps {
  facility: IFacility
  loadingTimeslots: boolean
  timeslotLoadingError: ApiError
  timeslots: IScheduleAvailability[]
  onSelect(timeslot: IScheduleAvailability): void
  selectedTimeslot: IScheduleAvailability
}

const TimeslotPicker: React.FC<ITimeslotPickerProps> = props => {
  const [selected, selectTimeslot] = useState(null);

  return (
    <>
      <h3>Select visitation at the jail</h3>

      {props.timeslotLoadingError &&
        <ErrorMessage error={props.timeslotLoadingError} />
      }

      {!props.loadingTimeslots && props.timeslots && props.timeslots.length == 0 &&
        <ErrorMessage message="There are no available timeslots for a visitation with this inmate" />
      }

      {props.loadingTimeslots &&
        <div style={{marginBottom: 20}}>
          <Skeleton height={40} />
          <Skeleton height={40} />
          <Skeleton height={40} />
          <Skeleton height={40} />
        </div>
      }

      {props.timeslots && props.timeslots.length > 0 &&
        <VisitationWindows
          facility={props.facility}
          loadingTimeslots={props.loadingTimeslots}
          availability={props.timeslots}
          onBlockSelect={selectTimeslot}
          selectedBlock={selected}
          />
      }

      <button
        className="btn btn-muted btn-block btn-lg text-white py-3 mb-5"
        type="submit"
        disabled={props.loadingTimeslots || selected == null}
        onClick={() => props.onSelect(selected)}>
        <strong>Continue</strong>
      </button>
    </>
  )
}

export default TimeslotPicker
