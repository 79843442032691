import React from 'react';
import { ICreditCard } from '../../models/CreditCard';
import SavedCardLoadingRow from '../Authenticated/Settings/SavedCardLoadingRow';
import CreditCardRow from './CreditCardRow';

interface ISavedCardPickerProps {
  loading: boolean
  selectedCard: ICreditCard
  savedCards: ICreditCard[]
  onCreditCardSelect: (card: ICreditCard) => void
}

const SavedCardPicker: React.FC<ISavedCardPickerProps> = props =>
  props.loading ?
    <div>
      <h4 className="mb-0 pt-3">Loading saved cards...</h4>
      <SavedCardLoadingRow />
    </div>
    :
    <div>
      <h4 className="mb-0 pt-3">Use a saved card</h4>
      <section className="bodysm">
        {props.savedCards.map(card =>
          <CreditCardRow
            creditCard={card}
            key={`saved-card-${card.id}`}
            selected={card == props.selectedCard}
            otherSelected={props.selectedCard && card != props.selectedCard}
            onSelect={props.onCreditCardSelect} />
        )}
      </section>
    </div>

export default SavedCardPicker
