import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { cardReceiptReducer, ICardReceiptState } from './receipt';

const cardPersistConfig = {
  key: 'card',
  timeout: 60 * 60,
  storage,
  whitelist: ['receipt']
}

export interface ICardState {
  receipt: ICardReceiptState
}

export const cardReducer = persistReducer(
  cardPersistConfig,
  combineReducers<ICardState>({
    receipt: cardReceiptReducer
  })
) as any; // Casting resolves a typescript complaint that doesn't make sense...
