
export const RECENT_CALLS_TOGGLE = 'ACCOUNT/RECENT_CALLS_TOGGLE';
export function toggleRecentCallsExpansion() {
  return { type: RECENT_CALLS_TOGGLE };
}

export const RECENT_PAYMENTS_TOGGLE = 'ACCOUNT/RECENT_PAYMENTS_TOGGLE';
export function toggleRecentPaymentsExpansion() {
  return { type: RECENT_PAYMENTS_TOGGLE };
}
