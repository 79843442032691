import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { ICreditCard, ICreditCardUpdateData } from '../../models/CreditCard';
import ErrorMessage from '../ErrorMessage';
import { renderActionSubmit } from '../Form/RenderForm';
import DetailsForm from './DetailsForm';

interface IEditableCreditCardRowProps {
  creditCard: ICreditCard
  onSubmit: (updateData: ICreditCardUpdateData) => Promise<any>
  onRemove: () => void
}

const EditableCreditCardRow: React.FC<IEditableCreditCardRowProps> = props => {
  const [expanded, expand] = useState(false);

  return (
    <div className="mx-lg-5 mx-4 my-3 p-2 row bg-light rounded">
      <div className="col-lg-4 my-2 d-flex align-self-center px-2">
        <span className="dashboard-cc-icon mr-3"></span>
        <p className="m-0 subtitle subtwo">
          <span style={{ textTransform: 'uppercase' }}>{props.creditCard.type}</span>&nbsp;
          ending in {props.creditCard.lastFour}
        </p>
      </div>
      <div className="col-lg-3 my-2 d-flex align-self-center px-2">
        <p className="m-0 subtitle subtwo">
          {props.creditCard.nameOnCard}
        </p>
      </div>
      <div className="col-lg-1 my-2 d-flex align-self-center px-1">
        <p className="m-0 subtitle subtwo">
          Exp {props.creditCard.expMonth}/{String(props.creditCard.expYear).substr(-2)}
        </p>
      </div>
      <div className="col-lg-2 my-2 d-flex align-self-center px-2">
        <button
          onClick={() => expand(!expanded)}
          className="btn btn-secondary btn-block text-light"
          type="button">
          Edit card
        </button>
      </div>
      <div className="col-lg-2 my-2 d-flex align-self-center px-2">
        <button
          className="btn btn-outline-secondary btn-block"
          onClick={props.onRemove}>
          Remove card
        </button>
      </div>
      {expanded &&
        <Collapse in={true}>
          <div className="w-100">
            <Form
              initialValues={{
                id: props.creditCard.id,
                nameOnCard: props.creditCard.nameOnCard,
                expirationDate: `${props.creditCard.expMonth}/${String(props.creditCard.expYear).substr(-2)}`,
                city: props.creditCard.city,
                state: props.creditCard.state,
                zip: props.creditCard.zip,
                address: props.creditCard.address
              }}
              keepDirtyOnReinitialize={true}
              onSubmit={(data: ICreditCardUpdateData, form: any) =>
                props
                  .onSubmit(data)
                  .then(() => setTimeout(() => form.restart()))
              }>
              {form =>
                <form className="p-2" onSubmit={form.handleSubmit} method="post">
                  {form.submitError &&
                    <ErrorMessage error={form.submitError} />
                  }

                  <DetailsForm editCard={props.creditCard} />
                  {renderActionSubmit<ICreditCardUpdateData>(form, 'Update card', 'Updating...')}
                </form>
              }
            </Form>
          </div>
        </Collapse>
      }
    </div>
  )
}

export default EditableCreditCardRow;
