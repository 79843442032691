import * as onlineUserActions from "../actions/online-user";

export interface IForgotPasswordState {
  sendingResetEmail: boolean,
  sentResetEmail: boolean,

  resettingPassword: boolean,

  error?: Error
}

const initialState:IForgotPasswordState = {
  sendingResetEmail: false,
  sentResetEmail: false,
  resettingPassword: false
};

export function forgotPasswordReducer(state: IForgotPasswordState = initialState, action): IForgotPasswordState {
  switch (action.type) {
    case onlineUserActions.RESET_PASSWORD_RESET:
      return initialState;

    case onlineUserActions.REQUEST_PASSWORD_RESET:
      return {
        ...state,
        sendingResetEmail: false,
        error: action.error,
        sentResetEmail: false
      };

    case onlineUserActions.REQUEST_PASSWORD_RESET_FAILURE:
      return {
        ...state,
        sendingResetEmail: false,
        error: action.error
      };

    case onlineUserActions.REQUEST_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        sendingResetEmail: false,
        error: undefined,
        sentResetEmail: true
      };

    case onlineUserActions.RESET_PASSWORD:
      return {
        ...state,
        resettingPassword: true,
        error: undefined
      };

    case onlineUserActions.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resettingPassword: false,
        error: action.error
      };

    case onlineUserActions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resettingPassword: false,
        error: undefined
      };
  }

  return state;
}
