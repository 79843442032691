import React from 'react';
import NumberFormat from 'react-number-format';

interface IPhoneNumberProps {
  value: string
}

export const formatPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber != null) { phoneNumber = phoneNumber.trim(); }

  // Remove US country code
  if (phoneNumber) {
    phoneNumber = phoneNumber.replace('+1', '');
    phoneNumber = phoneNumber.substr(-10);
  }

  if ((phoneNumber != null) && (phoneNumber.length === 11)) {
    return phoneNumber.substring(0,1) + "-" + phoneNumber.substring(1,4) + "-" + phoneNumber.substring(4,7) + "-" + phoneNumber.substring(7,11);
  } else if ((phoneNumber != null) && (phoneNumber.length === 10)) {
    return phoneNumber.substring(0,3) + "-" + phoneNumber.substring(3,6) + "-" + phoneNumber.substring(6,10);
  } else if ((phoneNumber != null) && (phoneNumber.length === 7)) {
    return phoneNumber.substring(0,3) + "-" + phoneNumber.substring(3,7);
  } else {
    return phoneNumber;
  }
}

const PhoneNumber: React.FC<IPhoneNumberProps> = props =>
  <NumberFormat displayType={'text'} format="(###) ###-####" value={props.value.substr(-10)} />

export default PhoneNumber;
