import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SavedCardLoadingRow: React.FC = _ =>
  <div className="mx-lg-5 mx-4 my-3 p-2 row rounded">
    <div className="col-lg-4 my-2 align-self-center px-2">
      <Skeleton width={'100%'} height={25} />
    </div>
    <div className="col-lg-3 my-2 align-self-center px-2">
      <Skeleton width={'100%'} height={25} />
    </div>
    <div className="col-lg-1 my-2 align-self-center px-1">
      <Skeleton width={'100%'} height={25} />
    </div>
  </div>

export default SavedCardLoadingRow;
