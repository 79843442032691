import React from 'react';

const OtherFeeInformation = (
  <>
    <p className="font-weight-bold mb-0"><u>Automated payment fee</u></p>
    <p>$3.00 applies to all payments made via our website</p>
    <p className="font-weight-bold mb-0"><u>Live Agent Fee</u></p>
    <p>$5.95 applies to all payments made via our Customer Service Department</p>
    <p className="font-weight-bold mb-0"><u>Third-Party Financial Transaction Fees</u></p>
    <p>Amount varies by payment method</p>
  </>
)

export default OtherFeeInformation;
