import * as accountActions from '../../actions/account';
import * as creditCardActions from '../../actions/credit-card';
import * as onlineUserAccountActions from '../../actions/online-user-account';
import { ICreditCard } from '../../models/CreditCard';
import { IOnlineUserAccount } from '../../models/OnlineUserAccount';

export interface IAccountViewState {
  loading: boolean
  error?: Error
  account?: IOnlineUserAccount
  recentCallsExpanded: boolean
  recentPaymentsExpanded: boolean
  savedCardsLoading: boolean
  savedCards?: ICreditCard[]
  savedCardsLoadingError?: Error

  savingCreditCardChanges: boolean
  savingCreditCard?: ICreditCard
  savingCreditCardError?: Error

  savingNewCreditCard: boolean
  savingNewCreditCardError?: Error

  removingCard?: boolean
}

const initialState:IAccountViewState = {
  loading: false,
  recentCallsExpanded: false,
  recentPaymentsExpanded: false,
  savedCardsLoading: false,

  savingCreditCardChanges: false,
  savingNewCreditCard: false
};

export function accountViewReducer(state: IAccountViewState = initialState, action): IAccountViewState {
  switch (action.type) {
    case onlineUserAccountActions.RESET:
      return initialState;

    case onlineUserAccountActions.LOADING_ACCOUNT:
      return {
        ...state,
        loading: true,
        error: undefined
      };

    case onlineUserAccountActions.LOAD_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case onlineUserAccountActions.LOAD_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        account: action.account
      };

    case accountActions.RECENT_CALLS_TOGGLE:
      return {
        ...state,
        recentCallsExpanded: !state.recentCallsExpanded
      };

    case accountActions.RECENT_PAYMENTS_TOGGLE:
      return {
        ...state,
        recentPaymentsExpanded: !state.recentPaymentsExpanded
      };

    case creditCardActions.LOADING:
      return {
        ...state,
        savedCardsLoading: true,
        savedCardsLoadingError: undefined
      };

    case creditCardActions.LOAD_FAILURE:
      return {
        ...state,
        savedCardsLoading: false,
        savedCardsLoadingError: action.error
      };

    case creditCardActions.LOAD_SUCCESS:
      return {
        ...state,
        savedCardsLoading: false,
        savedCardsLoadingError: undefined,
        savedCards: action.creditCards
      };

    case creditCardActions.CHANGES_SAVING:
      return {
        ...state,
        savingCreditCardChanges: true,
        savingCreditCardError: undefined,
        savingCreditCard: action.creditCard
      };

    case creditCardActions.CHANGES_SAVE_FAILURE:
      return {
        ...state,
        savingCreditCardChanges: false,
        savingCreditCardError: action.error
      };

    case creditCardActions.CHANGES_SAVE_SUCCESS:
      return {
        ...state,
        savingCreditCardChanges: false,
        savingCreditCardError: undefined,
        savingCreditCard: null
      };

    case creditCardActions.NEW_SAVING:
      return {
        ...state,
        savingNewCreditCard: true,
        savingCreditCardError: undefined
      };

    case creditCardActions.NEW_SAVE_FAILURE:
      return {
        ...state,
        savingNewCreditCard: false,
        savingCreditCardError: action.error
      };

    case creditCardActions.NEW_SAVE_SUCCESS:
      return {
        ...state,
        savingNewCreditCard: false,
        savingCreditCardError: undefined
      };

    case creditCardActions.REMOVING:
      return {
        ...state,
        removingCard: true
      };

    case creditCardActions.REMOVE_FAILURE:
      return {
        ...state,
        removingCard: false
      };

    case creditCardActions.REMOVE_SUCCESS:
      return {
        ...state,
        removingCard: false
      };
  }

  return state;
}
