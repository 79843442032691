import { IPersonalInformationData } from '../components/Checkout/PersonalInformation';
import { ICreditCardData } from '../models/CreditCard';
import { IWalletPayment } from '../models/WalletPayment';
import Api, { IPaginatedResponse } from '../services/api';

export function index(page = 1, perPage = 250) {
  return Api
    .get<IPaginatedResponse<IWalletPayment>>({
      url: '/inmate-account-payment',
      query: { page, perPage }
    });
}

export function audioVideoIndex(page = 1, perPage = 250) {
  return Api
    .get<IPaginatedResponse<IWalletPayment>>({
      url: '/inmate-account-payment/audio-video',
      query: { page, perPage }
    });
}

type InmateAccountPaymentRequestData = {
  payment: {
    accountType: 'wallet' | 'audio-video'
    facilityId: number
    inmateId: number
    amount: number
    receiptEmailAddress: string
  }
  cardSecurityCode: string
  savedCreditCardId?: number
  newCreditCard: ICreditCardData
  purchaserDetails: IPersonalInformationData
}

type InmateAccountPaymentResponse = {
  success: boolean
  payment: IWalletPayment
}

export function post(paymentRequest: InmateAccountPaymentRequestData) {
  return Api
    .post<InmateAccountPaymentResponse>({
      url: '/inmate-account-payment',
      data: paymentRequest
    })
}

export function get(id: number) {
  return Api
    .get<IWalletPayment>({
      url: `/inmate-account-payment/${id}`
    })
}