import React from 'react';
import { IFacility } from '../../models/Facility';
import { IInmate } from '../../models/Inmate';
import { IScheduleAvailability } from '../../models/Visitation';
import Navigation from '../Checkout/Navigation';
import { CheckoutStep } from '../Checkout/Service';
import PageTitle from '../PageTitle';
import InfoBox from './InfoBox';

interface IContainerProps {
  facility: IFacility
  inmate?: IInmate
  timeslot?: IScheduleAvailability
  onResetService: () => void
}

const Container: React.FC<IContainerProps> = props =>
  <>
    <PageTitle title="Schedule Visitation" />
    <Navigation step={CheckoutStep.Service} onResetService={props.onResetService} />

    <main className="container align-items-top justify-content-center mt-3 pb-4">
      <section className="no-gutters">
        <div className="main row">
          <InfoBox
            facility={props.facility}
            inmate={props.inmate}
            timeslot={props.timeslot}
            />

          <div className="col-md-8 mb-3">
            {props.children}
          </div>
        </div>
      </section>
    </main>
  </>

export default Container;
