import React from 'react';

interface IVisitationWindowBlockProps {
  time: number,
  closed: boolean,
  open: boolean,
  onClick(): void
  selected: boolean
}

const VisitationWindowBlock: React.FC<IVisitationWindowBlockProps> = (props) => {
  let blockStyle = {
    cursor: 'default',
    textDecoration: 'default'
  };

  var hours = Math.floor(props.time / 100);
  var minutes = String(((props.time % 100) / 100) * 60);
  minutes = (`00${minutes}`).slice(-2); // Pad left with 0
  var amPM = hours >= 12 ? 'PM' : 'AM';

  if (hours > 12) {
    hours = hours % 12;
  }

  let displayTime = `${hours}:${minutes}${amPM}`;

  if (props.open) {
    blockStyle.cursor = 'pointer';
  } else {
    blockStyle.cursor = 'not-allowed';
    blockStyle.textDecoration = 'line-through';
  }

  return (
    <button
      className={`btn btn-block py-3 ${props.selected ? 'btn-primary' : 'btn-outline-light text-dark'}`}
      onClick={props.onClick}
      disabled={props.closed}
      style={blockStyle}>
      {displayTime}
    </button>
  );
};

export default VisitationWindowBlock;
