import React from 'react';
import { IOnlineUserAccount } from '../../../models/OnlineUserAccount';
import PhoneNumber from '../../Format/PhoneNumber';

interface ISelectedNumberRowProps {
  account: IOnlineUserAccount
  isNew: boolean
  onCancel(): void
}

const SelectedNumberRow: React.FC<ISelectedNumberRowProps> = props =>
  <div className="my-3 p-2 row bg-light rounded">
    <div className="col-lg-1 my-2 d-flex align-self-center px-2">
      <span className="dashboard-tab-icon collect-call mr-3"></span>
    </div>
    <div className="col-lg-4 my-2 d-flex flex-column align-self-center px-2">
      <p className="m-0 subtitle subtwo"><PhoneNumber value={props.account.ani} /></p>
    </div>
    <div className="col-lg-3 my-2 d-flex flex-column align-self-center px-2 text-align-right">
      <p className="m-0 subtitle subtwo text-success text-lg-right">
        {props.isNew ? 'Added' : 'Selected'}
        <span className="checkicon"></span>
      </p>
    </div>
    <div className="col-lg-4 my-2 d-flex align-self-center px-2">
      <button className="btn btn-outline-secondary btn-block" onClick={props.onCancel}>
        Cancel
      </button>
    </div>
  </div>

export default SelectedNumberRow;
