import React from 'react';
import { Link } from 'react-router-dom';
import { IOnlineUserAccount } from '../../../models/OnlineUserAccount';
import Currency from '../../Format/Currency';
import PhoneNumber from '../../Format/PhoneNumber';

interface IDetailsProps {
  account: IOnlineUserAccount
}

const Details: React.FC<IDetailsProps> = props =>
  <div className="bg-light rounded text-dark p-5">
    <h4 className="mb-4">Account # <PhoneNumber value={props.account.account.ani} /></h4>
    <h4 className="m-0">Balance: <Currency value={props.account.account.balance.balance * -1} /></h4>
    <h4 className="mb-4">
      Status: {props.account.blocked ?
        <span className="text-error">Blocked <span className="xicon"></span></span> :
        <span className="text-success">Open <span className="checkicon"></span></span>
      }
    </h4>

    {props.account.account.billing && (
      <>
        <p className="m-0 subtitle subtwo">{props.account.account.billing.firstName} {props.account.account.billing.lastName}</p>
        <p className="m-0 subtitle subtwo">{props.account.account.billing.address1}</p>
        {props.account.account.billing.address2 && (
          <p className="m-0 subtitle subtwo">{props.account.account.billing.address2}</p>
        )}
        <p className="mt-0 mb-4 subtitle subtwo">
        {props.account.account.billing.city}, {props.account.account.billing.state} {props.account.account.billing.zip}
        </p>
      </>
    )}

    <Link to="/accounts" className="subtitle subtwo text-secondary"><span className="back-arrow"></span>Back to all accounts</Link>
  </div>

export default Details;
