import { ThunkAction } from 'redux-thunk';
import { IApiErrorResponse } from '../models/Api';
import { IOnlineUserUpdateData } from '../models/OnlineUser';
import { IAppState } from '../reducers';
import * as authResource from '../resources/Auth';
import * as onlineUserResource from '../resources/OnlineUser';
import * as authActions from './auth';
import { AUTH_TOKEN_LOAD_SUCCESS, LOGIN_SUCCESS } from './auth';

export const PROFILE_UPDATING = 'ONLINE_USER/PROFILE_UPDATING';
export const PROFILE_UPDATE_SUCCESS = 'ONLINE_USER/PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_FAILURE = 'ONLINE_USER/PROFILE_UPDATE_FAILURE';
interface IProfileUpdatingAction {
  type: typeof PROFILE_UPDATING
  changes: IOnlineUserUpdateData
}
export interface IProfileUpdateSuccessAction {
  type: typeof PROFILE_UPDATE_SUCCESS
  user: IOnlineUserUpdateData
}
interface IProfileUpdateFailureAction {
  type: typeof PROFILE_UPDATE_FAILURE,
  error: IApiErrorResponse
}
type ProfileUpdateActionTypes = IProfileUpdatingAction | IProfileUpdateSuccessAction | IProfileUpdateFailureAction;
export function updateProfile(profileChanges: IOnlineUserUpdateData): ThunkAction<Promise<any>, IAppState, undefined, ProfileUpdateActionTypes> {
  return dispatch => {
    if (profileChanges.phoneNumber) {
      profileChanges.phoneNumber = profileChanges.phoneNumber.replace(/\D/g, '');
    }
    dispatch({ type: PROFILE_UPDATING, changes: profileChanges });

    return onlineUserResource.putSettings(profileChanges)
      .then(() => dispatch({ type: PROFILE_UPDATE_SUCCESS, user: profileChanges }))
      .catch(error => {
        dispatch({ type: PROFILE_UPDATE_FAILURE, error });
        throw error;
      })
  }
}

export const PASSWORD_CHANGING = 'ONLINE_USER/PASSWORD_CHANGING';
export const PASSWORD_CHANGE_SUCCESS = 'ONLINE_USER/PASSWORD_CHANGE_SUCCESS';
export const PASSWORD_CHANGE_FAILURE = 'ONLINE_USER/PASSWORD_CHANGE_FAILURE';
interface IPasswordChangingAction {
  type: typeof PASSWORD_CHANGING
}
interface IPasswordChangeSuccessAction {
  type: typeof PASSWORD_CHANGE_SUCCESS
}
interface IPasswordChangeFailureAction {
  type: typeof PASSWORD_CHANGE_FAILURE
  error: IApiErrorResponse
}
type PasswordChangeActionTypes = IPasswordChangingAction | IPasswordChangeSuccessAction | IPasswordChangeFailureAction;
export function changePassword(currentPassword: string, newPassword: string): ThunkAction<Promise<any>, IAppState, undefined, PasswordChangeActionTypes> {
  return dispatch => {
    dispatch({ type: PASSWORD_CHANGING });

    return onlineUserResource.putSettings(null, { currentPassword, newPassword })
      .then(() => dispatch({ type: PASSWORD_CHANGE_SUCCESS }))
      .catch(error => {
        dispatch({ type: PASSWORD_CHANGE_FAILURE, error });
        throw error;
      })
  }
}

export const REQUEST_PASSWORD_RESET = 'ONLINE_USER/REQUEST_PASSWORD_RESET';
export const REQUEST_PASSWORD_RESET_SUCCESS = 'ONLINE_USER/REQUEST_PASSWORD_RESET_SUCCESS';
export const REQUEST_PASSWORD_RESET_FAILURE = 'ONLINE_USER/REQUEST_PASSWORD_RESET_FAILURE';
interface IRequestPasswordResetAction {
  type: typeof REQUEST_PASSWORD_RESET
}
interface IRequestPasswordResetSuccessAction {
  type: typeof REQUEST_PASSWORD_RESET_SUCCESS
}
interface IRequestPasswordResetFailureAction {
  type: typeof REQUEST_PASSWORD_RESET_FAILURE
  error: IApiErrorResponse
}
type RequestPasswordActionTypes = IRequestPasswordResetAction | IRequestPasswordResetSuccessAction | IRequestPasswordResetFailureAction;
export function requestPasswordReset(emailAddress: string): ThunkAction<Promise<any>, IAppState, undefined, RequestPasswordActionTypes> {
  return dispatch => {
    dispatch({ type: REQUEST_PASSWORD_RESET });

    return onlineUserResource.postPasswordReset(emailAddress)
      .then(() => dispatch({ type: REQUEST_PASSWORD_RESET_SUCCESS }))
      .catch(error => {
        dispatch({ type: REQUEST_PASSWORD_RESET_FAILURE, error });
        throw error;
      })
  }
}

export const RESET_PASSWORD = 'ONLINE_USER/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'ONLINE_USER/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'ONLINE_USER/RESET_PASSWORD_FAILURE';
interface IResetPasswordAction {
  type: typeof RESET_PASSWORD
}
interface IResetPasswordSuccessAction {
  type: typeof RESET_PASSWORD_SUCCESS
}
interface IResetPasswordFailureAction {
  type: typeof RESET_PASSWORD_FAILURE
  error: IApiErrorResponse
}
type ResetPasswordActionTypes = IResetPasswordAction | IResetPasswordSuccessAction | IResetPasswordFailureAction;
export function resetPassword(emailAddress: string, resetCode: string, newPassword: string): ThunkAction<Promise<any>, IAppState, undefined, ResetPasswordActionTypes> {
  return dispatch => {
    dispatch({ type: RESET_PASSWORD });

    return onlineUserResource.putPasswordReset(emailAddress, resetCode, newPassword)
      .then(() => dispatch({ type: RESET_PASSWORD_SUCCESS }))
      .catch(error => {
        dispatch({ type: RESET_PASSWORD_FAILURE, error });
        throw error;
      })
  }
}

export const RESET_PASSWORD_RESET = 'ONLINE_USER/RESET_PASSWORD_RESET';
interface IResetPasswordResetAction {
  type: typeof RESET_PASSWORD_RESET
}
export function resetPasswordReset(): IResetPasswordResetAction {
  return { type: RESET_PASSWORD_RESET };
}

export const REGISTERING = 'ONLINE_USER/REGISTERING';
export const REGISTER_SUCCESS = 'ONLINE_USER/REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'ONLINE_USER/REGISTER_FAILURE';
interface IRegisteringAction {
  type: typeof REGISTERING
}
interface IRegisterSuccessAction {
  type: typeof REGISTER_SUCCESS
}
interface IRegisterFailureAction {
  type: typeof REGISTER_FAILURE
  error: Error
}
type RegisterActionTypes = IRegisteringAction | IRegisterSuccessAction | IRegisterFailureAction | authActions.AuthActionTypes;
export function register(emailAddress: string, password: string): ThunkAction<Promise<any>, IAppState, undefined, RegisterActionTypes> {
  return dispatch => {
    dispatch({ type: REGISTERING });

    return onlineUserResource.post(emailAddress, password)
      .then((res =>
        authResource
          .postToken(emailAddress, password)
          .then(loginResponse => {
            dispatch({
              type: AUTH_TOKEN_LOAD_SUCCESS,
              token: loginResponse
            });
            dispatch({
              type: LOGIN_SUCCESS,
              user: loginResponse.onlineUser,
              email: loginResponse.onlineUser.emailAddress,
              token: loginResponse
            });
          })
          .then(() =>
            dispatch({
              type: REGISTER_SUCCESS
            }))
          )
      )
      .catch(error => {
        dispatch({ type: REGISTER_FAILURE, error });
        throw error;
      })
  }
}

export type OnlineUserActionTypes = (
  RegisterActionTypes |
  ResetPasswordActionTypes |
  IResetPasswordResetAction |
  RequestPasswordActionTypes |
  ProfileUpdateActionTypes |
  PasswordChangeActionTypes
)