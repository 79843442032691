import React from 'react';
import { IOnlineUserAccount } from '../../../models/OnlineUserAccount';
import ErrorMessage from '../../ErrorMessage';
import Currency from '../../Format/Currency';
import AmountInput from './AmountInput';
import SelectedNumberRow from './SelectedNumberRow';

interface IPaymentAmountProps {
  account: IOnlineUserAccount
  onCancel(): void
  onSubmit(amount: number): void
}

const PaymentAmount: React.FC<IPaymentAmountProps> = props =>
  <div className="collectcall service mainwindow border rounded border-action">
    <h3 className="product bg-action p-2 py-3 text-white"><span className="icon-button"></span>Prepaid Collect Call</h3>
    <div className="col-md-10 service-window offset-md-1 d-flex align-items-center justify-content-center">
      <div className="maincontent w-100">
        <h3>Selected account</h3>
        <SelectedNumberRow
          account={props.account}
          isNew={props.account.id == undefined}
          onCancel={props.onCancel} />

        {(props.account.effectiveBlock == 'County Block') &&
          <div className="m-2">
            <ErrorMessage
              severity="warning"
              message="The account you specified has a county block placed on it that prevents inmate calls to this number. You can continue to place a payment on the account, but until the block is removed by the county, calls cannot be received." />
          </div>
        }

        <AmountInput
          onSubmit={(data) => props.onSubmit(data.amount)} />
        {props.account.neededToOpen < 0 &&
          <p>You must pay at least <strong><Currency value={props.account.neededToOpen * -1} /></strong> to unblock your account to allow phone calls to continue</p>
        }
      </div>
    </div>
  </div>

export default PaymentAmount;
