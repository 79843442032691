import React from 'react';
import { Link } from 'react-router-dom';
import Currency from '../../Format/Currency';

interface IHistoryRowProps {
  id: number
  inmateFirstName: string
  inmateLastName: string
  unitPrice: number
  pin: string
  facilityName: string
  facilityCity: string
  facilityState: string
  onBuyAgain: () => void
}

const HistoryRow: React.FC<IHistoryRowProps> = props =>
  <div className="mx-lg-5 mx-4 my-3 p-2 row bg-light rounded">
    <div className="col-lg-1 my-2 d-flex align-self-center px-2">
      <Link to={`/checkout/card/receipt/${props.id}`}>
        <span className="dashboard-tab-icon phone-card mr-3"></span>
      </Link>
    </div>
    <div className="col-lg-3 my-2 d-flex flex-column align-self-center px-2">
      <p className="m-0 subtitle subtwo">
        <Currency value={props.unitPrice} />
        &nbsp;Phone Card&nbsp;
        for {props.inmateFirstName} {props.inmateLastName}
      </p>
    </div>
    <div className="col-lg-3 my-2 d-flex flex-column align-self-center px-2">
      <p className="m-0 subtitle subtwo">For use at {props.facilityName} ({props.facilityCity}, {props.facilityState})</p>
    </div>
    <div className="col-lg-3 my-2 d-flex flex-column align-self-center px-2 text-align-right">
      <p className="m-0 subtitle subtwo">PIN: {props.pin}</p>
    </div>
    <div className="col-lg-2 my-2 d-flex align-self-center px-2">
      <button className="btn btn-outline-action btn-block" onClick={props.onBuyAgain}>Buy again</button>
    </div>
  </div>

export default HistoryRow;
