import React from 'react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { IOutsideParty } from '../../models/Visitation';
import { IAppState } from '../../reducers';
import { fullDate, maxLength, minYearsSinceDate, postalCode, required, usPhoneNumber, validDate } from '../../validation';
import ErrorMessage from '../ErrorMessage';
import { renderActionSubmit, renderCheckboxInput, renderSelect, renderTextInput } from '../Form/RenderForm';
import State from '../Form/State';

const visitationPhotoIdOptions = [
  "State Driver's License/ID",
  "Passport",
  "Military ID"
]

interface IVisitorInformationProps {
  onSubmit: (data: IOutsideParty) => Promise<any>
}

const VisitorInformation: React.FC<IVisitorInformationProps> = props => {
  let initialValues = useSelector((state: IAppState) =>
    state.auth.user
  )

  return (
    <Form
      initialValues={initialValues}
      keepDirtyOnReinitialize={true}
      onSubmit={props.onSubmit}>
      {form =>
        <form onSubmit={form.handleSubmit} method="post">
          {form.submitError &&
            <ErrorMessage error={form.submitError} />
          }

          <h4 className="pt-3 pt-lg-5">Visitor Information</h4>
          <section className="row">
            <div className="col-12 col-lg-6">
              <div className="form-label-group">
                {renderTextInput(
                  'visitorInformation.firstName',
                  'firstName',
                  'First name',
                  'First name',
                  [required, maxLength(50)]
                )}
              </div>
            </div>
            <div className="col-12 col-lg-6">
                <div className="form-label-group">
                {renderTextInput(
                  'visitorInformation.lastName',
                  'lastName',
                  'Last name',
                  'Last name',
                  [required, maxLength(50)]
                )}
              </div>
            </div>
            <div className="col-12">
              <div className="form-label-group">
                {renderTextInput(
                  'visitorInformation.address',
                  'address',
                  'Street address',
                  'Street address',
                  [required, maxLength(75)]
                )}
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="form-label-group">
                {renderTextInput(
                  'visitorInformation.city',
                  'city',
                  'City',
                  'City',
                  [required, maxLength(50)]
                )}
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="form-label-group">
                <State
                  name="state"
                  required={true}
                  includeCanada={true} />
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="form-label-group">
                {renderTextInput(
                  'visitorInformation.zip',
                  'zip',
                  'Postal code',
                  'Postal code',
                  [required, postalCode]
                )}
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="form-label-group">
                {renderTextInput(
                  'visitorInformation.phoneNumber',
                  'phoneNumber',
                  'Phone number',
                  'Phone number',
                  [required, usPhoneNumber]
                )}
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="form-label-group">
                {renderTextInput(
                  'visitorInformation.dateOfBirth',
                  'dateOfBirth',
                  'Date of birth (MM/DD/YYYY)',
                  'Date of birth (MM/DD/YYYY)',
                  [required, fullDate, validDate, minYearsSinceDate(18, 'Must be at least 18 years old')]
                )}
              </div>
            </div>
          </section>

          <section className="row mt-5">
            <div className="col-12 col-lg-6 ">
              <div className="form-label-group mt-3">
                {renderSelect(
                  'visitorInformation.photoIdType',
                  'photoIdType',
                  null,
                  [
                    <option value="" key="photoIdType.blank">Photo ID Type</option>,
                    ...visitationPhotoIdOptions.map(o =>
                      <option key={o} value={o}>{o}</option>
                    )
                  ],
                  [required]
                )}
              </div>

              <div className="form-label-group">
                {renderTextInput(
                  'visitorInformation.photoIdState',
                  'photoIdState',
                  'Issuing state/authority',
                  'Issuing state/authority',
                  [required, maxLength(2)]
                )}
              </div>

              <div className="form-label-group">
                {renderTextInput(
                  'visitorInformation.photoIdNumber',
                  'photoIdNumber',
                  'ID number',
                  'ID number',
                  [required, maxLength(30)]
                )}
              </div>
            </div>

            {form.values.photoIdNumber && form.values.photoIdType && form.values.photoIdNumber &&
              <div className="col-12 col-lg-6">
                <div className="visitor_id h-100 bg-light text-muted rounded px-3 py-4">
                  <span className="id-bust pl-3"></span>
                  <div className="offset-2">
                    <p><strong className="bodysm text-uppercase">{form.values.photoIdType}</strong></p>
                    <p className="bodysm">Issued by: {form.values.photoIdState}</p>
                    <p className="caption border border-muted bg-white rounded p-1"><strong>ID# {form.values.photoIdNumber}</strong></p>
                  </div>
                </div>
              </div>
            }
          </section>

          <div className="alert alert-secondary mt-5" role="alert">
            <p className="bodysm">At this facility:</p>
            <ul className="bodysm">
              <li>Visitations are recorded and monitored</li>
              <li>You will be disconnected for inappropriate conduct</li>
            </ul>
            <hr/>
            <div className="form-group form-check m-0">
            {renderCheckboxInput(
              'visitorInformation.iAgree',
              'visitorInformation.iAgree',
              'I agree to these terms and confirm all information is correct.',
              [required],
            )}
            </div>
          </div>

          {renderActionSubmit<IOutsideParty>(form, 'Confirm visitation', 'Confirming...')}
        </form>
      }
    </Form>
  )
}

export default VisitorInformation
