import React from 'react';
import Header from './Header';
import PageTitle from './PageTitle';

type MaintenanceProps = {
  endTimes: string
}

const Maintenance: React.FC<MaintenanceProps> = (props) =>
  <div>
    <Header errorMode={true} />

    <PageTitle title="Under maintenance" />

    <main className="container">
      <div className="row py-5 justify-content-center">
        <div className="col-md-6 py-5">
          <h3>Under maintenance</h3>

          <p>We are currently performing scheduled maintenance.</p>

          <p>We anticipate completing by approximately {props.endTimes}.</p>
        </div>
      </div>
    </main>
  </div>

export default Maintenance;