import React from 'react';
import { Form } from 'react-final-form';
import { email, required } from '../../validation';
import ErrorMessage from '../ErrorMessage';
import { renderPrimarySubmit, renderTextInput } from '../Form/RenderForm';

interface IForgotPasswordFormProps {
  onSendResetEmail: (data: IForgotPasswordRequestData, form) => Promise<any>
}

interface IForgotPasswordRequestData {
  emailAddress: string
}

const ForgotPasswordForm: React.FC<IForgotPasswordFormProps> = props =>
  <Form
    onSubmit={(data: IForgotPasswordRequestData, form) =>
      props.onSendResetEmail(data, form)
    }>
    {form =>
      <form className="form-account-new" onSubmit={form.handleSubmit} method="post">
        <div className="mb-4 text-center">
          <h3>Forgotten password</h3>
          <p className="bodysm">If you have forgotten your password, you can request a reset email below.</p>
        </div>

        {form.submitError &&
          <ErrorMessage
            error={form.submitError} />
        }

        <div className="form-label-group">
          {renderTextInput(
            'forgotPassword.emailAddress',
            'emailAddress',
            'Enter email',
            'Enter email',
            [required, email]
          )}
        </div>
        {renderPrimarySubmit<IForgotPasswordRequestData>(form, 'Send reset email', 'Sending...')}
      </form>
    }
  </Form>

export default ForgotPasswordForm;