import cardValidator from 'card-validator';
import moment from 'moment';

export const required = value => (value ? undefined : 'Required')
export const requiredWithIgnored = (ignored: any[]) => value => (value && ignored.indexOf(value) === -1 ? undefined : 'Required')
export const requiredWithMessage = (message: string) => value => (value ? undefined : message)
export const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined
export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
export const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined
export const minValue = min => value =>
  value && value < min ? `Must be at least $${min}` : undefined
export const maxValue = max => value =>
  value && value > max ? `Must be up to or less than $${max}` : undefined
export const email = value =>
  value && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(value) ?
  'Invalid email address' : undefined
export const match = (otherFieldName:string, message:string) => (value, allValues) =>
  value !== allValues[otherFieldName] ?
    message :
    undefined
export const containsNumeric = value =>
  value && !/[0-9]/.test(value) ? 'Must contain at least one numeric character' : undefined
export const containsAlpha = value =>
  value && !/[a-z]/i.test(value) ? 'Must contain at least one letter' : undefined
export const password = value =>
  value && (minLength(8)(value) || containsNumeric(value) || containsAlpha(value))
export const creditCard = value =>
  value && !(cardValidator.number(value).isValid) ? 'Invalid credit card number' : undefined
export const creditCardExpiration = (value: string) => {
  if (value == null) {
    return undefined;
  }
  if (!/^[0-9]{1,2}\/(20)?[0-9]{2}$/.test(value)) {
    return 'Invalid format (MM/YY)';
  }
  const parts = value.split('/');
  const month = parts[0];
  if (!/^(1[0-2]|0?[1-9])$/.test(month)) {
    return 'Invalid month';
  }
  const year = parts[1].substr(-2);
  if (
    parseInt('20'+year) < (new Date()).getFullYear() ||
    (
      parseInt('20'+year) == (new Date()).getFullYear() &&
      parseInt(month) < (new Date()).getMonth()+1
    )
  ) {
    return 'Card is expired';
  }
  return undefined;
}
export const postalCode = value => {
  const us = new RegExp(/^\d{5}$/);
  // const usWithPlus4 = new RegExp(/^\d{5}(-{0,1}\d{4})?$/);
  const ca = new RegExp(/^([ABCEGHJKLMNPRSTVXY]\d)([ABCEGHJKLMNPRSTVWXYZ]\d){2}$/i);
  return !us.test(value) && !ca.test(value) ? 'Invalid postal code' : undefined;
}
export const usPhoneNumber = value =>
  value && !/^[(]?\d{3}[)]?[(\s)?.-]?\d{3}[\s.-]?\d{4}$/.test(value.trim()) ? 'Invalid phone number (10 digits)' : undefined
export const fullDate = value =>
  value && !/^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/.test(value.trim()) ? 'Invalid date (MM/DD/YYYY)' : undefined
export const validDate = value =>
  value && !moment(value.trim()).isValid() ? 'Invalid date' : undefined
export const minYearsSinceDate = (yearsAgo, message = null) => value =>
  value && moment(value.trim()).add(yearsAgo, 'years') > moment() ?
    (message || `Must be at least ${yearsAgo} ago`) :
    undefined

export const composeValidators = (validators) => (value, allValues, meta) =>
  validators.reduce((error, validator) => error || validator(value, allValues, meta), undefined)