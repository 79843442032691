import moment from 'moment';
import React from 'react';
import { ICreditCardPayment } from '../../../models/Transaction';
import Currency from '../../Format/Currency';
import PhoneNumber from '../../Format/PhoneNumber';
import RecentAction from './RecentAction';

interface IRecentCollectCallPaymentProps {
  loading: boolean
  payment: ICreditCardPayment
  onRepeat: () => void
}

const RecentCollectCall: React.FC<IRecentCollectCallPaymentProps> = props =>
  <RecentAction
    loading={props.loading}
    title="Prepaid Collect Call Accounts"
    repeatActionTitle="Buy Again"
    repeatAction={props.onRepeat}
    historyUrl="/accounts">
    {props.payment &&
      <>
        <div className="my-4">
          <h6>Account # <PhoneNumber value={props.payment.account.ani}/></h6>
          <h6 className="m-0">
            Balance: {props.payment.onlineUserAccount.blocked ?
              <span className="text-error"><Currency value={props.payment.account.balance.balance * -1} /></span> :
              <span className="text-success"><Currency value={props.payment.account.balance.balance * -1} /></span>
            }
          </h6>
          <h6 className="m-0">
            Status: {props.payment.onlineUserAccount.blocked ?
              <span className="text-error">Blocked <span className="xicon"></span></span> :
              <span className="text-success">Open <span className="checkicon"></span></span>
            }
          </h6>
        </div>
        <div className="text-dark mb-5">
          <p className="m-0 subtitle subtwo">Last payment</p>
          <p className="m-0 subtitle subtwo">
            <Currency value={props.payment.amount} />&nbsp;
            on&nbsp;
            {moment(props.payment.date.date).format('MM/DD/YY')}
          </p>
        </div>
      </>
    }
  </RecentAction>

export default RecentCollectCall;
