import React from 'react';
import { FormRenderProps } from 'react-final-form';
import { ICreditCard, ICreditCardData } from '../../models/CreditCard';
import { creditCard, creditCardExpiration, maxLength, minLength, number, postalCode, required } from '../../validation';
import SavedCardPicker from '../CreditCard/SavedCardPicker';
import { renderCheckboxInput, renderTextInput } from '../Form/RenderForm';
import State from '../Form/State';

interface IPaymentInformationProps {
  form: FormRenderProps<any>
  loggedIn: boolean
  savedCards: ICreditCard[]
  selectedSavedCard: ICreditCard
  onCreditCardSelect: (card: ICreditCard) => void
  onAddCreditCard: () => void
  addingCreditCard: boolean
  loadingSavedCreditCards: boolean
}

export interface IPaymentInformationData {
  cc?: ICreditCardData
}

const PaymentInformation: React.FC<IPaymentInformationProps> = props =>
  <>
    {(props.loadingSavedCreditCards || (props.savedCards||[]).length > 0) &&
      <SavedCardPicker
        loading={props.loadingSavedCreditCards}
        onCreditCardSelect={card => {
          props.form.form.mutators.useSavedCreditCard()
          props.onCreditCardSelect(card)
        }}
        selectedCard={props.selectedSavedCard}
        savedCards={props.savedCards} />
    }

    {(props.savedCards||[]).length > 0 ?
      <section className="row pt-2 pb-4 mr-lg-1">
        <h4 className="col-lg-10 col-8 mb-0 d-flex align-self-center">Add a new card</h4>
        <div className="col-lg-2 col-4 px-2 px-lg-2 pr-3 d-flex align-self-center bodysm">
          <button className="btn btn-secondary btn-sm btn-block text-light" type="button" onClick={props.onAddCreditCard}>
            Add card
          </button>
        </div>
      </section> :
      <h4 className="mb-0 pt-3">
        Payment information
      </h4>
    }

    {((props.savedCards||[]).length == 0 || props.addingCreditCard) &&
      <>
        <p className="mt-0 bodysm">We accept Visa, Mastercard, Discover, or American Express debit or check cards</p>

        <section className="row">
          <div className="col-12 form-label-group">
            {renderTextInput(
              'cc.nameOnCard',
              'cc.nameOnCard',
              'Name on card',
              'Name on card',
              [required]
            )}
          </div>
          <div className="col-12 col-lg-6 form-label-group">
            {renderTextInput(
              'cc.cardNumber',
              'cc.cardNumber',
              'Card number',
              'Card number',
              [required, creditCard]
            )}
          </div>
          <div className="col-12 col-lg-3 form-label-group">
            {renderTextInput(
              'cc.expirationDate',
              'cc.expirationDate',
              'Exp. Date (MM/YY)',
              'Exp. Date (MM/YY)',
              [required, creditCardExpiration]
            )}
          </div>
          <div className="col-12 col-lg-3 form-label-group">
            {renderTextInput(
              'cc.cardSecurityCode',
              'cc.cardSecurityCode',
              'CVC',
              'CVC',
              [required, number, maxLength(4), minLength(3)]
            )}
          </div>
        </section>

        <section className="row">
          <div className="col-12">
            <button
              className="btn btn-action btn-block text-light"
              type="button"
              onClick={props.form.form.mutators.copyPersonalToBilling}>
              Use same address as above for billing address
            </button>
            <br/>
          </div>
        </section>

        <section className="row">
          <div className="col-12 form-label-group">
            {renderTextInput(
              'cc.address',
              'cc.address',
              'Address',
              'Address',
              [required]
            )}
          </div>
          <div className="col-12 col-lg-6 form-label-group">
            {renderTextInput(
              'cc.city',
              'cc.city',
              'City',
              'City',
              [required]
            )}
          </div>
          <div className="col-12 col-lg-3 form-label-group">
            <State
              name="cc.state"
              includeCanada={true}
              required={true} />
          </div>
          <div className="col-12 col-lg-3 form-label-group">
            {renderTextInput(
              'cc.zip',
              'cc.zip',
              'Postal code',
              'Postal code',
              [required, postalCode]
            )}
          </div>
          <div className="col-12 form-label-group">
            {renderCheckboxInput(
              'cc.save',
              'cc.save',
              'Save this card for future payments',
              [],
              'bodymd'
            )}
          </div>
        </section>
      </>
    }
  </>

export default PaymentInformation;
