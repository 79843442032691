import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as websiteActions from '../actions/website';
import ErrorMessage from '../components/ErrorMessage';
import { renderSelect, renderTextAreaInput, renderTextInput } from '../components/Form/RenderForm';
import PageTitle from '../components/PageTitle';
import { IAppState } from '../reducers';
import { email, required, usPhoneNumber } from '../validation';

const Contact: React.FC = () => {
  const dispatch = useDispatch() as ThunkDispatch<IAppState, undefined, Action>;
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  return (
    <div>
      <PageTitle title="Contact" />

      <div className="hero bg-primary text-light py-5">
        <div className="container">
          <div className="row pt-5">
            <div className="col-lg-8 offset-lg-2 pt-5">
              <h1 className="d-none">Contact Reliance Telephone</h1>
              <span className="page-icon rt-contact"></span>
              <h2>At Reliance Telephone, we pride ourselves in our outstanding customer service.</h2>
            </div>
          </div>
        </div>
      </div>

      <main className="container">
        <div className="row pt-5">
          <div className="col-lg-8 offset-lg-2">
            <Form
              initialValues={{
                contact: {
                  'reason': 'General Support'
                }
              }}
              onSubmit={({contact}, form: any) =>
                dispatch(websiteActions.processContact(contact))
                  .then(() => setTimeout(() => form.restart()))
                  .then(() => {
                    setSuccess(true);
                    setError(null);
                  })
                  .catch(err => {
                    setError(err);
                    setSuccess(false);
                  })
              }>
              {form =>
                <form onSubmit={form.handleSubmit} method="post">
                  <div className="mb-4">
                    <h3>Send us an email</h3>
                  </div>

                  {success && <Alert variant="success">Your contact submission was successfully received</Alert>}
                  {error && <ErrorMessage error={error} />}

                  <div className="form-label-group">
                    {renderSelect(
                      'contact.reason',
                      'contact.reason',
                      null,
                      [
                        <option>General Support</option>,
                        <option>Account Deletion</option>,
                      ],
                      [required]
                    )}
                  </div>

                  <div className="form-label-group">
                    {renderTextInput(
                      'contact.name',
                      'contact.name',
                      'Full name',
                      'Full name',
                      [required]
                    )}
                  </div>

                  <div className="form-label-group">
                    {renderTextInput(
                      'contact.email',
                      'contact.email',
                      'Email address',
                      'Email address',
                      [required, email]
                    )}
                  </div>

                  <div className="form-label-group">
                    {renderTextInput(
                      'contact.phone',
                      'contact.phone',
                      'Phone number',
                      'Phone number',
                      [usPhoneNumber, required]
                    )}
                  </div>

                  <div className="form-label-group">
                    {renderTextInput(
                      'contact.subject',
                      'contact.subject',
                      'Subject',
                      'Subject',
                      [required]
                    )}
                  </div>

                  <div className="form-label-group">
                    {renderTextAreaInput(
                      'contact.message',
                      'contact.message',
                      'Questions and/or comments',
                      null,
                      4,
                      [required]
                    )}
                  </div>

                  <button className="btn btn-lg btn-action btn-block" type="submit">Send</button>
                </form>
              }
            </Form>
          </div>
        </div>
      </main>

      <div className="container bg-light rounded p-5 my-5">
        <div className="row pt-5">
          <div className="col-lg-8 offset-lg-2">
            <h4>For questions about inmate wallet (texting) and phone cards, please use the form above or send us an email at <a href="">cs@reliancetelephone.com</a></h4>
            <h4 className="pt-5 pb-3">We’re glad to be able to assist prepaid collect call customers over the phone from 9AM - 6PM Monday through Friday.</h4>
            <div className="row text-dark">
              <div className="col-md-6">
                <p className="font-weight-bold mb-0">Prepaid Collect Call Help Line</p>
                <a href="tel:1-800-896-3201">800-896-3201</a>
              </div>
              <div className="col-md-6">
                <p className="font-weight-bold mb-0">Fax Number</p>
                <a href="tel:1-701-772-3757">701-772-3757</a>
              </div>
            </div>
            <h4 className="pt-5 pb-3">If you are staff at a correctional facility, we have a dedicated line to help answer any questions or concerns you have.</h4>
            <div className="row text-dark">
              <div className="col-md-6">
                <p className="font-weight-bold mb-0">Correctional Facility Service Line</p>
                <a href="tel:1-888-773-6408">888-773-6408</a>
              </div>
              <div className="col-md-6">
                <p className="font-weight-bold mb-0">Correctional Facility Service Email</p>
                <a href="mailto:facility@reliancetelephone.com">facility@reliancetelephone.com</a>
              </div>
            </div>
            <hr/>
            <div className="row pt-2 pb-5">
              <div className="col-md-6">
                <span></span>
                <div id="map">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2674.598754375868!2d-97.08956568435907!3d47.90545097920563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52c681015e84ed59%3A0xf94643813457446b!2s1533%20S%2042nd%20St%2C%20Grand%20Forks%2C%20ND%2058201!5e0!3m2!1sen!2sus!4v1574098273049!5m2!1sen!2sus" frameBorder="0" style={{border:0}} allowFullScreen={false}></iframe>
                </div>
              </div>
              <div className="col-md-6 text-dark pt-5 pt-lg-2">
                <p className="font-weight-bold mb-0">Physical Address</p>
                <address className="">
                  <a href="https://www.google.com/maps/place/Reliance+Telephone,+Inc./@47.9053226,-97.0896597,17z/data=!3m1!4b1!4m5!3m4!1s0x52c681015d94bad3:0x855eb5ffd7111b7!8m2!3d47.905319!4d-97.087471">
                  Reliance Telephone, Inc.<br/>
                  1533 S. 42nd St<br/>
                  Grand Forks, ND 58201-3746</a>
                </address>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact;
