import React from 'react';
import { Facility } from '../../../models/Facility';
import { IOnlineUserAccount } from '../../../models/OnlineUserAccount';
import NameAndAddress from '../../Facility/NameAndAddress';
import Currency from '../../Format/Currency';
import PhoneNumber from '../../Format/PhoneNumber';

interface IInfoBoxProps {
  facility: Facility
  account?: IOnlineUserAccount
  amount?: number
  fees?: number
}

const InfoBox: React.FC<IInfoBoxProps> = props =>
  <div className="col-md-4 mb-3 rt-status">
    <aside className="bg-secondary text-light rounded p-3 p-lg-5 h-100">
      <h4>You've selected:</h4>

      <div className="selected-service py-lg-2">
        <h5>Prepaid Collect Call</h5>

        {props.amount &&
          <div className="checkout-fees">
            <p className="total"><Currency value={props.amount} /></p>
            <p className="transaction-fee"><Currency value={props.fees} /></p>
            <p className="total-charge"><Currency value={props.amount + props.fees} /></p>
          </div>
        }

        <p className="bodysm">
          Collect call accounts are on a prepayment basis and you must maintain a positive credit balance to keep your line open. Any calls received that exceed account balance will be billed to your account and due immediately.
        </p>
        <p className="bodysm">
          Payments to your collect call account can be made online or over the phone by calling 1-800-896-3201. Live representatives are available Monday-Friday 9am to 6pm CST.
        </p>
      </div>
      {props.account && (
        <>
          <hr className="border-light" />
          <div className="selected-account py-lg-2">
            <h5>For account:</h5>
            <p><PhoneNumber value={props.account.ani} /></p>
          </div>
        </>
      )}
      {props.facility && (
        <>
          <hr className="border-light" />
          <div className="selected-facility py-lg-2">
            <h5>At facility:</h5>
            <NameAndAddress facility={props.facility} />
          </div>
        </>
      )}
    </aside>
  </div>

export default InfoBox;
