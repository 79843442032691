import { IFacility } from '../models/Facility';
import { IScheduleAvailability, IVisitation, IVisitationScheduleRequest, IVisitationScheduleResponse } from '../models/Visitation';
import { IVisitationData } from '../reducers/visitation/schedule';
import Api, { IPaginatedResponse } from '../services/api';

export function index(page = 1, perPage = 250) {
  return Api
    .get<IPaginatedResponse<IVisitation>>({
      url: '/visitation',
      query: {page, perPage}
    });
}

export function availability(facilityId: number, inmateId: number) {
  return Api
    .get({
      url: '/schedule/availability',
      query: { facilityId, inmateId }
    })
    .then((res: IScheduleAvailability[]) => res);
}

export function post(facility: IFacility, visitationData: IVisitationData) {
  const scheduleRequest: IVisitationScheduleRequest = {
    outsideParty: visitationData.visitorInformation,
    visitation: {
      inmateId: visitationData.inmate.id,
      facilityId: facility.id,
      startDate: visitationData.timeslot.reservationStartDate.date
    }
  };

  return Api
    .post<IVisitationScheduleResponse>({
      url: '/visitation',
      data: scheduleRequest
    });
}

export function cancel(visitation: IVisitation) {
  return Api
    .post<any>({
      url: '/visitation/cancel',
      data: { id: visitation.id, onlineUserId: 0 }
    });
}