import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { ICreditCard } from '../../models/CreditCard';
import { ButtonSize, renderDangerSubmit, renderSecondarySubmit } from '../Form/RenderForm';

interface IRemoveModalProps {
  creditCard: ICreditCard
  removing: boolean
  onCancel: () => void
  onRemove: () => void
}

const RemoveModal: React.FC<IRemoveModalProps> = props =>
  <Modal show={true} onHide={props.onCancel}>
    <Modal.Header closeButton>
      <Modal.Title>Remove saved card</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      Are you sure you want to remove {props.creditCard.type} ending in {props.creditCard.lastFour}?
    </Modal.Body>

    <Modal.Footer>
      {renderSecondarySubmit(null, 'Cancel', 'Cancel', ButtonSize.Default, props.onCancel, props.removing)}
      {renderDangerSubmit(null, 'Remove saved card', 'Removing...', ButtonSize.Default, true, props.onRemove, props.removing)}
    </Modal.Footer>
  </Modal>

export default RemoveModal
