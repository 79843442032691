import history from 'history';
import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { IAppState } from '../reducers';

function isActiveShow(location: history.Location, linkUrl: string, show: string|JSX.Element) {
  if (location.pathname == linkUrl) {
    return show;
  }
  return null;
}

function renderOption(location: history.Location, linkPath: string, content: string|JSX.Element, onClick: () => void): JSX.Element {
  return (
    <li className={`${isActiveShow(location, linkPath, 'active')} nav-item text-center`} key={linkPath}>
      <Link className="nav-link text-center" to={linkPath} onClick={onClick}>
        {content} {isActiveShow(location, linkPath, <span className="sr-only">(current)</span>)}
      </Link>
    </li>
  );
}

interface IHeaderProps {
  errorMode: boolean
}

const Header: React.FC<IHeaderProps> = props => {
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);

  const state = useSelector((appState: IAppState) => ({
    auth: appState.auth
  }));

  const options = [
    { linkPath: '/', content: 'Home' },
    state.auth.loggedIn ? { linkPath: '/account/dashboard', content: 'My Account' } : undefined,
    { linkPath: '/services', content: 'Communication Options' },
    { linkPath: '/about', content: 'About Reliance' },
    { linkPath: '/contact', content: 'Contact' },
    { linkPath: '/support', content: <b>Support | FAQ</b> }
  ];

  return (
    <Navbar bg="primary" expand="lg" variant="dark" className="fixed-top border-bottom border-info" expanded={expanded}>
      <Container>
        <a className="navbar-brand" title="Reliance Telephone, Inc." href="/"><span>Reliance Telephone, Inc.</span></a>

        {!props.errorMode &&
          <>
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
            <Navbar.Collapse id="navbarMain">
              <Nav>
                {options.map(option => option && renderOption(location, option.linkPath, option.content, () => setTimeout(() => {setExpanded(false)}, 100)))}
                <li className="nav-item btnlink">
                  {!state.auth.loggedIn && <Link to="/login" className="nav-link btnlink-rev text-center" onClick={() => setTimeout(() => {setExpanded(false)}, 100)}>Sign In</Link>}
                  {state.auth.loggedIn && <Link to="/logout" className="nav-link btnlink-rev text-center" onClick={() => setTimeout(() => {setExpanded(false)}, 100)}>Sign Out</Link>}
                </li>
              </Nav>
            </Navbar.Collapse>
          </>
        }
      </Container>
    </Navbar>
  );
}

export default Header;
