import React from 'react';
import { email, postalCode, required, usPhoneNumber } from '../../validation';
import { renderTextInput } from '../Form/RenderForm';
import State from '../Form/State';

export interface IPersonalInformationData {
  firstName: string
  lastName: string
  address: string
  city: string
  state: string
  zip: string
  emailAddress: string
  receiptEmailAddress?: string
  phoneNumber: string
  updateAccountSettings: boolean
}

const PersonalInformation: React.FC = () =>
  <>
    <h4 className="pt-3 pt-lg-5">Personal Information</h4>
    <section className="row">
      <div className="col-12 col-lg-6 form-label-group">
        {renderTextInput(
          'personalInformation.firstName',
          'firstName',
          'First name',
          'First name',
          [required]
        )}
      </div>
      <div className="col-12 col-lg-6 form-label-group">
        {renderTextInput(
          'personalInformation.lastName',
          'lastName',
          'Last name',
          'Last name',
          [required]
        )}
      </div>
      <div className="col-12 form-label-group">
        {renderTextInput(
          'personalInformation.address',
          'address',
          'Address',
          'Address',
          [required]
        )}
      </div>
      <div className="col-12 col-lg-6 form-label-group">
        {renderTextInput(
          'personalInformation.city',
          'city',
          'City',
          'City',
          [required]
        )}
      </div>
      <div className="col-12 col-lg-3 form-label-group">
        <State
          name="state"
          required={true}
          includeCanada={true} />
      </div>
      <div className="col-12 col-lg-3 form-label-group">
        {renderTextInput(
          'personalInformation.zip',
          'zip',
          'Postal code',
          'Postal code',
          [required, postalCode]
        )}
      </div>
      <div className="col-6 form-label-group">
        {renderTextInput(
          'personalInformation.receiptEmailAddress',
          'receiptEmailAddress',
          'Email address for receipt',
          'Email address for receipt',
          [required, email]
        )}
      </div>
      <div className="col-6 form-label-group">
        {renderTextInput(
          'personalInformation.phoneNumber',
          'phoneNumber',
          'Phone number',
          'Phone number',
          [required, usPhoneNumber]
        )}
      </div>
      {/* TODO: Add checkbox component to update online user details to have the above */}
    </section>
  </>

export default PersonalInformation;
