import * as collectActions from '../../actions/checkout/collect';
import * as collectReceiptActions from '../../actions/collect/receipt';
import { IPaymentData } from '../../components/Checkout/Collect/Payment';
import { ICreditCardPayment } from '../../models/Transaction';
import { ApiError } from '../../services/api';

export interface ICollectReceiptState {
  receiptEmailAddress?: string
  payment?: ICreditCardPayment
  paymentLoading: boolean
  paymentLoadError?: ApiError
}

const initialState:ICollectReceiptState = {
  paymentLoading: false
};

export function collectReceiptReducer(state: ICollectReceiptState = initialState, action): ICollectReceiptState {
  switch (action.type) {
    case collectReceiptActions.LOAD_PAYMENT_LOADING:
      return {
        ...state,
        payment: null,
        paymentLoading: true,
        paymentLoadError: null
      };

    case collectReceiptActions.LOAD_PAYMENT_FAILURE:
      return {
        ...state,
        payment: null,
        paymentLoading: false,
        paymentLoadError: action.error
      };

    case collectReceiptActions.LOAD_PAYMENT_SUCCESS:
      return {
        ...state,
        payment: action.payment,
        paymentLoading: false,
        paymentLoadError: null
      };

    case collectActions.PAYMENT_SUCCESS:
      const paymentData: IPaymentData = action.paymentData;
      return {
        ...state,
        payment: action.payment,
        receiptEmailAddress: paymentData.receiptEmailAddress
      };

    case collectReceiptActions.RESET:
      return initialState;
  }

  return state;
}
