import { FORM_ERROR } from 'final-form';
import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, RouteChildrenProps } from 'react-router';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as loginActions from '../../actions/auth';
import * as checkoutActions from '../../actions/checkout';
import * as walletActions from '../../actions/checkout/wallet';
import * as creditCardActions from '../../actions/credit-card';
import * as inmateActions from '../../actions/inmate';
import * as onlineUserActions from '../../actions/online-user';
import * as taxActions from '../../actions/tax';
import { FacilityFeature } from '../../models/Facility';
import { TaxAffectsType } from '../../models/Tax';
import { AffectsType, TransactionFee } from '../../models/Transaction';
import { IAppState } from '../../reducers';
import ErrorMessage from '../ErrorMessage';
import LoginOrRegister from './LoginOrRegister';
import Container from './Wallet/Container';
import Details from './Wallet/Details';
import Payment from './Wallet/Payment';

function getFees(feeTable: TransactionFee[], amount: number) {
  return Math.round(
    feeTable.reduce((prev, cur) => prev + cur.getFee(AffectsType.WalletPayment, amount), 0) * 100
  ) / 100;
}

interface IWalletUrlParams {
  ani?: string
}

const Wallet: React.FC<RouteChildrenProps<IWalletUrlParams>> = props => {
  const state = useSelector((appState: IAppState) => ({
    checkout: appState.checkout,
    auth: appState.auth,
    common: appState.common
  }));
  const dispatch: ThunkDispatch<IAppState, undefined, Action> = useDispatch();

  useEffect(() => {
    // If we have received an inmate ANI, load relevant information
    if (props.match.params.ani) {
      // Wait until we have the full facility list
      if (state.common.facilities != null) {
        dispatch(walletActions.loadInmateByAni(props.match.params.ani))
          .then((inmate) => dispatch(inmateActions.loadInmates(inmate.facility.id)))
          .catch(() => {
            dispatch(walletActions.reset());
            props.history.push('/')
          });
      }
    } else {
      dispatch(walletActions.clearInmateAni());
    }

    // Load the inmates if we didn't receive a specific one
    if (props.match.params.ani == null && state.checkout.common.facility && state.checkout.wallet.inmates == null) {
      dispatch(inmateActions.loadInmates(state.checkout.common.facility.id));
    }

    // Reset data when leaving
    return function cleanupWalletCheckout() {
      dispatch(walletActions.reset());
    }
  }, [state.common.facilities]);

  useEffect(() => {
    // Load saved credit cards
    if (state.auth.loggedIn && state.checkout.common.savedCreditCards == null) {
      dispatch(creditCardActions.load());
    }
  }, [state.auth.loggedIn]);

  // If we have a payment completed, display it as the other items will be cleared out at this point
  if (state.checkout.wallet.payment) {
    return <Redirect to={{
      pathname: `/checkout/wallet/receipt/${state.checkout.wallet.payment.id}`,
      state: { payment: state.checkout.wallet.payment }
    }} />
  }

  // Display a loading indication if we are loading an inmate by ANI
  if (state.common.loadingFacilities || state.checkout.wallet.loadingInmateByAni || (props.match.params.ani && state.checkout.common.facility == null)) {
    return (
      <Container
        onResetService={() => dispatch(checkoutActions.selectService(null))}
        facility={null}
        paymentData={null}>
        <Skeleton height={200} />
      </Container>
    );
  }

  // If this page was accessed directly, without having previously set a facility (or if the facility doesn't have this service), redirect to the facility picker
  const facility = state.checkout.common.facility;
  if (props.match.params.ani == null && facility == null) {
    return <Redirect to="/checkout/facility" />
  }
  const hasWallet = (
    facility.featureTexting ||
    facility.features.indexOf(FacilityFeature.KioskAudioCalls) > -1 ||
    facility.features.indexOf(FacilityFeature.WirelessAudioCalls) > -1 ||
    facility.features.indexOf(FacilityFeature.WirelessVideoCalls) > -1
  );
  if (facility.getConfigurationValue('FacilityOfflineMessage')) {
    return <Redirect to={`/checkout/service`} />
  }
  if (!hasWallet) {
    return <Redirect to="/checkout/facility" />
  }

  // Check if the incoming inmate is disabled (if selected from a historical payment)
  let selectedInmateDisabled = false;
  if (state.checkout.wallet.paymentData && state.checkout.wallet.paymentData.inmate && state.checkout.wallet.paymentData.inmate.status != 'Enabled') {
    selectedInmateDisabled = true;
  }

  // Start by requesting the inmate and amount details
  if (
    state.checkout.wallet.paymentData == null ||
    state.checkout.wallet.paymentData.amount === null ||
    state.checkout.wallet.paymentData.inmate == null ||
    selectedInmateDisabled
  ) {
    return (
      <Container
        onResetService={() => dispatch(checkoutActions.selectService(null))}
        facility={facility}
        paymentData={state.checkout.wallet.paymentData}>
        <Details
          facility={facility}
          hideInmatePicker={state.checkout.wallet.inmateAni != null}
          inmates={state.checkout.wallet.inmates}
          loadingInmates={state.checkout.wallet.inmatesLoading}
          selectedInmate={state.checkout.wallet.paymentData ? state.checkout.wallet.paymentData.inmate : null}
          onSubmit={(inmate, amount) => {
            dispatch(walletActions.setDetails(inmate, amount));
            window.scrollTo(0, 0);
          }} />
      </Container>
    )
  }

  const fees = getFees(state.common.feeTable, state.checkout.wallet.paymentData.amount);

  // Login or register next
  if (!state.auth.loggedIn) {
    return (
      <Container
        onResetService={() => dispatch(checkoutActions.selectService(null))}
        paymentData={state.checkout.wallet.paymentData}
        fees={fees}
        taxes={state.checkout.wallet.taxAmounts ? state.checkout.wallet.taxAmounts.combinedRate : null}
        loadingTaxes={state.checkout.wallet.fetchingTaxAmounts}
        facility={facility}>
        <LoginOrRegister
          serviceCssClass="inmatewallet"
          title="Inmate Wallet (Texting): Checkout"
          onLogin={(emailAddress, password) =>
            dispatch(loginActions.login(emailAddress, password))
              .then(() => window.scrollTo(0, 0))
              .catch(err => {
                return { [FORM_ERROR]: err.error ? err.error : err };
              })
          }
          onRegister={(emailAddress, password) =>
            dispatch(onlineUserActions.register(emailAddress, password))
              .then(() => window.scrollTo(0, 0))
              .catch(err => {
                return { [FORM_ERROR]: err.error ? err.error : err };
              })
          } />
      </Container>
    );
  }

  // Receive payment information
  if (state.checkout.wallet.payment == null) {
    return (
      <Container
        onResetService={() => dispatch(checkoutActions.selectService(null))}
        paymentData={state.checkout.wallet.paymentData}
        fees={fees}
        taxes={state.checkout.wallet.taxAmounts ? state.checkout.wallet.taxAmounts.combinedRate : null}
        loadingTaxes={state.checkout.wallet.fetchingTaxAmounts}
        facility={facility}>
        <>
          {state.checkout.wallet.processingError &&
            <ErrorMessage
              error={state.checkout.wallet.processingError}
              httpGenericMessage={'An error occurred while processing your payment'}
              httpCodeMessages={{
                402: 'The credit card charge was declined',
                403: 'Wallet payments are not supported at this county jail',
                423: 'This credit card has a hold on it. Please contact Reliance Telephone at 1-800-896-3201 between 9am-6pm CST.',
                406: 'Your credit card number is invalid'
              }} />
          }
          <Payment
            loggedIn={state.auth.loggedIn}
            loadingSavedCreditCards={state.checkout.common.loadingSavedCreditCards}
            savedCards={state.checkout.common.savedCreditCards}
            selectedSavedCard={state.checkout.common.selectedSavedCard}
            onCreditCardSelect={card => {
              dispatch(checkoutActions.selectSavedCreditCard(card));
              dispatch(taxActions.getTaxAmount(TaxAffectsType.WalletPaymentFees, fees, card.zip));
            }}
            onAddCreditCard={() => {
              dispatch(checkoutActions.addCreditCard());
              dispatch(taxActions.resetTaxAmount());
            }}
            onZipCodeChanged={zip => {
              if (zip.length >= 5) {
                dispatch(taxActions.getTaxAmount(TaxAffectsType.WalletPaymentFees, fees, zip));
              }
            }}
            addingCreditCard={state.checkout.common.addingCreditCard}
            onSubmit={data => {
              const paymentData = dispatch(walletActions.setPaymentDetails(data))
              return dispatch(walletActions.processPayment(
                  paymentData, state.checkout.common.selectedSavedCard
                ))
                .then(() => window.scrollTo(0, 0))
                .catch(() => window.scrollTo(0, 0))
            }}
            amount={state.checkout.wallet.paymentData.amount}
            fees={fees}
            taxes={state.checkout.wallet.taxAmounts ? state.checkout.wallet.taxAmounts.combinedRate : 0} />
        </>
      </Container>
    )
  }

  return <Redirect to="/" />
}

export default Wallet
