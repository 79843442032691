import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-final-form';
import { ICreditCardData } from '../../models/CreditCard';
import ErrorMessage from '../ErrorMessage';
import { ButtonSize, renderActionSubmit, renderSecondarySubmit } from '../Form/RenderForm';
import DetailsForm from './DetailsForm';

interface IAddModalProps {
  onCancel: () => void
  onSubmit: (data: ICreditCardData) => Promise<any>
}

const AddModal: React.FC<IAddModalProps> = props =>
  <Modal show={true} onHide={props.onCancel}>
    <Form
      onSubmit={props.onSubmit}>
      {form =>
        <form onSubmit={form.handleSubmit} method="post">
          <Modal.Header closeButton>
            <Modal.Title>Add new card</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {form.submitError &&
              <ErrorMessage error={form.submitError} />
            }

            <DetailsForm />
          </Modal.Body>

          <Modal.Footer>
            {renderSecondarySubmit(form, 'Cancel', 'Cancel', ButtonSize.Default, props.onCancel)}
            {renderActionSubmit(form, 'Save new card', 'Saving...')}
          </Modal.Footer>
        </form>
      }
    </Form>
  </Modal>

export default AddModal
