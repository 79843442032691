import React from 'react';
import { Facility, IFacility } from '../../../models/Facility';
import { IOnlineUserAccount } from '../../../models/OnlineUserAccount';
import PageTitle from '../../PageTitle';
import Navigation from '../Navigation';
import { CheckoutStep } from '../Service';
import InfoBox from './InfoBox';

interface IContainerProps {
  facility: IFacility
  account?: IOnlineUserAccount
  amount?: number
  fees?: number
  onResetService: () => void
}

const Container: React.FC<IContainerProps> = props =>
  <>
    <PageTitle title="Prepaid Collect Call" />
    <Navigation step={CheckoutStep.Service} onResetService={props.onResetService} />

    <main className="container align-items-top justify-content-center mt-3 pb-4">
      <section className="no-gutters">
        <div className="main row">
          <InfoBox
            facility={props.facility ? new Facility(props.facility) : null}
            account={props.account}
            amount={props.amount}
            fees={props.fees}
            />

          <div className="col-md-8 mb-3">
            {props.children}
          </div>
        </div>
      </section>
    </main>
  </>

export default Container;
