
export interface IHomeState {
}

const initialState:IHomeState = {
};

export function homeReducer(state: IHomeState = initialState, action): IHomeState {
  return state;
}
