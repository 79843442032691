import * as checkoutActions from '../../actions/checkout';
import * as collectActions from '../../actions/checkout/collect';
import { IPaymentData } from '../../components/Checkout/Collect/Payment';
import { IOnlineUserAccount } from '../../models/OnlineUserAccount';
import { ICreditCardPayment } from '../../models/Transaction';
import { ApiError } from '../../services/api';

export interface ICheckoutCollectState {
  account?: IOnlineUserAccount
  amount?: number
  paymentData?: IPaymentData
  processing: boolean
  processingError?: ApiError

  payment?: ICreditCardPayment
  subTotal?: number
  transactionFee?: number
  total?: number
}

const initialState:ICheckoutCollectState = {
  processing: false
};

export function checkoutAccountReducer(state: ICheckoutCollectState = initialState, action): ICheckoutCollectState {
  switch (action.type) {
    case checkoutActions.SELECT_FACILITY:
      return initialState;

    case checkoutActions.SELECT_SERVICE:
      return initialState;

    case collectActions.SELECT_COLLECT_ACCOUNT:
      return {
        ...state,
        account: action.account
      };

    case collectActions.CLEAR_COLLECT_ACCOUNT:
      return {
        ...state,
        account: null
      };

    case collectActions.SET_AMOUNT:
      return {
        ...state,
        amount: action.amount
      };

    case collectActions.PAYMENT_PROCESSING:
      return {
        ...state,
        processing: true,
        processingError: null,
        paymentData: action.payment
      };

    case collectActions.PAYMENT_FAILURE:
      return {
        ...state,
        processing: false,
        processingError: action.error
      };

    case collectActions.PAYMENT_SUCCESS:
      return {
        ...state,
        payment: action.payment,
        subTotal: action.subTotal,
        transactionFee: action.transactionFee,
        total: action.total,
        processing: false
      };

    case collectActions.RESET_SENSITIVE:
      return {
        ...state,
        payment: null,

        paymentData: {
          ...state.paymentData,
          cc: null
        }
      };

    case collectActions.RESET:
      return initialState;
  }

  return state;
}
