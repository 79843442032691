import React from 'react';
import { Form } from 'react-final-form';
import { IOnlineUserAccount } from '../../../models/OnlineUserAccount';
import { maxValue, minValue, required } from '../../../validation';
import { InputType, renderSecondarySubmit, renderTextInput } from '../../Form/RenderForm';
import Currency from '../../Format/Currency';

interface IMakePaymentData {
  amount: number
}

interface IMakePaymentProps {
  account: IOnlineUserAccount
  onSubmit: (data: IMakePaymentData) => void
}

const MakePayment: React.FC<IMakePaymentProps> = props =>
  <div className="border border-dark rounded h-100 p-5 d-flex flex-column justify-content-center">
    <h4 className="mb-4">Make a payment</h4>
    <p>Amount must be between $0.25 and $500</p>
    <div>
      <Form
        onSubmit={props.onSubmit}>
        {form =>
          <form onSubmit={form.handleSubmit} method="post">
            <div className="form-label-group">
              {renderTextInput(
                'makePayment.amount',
                'amount',
                'Min. $0.25 - Max. $500',
                'Amount',
                [required, minValue(0.25), maxValue(500)],
                InputType.number
              )}
            </div>
            {props.account.neededToOpen < 0 &&
              <p>You must pay at least <strong><Currency value={props.account.neededToOpen * -1} /></strong> to unblock your account to allow phone calls to continue</p>
            }
            {renderSecondarySubmit<IMakePaymentData>(form, 'Continue')}
          </form>
        }
      </Form>
    </div>
  </div>

export default MakePayment
