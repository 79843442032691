import React from 'react';
import { IOnlineUserAccount } from '../../../models/OnlineUserAccount';
import ExistingNumberRow from './ExistingNumberRow';

interface ISelectExistingNumberProps {
  accounts: IOnlineUserAccount[]
  onSelect(account: IOnlineUserAccount): void
  onVerify(account: IOnlineUserAccount): void
}

const SelectExistingNumber: React.FC<ISelectExistingNumberProps> = props =>
  <>
    <h4>Select existing prepaid number</h4>
    {props.accounts.map(a =>
      <ExistingNumberRow
        key={a.id}
        account={a}
        onVerify={props.onVerify}
        onSelect={props.onSelect} />
    )}
  </>

export default SelectExistingNumber;
