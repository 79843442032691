import React from 'react';
import { IOnlineUserAccount } from '../../../models/OnlineUserAccount';
import { maxLength, number, required } from '../../../validation';
import { renderTextInput } from '../../Form/RenderForm';

interface IVerifyProps {
  account: IOnlineUserAccount
  showResendVerificationCode: () => void
}

const Verify: React.FC<IVerifyProps> = props =>
  <div className="form-label-group">
    {renderTextInput(
      'verifyAccount.code',
      'verifyAccount.code',
      'Code',
      'Verification code',
      [required, number, maxLength(6)]
    )}
    <a href="#" onClick={() => {
      props.showResendVerificationCode();
      return false;
    }}>I didn't receive the verification code</a>
  </div>

export default Verify