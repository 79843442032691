import moment, { Moment } from 'moment';
import React from 'react';
import _ from 'underscore';
import { IScheduleAvailability } from '../../models/Visitation';
import VisitationWindowBlock from './VisitationWindowBlock';

interface IVisitationWindowDayProps {
  day: Moment,
  availability: IScheduleAvailability[],
  startTime: number,
  endTime: number,
  onBlockSelect(block): void
  selectedBlock: IScheduleAvailability
}

class VisitationWindowDay extends React.Component<IVisitationWindowDayProps> {
  handleBlockSelect(block) {
    this.props.onBlockSelect(block);
  }

  // Calculate the number of half hour increments between the start and end times
  // If this gets changed, update Relcore Central's logic to match
  calculateNumRows(startTime, endTime) {
    return ((endTime - startTime)/50) + 1;
  }

  // If this gets changed, update Relcore Central's logic to match
  filterAvailability(currentDay, availability) {
    return availability.filter(a =>
      moment(currentDay).startOf('day').isSame(moment(a.reservationStartDate.date).startOf('day'))
    );
  }

  // If this gets changed, update Relcore Central's logic to match
  groupByTimeOfDay(availability) {
    return _.groupBy(availability, a => {
      let aDate = moment(a.reservationStartDate.date)
      return parseInt(`${aDate.hour()}${("00" + Math.floor(aDate.minute()/60*100)).slice(-2)}`)
    });
  }

  render() {
    let numRows = this.calculateNumRows(this.props.startTime, this.props.endTime)
    let blockAvailability = this.groupByTimeOfDay(
      this.filterAvailability(this.props.day, this.props.availability)
    );

    let buildRow = (time) => {
      let match = blockAvailability[time];

      let boundClick;
      if (match != null && match[0].status == 'available') {
        boundClick = () => this.props.onBlockSelect(match[0]);
      } else {
        boundClick = null;
      }

      return (
        <VisitationWindowBlock
          key={`${this.props.day.day()}_${time}`}
          closed={match == null}
          open={match != null && match[0].status == 'available'}
          time={time}
          selected={match && this.props.selectedBlock == match[0]}
          onClick={boundClick} />
      );
    };

    return (
      <div className="col-lg-3 text-center pt-4">
        <h6>{this.props.day.format('ddd, MMM D')}</h6>
        {Array.from(Array(numRows).keys()).map((time) =>
          buildRow(this.props.startTime+(time*50))
        )}
      </div>
    );
  }
}

export default VisitationWindowDay;
