import { Service } from '.';
import * as checkoutActions from '../../actions/checkout';
import * as cardCheckoutActions from '../../actions/checkout/card';
import * as collectCheckoutActions from '../../actions/checkout/collect';
import * as walletCheckoutActions from '../../actions/checkout/wallet';
import * as creditCardActions from '../../actions/credit-card';
import { ICreditCard } from '../../models/CreditCard';
import { Facility, IFacility } from '../../models/Facility';

export interface ICheckoutCommonState {
  service?: Service
  facility?: IFacility
  loadingSavedCreditCards: boolean
  savedCreditCards?: ICreditCard[]
  selectedSavedCard: ICreditCard
  addingCreditCard: boolean
}

const initialState:ICheckoutCommonState = {
  loadingSavedCreditCards: false,
  addingCreditCard: false,
  selectedSavedCard: null
};

export function checkoutCommonReducer(state: ICheckoutCommonState = initialState, action): ICheckoutCommonState {
  switch (action.type) {
    case checkoutActions.SELECT_FACILITY:
      return {
        ...initialState,
        facility: action.facility
      };

    case checkoutActions.SELECT_SERVICE:
      return {
        ...state,
        service: action.service,
        savedCreditCards: null,
        selectedSavedCard: null
      };

    case creditCardActions.LOADING:
      return {
        ...state,
        loadingSavedCreditCards: true
      };

    case creditCardActions.LOAD_FAILURE:
      return {
        ...state,
        loadingSavedCreditCards: false
      };

    case creditCardActions.LOAD_SUCCESS:
      return {
        ...state,
        loadingSavedCreditCards: false,
        savedCreditCards: action.creditCards
      };

    case checkoutActions.SELECT_SAVED_CREDIT_CARD:
      return {
        ...state,
        selectedSavedCard: action.creditCard,
        addingCreditCard: false
      };

    case checkoutActions.ADD_CREDIT_CARD:
      return {
        ...state,
        selectedSavedCard: null,
        addingCreditCard: true
      };

    case walletCheckoutActions.RESET:
      return initialState;

    case cardCheckoutActions.RESET:
      return initialState;

    case collectCheckoutActions.RESET:
      return initialState;

    case 'persist/REHYDRATE':
      let newState = state;

      // Set selected facility if applicable
      if (action && action.payload && action.payload.checkout && action.payload.checkout.common && action.payload.checkout.common.facility) {
        return {
          ...newState,
          facility: new Facility(action.payload.checkout.common.facility)
        };
      }

      return newState;
  }

  return state;
}
