import React from 'react';

const PhoneCardInformation = (
  <>
    <p>Inmate Phone Cards are ONLY for calls from Reliance county correctional facilities —they are not normal prepaid phone cards. Treat the PIN you receive at checkout as cash and share it with the inmate in a secure manner - do not leave it on the inmate voicemail. Be sure to write down or print the PIN as we CANNOT access it for you later.</p>
    <p>Displayed rates are for domestic (US) calls only. Actual rates may vary by number/country called. Remaining phone card balances can be transferred to Inmate Wallet where available. Reliance is not responsible for unauthorized use and we cannot reimburse you for such use. </p>
    <p>Reliance Telephone inmate phone card costs are as follows:</p>
    <p className="font-weight-bold mb-0"><u>Phone Card In-State Calls</u></p>
    <p>$0.25 - $0.50 per minute depending on the facility</p>
    <p className="font-weight-bold mb-0"><u>Phone Card State to State Calls</u></p>
    <p>$0.21 per minute + Federal Universal Service Fund</p>
  </>
)

export default PhoneCardInformation;
