import React from 'react';
import { required as requiredRule } from '../../validation';
import { renderSelect } from './RenderForm';

interface IStateProps {
  value?: string
  label?: string
  promptOption?: string
  name: string
  includeCanada: boolean
  required?: boolean
}

const usStates = {
  'AL': 'Alabama',
  'AK': 'Alaska',
  'AS': 'American Samoa',
  'AZ': 'Arizona',
  'AR': 'Arkansas',
  'CA': 'California',
  'CO': 'Colorado',
  'CT': 'Connecticut',
  'DE': 'Delaware',
  'DC': 'District Of Columbia',
  'FM': 'Federated States Of Micronesia',
  'FL': 'Florida',
  'GA': 'Georgia',
  'GU': 'Guam',
  'HI': 'Hawaii',
  'ID': 'Idaho',
  'IL': 'Illinois',
  'IN': 'Indiana',
  'IA': 'Iowa',
  'KS': 'Kansas',
  'KY': 'Kentucky',
  'LA': 'Louisiana',
  'ME': 'Maine',
  'MH': 'Marshall Islands',
  'MD': 'Maryland',
  'MA': 'Massachusetts',
  'MI': 'Michigan',
  'MN': 'Minnesota',
  'MS': 'Mississippi',
  'MO': 'Missouri',
  'MT': 'Montana',
  'NE': 'Nebraska',
  'NV': 'Nevada',
  'NH': 'New Hampshire',
  'NJ': 'New Jersey',
  'NM': 'New Mexico',
  'NY': 'New York',
  'NC': 'North Carolina',
  'ND': 'North Dakota',
  'MP': 'Northern Mariana Islands',
  'OH': 'Ohio',
  'OK': 'Oklahoma',
  'OR': 'Oregon',
  'PW': 'Palau',
  'PA': 'Pennsylvania',
  'PR': 'Puerto Rico',
  'RI': 'Rhode Island',
  'SC': 'South Carolina',
  'SD': 'South Dakota',
  'TN': 'Tennessee',
  'TX': 'Texas',
  'UT': 'Utah',
  'VT': 'Vermont',
  'VI': 'Virgin Islands',
  'VA': 'Virginia',
  'WA': 'Washington',
  'WV': 'West Virginia',
  'WI': 'Wisconsin',
  'WY': 'Wyoming'
};

const caProvinces = {
  'AB': 'Alberta',
  'BC': 'British Columbia',
  'MB': 'Manitoba',
  'NB': 'New Brunswick',
  'NL': 'Newfoundland and Labrador',
  'NS': 'Nova Scotia',
  'ON': 'Ontario',
  'PE': 'Prince Edward Island',
  'QC': 'Quebec',
  'SK': 'Saskatchewan',
  'NT': 'Northwest Territories',
  'NU': 'Nunavut',
  'YT': 'Yukon'
};

const State: React.FC<IStateProps> = props => {
  const { label, promptOption, includeCanada, name, required } = props

  let caOptions
  if (includeCanada) {
    caOptions = (
      <optgroup label="Canada" key="canada">
        {Object.keys(caProvinces).map(abbr =>
          <option key={abbr} value={abbr}>{caProvinces[abbr]}</option>
        )}
      </optgroup>
    )
  }

  const options = [
    <option value="" key="emptyState">{promptOption}</option>,
    <optgroup label="United States" key="usa">
      {Object.keys(usStates).map(abbr =>
        <option key={abbr} value={abbr}>{usStates[abbr]}</option>
      )}
    </optgroup>
  ];

  if (caOptions) {
    options.push(caOptions);
  }

  return renderSelect(
    name,
    name,
    label,
    options,
    required ? [requiredRule] : []
  );
};

State.defaultProps = {
  promptOption: 'State',
  includeCanada: false
};

export default State;