interface IApiErrorResponseDetails {
  code: number
  message: string
}

export interface IApiErrorResponse {
  error: IApiErrorResponseDetails
}

export interface IApiSuccessResponse<T> {
  success: boolean
  data?: T
}

export class ApiErrorResponse extends Error {
  error: IApiErrorResponseDetails

  constructor(res: IApiErrorResponse) {
    super(res.error.message);
    this.error = res.error;
  }
}

export function isErrorResponse(response: any | IApiErrorResponse): response is IApiErrorResponse {
  return (response as IApiErrorResponse).error !== undefined;
}