import React from 'react';
import { Form } from 'react-final-form';
import { maxValue, minValue, number, required } from '../../../validation';
import { ButtonSize, renderActionSubmit, renderTextInput } from '../../Form/RenderForm';

interface IAmountData {
  amount: number
}

interface IAmountProps {
  onSubmit: (data: IAmountData) => void
}

const AmountInput: React.FC<IAmountProps> = props =>
  <Form
    onSubmit={props.onSubmit}>
    {form =>
      <form onSubmit={form.handleSubmit} method="post">
        <h3 className="mt-5">Enter amount</h3>
        <p>Amount must be between $0.25 and $500</p>
        <div className="row my-lg-4 no-gutters">
          <div className="form-label-group col-lg-8">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              {renderTextInput(
                'amount',
                'amount',
                'Amount',
                null,
                [required, number, minValue(0.25), maxValue(500)]
              )}
            </div>
          </div>
          <div className="col-lg-3 offset-lg-1 form-button-action">
            {renderActionSubmit(form, 'Continue', 'Continue', ButtonSize.Large)}
          </div>
        </div>
      </form>
    }
  </Form>

export default AmountInput
