import * as checkoutActions from '../../actions/checkout';
import * as cardActions from '../../actions/checkout/card';
import * as inmateActions from '../../actions/inmate';
import * as taxActions from '../../actions/tax';
import { ICardPurchaseData } from '../../components/Checkout/Card/Details';
import { IInmate } from '../../models/Inmate';
import { ICardTypeAvailable, IPhoneCardPurchase } from '../../models/PhoneCard';
import { ITaxAmountResponse } from '../../resources/Tax';
import { ApiError } from '../../services/api';

export interface ICheckoutCardState {
  detailsSet: boolean
  cardTypes?: ICardTypeAvailable[]
  loadingCardTypes: boolean
  purchaseData?: ICardPurchaseData
  purchase?: IPhoneCardPurchase
  processing: boolean
  processingError?: ApiError
  inmates?: IInmate[]
  inmatesLoading: boolean
  fetchingTaxAmounts: boolean
  taxAmounts?: ITaxAmountResponse
}

const initialState:ICheckoutCardState = {
  processing: false,
  detailsSet: false,
  inmatesLoading: false,
  loadingCardTypes: false,
  fetchingTaxAmounts: false
};

export function checkoutCardReducer(state: ICheckoutCardState = initialState, action): ICheckoutCardState {
  switch (action.type) {
    case checkoutActions.SELECT_SERVICE:
      return initialState;

    case checkoutActions.SELECT_FACILITY:
      return initialState;

    case cardActions.SET_DETAILS:
      return {
        ...state,
        purchaseData: action.details,
        detailsSet: true
      };

    case cardActions.CARD_TYPES_LOADING:
      return {
        ...state,
        loadingCardTypes: true,
        cardTypes: null
      };

    case cardActions.CARD_TYPES_LOAD_FAILURE:
      return {
        ...state,
        loadingCardTypes: false,
        cardTypes: null
      };

    case cardActions.CARD_TYPES_LOAD_SUCCESS:
      return {
        ...state,
        loadingCardTypes: false,
        cardTypes: action.cardTypes
      };

    case inmateActions.INMATES_LOADING:
      return {
        ...state,
        inmatesLoading: true,
        inmates: null
      };

    case inmateActions.INMATES_LOAD_FAILURE:
      return {
        ...state,
        inmatesLoading: false,
        inmates: null
      };

    case inmateActions.INMATES_LOAD_SUCCESS:
      return {
        ...state,
        inmatesLoading: false,
        inmates: action.inmates
      };

    case cardActions.PURCHASE_PROCESSING:
      return {
        ...state,
        processing: true,
        processingError: null
      };

    case cardActions.PURCHASE_FAILURE:
      return {
        ...state,
        processing: false,
        processingError: action.error
      };

    case cardActions.PURCHASE_SUCCESS:
      return {
        ...state,
        purchase: action.purchase,
        processing: false
      };

    case cardActions.RESET_SENSITIVE:
      return {
        ...state,
        purchase: null,
        purchaseData: {
          ...state.purchaseData,
          cc: null
        }
      };

    case taxActions.AMOUNT_FETCHING:
      return {
        ...state,
        fetchingTaxAmounts: true,
        taxAmounts: null
      };

    case taxActions.AMOUNT_FETCHING_FAILURE:
      return {
        ...state,
        fetchingTaxAmounts: false
      };

    case taxActions.AMOUNT_FETCHING_SUCCESS:
      return {
        ...state,
        fetchingTaxAmounts: false,
        taxAmounts: action.amounts
      };

    case taxActions.AMOUNT_RESET:
      return {
        ...state,
        fetchingTaxAmounts: false,
        taxAmounts: null
      };

    case cardActions.RESET:
      return initialState;
  }

  return state;
}
