import * as cardReceiptActions from '../../actions/card/receipt';
import * as cardActions from '../../actions/checkout/card';
import { ICardPurchaseData } from '../../components/Checkout/Card/Details';
import { IPhoneCardPurchase } from '../../models/PhoneCard';
import { ApiError } from '../../services/api';

export interface ICardReceiptState {
  receiptEmailAddress?: string
  purchase?: IPhoneCardPurchase
  purchaseLoading: boolean
  purchaseLoadError?: ApiError
}

const initialState:ICardReceiptState = {
  purchaseLoading: false
};

export function cardReceiptReducer(state: ICardReceiptState = initialState, action): ICardReceiptState {
  switch (action.type) {
    case cardReceiptActions.LOAD_PURCHASE_LOADING:
      return {
        ...state,
        purchase: null,
        purchaseLoading: true,
        purchaseLoadError: null
      };

    case cardReceiptActions.LOAD_PURCHASE_FAILURE:
      return {
        ...state,
        purchase: null,
        purchaseLoading: false,
        purchaseLoadError: action.error
      };

    case cardReceiptActions.LOAD_PURCHASE_SUCCESS:
      return {
        ...state,
        purchase: action.purchase,
        purchaseLoading: false,
        purchaseLoadError: null
      };

    case cardActions.PURCHASE_SUCCESS:
      const purchaseData: ICardPurchaseData = action.purchaseData;
      return {
        ...state,
        purchase: action.purchase,
        receiptEmailAddress: purchaseData.receiptEmailAddress
      };

    case cardReceiptActions.RESET:
      return initialState;
  }

  return state;
}
