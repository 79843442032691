import { IApiSuccessResponse } from '../models/Api';
import { IOnlineUserAccount } from '../models/OnlineUserAccount';
import Api from '../services/api';

export function index(page = 1, perPage = 250) {
  return Api
    .get({
      url: '/onlineuser/account',
      query: {page, perPage, includeBlockStatus: true}
    })
    .then((res: IOnlineUserAccount[]) => res);
}

export function post(ani: string, verificationMethod: 'Text'|'Call') {
  return Api
    .post({
      url: '/onlineuser/account',
      data: { ani, verificationMethod }
    })
    .then((res: IApiSuccessResponse<IOnlineUserAccount>) => res.data)
}

export function postResendVerification(ani: string, verificationMethod: 'Text'|'Call') {
  return Api
    .post({
      url: `/onlineuser/account/${ani}/resend-verification`,
      data: { verificationMethod }
    })
    .then((res: IApiSuccessResponse<IOnlineUserAccount>) => res.data)
}

export function postVerify(ani: string, code: string) {
  return Api
    .post({
      url: `/onlineuser/account/${ani}/verify`,
      data: { ani, code }
    })
    .then((res: IApiSuccessResponse<IOnlineUserAccount>) => res)
}