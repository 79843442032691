import React from 'react';
import { FraudControlStatus, ICreditCard } from '../../models/CreditCard';
import { maxLength, minLength, number, required } from '../../validation';
import ErrorMessage from '../ErrorMessage';
import { renderTextInput } from '../Form/RenderForm';

interface ICreditCardRowProps {
  creditCard: ICreditCard
  selected: boolean
  otherSelected: boolean
  onSelect: (card: ICreditCard) => void
}

function creditCardRowStyle(selected: boolean, otherSelected: boolean): string {
  const baseStyle = 'row mx-lg-0 mx-4 my-3 p-2 row bg-light rounded';

  if (selected) {
    return `${baseStyle} text-primary`;
  }

  return `${baseStyle} text-secondary`;
}

const CreditCardRow: React.FC<ICreditCardRowProps> = props =>
  <div className={creditCardRowStyle(props.selected, props.otherSelected)}>
    <div className="col-lg-4 my-2 d-flex align-self-center px-2">
      <span className="dashboard-cc-icon mr-3"></span>
      <p className="m-0 subtitle subtwo">
        <span style={{ textTransform: 'uppercase' }}>{props.creditCard.type}</span>&nbsp;
        ending in {props.creditCard.lastFour}
      </p>
    </div>
    <div className="col-lg-3 my-2 d-flex align-self-center px-2">
      <p className="m-0 subtitle subtwo">{props.creditCard.nameOnCard}</p>
    </div>
    <div className="col-lg-2 my-2 d-flex align-self-center px-1">
      <p className="m-0 subtitle subtwo">Exp {props.creditCard.expMonth}/{props.creditCard.expYear}</p>
    </div>
    {props.creditCard.fraudControlStatus != FraudControlStatus.Hold && (
      props.selected ?
        <div className="col-lg-3 my-2 d-flex align-self-center px-2">
          {renderTextInput(
            'cc.cardSecurityCode',
            'cc.cardSecurityCode',
            'CVC',
            null,
            [required, number, maxLength(4), minLength(3)]
          )}
        </div> :
        <div className="col-lg-2 my-2 d-flex align-self-center px-2">
          <button
            onClick={() => props.onSelect(props.creditCard)}
            className={props.selected ?
              "btn btn-primary btn-sm btn-block" :
              "btn btn-muted btn-sm btn-block text-light"
            }
            type="button">
            {props.selected ? 'Selected' : 'Select card'}
          </button>
        </div>
    )}

    {props.creditCard.fraudControlStatus == FraudControlStatus.Hold &&
      <ErrorMessage
        severity="danger"
        message={
          <>
            This card has a hold placed on it. Please call Reliance Customer Service at 1-800-896-3201 or you can email or text us an image of your credit card and ID to cs@reliancetelephone.com or text # (701) 484-3090. Phone calls, emails and texts will be monitored and answered during Customer Service hours: Monday-Friday 8am-6pm CST.
          </>
        } />
    }
  </div>

export default CreditCardRow
