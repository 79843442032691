import { FORM_ERROR } from 'final-form';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as loginActions from '../../actions/auth';
import * as checkoutActions from '../../actions/checkout';
import * as collectCheckoutActions from '../../actions/checkout/collect';
import * as creditCardActions from '../../actions/credit-card';
import * as onlineUserActions from '../../actions/online-user';
import * as onlineUserAccountActions from '../../actions/online-user-account';
import { AccountLinkStatus } from '../../models/Account';
import { FacilityFeature } from '../../models/Facility';
import { AffectsType } from '../../models/Transaction';
import { IAppState } from '../../reducers';
import AccountSelect from './Collect/AccountSelect';
import Container from './Collect/Container';
import Payment from './Collect/Payment';
import PaymentAmount from './Collect/PaymentAmount';
import ProcessingError from './Collect/ProcessingError';
import LoginOrRegister from './LoginOrRegister';

const Collect: React.FC = () => {
  const state = useSelector((appState: IAppState) => ({
    checkout: appState.checkout,
    auth: appState.auth,
    account: appState.account,
    common: appState.common
  }));
  const dispatch = useDispatch() as ThunkDispatch<IAppState, undefined, Action>;

  useEffect(() => {
    // Load accounts to display for the picker
    if (state.auth.loggedIn && state.account.list.accounts == null && !state.account.list.loading) {
      dispatch(onlineUserAccountActions.load());
    }

    // Load saved credit cards
    if (state.auth.loggedIn && state.checkout.common.savedCreditCards == null) {
      dispatch(creditCardActions.load());
    }
  }, [state.auth.loggedIn]);

  // Reset data when leaving
  useEffect(() => {
    return function cleanupCollectCheckout() {
      dispatch(collectCheckoutActions.reset());
    }
  }, []);

  // If we have a payment completed, display it as the other items will be cleared out at this point
  if (state.checkout.collect.payment) {
    return <Redirect to={{
      pathname: `/checkout/collect/receipt/${state.checkout.collect.payment.id}`,
      state: {
        payment: state.checkout.collect.payment,
        facility: state.checkout.common.facility,
        subTotal: state.checkout.collect.subTotal,
        transactionFee: state.checkout.collect.transactionFee
      }
    }} />
  }

  // If there is a facility selected, and this facility doesn't support collect calls, redirect to the facility picker
  if (state.checkout.common.facility && state.checkout.common.facility.features.indexOf(FacilityFeature.PrepaidCollectCalls) == -1) {
    return <Redirect to="/checkout/facility" />
  }

  // Display login/register if not authenticated yet
  if (!state.auth.loggedIn) {
    return (
      <Container
        onResetService={() => dispatch(checkoutActions.selectService(null))}
        facility={state.checkout.common.facility}>
        <LoginOrRegister
          serviceCssClass="collectcall"
          title="Prepaid Collect Call"
          onLogin={(emailAddress, password) =>
            Promise.resolve() // Resolves typescript complaints
              .then(() => dispatch(loginActions.login(emailAddress, password)))
              .then(() => window.scrollTo(0, 0))
              .catch(err => {
                return { [FORM_ERROR]: err.error ? err.error : err };
              })
          }
          onRegister={(emailAddress, password) =>
            dispatch(onlineUserActions.register(emailAddress, password))
              .then(() => window.scrollTo(0, 0))
              .catch(err => {
                return { [FORM_ERROR]: err.error ? err.error : err };
              })
          } />
      </Container>
    )
  }

  // Show account selection if not made yet
  if (
    state.checkout.collect.account == null ||
    state.checkout.collect.account.account.accountLinkStatus == AccountLinkStatus.DISABLED ||
    state.checkout.collect.account.account.accountLinkStatus == AccountLinkStatus.PAYMENT_HOLD
  ) {
    return (
      <Container onResetService={() => dispatch(checkoutActions.selectService(null))} facility={state.checkout.common.facility}>
        <AccountSelect
          add={(ani, verificationMethod) => dispatch(onlineUserAccountActions.add(ani, verificationMethod)) }
          resendVerification={(account, method: 'Call'|'Text') => dispatch(onlineUserAccountActions.resendVerificationCode(account.ani, method)) }
          verify={(ani, verificationCode) => dispatch(onlineUserAccountActions.verify(ani, verificationCode)) }
          verifyingAccount={state.account.list.verifying}
          addingNewAccount={state.account.list.adding}
          loading={state.account.list.loading}
          accounts={state.account.list.accounts}
          selectedAccount={state.checkout.collect.account}
          onAdded={account =>
            dispatch(collectCheckoutActions.setAccount(account))
          }
          onAccountSelect={account => {
            dispatch(collectCheckoutActions.setAccount(account));
            window.scrollTo(0, 0);
          }} />
      </Container>
    )
  }

  // Show amount option if not defined yet
  if (state.checkout.collect.amount == null) {
    return (
      <Container
        onResetService={() => dispatch(checkoutActions.selectService(null))}
        facility={state.checkout.common.facility}
        account={state.checkout.collect.account}>
        <PaymentAmount
          account={state.checkout.collect.account}
          onCancel={() => dispatch(collectCheckoutActions.clearAccount())}
          onSubmit={(amount) => {
            dispatch(collectCheckoutActions.setAmount(amount));
            window.scrollTo(0, 0);
          }}
          />
      </Container>
    );
  }

  const fees = Math.round(
    state.common.feeTable.reduce((prev, cur) => prev + cur.getFee(AffectsType.Collect, state.checkout.collect.amount), 0) * 100
  ) / 100;

  // Show payment information
  if (state.checkout.collect.payment == null) {
    return (
      <Container
        onResetService={() => dispatch(checkoutActions.selectService(null))}
        facility={state.checkout.common.facility}
        amount={state.checkout.collect.amount}
        fees={fees}
        account={state.checkout.collect.account}>
          <>
            {state.checkout.collect.processingError &&
              <ProcessingError error={state.checkout.collect.processingError} />
            }
            <Payment
              savedCards={state.checkout.common.savedCreditCards}
              loadingSavedCreditCards={state.checkout.common.loadingSavedCreditCards}
              selectedSavedCard={state.checkout.common.selectedSavedCard}
              onCreditCardSelect={card => dispatch(checkoutActions.selectSavedCreditCard(card))}
              onAddCreditCard={() => dispatch(checkoutActions.addCreditCard())}
              addingCreditCard={state.checkout.common.addingCreditCard}
              amount={state.checkout.collect.amount}
              fees={fees}
              onSubmit={data =>
                dispatch(collectCheckoutActions.processPayment({
                  ...data,
                  ani: state.checkout.collect.account.ani,
                  amount: state.checkout.collect.amount
                }, state.checkout.common.selectedSavedCard))
                .then(() => window.scrollTo(0, 0))
                .catch(() => window.scrollTo(0, 0))
              } />
          </>
      </Container>
    );
  }

  return <Redirect to="/" />
}

export default Collect;
