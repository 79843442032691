import React from 'react';
import { Form } from 'react-final-form';
import { IOnlineUser, IOnlineUserUpdateData } from '../../../models/OnlineUser';
import { email, postalCode, required, usPhoneNumber } from '../../../validation';
import ErrorMessage from '../../ErrorMessage';
import { renderSecondarySubmit, renderTextInput } from '../../Form/RenderForm';
import State from '../../Form/State';
import SuccessMessage from '../../SuccessMessage';

interface IPersonalInformationProps {
  updated: boolean
  onlineUser: IOnlineUser
  onSubmit: (data: IOnlineUserUpdateData) => Promise<any>
}

const PersonalInformation: React.FC<IPersonalInformationProps> = props =>
  <div className="border border-dark rounded">
    <h5 className="bg-dark text-light px-5 py-3"><span></span>Personal Information</h5>
      <Form
        initialValues={props.onlineUser}
        keepDirtyOnReinitialize={true}
        onSubmit={(data: IOnlineUserUpdateData) => props.onSubmit(data)}>
        {form =>
          <form className="p-lg-5 p-4" onSubmit={form.handleSubmit} method="post">
            {form.submitError &&
              <ErrorMessage
                error={form.submitError}
                httpCodeMessages={{
                  400: 'Invalid form input(s)',
                  409: 'Email address is already in use'
                }} />
            }

            {props.updated &&
              <SuccessMessage message="Changes were successfully saved" />
            }

            <div className="form-label-group">
              {renderTextInput(
                'personalInformation.firstName',
                'firstName',
                'First name',
                'First name',
                [required]
              )}
            </div>
            <div className="form-label-group">
              {renderTextInput(
                'personalInformation.lastName',
                'lastName',
                'Last name',
                'Last name',
                [required]
              )}
            </div>
            <div className="form-label-group">
              {renderTextInput(
                'personalInformation.emailAddress',
                'emailAddress',
                'Email address',
                'Email address',
                [required, email]
              )}
            </div>
            <div className="form-label-group">
              {renderTextInput(
                'personalInformation.phoneNumber',
                'phoneNumber',
                'Phone number',
                'Phone number',
                [required, usPhoneNumber]
              )}
            </div>
            <div className="form-label-group">
              {renderTextInput(
                'personalInformation.address',
                'address',
                'Address',
                'Address',
                [required]
              )}
            </div>
            <div className="form-label-group">
              {renderTextInput(
                'personalInformation.city',
                'city',
                'City',
                'City',
                [required]
              )}
            </div>

            <div className="row no-gutters">
              <div className="form-label-group col-md-6">
                <State name="state" includeCanada={true} required={true} />
              </div>
              <div className="col-md-5 offset-md-1 form-label-group">
                {renderTextInput(
                  'personalInformation.zip',
                  'zip',
                  'Postal code',
                  'Postal code',
                  [required, postalCode]
                )}
              </div>
            </div>

            {renderSecondarySubmit<IOnlineUserUpdateData>(form, 'Update information', 'Updating...')}
          </form>
        }
    </Form>
  </div>

export default PersonalInformation;