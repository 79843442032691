import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { IFacility } from '../../../models/Facility';
import { IInmate } from '../../../models/Inmate';
import { ICardTypeAvailable } from '../../../models/PhoneCard';
import { IAppState } from '../../../reducers';
import ErrorMessage from '../../ErrorMessage';
import { ButtonSize, renderActionSubmit } from '../../Form/RenderForm';
import { IPaymentInformationData } from '../PaymentInformation';
import { IPersonalInformationData } from '../PersonalInformation';
import InmateInformation, { IInmateInformationData } from './InmateInformation';
import PhoneCardPicker from './PhoneCardPicker';

export interface ICardPurchaseData extends IPersonalInformationData, IPaymentInformationData {
  inmate?: IInmate
  typeValueId?: number
  quantity?: number
  inmateInformation: IInmateInformationData
}

interface IDetailsProps {
  facility: IFacility
  inmates: IInmate[]
  loadingInmates: boolean
  selectedInmate: IInmate

  loadingCardTypes: boolean
  cardTypes?: ICardTypeAvailable[]
  onSubmit: (data: ICardPurchaseData) => void
}

const Details: React.FC<IDetailsProps> = props => {
  let state = useSelector((state: IAppState) => state.checkout.card);

  const [selectedInmate, selectInmate] = useState(props.selectedInmate);

  return (
    <Form
      initialValues={state.purchaseData}
      keepDirtyOnReinitialize={true}
      onSubmit={(data: ICardPurchaseData) =>
        props.onSubmit({
          ...data,
          inmate: selectedInmate
        })
      }>
      {form =>
        <form onSubmit={form.handleSubmit} method="post">
          <div className="phonecard service mainwindow border rounded border-action pb-4">
            <h3 className="product bg-action p-2 py-3 text-white"><span className="icon-button"></span>Phone Card</h3>
            <div className="col-md-10 service-window offset-md-1 d-flex align-items-center justify-content-center">
              <div className="maincontent">
                <div className="row no-gutters">
                  {form.submitError &&
                    <ErrorMessage error={form.submitError} />
                  }

                  {props.selectedInmate && props.selectedInmate.status == 'Disabled' &&
                    <ErrorMessage message="This inmate is either released or unable to receive phone cards at this time." />
                  }

                  <InmateInformation
                    facility={props.facility}
                    inmates={props.inmates}
                    loadingInmates={props.loadingInmates}
                    selectedInmate={props.selectedInmate}
                    selectInmate={selectInmate}
                    />

                  {(!props.facility.getConfigurationValue('RelayPhoneCardsViaVoicemail') || selectedInmate) &&
                    <>
                      <PhoneCardPicker cardTypes={props.cardTypes} />

                      <div className="col-12 form-button-action">
                        {renderActionSubmit<ICardPurchaseData>(form, 'Continue', 'Continue', ButtonSize.Large, false, null, props.loadingCardTypes)}
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </form>
      }
    </Form>
  )
}

export default Details
