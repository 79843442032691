import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ICreditCard, ICreditCardData, ICreditCardUpdateData } from '../models/CreditCard';
import { IAppState } from '../reducers';
import * as creditCardResource from '../resources/CreditCard';

export const LOADING = 'CREDIT_CARD/LOADING';
export const LOAD_SUCCESS = 'CREDIT_CARD/LOAD_SUCCESS';
export const LOAD_FAILURE = 'CREDIT_CARD/LOAD_FAILURE';
export function load(): ThunkAction<Promise<ICreditCard[]>, IAppState, undefined, Action> {
  return dispatch => {
    dispatch({ type: LOADING });

    return creditCardResource.index()
      .then((res): ICreditCard[] => {
        dispatch({ type: LOAD_SUCCESS, creditCards: res });
        return res;
      })
      .catch(error => {
        dispatch({ type: LOAD_FAILURE, error });
        throw error;
      })
  }
}

export const NEW_SAVING = 'CREDIT_CARD/NEW_SAVING';
export const NEW_SAVE_SUCCESS = 'CREDIT_CARD/NEW_SAVE_SUCCESS';
export const NEW_SAVE_FAILURE = 'CREDIT_CARD/NEW_SAVE_FAILURE';
export function save(creditCard: ICreditCardData): ThunkAction<Promise<any>, IAppState, undefined, Action> {
  return dispatch => {
    dispatch({ type: NEW_SAVING });

    if (creditCard.expirationDate) {
      const expParts = creditCard.expirationDate.split('/');
      const expMonth = expParts[0];
      const expYear = expParts[1];
      creditCard.expMonth = expMonth;
      creditCard.expYear = expYear.substr(-2);
    }

    return creditCardResource.post(creditCard)
      .then(() => dispatch(load()))
      .then(() => dispatch({ type: NEW_SAVE_SUCCESS }))
      .catch(error => {
        dispatch({ type: NEW_SAVE_FAILURE, error });
        throw error;
      })
  }
}

export const CHANGES_SAVING = 'CREDIT_CARD/CHANGES_SAVING';
export const CHANGES_SAVE_SUCCESS = 'CREDIT_CARD/CHANGES_SAVE_SUCCESS';
export const CHANGES_SAVE_FAILURE = 'CREDIT_CARD/CHANGES_SAVE_FAILURE';
export function saveChanges(creditCard: ICreditCardUpdateData): ThunkAction<Promise<any>, IAppState, undefined, Action> {
  return dispatch => {
    dispatch({ type: CHANGES_SAVING });

    if (creditCard.expirationDate) {
      const expParts = creditCard.expirationDate.split('/');
      const expMonth = expParts[0];
      const expYear = expParts[1];
      creditCard.expMonth = expMonth;
      creditCard.expYear = expYear.substr(-2);
    }

    return creditCardResource.put(creditCard)
      .then(() => dispatch(load()))
      .then(() => dispatch({ type: CHANGES_SAVE_SUCCESS }))
      .catch(error => {
        dispatch({ type: CHANGES_SAVE_FAILURE, error });
        throw error;
      })
  }
}

export const REMOVING = 'CREDIT_CARD/REMOVING';
export const REMOVE_SUCCESS = 'CREDIT_CARD/REMOVE_SUCCESS';
export const REMOVE_FAILURE = 'CREDIT_CARD/REMOVE_FAILURE';
export function remove(creditCard: ICreditCard): ThunkAction<Promise<any>, IAppState, undefined, Action> {
  return dispatch => {
    dispatch({ type: REMOVING });

    return creditCardResource.remove(creditCard)
      .then(() => dispatch(load()))
      .then(() => dispatch({ type: REMOVE_SUCCESS }))
      .catch(error => {
        dispatch({ type: REMOVE_FAILURE, error });
        throw error;
      })
  }
}