import React from 'react';
import { IFacilityName } from '../../models/Facility';

export interface IFacilityProps {
  facility: IFacilityName
}

const Facility: React.FC<IFacilityProps> = props =>
  <span>{props.facility.name} ({props.facility.city}, {props.facility.state})</span>

export default Facility