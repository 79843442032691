import * as loginActions from "../actions/auth";
import * as onlineUserActions from "../actions/online-user";

export interface ILoginState {
  loggingIn: boolean,
  resetPassword: boolean,
  error?: Error
}

const initialState:ILoginState = {
  loggingIn: false,
  resetPassword: false
};

export function loginReducer(state: ILoginState = initialState, action): ILoginState {
  switch (action.type) {
    case loginActions.RESET_LOGIN:
      return initialState;

    case loginActions.LOGGING_IN:
      return {
        ...state,
        resetPassword: false,
        loggingIn: true,
        error: null
      };

    case loginActions.LOGIN_FAILURE:
      return {
        ...state,
        loggingIn: false,
        error: action.error
      };

    case loginActions.LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        error: undefined
      };

    case onlineUserActions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: true
      };
  }

  return state;
}
