import React from 'react';
import { Form } from 'react-final-form';
import { email, required } from '../../validation';
import ErrorMessage from '../ErrorMessage';
import { InputType, renderActionSubmit, renderTextInput } from '../Form/RenderForm';

interface ILoginData {
  emailAddress: string
  password: string
}

interface IReturningCustomerProps {
  size?: string
  onSubmit: (data: ILoginData) => Promise<any>
}

const ReturningCustomer: React.FC<IReturningCustomerProps> = props =>
  <Form
    onSubmit={props.onSubmit}>
    {form =>
      <form className="form-account-existing" onSubmit={form.handleSubmit} method="post">
        <div className="mb-4 text-center">
          {props.size && props.size == 'small' ?
            <h4>Returning customer</h4> :
            <h3>Returning customer</h3>
          }
          <p className="bodysm">Already have an account?<br/>Sign in to continue.</p>
        </div>
        {form.submitError &&
          <ErrorMessage
            error={form.submitError}
            httpCodeMessages={{
              401: 'Your login credentials are invalid'
            }} />
        }
        <div className="form-label-group">
          {renderTextInput('login.emailAddress', 'emailAddress', 'Email address', 'Email address', [required, email], InputType.email)}
        </div>
        <div className="form-label-group">
          {renderTextInput('login.password', 'password', 'Password', 'Password', [required], InputType.password)}
        </div>
        {renderActionSubmit<ILoginData>(form, 'Log in to account', 'Logging in...')}
      </form>
    }
  </Form>

export default ReturningCustomer
