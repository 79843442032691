import React from 'react';
import { IOnlineUserAccount } from '../../../models/OnlineUserAccount';
import PhoneNumber from '../../Format/PhoneNumber';

interface IExistingNumberRowProps {
  account: IOnlineUserAccount
  onSelect(account: IOnlineUserAccount): void
  onVerify(account: IOnlineUserAccount): void
}

const ExistingNumberRow: React.FC<IExistingNumberRowProps> = props =>
  <div className="my-3 p-2 row bg-light rounded">
    <div className="col-lg-1 my-2 d-flex align-self-center px-2">
      <span className="dashboard-tab-icon collect-call mr-3"></span>
    </div>
    <div className="col-lg-7 my-2 d-flex flex-column align-self-center px-2">
      <p className="m-0 subtitle subtwo"><PhoneNumber value={props.account.ani} /></p>
    </div>
    <div className="col-lg-4 my-2 d-flex align-self-center px-2">
      {props.account.dateVerified ?
        <button className="btn btn-action btn-block" onClick={() => props.onSelect(props.account)}>Select</button> :
        <button className="btn btn-primary btn-block" onClick={() => props.onVerify(props.account)}>Verify</button>
      }
    </div>
</div>

export default ExistingNumberRow;
