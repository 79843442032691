import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IVisitation } from '../models/Visitation';
import { IAppState } from '../reducers';
import * as visitationResource from '../resources/Visitation';
import { IPaginatedResponse } from '../services/api';

export const LOADING_SCHEDULED = 'VISITATION/LOADING_SCHEDULED';
export const LOAD_SCHEDULED_SUCCESS = 'VISITATION/LOAD_SCHEDULED_SUCCESS';
export const LOAD_SCHEDULED_FAILURE = 'VISITATION/LOAD_SCHEDULED_FAILURE';
export function load(): ThunkAction<Promise<IPaginatedResponse<IVisitation>>, IAppState, undefined, Action> {
  return dispatch => {
    dispatch({ type: LOADING_SCHEDULED });

    return visitationResource.index()
      .then((paginatedResponse): IPaginatedResponse<IVisitation> => {
        dispatch({ type: LOAD_SCHEDULED_SUCCESS, scheduled: paginatedResponse });
        return paginatedResponse;
      })
      .catch(error => {
        dispatch({ type: LOAD_SCHEDULED_FAILURE, error });
        throw error;
      })
  }
}

export const CANCELLING = 'VISITATION/CANCELLING';
export const CANCEL_SUCCESS = 'VISITATION/CANCEL_SUCCESS';
export const CANCEL_FAILURE = 'VISITATION/CANCEL_FAILURE';
export function cancel(visitation: IVisitation): ThunkAction<Promise<any>, IAppState, undefined, Action> {
  return dispatch => {
    dispatch({ type: CANCELLING, visitation });

    return visitationResource.cancel(visitation)
    .then(res => {
      dispatch({ type: CANCEL_SUCCESS, visitation });
      return res;
    })
    .catch(error => {
      dispatch({ type: CANCEL_FAILURE, error });
      throw error;
    })
  }
}