import { IApiSuccessResponse } from '../models/Api';
import { TaxAffectsType } from '../models/Tax';
import Api from '../services/api';

export interface ITaxAmountResponse {
  combinedRate: number
}

export function getTaxAmount(type: TaxAffectsType, amount: number, zip: string): Promise<IApiSuccessResponse<ITaxAmountResponse>> {
  return Api
    .get({
      url: '/tax/amount',
      query: {type, amount, zipCode: zip}
    })
    .then((res:IApiSuccessResponse<ITaxAmountResponse>) => res);
}
