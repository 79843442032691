import { combineReducers } from 'redux';
import { accountsListReducer, IAccountsListState } from './list';
import { accountViewReducer, IAccountViewState } from './view';

export interface IAccountState {
  list: IAccountsListState,
  view: IAccountViewState
}

export default combineReducers<IAccountState>({
  list: accountsListReducer,
  view: accountViewReducer
});
