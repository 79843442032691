import { combineReducers } from 'redux';
import { checkoutCardReducer, ICheckoutCardState } from './card';
import { checkoutAccountReducer, ICheckoutCollectState } from './collect';
import { checkoutCommonReducer, ICheckoutCommonState } from './common';
import { checkoutWalletReducer, ICheckoutWalletState } from './wallet';

export enum Service {
  Wallet,
  PhoneCard,
  Collect,
  Visitation
}

export interface ICheckoutState {
  card: ICheckoutCardState
  collect: ICheckoutCollectState
  common: ICheckoutCommonState
  wallet: ICheckoutWalletState
}

export const checkoutReducer = combineReducers<ICheckoutState>({
  card: checkoutCardReducer,
  collect: checkoutAccountReducer,
  common: checkoutCommonReducer,
  wallet: checkoutWalletReducer
});
