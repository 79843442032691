import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IApiSuccessResponse } from '../models/Api';
import { IOnlineUserAccount } from '../models/OnlineUserAccount';
import { IAppState } from '../reducers';
import * as onlineUserAccountResource from '../resources/OnlineUserAccount';

export const LOADING = 'ONLINE_USER_ACCOUNT/LOADING';
export const LOAD_SUCCESS = 'ONLINE_USER_ACCOUNT/LOAD_SUCCESS';
export const LOAD_FAILURE = 'ONLINE_USER_ACCOUNT/LOAD_FAILURE';
export function load(): ThunkAction<Promise<IOnlineUserAccount[]>, IAppState, undefined, Action> {
  return dispatch => {
    dispatch({ type: LOADING });

    return onlineUserAccountResource.index()
      .then((data): IOnlineUserAccount[] => {
        dispatch({ type: LOAD_SUCCESS, accounts: data });
        return data;
      })
      .catch(error => {
        dispatch({ type: LOAD_FAILURE, error });
        throw error;
      })
  }
}

export const LOADING_ACCOUNT = 'ONLINE_USER_ACCOUNT/LOADING_ACCOUNT';
export const LOAD_ACCOUNT_SUCCESS = 'ONLINE_USER_ACCOUNT/LOAD_ACCOUNT_SUCCESS';
export const LOAD_ACCOUNT_FAILURE = 'ONLINE_USER_ACCOUNT/LOAD_ACCOUNT_FAILURE';
export function loadAccount(ani: string): ThunkAction<Promise<IOnlineUserAccount>, IAppState, undefined, Action> {
  return dispatch => {
    dispatch({ type: LOADING });

    return dispatch(load())
      .then(res => {
        let matches = res.filter(a => a.ani == ani);
        if (matches.length > 0) {
          dispatch({ type: LOAD_ACCOUNT_SUCCESS, account: matches[0] });
          return matches[0];
        }
        return null;
      })
      .catch(error => {
        dispatch({ type: LOAD_ACCOUNT_FAILURE, error });
        throw error;
      })
  }
}

export const ADDING = 'ONLINE_USER_ACCOUNT/ADDING';
export const ADD_SUCCESS = 'ONLINE_USER_ACCOUNT/ADD_SUCCESS';
export const ADD_FAILURE = 'ONLINE_USER_ACCOUNT/ADD_FAILURE';
export function add(ani: string, verificationMethod: 'Text'|'Call'): ThunkAction<Promise<IOnlineUserAccount>, IAppState, undefined, Action> {
  return dispatch => {
    dispatch({ type: ADDING });

    return onlineUserAccountResource.post(ani.replace(/[^0-9]/g, ''), verificationMethod)
      .then((account): IOnlineUserAccount => {
        dispatch({ type: ADD_SUCCESS, account: account });
        return account;
      })
      .catch(error => {
        dispatch({ type: ADD_FAILURE, error });
        throw error;
      })
  }
}

export const VERIFYING = 'ONLINE_USER_ACCOUNT/VERIFYING';
export const VERIFY_SUCCESS = 'ONLINE_USER_ACCOUNT/VERIFY_SUCCESS';
export const VERIFY_FAILURE = 'ONLINE_USER_ACCOUNT/VERIFY_FAILURE';
export function verify(ani: string, verificationCode: string): ThunkAction<Promise<IApiSuccessResponse<IOnlineUserAccount>>, IAppState, undefined, Action> {
  return dispatch => {
    dispatch({ type: VERIFYING });

    return onlineUserAccountResource.postVerify(ani.replace(/[^0-9]/g, ''), verificationCode)
      .then(res => {
        if (!res.success) {
          dispatch({ type: VERIFY_FAILURE, error: new Error('Invalid verification code') });
          return res;
        }
        dispatch({ type: VERIFY_SUCCESS, account: res.data });
        return res;
      })
      .catch(error => {
        dispatch({ type: VERIFY_FAILURE, error });
        throw error;
      })
  }
}

export const VERIFY_CODE_RESENDING = 'ONLINE_USER_ACCOUNT/VERIFY_CODE_RESENDING';
export const VERIFY_CODE_RESEND_SUCCESS = 'ONLINE_USER_ACCOUNT/VERIFY_CODE_RESEND_SUCCESS';
export const VERIFY_CODE_RESEND_FAILURE = 'ONLINE_USER_ACCOUNT/VERIFY_CODE_RESEND_FAILURE';
export function resendVerificationCode(ani: string, verificationMethod: 'Text'|'Call'): ThunkAction<Promise<IOnlineUserAccount>, IAppState, undefined, Action> {
  return dispatch => {
    dispatch({ type: VERIFY_CODE_RESENDING });

    return onlineUserAccountResource.postResendVerification(ani.replace(/[^0-9]/g, ''), verificationMethod)
      .then((account): IOnlineUserAccount => {
        dispatch({ type: VERIFY_CODE_RESEND_SUCCESS, account: account });
        return account;
      })
      .catch(error => {
        dispatch({ type: VERIFY_CODE_RESEND_FAILURE, error });
        throw error;
      })
  }
}

export const RESET = 'ONLINE_USER_ACCOUNT/RESET';
interface IResetAction {
  type: typeof RESET
}
export function reset(): IResetAction {
  return { type: RESET }
}