import React from 'react';
import { Link } from 'react-router-dom';
import shouldShowInmateNumber from '../../../functions/shouldShowInmateNumber';
import { Facility, IFacility } from '../../../models/Facility';
import { IWalletPayment } from '../../../models/WalletPayment';
import NameAndAddress from '../../Facility/NameAndAddress';
import Currency from '../../Format/Currency';
import PhoneNumber from '../../Format/PhoneNumber';

interface IConfirmationProps {
  facility: IFacility
  payment: IWalletPayment
  receiptEmailAddress: string
}

const Confirmation: React.FC<IConfirmationProps> = props =>
  <div className="main row">
    <div className="col-md-6 offset-md-3 mb-3 ">
      <h3 className="text-center">Transaction complete</h3>
      {props.receiptEmailAddress &&
        <p className="bodysm text-center">
          A receipt has been emailed to {props.receiptEmailAddress}
        </p>
      }
    </div>
    <div className="col-md-6 offset-md-3 mb-3 rt-status receipt">
      <aside className="bg-light text-dark rounded p-3 py-5 p-lg-5">
        <h3 className="text-action text-center">Your purchase:</h3>

        <div className="selected-service py-lg-2">
          <h5 className="text-center">Inmate Wallet (Texting)</h5>
          <div className="checkout-fees">
            <p className="total">
              <Currency value={props.payment.amount} />
            </p>
            <p className="transaction-fee">
              <Currency value={props.payment.processingFee} />
            </p>
            <p className="taxes">
              <Currency value={props.payment.totalTax} />
            </p>
            <p className="total-charge">
              <Currency value={props.payment.charge} />
            </p>
          </div>
          <p className="bodysm">
            The funds paid into an Inmate Wallet account are tied directly to an inmate’s personal device for them to use for texting, and video and voice calls where available. These funds are non-transferable and non-refundable.
          </p>
        </div>
        <hr className="border-muted"/>
        <div className="selected-facility py-lg-2">
          <h5>For inmate:</h5>

          <p className="subtitle subhead inmate-name">
            {props.payment.inmate.firstName} {props.payment.inmate.lastName}
          </p>

          {props.payment.inmate.account &&
            <p className="inmate-phonenum">#{props.payment.inmate.account}</p>
          }

          {shouldShowInmateNumber(props.facility, props.payment.inmate) &&
            <>
              <p className="subtitle subhead inmate-txtdev">Texting Device #</p>
              <p className="inmate-textdevnum">
                <a href={`sms:+1${props.payment.inmate.device.smsNumber.number}`}>
                  <PhoneNumber value={props.payment.inmate.device.smsNumber.number} />
                </a>
              </p>
            </>
          }
        </div>
        <hr className="border-muted"/>
        <div className="selected-facility py-lg-2">
          <h5>At Facility:</h5>
          <NameAndAddress facility={new Facility(props.payment.inmate.facility)} />
        </div>
      </aside>
    </div>
    <div className="col-md-6 offset-md-3 " style={{marginBottom: 15}}>
      <button className="btn btn-block btn-outline-secondary btn-lg text-secondary py-2 my-3 print" type="submit" onClick={() => window.print()}>
        <strong>Print Receipt</strong>
      </button>

      <Link className="btn btn-action btn-block btn-lg text-white py-3" to="/account/dashboard"><strong>View account dashboard</strong></Link>
    </div>
  </div>

export default Confirmation