import React from 'react';
import { Modal } from 'react-bootstrap';

type UpcomingMaintenanceProps = {
  startTimes: string
  endTimes: string
  onClose(): void
}

const UpcomingMaintenance: React.FC<UpcomingMaintenanceProps> = (props) =>
  <Modal show={true} onHide={props.onClose}>
    <Modal.Header closeButton>
      <Modal.Title>
        Upcoming Maintenance
      </Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <p>We will be performining scheduled maintenance from {props.startTimes} to approximately {props.endTimes}</p>
    </Modal.Body>

    <Modal.Footer>
      <button
        onClick={props.onClose}
        className={`btn btn-block btn-primary`}>
        Close
      </button>
    </Modal.Footer>
  </Modal>

export default UpcomingMaintenance;