import React from 'react';
import { Modal } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import TabletPhoneFeatures from '../../../images/tablet_phone_features.jpg';

interface IWalletFeaturesModalProps extends RouteComponentProps {
  show: boolean
  onHide: () => void
}

const WalletFeaturesModal: React.FC<IWalletFeaturesModalProps> = props =>
  <Modal show={props.show} size="xl" onHide={props.onHide}>
    <Modal.Header closeButton />
    <Modal.Body className="p-5">
      <img src={TabletPhoneFeatures} style={{ maxWidth: '100%', maxHeight: '100%' }} />
    </Modal.Body>
  </Modal>

export default withRouter(WalletFeaturesModal);
