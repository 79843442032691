import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { collectReceiptReducer, ICollectReceiptState } from './receipt';

const collectPersistConfig = {
  key: 'collect',
  timeout: 60 * 60,
  storage,
  whitelist: ['receipt']
}

export interface ICollectState {
  receipt: ICollectReceiptState
}

export const collectReducer = persistReducer(
  collectPersistConfig,
  combineReducers<ICollectState>({
    receipt: collectReceiptReducer
  })
) as any; // Casting resolves a typescript complaint that doesn't make sense...
