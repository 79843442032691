import { FORM_ERROR } from 'final-form';
import React, { useState } from 'react';
import { AccountLinkStatus } from '../../../models/Account';
import { IApiSuccessResponse } from '../../../models/Api';
import { IOnlineUserAccount } from '../../../models/OnlineUserAccount';
import AddModal from '../../Authenticated/Account/AddModal';
import LoadingRow from '../../Authenticated/Account/LoadingRow';
import ErrorMessage from '../../ErrorMessage';
import AddNewNumber from './AddNewNumber';
import SelectExistingNumber from './SelectExistingNumber';

interface IAccountSelectProps {
  loading: boolean
  accounts: IOnlineUserAccount[]
  onAccountSelect(account: IOnlineUserAccount): void
  add(ani, verificationMethod): Promise<IOnlineUserAccount>
  verify(ani, verificationCode): Promise<IApiSuccessResponse<IOnlineUserAccount>>
  resendVerification(account: IOnlineUserAccount, verificationMethod): Promise<IOnlineUserAccount>
  addingNewAccount: boolean
  verifyingAccount: boolean
  onAdded(account: IOnlineUserAccount): any
  selectedAccount: IOnlineUserAccount
}

const AccountSelect: React.FC<IAccountSelectProps> = props => {
  const [verifyingAccount, verifyAccount] = useState<IOnlineUserAccount>(null)
  const [addingNewAccount, showAddAccount] = useState(false)
  const [ani, addAni] = useState('')
  const [resendVerificationCode, showResendVerificationCode] = useState(false)

  return (
    <div className="collectcall service mainwindow border rounded border-action">
      <h3 className="product bg-action p-2 py-3 text-white"><span className="icon-button"></span>Prepaid Collect Call</h3>

      {(props.selectedAccount != null && props.selectedAccount.account.accountLinkStatus == AccountLinkStatus.DISABLED) &&
        <div className="m-2">
          <ErrorMessage message="The account you specified has been temporarily disabled. Please contact Reliance Telephone at 1-800-896-3201 between 9am-6pm CST for more information." />
        </div>
      }
      {(props.selectedAccount != null && props.selectedAccount.account.accountLinkStatus == AccountLinkStatus.PAYMENT_HOLD) &&
        <div className="m-2">
          <ErrorMessage message="The account you specified has a payment hold in place and is not available to receive payments at this moment. Please call us at 800-896-3201 between 9am-6pm CST." />
        </div>
      }

      <div className="col-md-10 serv'ice-window offset-md-1 d-flex align-items-center justify-content-center">
        <div className="maincontent w-100">
          {props.loading &&
            <div>
              <h4 className="mb-0 pt-3">Loading accounts...</h4>
              <LoadingRow />
            </div>
          }
          {props.accounts && (
            <>
              <SelectExistingNumber
                onSelect={props.onAccountSelect}
                onVerify={verifyAccount}
                accounts={props.accounts} />
              <h5 className="mb-0 pt-3 pb-5">-OR-</h5>
            </>
          )}

          <AddNewNumber
            onAddAni={newAni => {
              addAni(newAni);
              showAddAccount(true);
            }} />

          {(addingNewAccount || verifyingAccount) &&
            <AddModal
              ani={ani}
              pendingAccount={verifyingAccount}
              resendVerificationCode={resendVerificationCode}
              showResendVerificationCode={() => showResendVerificationCode(!resendVerificationCode)}
              onCancel={() => {
                showAddAccount(false)
                verifyAccount(null)
              }}
              onSubmit={(data) => {
                if (data.resendVerification && data.resendVerification.method) {
                  return props
                    .resendVerification(verifyingAccount, data.resendVerification.method)
                    .then(() => showResendVerificationCode(false))
                    .catch(err => ({ [FORM_ERROR]: err.error ? err.error : err }));
                }

                if (verifyingAccount == null) {
                  return props
                    .add(data.account.ani, data.verifyAccount.method)
                    .then(res => verifyAccount(res))
                    .catch(err => ({ [FORM_ERROR]: err.error ? err.error : err }));
                }

                return props
                  .verify(verifyingAccount.ani, data.verifyAccount.code)
                  .then(res => {
                    if (res.success == false) {
                      return { [FORM_ERROR]: 'Invalid verification code' };
                    }
                    props.onAdded(res.data);
                    return res;
                  })
                  .catch(err => ({ [FORM_ERROR]: err.error ? err.error : err }))
              }} />
          }
        </div>
      </div>
    </div>
  );
}

export default AccountSelect;
