import React from 'react';
import { Link } from 'react-router-dom';
import { Facility, IFacility } from '../../../models/Facility';
import { IPhoneCardPurchase } from '../../../models/PhoneCard';
import NameAndAddress from '../../Facility/NameAndAddress';
import Currency from '../../Format/Currency';

interface IConfirmationProps {
  facility: IFacility
  purchase: IPhoneCardPurchase
  receiptEmailAddress: string
}

const Confirmation: React.FC<IConfirmationProps> = props =>
  <main className="container align-items-top justify-content-center mt-5 mt-lg-3 pt-4 pb-4">
    <section className="no-gutters">
      <div className="main row">
        <div className="col-md-6 offset-md-3 mb-3 ">
          <h3 className="text-center">Transaction complete</h3>
          {props.receiptEmailAddress &&
            <p className="bodysm text-center">A receipt has been emailed to {props.receiptEmailAddress}</p>
          }
        </div>
        <div className="col-md-6 offset-md-3 mb-3 rt-status receipt">
          <aside className="bg-light text-dark rounded p-3 py-5 p-lg-5">
            <h3 className="text-action text-center">Your purchase:</h3>

            <div className="selected-service py-lg-2">
              <h5 className="text-center">Phone Card</h5>
              <div className="checkout-fees">
                <p className="total"><Currency value={props.purchase.summary.subTotal}/></p>
                <p className="transaction-fee"><Currency value={props.purchase.summary.transactionFee}/></p>
                <p className="taxes"><Currency value={props.purchase.summary.tax}/></p>
                <p className="total-charge"><Currency value={props.purchase.summary.total}/></p>
              </div>

              {!props.purchase.facility.privatePin && props.purchase.onlineOrderItems[0].phoneCards.map(c =>
                <div className="alert alert-primary text-center pb-0" role="alert" key={`phone-card-pin-${c.pin}`}>
                  <p>Your PIN: {c.pin}</p>
                </div>
              )}

              <div className="bodysm">
                <p className="bodysm">
                  {props.purchase.onlinePinInstructions}
                </p>
                {!props.purchase.facility.privatePin &&
                  <p className="bodysm">
                    Treat the PIN you receive at checkout as cash and share it with the inmate in a secure manner - do not leave it on the inmate voicemail. Be sure to write down or print the PIN as we CANNOT access it for you later.
                  </p>
                }
                <p className="bodysm">
                  Displayed rates are for domestic (US) calls only. Actual rates may vary by number/country called. Remaining phone card balances can be transferred to Inmate Wallet. Reliance is not responsible for unauthorized use and we cannot reimburse you for such use.   
                  </p>
              </div>
            </div>
            <hr className="border-muted"/>
            <div className="selected-facility py-lg-2">
              <h5>For inmate:</h5>
              <p className="subtitle subhead inmate-name">
                {props.purchase.inmateFirstName} {props.purchase.inmateLastName}
              </p>
            </div>
            <hr className="border-muted"/>
            <div className="selected-facility py-lg-2">
              <h5>At Facility:</h5>
              <NameAndAddress facility={new Facility(props.facility)} />
            </div>
          </aside>
        </div>
        <div className="col-md-6 offset-md-3 ">
          <button className="btn btn-block btn-outline-secondary btn-lg text-secondary py-2 my-3 print" type="submit" onClick={() => window.print()}>
            <strong>Print Receipt</strong>
          </button>

          <Link className="btn btn-action btn-block btn-lg text-white py-3" to="/account/dashboard"><strong>View account dashboard</strong></Link>
        </div>
      </div>
    </section>
  </main>

export default Confirmation
