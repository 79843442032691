import React from 'react';
import { requiredWithMessage } from '../../../validation';
import { renderCheckboxInput } from '../../Form/RenderForm';

const Agreement: React.FC = () =>
  <div className="alert alert-secondary" role="alert">
    <p className="bodysm">
    All purchases are final and no replacement inmate phone cards, refunds, or credits will be given once purchased.
    Reliance is not responsible for unauthorized use and we cannot reimburse you for such use.
    </p>
    <hr/>
    {renderCheckboxInput(
      'checkout.card.agreement',
      'checkout.card.agreement',
      'I understand and confirm all information is correct',
      [requiredWithMessage('You must agree before submitting')]
    )}
  </div>

export default Agreement;
