import * as Sentry from "@sentry/react";
import { Integrations } from '@sentry/tracing';

const ignoreUrlsWithResponseCodes = {
  '/checkout/wallet': [
    402 // Payment declined. Message should be displayed to user.
  ],
  '/checkout/card': [
    402 // Payment declined. Message should be displayed to user.
  ],
  '/checkout/collect': [
    402, // Payment declined. Message should be displayed to user.
    409, // Account already linked. Message should be displayed to user.
  ],
  '/login': [
    409 // Email address is taken. Message should be displayed to user.
  ]
}

// Configure sentry for production
if (process.env.NODE_ENV == 'production') {
  Sentry.init({
    dsn: 'https://f928504d059e474286f89d48145068a1@o36100.ingest.sentry.io/5681188',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.APP_ENV || 'development',
    release: 'website@' + process.env.WEBSITE_VERSION,
    normalizeDepth: 5,
    beforeSend(event) {
      const contexts = event.contexts as any;

      let sendToSentry = true;
      if (contexts && contexts.data && contexts.data.statusCode) {
        sendToSentry = Object.keys(ignoreUrlsWithResponseCodes).reduce((prev: boolean, urlKey: string) => {
          if (window.location.pathname.indexOf(urlKey) > -1) {
            return prev && ignoreUrlsWithResponseCodes[urlKey].indexOf(contexts.data.statusCode) == -1;
          }
          return prev;
        }, true);
      }

      if (!sendToSentry) {
        return null;
      }

      event.breadcrumbs = (event.breadcrumbs||[]).filter(b =>
        // Filter out redux logging messages
        b.data == null || typeof b.data.arguments != 'object' || b.data.arguments.length < 3 || (
          b.data.arguments[1].indexOf('color: #') !== 0
        )
      )
      return event;
    }
  });
}

export default Sentry;
