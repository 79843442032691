import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IAppState } from '../reducers';
import * as onlineUserResource from '../resources/OnlineUser';

export const LOADING_RECENT_ACTIONS = 'DASHBOARD/LOADING_RECENT_ACTIONS';
export const LOAD_RECENT_ACTIONS_SUCCESS = 'DASHBOARD/LOAD_RECENT_ACTIONS_SUCCESS';
export const LOAD_RECENT_ACTIONS_FAILURE = 'DASHBOARD/LOAD_RECENT_ACTIONS_FAILURE';
export function loadRecentActions(): ThunkAction<Promise<onlineUserResource.IRecentActions>, IAppState, undefined, Action> {
  return dispatch => {
    dispatch({ type: LOADING_RECENT_ACTIONS });

    return onlineUserResource.getRecentActions()
      .then(response => {
        dispatch({ type: LOAD_RECENT_ACTIONS_SUCCESS, recentActions: response });
        return response;
      })
      .catch(error => {
        dispatch({ type: LOAD_RECENT_ACTIONS_FAILURE, error });
        throw error;
      })
  }
}