import { IOnlineUser } from '../models/OnlineUser';
import Api from '../services/api';

export interface IToken {
  access_token: string,
  expires_in: number,
  expires?: Date
  refresh_token: string,
  onlineUser: IOnlineUser,
  error?: any,
  systemTimezone: string,
  tenantTimezone: string
}

export function postToken(email: string, password: string) {
  return Api
    .post<IToken>({
      url: '/auth/token',
      data: {
        username: 'OnlineUser|'+email,
        password,
        grant_type: 'password',
        client_id: 'website'
      }
    });
}
