import React from 'react';
import { Modal } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { IOnlineUserAccount } from '../../../models/OnlineUserAccount';
import { required } from '../../../validation';
import ErrorMessage from '../../ErrorMessage';
import { ButtonSize, renderActionSubmit, renderButtonGroup, renderSecondarySubmit } from '../../Form/RenderForm';
import PhoneNumber from '../../Format/PhoneNumber';
import DetailsForm from './DetailsForm';
import Verify from './Verify';

interface ILinkAccountAccountData {
  ani: string
}

interface ILinkAccountVerifyData {
  method?: 'Text' | 'Call'
  code?: string
}

export interface ILinkAccountData {
  account: ILinkAccountAccountData
  verifyAccount: ILinkAccountVerifyData
  resendVerification: ILinkAccountVerifyData
}

interface IAddModalProps {
  ani?: string // Allow sending in a preselected ANI
  pendingAccount: IOnlineUserAccount
  onCancel: () => void
  onSubmit: (data: ILinkAccountData) => Promise<any>
  resendVerificationCode: boolean
  showResendVerificationCode: () => void
  isDuplicate?: boolean
}

const AddModal: React.FC<IAddModalProps> = props =>
  <Modal show={true} onHide={props.onCancel}>
    <Form
      initialValues={{ account: { ani: props.ani } }}
      keepDirtyOnReinitialize={true}
      onSubmit={props.onSubmit}>
      {form =>
        <form onSubmit={form.handleSubmit} method="post">
          <Modal.Header closeButton>
            <Modal.Title>
              {props.pendingAccount && <span>Verify <PhoneNumber value={props.pendingAccount.ani}/></span>}
              {props.pendingAccount == null && <span>Add new account</span>}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {form.submitError &&
              <ErrorMessage
                httpCodeMessages={{
                  403: 'Too many failed verification attempts',
                  409: 'This account is already linked'
                }}
                error={form.submitError} />
            }

            {props.pendingAccount == null && !props.isDuplicate &&
              <DetailsForm newAccount={true} />
            }

            {!props.resendVerificationCode && props.pendingAccount &&
              <Verify
                account={props.pendingAccount}
                showResendVerificationCode={props.showResendVerificationCode}
                />
            }

            {props.resendVerificationCode &&
              <div className="form-label-group">
                <div>
                  {renderButtonGroup(
                    'resendVerification.method',
                    'resendVerification.method',
                    'Verification method',
                    [
                      { variant: 'light', value: 'Text', label: 'Text/SMS' },
                      { variant: 'light', value: 'Call', label: 'Phone Call' }
                    ],
                    [required]
                  )}
                  <p className="small text-dark">We will send a verification code to your number to verify ownership</p>
                </div>
                {renderActionSubmit(form, 'Send new code', 'Sending...')}
              </div>
            }
          </Modal.Body>

          <Modal.Footer>
            {renderSecondarySubmit(form, 'Cancel', 'Cancel', ButtonSize.Default, props.onCancel)}
            {renderActionButton(form, props)}
          </Modal.Footer>
        </form>
      }
    </Form>
  </Modal>

const renderActionButton = (form, props) => {
  if (props.isDuplicate) {
    return renderActionSubmit(form, 'Make a payment')
  }
  else if (!props.resendVerificationCode) {
    return renderActionSubmit(form,
      props.pendingAccount ? 'Verify' : 'Send verification code',
      props.pendingAccount ? 'Verifying...' : 'Sending...'
    )
  }
  return null;
};

export default AddModal