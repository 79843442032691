import { ICardTypeAvailable } from '../models/PhoneCard';
import Api from '../services/api';

export function getAvailableCardTypes(facilityId: number) {
  return Api
    .get({
      url: '/phone-card/available-card-types',
      query: {facilityId}
    })
    .then((res:ICardTypeAvailable[]) => res);
}
