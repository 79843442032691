import React from 'react';
import { Link } from 'react-router-dom';
import { IOnlineUser } from '../../../models/OnlineUser';

interface IAccountDetailsProps {
  user: IOnlineUser
}

const AccountDetails: React.FC<IAccountDetailsProps> = props =>
  <div className="row">
    <div className="col">
      <h4>Your Account Details</h4>
      <div className="row text-dark">
        <div className="col-md-8">
          <p className="m-0">{props.user.firstName} {props.user.lastName}</p>
          <p className="m-0">{props.user.emailAddress}</p>
        </div>
        <div className="col-md-4">
          <Link className="btn btn-outline-secondary btn-block my-lg-0 my-4" to="/account/settings">View or edit details</Link>
        </div>
      </div>
    </div>
  </div>

export default AccountDetails;
