import React from 'react';
import PageTitle from '../components/PageTitle';

const Troubleshooting: React.FC = () =>
  <div>
    <PageTitle title="Troubleshooting" />

    <div className="hero bg-primary text-light pt-5">
          <div className="container">
            <div className="row pt-5 pb-4">
              <div className="col-lg-8 offset-lg-2 pt-5">
                <h1 className="d-none">Reliance Connect Video Call Troubleshooting</h1>
                <span className="page-icon rt-faq"></span>
                <h3>Reliance Connect<br/>
                  Video Call Troubleshooting</h3>
                <h4 className="py-3">See below for a list of tips on using the Reliance Connect App for Video Calls with your friends and family.</h4>
              </div>
            </div>
          </div>
        </div>


    <main className="container">
      <br></br>
         <p>
        <li> Have the App open prior to receiving the call to ensure no delays. If the device is locked or sleeping having to sign into the app can cause delays that lead to issues with answering calls.</li>
         </p>
         <p>
         <li>If you are having issues getting connected while only on cellular data, try connecting to a WiFi network. You can put your device in airplane mode, with WiFi on, to ensure the connection is attempted over WiFi. If you are already on WiFi and having issues, try turning your devices WiFi off, and attempt the connection on cellular data (good LTE coverage for best performance).</li>
         </p>
         <p>
         <li> On an iPhone/iOS device close and reopen the app.   To do this you can double tap the home button or swipe up if there is not a home button and then slide up on the apps that you want to close out.</li>
         </p>
         <p>
         <li> On an Android device Force quit and restart the application. To do this on Android you go to Settings, Apps, Reliance Connect. From there you hit “Force Stop”. This will stop any of the application’s sub processes that may be running on the phone.</li>
         </p>
         <p>
         <li> Logout of the app and back in.  To do this open up Reliance Connect and click the gear icon in the upper right, then click sign out.</li>
         </p>
         <p>
         <li> Ensure that you are connected to the call server. You can reconnect to the call server by hitting the cog wheel icon and then hitting reconnect to call server. The status of the connection will display on the home screen of the app right next to the cog wheel.</li>
         </p>     
         <p>
         <li> Power cycle your device by restarting it.</li>
         </p>
         <p>
         <li> Check for App updates in the App Store or Google Play. </li>
         </p>
         <p>
         <li> Try the app on a different phone, tablet, or device.  If your phone number is registered, the app can be used on any device with your phone number and account. You can even try a different number if it is registered. </li>
         </p>
         <p>
         <li> Uninstall/reinstall the App.</li>
         </p>
    </main>
  </div>

export default Troubleshooting;
