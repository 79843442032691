import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Facility, IFacility } from '../../models/Facility';
import { IAppState } from '../../reducers';

interface INameAndAddressProps {
  facility: Facility|IFacility
  showAddress?: boolean
  oneLine?: boolean
}

const NameAndAddress: React.FC<INameAndAddressProps> = props => {
  // Make sure we have a fully populated facility
  let facility = props.facility;
  let urlName = '';
  if (facility.configurations && facility.getConfigurationValue == null) {
    facility = new Facility(facility);
    urlName = facility.getConfigurationValue('UrlName');
  } else if (facility.configurations == null) {
    const commonState = useSelector((appState: IAppState) => appState.common);

    useEffect(() => {
      // Load the URL name from the facility in the fully facility list if this was a rehydrated facility without methods
      if (commonState.facilities && props.facility.getConfigurationValue == null) {
        const facilityFromState = commonState.facilities.filter(f => f.id == props.facility.id).pop();
        if (facilityFromState) {
          urlName = facility.getConfigurationValue['UrlName'];
        }
      }
    }, [commonState.facilities]);
  } else {
    urlName = facility.getConfigurationValue('UrlName');
  }

  if (props.oneLine) {
    return (
      <span>
        {urlName ?
          <span>
            <a target="_blank" href={`/facility/${facility.state.toLowerCase()}/${urlName}`}>
              {facility.name}
            </a> ({facility.city}, {facility.state})
          </span> :
          <span>{facility.name} ({facility.city}, {facility.state})</span>
        }
      </span>
    );
  }

  return (
    <>
      <p className="subtitle subhead facility">
        {urlName ?
          <a target="_blank" href={`/facility/${facility.state.toLowerCase()}/${urlName}`}>
            {facility.name}
          </a> :
          <span>{facility.name}</span>
        }
      </p>
      {props.showAddress !== false &&
        <address className="mb-0 pb-0">
          {facility.address}<br/>
          {facility.city}, {facility.state} {facility.zip}
        </address>
      }
    </>
  );
}

export default NameAndAddress;