import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import * as checkoutActions from '../../actions/checkout';
import { Service as ServiceType } from '../../reducers/checkout';

interface ICollectCallCostModalProps extends RouteComponentProps {
  show: boolean
  onHide: () => void
}

const CollectCallCostModal: React.FC<ICollectCallCostModalProps> = props => {
  const dispatch = useDispatch();

  return (
    <Modal show={props.show} size="lg" onHide={props.onHide}>
      <Modal.Header closeButton />
      <Modal.Body className="p-5">
        <h3>Prepaid Collect Calls</h3>
        <p>To keep costs low, each incoming call must be accepted by the owner of the specific telephone line account. For those customers who don’t have a computer or smartphone access, our customer service center is available to provide assistance by calling 1-800-896-3201.</p>
        <p className="font-weight-bold mb-0"><u>Collect Local Calls</u></p>
        <p>$0.21 - $0.25 per minute + applicable taxes (Rate may vary depending on facility)</p>
        <p className="font-weight-bold mb-0"><u>Collect In-State Calls</u></p>
        <p>$0.21 - $0.40 per minute + applicable taxes (Rate may vary depending on facility)</p>
        <p className="font-weight-bold mb-0"><u>Collect State to State Calls</u></p>
        <p>$0.21 per minute + applicable taxes + Federal Universal Service Fund</p>

        <button className="btn btn-lg btn-action btn-block mt-5" onClick={() => {
          props.onHide();
          dispatch(checkoutActions.selectService(ServiceType.Collect));
          props.history.push('/checkout/collect');
        }}>
          Set up a prepaid call account
        </button>
      </Modal.Body>
    </Modal>
  )
}

export default withRouter(CollectCallCostModal);
