import React from 'react';
import { IFacility } from '../../../models/Facility';
import { IWalletPaymentData } from '../../../models/WalletPayment';
import PageTitle from '../../PageTitle';
import Navigation from '../Navigation';
import { CheckoutStep } from '../Service';
import InfoBox from './InfoBox';

interface IContainerProps {
  facility: IFacility
  paymentData: IWalletPaymentData
  fees?: number
  taxes?: number
  loadingTaxes?: boolean
  onResetService: () => void
}

const Container: React.FC<IContainerProps> = props =>
  <>
    <PageTitle title="Inmate Wallet" />
    <Navigation step={CheckoutStep.Service} onResetService={props.onResetService} />

    <main className="container align-items-top justify-content-center mt-3 pb-4">
      <section className="no-gutters">
        <div className="main row">
          <InfoBox
            facility={props.facility}
            inmate={props.paymentData ? props.paymentData.inmate : null}
            cost={(props.paymentData && props.paymentData.amount) ? parseFloat(props.paymentData.amount.toString()) : null}
            fees={props.fees}
            taxes={props.taxes}
            loadingTaxes={props.loadingTaxes}
            />

          <div className="col-md-8 mb-3">
            {props.children}
          </div>
        </div>
      </section>
    </main>
  </>

export default Container;
