export interface ICreditCard {
  id: number
  nameOnCard: string
  expMonth: string
  expYear: string
  expired: boolean
  lastFour: string
  type: string
  address: string
  city: string
  state: string
  zip: string
  fraudControlStatus: FraudControlStatus
}

// Represents a new credit card
export interface ICreditCardData {
  id?: number
  nameOnCard?: string
  cardNumber?: string
  expirationDate?: string
  expMonth?: string
  expYear?: string
  cardSecurityCode: string
  address?: string
  city?: string
  state?: string
  zip?: string
  save?: boolean
}

// Represents an updated credit card
export interface ICreditCardUpdateData {
  id: number
  nameOnCard?: string
  cardNumber?: string
  expirationDate?: string
  expMonth?: string
  expYear?: string
  address?: string
  city?: string
  state?: string
  zip?: string
}

export enum FraudControlStatus {
  Normal = 1,
  Warning = 2,
  Hold = 3
}