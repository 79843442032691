import React from 'react';
import withSideEffect from 'react-side-effect';

const titleSuffix = `Reliance Telephone`;

function reducePropsToState(propsList) {
  var innermostProps = propsList[propsList.length - 1];
  if (innermostProps) {
    return innermostProps.title;
  }
}

function handleStateChangeOnClient(title) {
  var nextTitle = title ? `${title} | ${titleSuffix}` : titleSuffix;

  if (nextTitle !== document.title) {
    document.title = nextTitle;
  }
}

interface IPageTitleProps {
  title: string
  children: any
}

const PageTitle: React.FC<IPageTitleProps> = props =>
  props.children ? React.Children.only(props.children) : null

export default withSideEffect(
  reducePropsToState,
  handleStateChangeOnClient
)(PageTitle);