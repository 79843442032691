import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IApiSuccessResponse } from '../models/Api';
import { IWebsiteBaseData, IWebsiteContactData } from '../models/Website';
import { IAppState } from '../reducers';
import * as websiteResource from '../resources/Website';

export const CONTACT_PROCESSING = 'WEBSITE/CONTACT_PROCESSING';
export const CONTACT_SUCCESS = 'WEBSITE/CONTACT_SUCCESS';
export const CONTACT_FAILURE = 'WEBSITE/CONTACT_FAILURE';
export function processContact(
  contactData: IWebsiteContactData
): ThunkAction<Promise<IApiSuccessResponse<any>>, IAppState, null, Action> {
  return dispatch => {
    dispatch({ type: CONTACT_PROCESSING, contactData });

    return websiteResource
      .postContact(contactData)
      .then(res => {
        if (res == null || !res.success) {
          throw new Error('Invalid response from server');
        }

        dispatch({ type: CONTACT_SUCCESS });
        return res;
      })
      .catch(error => {
        dispatch({ type: CONTACT_FAILURE, error: error.message });
        throw error;
      });
  }
}

export const BASE_DATA_LOADING = 'WEBSITE/BASE_DATA_LOADING';
export const BASE_DATA_LOAD_FAILURE = 'WEBSITE/BASE_DATA_LOAD_FAILURE';
export const BASE_DATA_LOAD_SUCCESS = 'WEBSITE/BASE_DATA_LOAD_SUCCESS';
export function loadBaseData(): ThunkAction<Promise<IWebsiteBaseData>, IAppState, null, Action> {
  return dispatch => {
    dispatch({ type: BASE_DATA_LOADING });

    return websiteResource
      .getIndex()
      .then(res => {
        dispatch({
          type: BASE_DATA_LOAD_SUCCESS,
          facilities: res.facilities,
          feeTable: res.feeTable
        });

        return res;
      })
      .catch(error => {
        dispatch({ type: BASE_DATA_LOAD_FAILURE, error });
        return error;
      });
  }
}