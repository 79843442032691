import React from 'react';
import { Link } from 'react-router-dom';
import { CheckoutStep } from './Service';

interface INavigationProps {
  step: CheckoutStep
  onResetService?: () => void
}

function tabClass(tabStep: CheckoutStep, currentStep: CheckoutStep) {
  switch (tabStep) {
    case CheckoutStep.Facility:
      return currentStep == CheckoutStep.Facility ? 'stepfocus' : 'steppast';
    case CheckoutStep.Service:
      return currentStep == CheckoutStep.Service ? 'stepfocus' : (
        currentStep == CheckoutStep.Facility ? 'stepfuture' : 'steppast'
      );
    case CheckoutStep.Checkout:
      return currentStep == CheckoutStep.Checkout ? 'stepfocus' : 'stepfuture';
  }
}

const Navigation: React.FC<INavigationProps> = props =>
  <section className="pt-5 pt-lg-4 pb-lg-3 mt-3">
    <div className="container">
      <h1 className="d-none">Select Reliance Service</h1>
      <div className="wayfinding row no-gutters text-light align-items-center mt-2">
        <div className={"step-1 col-3 col-md-4 text-center " + tabClass(CheckoutStep.Facility, props.step)}>
          <p className="py-0">
            {props.step != CheckoutStep.Facility ?
              <Link to="/checkout/facility">Step 1.<span> Find Facility</span></Link> :
              <>Step 1. <span>Find Facility</span></>
            }
          </p>
        </div>
        <div className={"step-3 col-6 col-md-4 text-center " + tabClass(CheckoutStep.Service, props.step)}>
          <p className="py-0">
            {props.step != CheckoutStep.Facility ?
              <Link to="/checkout/service" className="text-white" onClick={props.onResetService}>Step 2.<span> Select Service</span></Link> :
              <>Step 2. <span>Select Service</span></>
            }
          </p>
        </div>
        <div className={"step-3 col-3 col-md-4 text-center " + tabClass(CheckoutStep.Checkout, props.step)}>
          <p className="py-0">
            Step 3.<span> Checkout</span>
          </p>
        </div>
      </div>
    </div>
  </section>

export default Navigation;
